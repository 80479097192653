import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SYSTEM_SETTINGS } from '@wx/common';

import SystemSettingsApi from '../../../api/systemSettings/systemSettings';
import { settingsFetchErrorText } from '../../../util/longText';
import { displaySimpleErrorModal } from '../../global/ModalController';
import SettingConfiguration from './SettingConfiguration';

const SystemSettings = () => {
    const dispatch = useDispatch();
    const [settings, setSettings] = useState({ [SYSTEM_SETTINGS.OVERNIGHT_RATE]: '0' });

    useEffect(() => {
        fetchSystemSettings();
    }, []);

    const fetchSystemSettings = () => {
        SystemSettingsApi.getSystemSettings()
            .then((data) => {
                // Assign values from fetched settings to state
                const stateSettings = { ...settings };
                data.forEach((dbSetting) => {
                    stateSettings[dbSetting.key] = dbSetting.value;
                });
                setSettings({ ...stateSettings });
            })
            .catch(() => {
                displaySimpleErrorModal(dispatch, settingsFetchErrorText);
            });
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {Object.entries(settings).map(([setting, value]) => (
                <SettingConfiguration
                    key={`setting-configuration-${setting}`}
                    settingName={setting}
                    settingValue={value}
                    isDollarValue={setting === SYSTEM_SETTINGS.OVERNIGHT_RATE}
                    onSettingUpdate={(newVal) => setSettings({ ...settings, [setting]: newVal })}
                />
            ))}
        </div>
    );
};
export default SystemSettings;
