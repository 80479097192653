import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PayTypesApi from '../../api/rateProfiles/payTypes';
import ServiceTypesApi from '../../api/rateProfiles/serviceTypes';
import { DEVICE_TYPE } from '../../store/constants/appActionTypes';
import { customerRateFetchDataErrorText, driverRateFetchDataErrorText } from '../../util/longText';
import { displaySimpleErrorModal } from '../global/ModalController';
import RateType from './RateType';

const RateTypes = () => {
    const deviceType = useSelector((state) => state.app.device);
    const dispatch = useDispatch();

    const [employeeRateTypes, setEmployeeRateTypes] = useState([]);
    const [customerRateTypes, setCustomerRateTypes] = useState([]);

    const TYPES = [
        {
            type: 'customer',
            data: [...customerRateTypes],
        },
        {
            type: 'employee',
            data: [...employeeRateTypes],
        },
    ];

    useEffect(() => {
        getRateTypeData();
    }, []);

    const getRateTypeData = async () => {
        PayTypesApi.getPayTypes('', true, 0, 1000)
            .then((employeeRateTypesData) => {
                setEmployeeRateTypes(employeeRateTypesData.data);
            })
            .catch(() => displaySimpleErrorModal(dispatch, driverRateFetchDataErrorText));

        ServiceTypesApi.getServiceTypes('', true, 0, 1000)
            .then((customerRateTypesData) => {
                setCustomerRateTypes(customerRateTypesData.data);
            })
            .catch(() => displaySimpleErrorModal(dispatch, customerRateFetchDataErrorText));
    };

    return (
        <div
            className={`rate-types__outer-container${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
        >
            {TYPES.map((type) => (
                <RateType
                    key={`rate-type-${type.type}`}
                    type={type.type}
                    data={type.data}
                    refetchData={getRateTypeData}
                />
            ))}
        </div>
    );
};
export default RateTypes;
