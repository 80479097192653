import cookie from 'react-cookies';

export const isLoggedIn = () => {
    return cookie.load('accessToken') !== undefined;
};

export const removeCachedToken = () => {
    cookie.remove('sessionExpiry', { path: '/' });
    cookie.remove('accessToken', { path: '/' });
};

export const setAccessToken = (token, expirySeconds) => {
    const expiryMilliseconds = expirySeconds * 1000;
    const expiryTimeStamp = Date.now() + expiryMilliseconds;

    cookie.save('accessToken', token);
    cookie.save('sessionExpiry', expiryTimeStamp);
};

export const isSessionAboutToExpire = () => {
    // Check if the session expiry time is under 5 mins (300000ms)
    const sessionExpiry = cookie.load('sessionExpiry');
    if (!sessionExpiry) return false;

    const currentTime = Date.now();

    return sessionExpiry - currentTime < 300000;
};

export const hasSessionExpired = () => {
    const sessionExpiry = cookie.load('sessionExpiry');
    if (!sessionExpiry) return true;

    const currentTime = Date.now();

    return sessionExpiry - currentTime < 0;
};

export const loginNotRequiredPaths = [
    '/login',
    '/forgotPassword',
    '/updateEmail',
    '/setPassword',
    '/oauthLogin',
    '/postoauth',
];
