import axios from 'axios';
import { getAuthHeader, getErrorMessage, getHost } from '../../util/apiUtil';
import { shouldSee } from '../../util/permissionsUtil';

const PAY_TYPES_URL = `${getHost()}/pay/types`;

class PayTypesApi {
    static getPayTypes(labelLike = '', includeInactive = true, skip = 0, take = 10) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['PAY_TYPES_READ_MANY'])) {
                console.error('API ERROR | Pay Types: User not permitted');
                reject();
            }

            let queriedUrl = `${PAY_TYPES_URL}?take=${take}&skip=${skip}&includeInactive=${includeInactive}`;
            if (labelLike.length) {
                queriedUrl += `&labelLike=${labelLike}`;
            }

            axios
                .get(queriedUrl, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Pay Types: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static getPayTypeById(id) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['PAY_TYPES_READ'])) {
                console.error('API ERROR | Pay Types: User not permitted');
                reject();
            }

            axios
                .get(`${PAY_TYPES_URL}/${id}`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Pay Types: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static createPayType(payTypeData) {
        return new Promise((resolve, reject) => {
            // TODO: Uncomment when API enabled
            //
            // if (!shouldSee( [''])) {
            //     console.error('API ERROR | Pay Types: User not permitted');
            //     reject();
            // }

            axios
                .post(
                    `${PAY_TYPES_URL}`,
                    { ...payTypeData },
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Pay Types: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static updatePayType(payTypeData) {
        return new Promise((resolve, reject) => {
            // TODO: Uncomment when API enabled
            //
            // if (!shouldSee( [''])) {
            //     console.error('API ERROR | Pay Types: User not permitted');
            //     reject();
            // }

            axios
                .patch(PAY_TYPES_URL, { ...payTypeData }, { headers: getAuthHeader() })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Pay Types: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }
}
export default PayTypesApi;
