import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PulseLoader from 'react-spinners/PulseLoader';

import {
    displaySimpleErrorModal,
    displaySimpleSuccessModal,
} from '../../component/global/ModalController';
import Breadcrumbs from '../../component/reusable/Breadcrumbs';
import { DEVICE_TYPE } from '../../store/constants/appActionTypes';
import {
    invoiceApprovedErrorText,
    invoiceApprovedSuccessText,
    invoiceDataFetchErrorText,
    invoiceOnHoldErrorText,
    invoiceOnHoldSuccessText,
    invoiceRecalculateErrorText,
    invoiceRecalculateSuccessText,
} from '../../util/longText';
import ReportsApi from '../../api/reports/reports';
import CustomerInvoiceTemplate from '../../component/invoices/CustomerInvoiceTemplate';
import { shouldSee } from '../../util/permissionsUtil';

const BREADCUMB_LINKS = [
    { title: 'All Invoices', href: `${(window.location, origin)}/invoices#customer-invoices` },
    { title: 'Customer Invoice', href: null },
];

const INVOICE_ACTIONS = Object.freeze({ APPROVE: 'approve', HOLD: 'hold' });

const SingleCustomerInvoice = ({ match }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const deviceType = useSelector((state) => state.app.device);
    const invoiceId = match.params.id;

    const [invoiceData, setInvoiceData] = useState(null);

    useEffect(() => {
        fetchInvoiceData();
    }, []);

    const fetchInvoiceData = () => {
        if (invoiceId) {
            ReportsApi.generateCustomerInvoiceJSON(invoiceId)
                .then((data) => {
                    setInvoiceData({ ...data });
                })
                .catch(() => displaySimpleErrorModal(dispatch, invoiceDataFetchErrorText));
        }
    };

    const changeInvoiceStatus = (action) => {
        if (shouldSee(['INVOICE_UPDATE'])) {
            ReportsApi.changeCustomerInvoiceStatus(invoiceId, action)
                .then(() => {
                    const successMsg =
                        action === INVOICE_ACTIONS.APPROVE
                            ? invoiceApprovedSuccessText
                            : invoiceOnHoldSuccessText;
                    displaySimpleSuccessModal(dispatch, successMsg);
                    history.push('/invoices');
                })
                .catch(() => {
                    const errMsg =
                        action === INVOICE_ACTIONS.APPROVE
                            ? invoiceApprovedErrorText
                            : invoiceOnHoldErrorText;
                    displaySimpleErrorModal(dispatch, errMsg);
                });
        }
    };

    const recalculateInvoice = () => {
        if (shouldSee(['INVOICE_UPDATE'])) {
            ReportsApi.recalculateInvoice(invoiceId)
                .then(() => {
                    fetchInvoiceData();
                    displaySimpleSuccessModal(dispatch, invoiceRecalculateSuccessText);
                })
                .catch(() => {
                    displaySimpleErrorModal(dispatch, invoiceRecalculateErrorText);
                });
        }
    };

    return (
        <div
            className={`single-customer-invoice__container${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
        >
            <Breadcrumbs links={BREADCUMB_LINKS} />

            {/* LOADING SPINNER */}
            {!invoiceData ? (
                <div className="centered-container" style={{ margin: '200px 0' }}>
                    <PulseLoader color="#0e76bc" />
                </div>
            ) : (
                <CustomerInvoiceTemplate
                    invoiceData={invoiceData}
                    approveInvoice={() => {
                        changeInvoiceStatus(INVOICE_ACTIONS.APPROVE);
                    }}
                    holdInvoice={() => {
                        changeInvoiceStatus(INVOICE_ACTIONS.HOLD);
                    }}
                    recalculateInvoice={recalculateInvoice}
                />
            )}
        </div>
    );
};
export default SingleCustomerInvoice;
