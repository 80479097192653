import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store/configureStore';

import Routes from './Routes';
import Navbar from './component/layout/Navbar';
import Sidebar from './component/layout/Sidebar';
import ModalController from './component/global/ModalController';
import SessionTimer from './component/global/SessionTimer';
import InfoMessageController from './component/global/InfoMessageController';

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <div className="App">
                    <Navbar />
                    <div className="outer-content">
                        <Sidebar />
                        <Routes />
                        <ModalController />
                        <InfoMessageController />
                        <SessionTimer />
                    </div>
                </div>
            </PersistGate>
        </Provider>
    );
}

export default App;
