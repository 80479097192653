import axios from 'axios';

import { getAuthHeader, getErrorMessage, getHost } from '../../util/apiUtil';
import { shouldSee } from '../../util/permissionsUtil';

const DRIVER_URL = `${getHost()}/driver`;

class DriversApi {
    static getDrivers(nickname = '', includeInactive = true, skip = 0, take = 10) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['DRIVER_READ_MANY'])) {
                console.error('API ERROR | Drivers: User not permitted');
                reject();
            }

            let queriedUrl = `${DRIVER_URL}?take=${take}&skip=${skip}&includeInactive=${includeInactive}`;
            if (nickname.length) {
                queriedUrl += `&nickNameLike=${nickname}`;
            }

            axios
                .get(queriedUrl, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Drivers: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static getDriverById(id) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['DRIVER_READ'])) {
                console.error('API ERROR | Drivers: User not permitted');
                reject();
            }

            axios
                .get(`${DRIVER_URL}/${id}`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Drivers: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static getIncompleteDrivers() {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['DRIVER_READ_MANY'])) {
                console.error('API ERROR | Drivers: User not permitted');
                reject();
            }
            axios
                .get(`${DRIVER_URL}?take=1000000&skip=0&showIncompleteOnly=true`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Drivers: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static createDriver(driverData) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['DRIVER_CREATE'])) {
                console.error('API ERROR | Drivers: User not permitted');
                reject();
            }

            axios
                .post(
                    `${DRIVER_URL}`,
                    { ...driverData },
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Drivers: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static updateDriver(driverData) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['DRIVER_UPDATE'])) {
                console.error('API ERROR | Drivers: User not permitted');
                reject();
            }

            axios
                .patch(DRIVER_URL, { ...driverData }, { headers: getAuthHeader() })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Drivers: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static archiveDriver(driverId) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['DRIVER_DELETE'])) {
                console.error('API ERROR | Drivers: User not permitted');
                reject();
            }

            axios
                .delete(`${DRIVER_URL}/${driverId}`, { headers: getAuthHeader() })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Drivers: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }
}

export default DriversApi;
