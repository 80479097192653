import axios from 'axios';
import { getAuthHeader, getErrorMessage, getHost } from '../../util/apiUtil';
import { shouldSee } from '../../util/permissionsUtil';

const REPORT_URL = `${getHost()}/report`;
const PAYROLL_URL = `${getHost()}/payroll`;
const PAYROLL_REPORT_URL = `${getHost()}/report/payroll/generate`;
const SALES_REPORT_URL = `${getHost()}/report/sales/generate`;
const CUSTOMER_INVOICE_URL = `${getHost()}/report/customerInvoice`;
const DELIVERY_CHARGE_URL = `${getHost()}/report/deliveryChargeReports`;

class ReportsApi {
    static generatePayrollReport(reportDetails) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['PAYROLL_GENERATE'])) {
                console.error('API ERROR | Reports: User not permitted');
                reject();
            }

            axios
                .post(
                    PAYROLL_REPORT_URL,
                    { ...reportDetails },
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Reports: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static generateSalesReport(reportDetails) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['SALES_GENERATE'])) {
                console.error('API ERROR | Reports: User not permitted');
                reject();
            }

            axios
                .post(
                    SALES_REPORT_URL,
                    { ...reportDetails },
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Reports: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static generateCustomerInvoiceCSV(customerId, startDate, endDate) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['INVOICE_READ_MANY'])) {
                console.error('API ERROR | Reports: User not permitted');
                reject();
            }

            axios
                .get(
                    `${CUSTOMER_INVOICE_URL}CSV/${customerId}?startDate=${startDate}&endDate=${endDate}`,
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    // Service will return 418 if no invoices found in selected date range
                    if (
                        error &&
                        error.response &&
                        error.response.status &&
                        error.response.status === 418
                    ) {
                        resolve(null);
                        return;
                    }

                    console.error(`API ERROR | Reports: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static generateCustomerInvoiceJSON(customerInvoiceId) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['INVOICE_READ_MANY'])) {
                console.error('API ERROR | Reports: User not permitted');
                reject();
            }

            axios
                .get(`${CUSTOMER_INVOICE_URL}JSON/${customerInvoiceId}`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Reports: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static getCustomerInvoices(
        skip = 0,
        take = 10,
        invoiceStatusFilter = null,
        ignoreInvoiceStatusFilter = null
    ) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['INVOICE_READ_MANY'])) {
                console.error('API ERROR | Reports: User not permitted');
                reject();
            }

            let queriedUrl = `${CUSTOMER_INVOICE_URL}s?take=${take}&skip=${skip}`;
            if (invoiceStatusFilter) {
                queriedUrl += `&invoiceStatusFilter=${invoiceStatusFilter}`;
            } else if (ignoreInvoiceStatusFilter) {
                queriedUrl += `&ignoreInvoiceStatusFilter=${ignoreInvoiceStatusFilter}`;
            }

            axios
                .get(queriedUrl, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Reports: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static changeCustomerInvoiceStatus(customerInvoiceId, action) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['INVOICE_UPDATE'])) {
                console.error('API ERROR | Reports: User not permitted');
                reject();
            }

            axios
                .post(
                    `${CUSTOMER_INVOICE_URL}/${customerInvoiceId}?action=${action}`,
                    {},
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Reports: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static recalculateInvoice(customerInvoiceId) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['INVOICE_UPDATE'])) {
                console.error('API ERROR | Reports: User not permitted');
                reject();
            }

            axios
                .patch(
                    `${CUSTOMER_INVOICE_URL}/${customerInvoiceId}`,
                    {},
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Reports: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static sendInvoice(customerInvoiceId) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['INVOICE_CREATE'])) {
                console.error('API ERROR | Reports: User not permitted');
                reject();
            }

            axios
                .post(
                    `${CUSTOMER_INVOICE_URL}/send/${customerInvoiceId}`,
                    {},
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Reports: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static getDeliveryChargeReports(customerId) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['DELIVERY_CHARGE_GENERATE'])) {
                console.error('API ERROR | Reports: User not permitted');
                reject();
            }

            let URL = DELIVERY_CHARGE_URL;
            if (customerId) {
                URL += `?customerId=${customerId}`;
            }

            axios
                .get(URL, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Reports: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static getReportEmailConfigs() {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['USER_MANAGE_UPDATE'])) {
                console.error('API ERROR | Reports: User not permitted');
                reject();
            }

            axios
                .get(`${REPORT_URL}/emailConfigs`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Reports: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static addReportEmailConfigs(userId, reportType) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['USER_MANAGE_UPDATE'])) {
                console.error('API ERROR | Reports: User not permitted');
                reject();
            }

            axios
                .post(
                    `${REPORT_URL}/emailConfigs`,
                    {
                        userId,
                        reportType,
                    },
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Reports: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static removeReportEmailConfigs(configId) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['USER_MANAGE_UPDATE'])) {
                console.error('API ERROR | Reports: User not permitted');
                reject();
            }

            axios
                .delete(`${REPORT_URL}/emailConfigs/${configId}`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Reports: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static generateEmployeePayrollCSV(employeePayrollId) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['PAYROLL_READ'])) {
                console.error('API ERROR | Reports: User not permitted');
                reject();
            }

            axios
                .get(`${PAYROLL_URL}/csv/${employeePayrollId}`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Reports: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }
}
export default ReportsApi;
