import { Badge, Collapse } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTotalTodoCount } from '../../../store/constants/todoActionTypes';
import { PERMISSION_CONDITION, shouldSee } from '../../../util/permissionsUtil';
import {
    fetchCustomerInvoicesFailedToSend,
    fetchIncompleteEntities,
    fetchInvoicesNeedingApproval,
    fetchInvoicesOnHold,
    fetchTrucksNeedingSupPay,
} from './dataFetcher';

import IncompleteEntities from './IncompleteEntities';
import TodoDetails, { TODO_TYPE } from './ToDoDetails';
import TrucksNeedingSupPay from './TrucksNeedingSupPay';

const { Panel } = Collapse;

const ToDoList = () => {
    const dispatch = useDispatch();
    const incompleteEntities = useSelector((state) => state.todo.incompleteEntities);
    const invoicesNeedingApproval = useSelector((state) => state.todo.invoicesNeedingApproval);
    const trucksNeedingSupPay = useSelector((state) => state.todo.trucksNeedingSupPay);
    const invoicesFailedToSend = useSelector((state) => state.todo.invoicesFailedToSend);
    const invoicesOnHold = useSelector((state) => state.todo.invoicesOnHold);
    const [counts, setCounts] = useState({
        incompleteEntitiesCount: 0,
        invoicesNeedingApprovalCount: 0,
        trucksNeedingSupPayCount: 0,
        invoicesFailedToSend: 0,
        invoicesOnHold: 0,
    });

    const panels = [
        {
            header: (
                <Badge count={counts.incompleteEntitiesCount} size="small" offset={[6, 0]}>
                    Incomplete Entities
                </Badge>
            ),
            key: 'Incomplete Entities',
            component: <IncompleteEntities />,
            visible:
                !!counts.incompleteEntitiesCount &&
                shouldSee(['DRIVER_UPDATE', 'CUSTOMER_UPDATE'], PERMISSION_CONDITION.OR),
        },
        {
            header: (
                <Badge count={counts.invoicesNeedingApprovalCount} size="small" offset={[8, 0]}>
                    Invoices For Approval
                </Badge>
            ),
            key: 'Invoices For Approval',
            component: <TodoDetails type={TODO_TYPE.INVOICES_FOR_APPROVAL} />,
            visible: !!counts.invoicesNeedingApprovalCount && shouldSee(['INVOICE_UPDATE']),
        },
        {
            header: (
                <Badge count={counts.invoicesOnHold} size="small" offset={[8, 0]}>
                    Invoices On Hold
                </Badge>
            ),
            key: 'Invoices On Hold',
            component: <TodoDetails type={TODO_TYPE.INVOICES_ON_HOLD} />,
            visible: !!counts.invoicesOnHold && shouldSee(['INVOICE_UPDATE']),
        },
        {
            header: (
                <Badge count={counts.trucksNeedingSupPayCount} size="small" offset={[8, 0]}>
                    Truck Supplemental Pay
                </Badge>
            ),
            key: 'Truck Supplemental Pay',
            component: <TrucksNeedingSupPay />,
            visible: !!counts.trucksNeedingSupPayCount && shouldSee(['TRUCK_UPDATE']),
        },
        {
            header: (
                <Badge count={counts.invoicesFailedToSend} size="small" offset={[8, 0]}>
                    Failed To Send Invoices
                </Badge>
            ),
            key: 'Failed To Send Invoices',
            component: <TodoDetails type={TODO_TYPE.FAILED_TO_SEND_INVOICES} />,
            visible: !!counts.invoicesFailedToSend && shouldSee(['INVOICE_READ_MANY']),
        },
    ];

    // Fetch TODOs on mount
    useEffect(() => {
        fetchIncompleteEntities(dispatch);
        fetchInvoicesNeedingApproval(dispatch);
        fetchTrucksNeedingSupPay(dispatch);
        fetchCustomerInvoicesFailedToSend(dispatch);
        fetchInvoicesOnHold(dispatch);
    }, []);

    // Update counts on TODOs change
    useEffect(() => {
        const newCounts = { ...counts };
        if (incompleteEntities) {
            newCounts.incompleteEntitiesCount = incompleteEntities.length;
        }
        if (invoicesNeedingApproval) {
            newCounts.invoicesNeedingApprovalCount = invoicesNeedingApproval.length;
        }
        if (trucksNeedingSupPay) {
            newCounts.trucksNeedingSupPayCount = trucksNeedingSupPay.length;
        }
        if (invoicesFailedToSend) {
            newCounts.invoicesFailedToSend = invoicesFailedToSend.length;
        }
        if (invoicesOnHold) {
            newCounts.invoicesOnHold = invoicesOnHold.length;
        }
        setCounts({ ...newCounts });
    }, [
        incompleteEntities,
        invoicesNeedingApproval,
        trucksNeedingSupPay,
        invoicesFailedToSend,
        invoicesOnHold,
    ]);

    return (
        <div className="todo-list__container">
            {getTotalTodoCount() ? (
                <Collapse className="todo-list__collapse-container">
                    {panels.map((panel) =>
                        panel.visible ? (
                            <Panel header={panel.header} key={panel.key}>
                                {panel.component}
                            </Panel>
                        ) : null
                    )}
                </Collapse>
            ) : (
                <div className="heading--smaller">All tasks are complete</div>
            )}
        </div>
    );
};
export default ToDoList;
