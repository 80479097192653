import { Modal, Select } from 'antd';
import validator from 'validator';

import { InfoModalHeader } from '../global/ModalController';
import LabelledInput from '../reusable/LabelledInput';

const { Option } = Select;

/** Used to quick create a driver record with minmum
 * required fields
 */
const DriverModal = ({
    displayDriverModal,
    driverData,
    setDriverData,
    submitAddNewDriver,
    onCancel,
    submittingDriver,
    driverRates,
}) => {
    const getDriverModalContent = () => (
        <>
            {/* NAMES */}
            <div className="driver-modal__input-container">
                <LabelledInput
                    labelText="First Name"
                    withValidation
                    noTopMargin
                    value={driverData.firstName}
                    onChangeAction={(value) => setDriverData({ ...driverData, firstName: value })}
                    isValid={driverData.firstName.length > 1}
                    validationText="Enter a valid name"
                />
                <LabelledInput
                    labelText="Last Name"
                    withValidation
                    noTopMargin
                    value={driverData.lastName}
                    onChangeAction={(value) => setDriverData({ ...driverData, lastName: value })}
                    isValid={driverData.lastName.length > 1}
                    validationText="Enter a valid name"
                />
            </div>

            {/* DETAILS */}
            <div className="driver-modal__input-container">
                <LabelledInput
                    labelText="Nickname"
                    withValidation
                    value={driverData.nickname}
                    onChangeAction={(value) => setDriverData({ ...driverData, nickname: value })}
                    isValid={driverData.nickname.length > 1}
                    validationText="Enter a valid nickname"
                />
                <LabelledInput
                    labelText="Work Phone No."
                    withValidation
                    value={driverData.workPhoneNumber}
                    onChangeAction={(value) =>
                        setDriverData({ ...driverData, workPhoneNumber: value })
                    }
                    isValid={validator.isMobilePhone(
                        driverData.workPhoneNumber,
                        ['en-CA', 'en-US'],
                        {
                            strictMode: true,
                        }
                    )}
                    validationText="Enter a valid phone number"
                />
            </div>

            {/* EMAIL */}
            <div>
                <LabelledInput
                    labelText="Email"
                    withValidation
                    value={driverData.email}
                    onChangeAction={(value) => setDriverData({ ...driverData, email: value })}
                    isValid={validator.isEmail(driverData.email)}
                    validationText="Enter a valid email"
                />
            </div>

            {/* RATE PROFILE */}
            <div>
                <div className="input-label" style={{ marginTop: 25 }}>
                    Rate Profile
                </div>
                <Select
                    size="large"
                    placeholder="Select one"
                    value={driverData.rateProfile ? driverData.rateProfile.profileName : null}
                    style={{ width: '100%' }}
                    status={driverData.rateProfile.driverRateProfileId ? null : 'error'}
                    onChange={(value) => {
                        const rateId = value.split('_')[1];
                        const newRate = driverRates.find(
                            (rate) =>
                                rate.driverRateProfile.driverRateProfileId.toString() === rateId
                        );
                        setDriverData({
                            ...driverData,
                            rateProfile: { ...newRate.driverRateProfile },
                        });
                    }}
                >
                    {driverRates.map((rate) => (
                        <Option
                            key={`driver-rate-type_${rate.driverRateProfile.driverRateProfileId}`}
                        >
                            {rate.driverRateProfile.profileName}
                        </Option>
                    ))}
                </Select>
            </div>
        </>
    );

    const allDataEntered = () =>
        driverData.firstName.length > 1 &&
        driverData.lastName.length > 1 &&
        driverData.nickname.length > 1 &&
        validator.isMobilePhone(driverData.workPhoneNumber, ['en-CA', 'en-US'], {
            strictMode: true,
        }) &&
        validator.isEmail(driverData.email) &&
        driverData.rateProfile.driverRateProfileId;

    return (
        <Modal
            title={InfoModalHeader('Add New Driver')}
            open={displayDriverModal}
            onOk={() => submitAddNewDriver()}
            onCancel={onCancel}
            closable={false}
            keyboard={false}
            maskClosable={false}
            confirmLoading={submittingDriver}
            okButtonProps={allDataEntered() ? {} : { disabled: true }}
            cancelButtonProps={submittingDriver ? { disabled: true } : {}}
        >
            {getDriverModalContent()}
        </Modal>
    );
};
export default DriverModal;
