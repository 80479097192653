import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';
import PulseLoader from 'react-spinners/PulseLoader';

import { APP_ACTION_TYPES, DEVICE_TYPE, MODAL_TYPES } from '../../store/constants/appActionTypes';
import { changeEmailErrorText, changeEmailSuccessText } from '../../util/longText';
import LabelledInput from '../reusable/LabelledInput';
import { displaySimpleErrorModal } from '../global/ModalController';
import UsersApi from '../../api/userManagement/users';

const ChangeEmail = () => {
    const dispatch = useDispatch();
    const deviceType = useSelector((state) => state.app.device);

    const [emailEntryOne, setEmailEntryOne] = useState('');
    const [emailEntryTwo, setEmailEntryTwo] = useState('');

    const [emailChangePending, setEmailChangePending] = useState(false);

    const submitEmailChange = () => {
        setEmailChangePending(true);

        UsersApi.changeEmail(emailEntryOne)
            .then(() => {
                setEmailEntryOne('');
                setEmailEntryTwo('');
                setEmailChangePending(false);

                dispatch({
                    type: APP_ACTION_TYPES.SET_DISPLAY_MODAL,
                    payload: {
                        type: MODAL_TYPES.SUCCESS,
                        hideCancelButton: true,
                        okAction: () => {
                            dispatch({
                                type: APP_ACTION_TYPES.SET_DISPLAY_MODAL,
                                payload: null,
                            });
                        },
                        content: <div>{changeEmailSuccessText}</div>,
                    },
                });
            })
            .catch(() => {
                setEmailChangePending(false);
                displaySimpleErrorModal(dispatch, changeEmailErrorText);
            });
    };

    const bothEmailsValid = () =>
        emailEntryOne.length > 0 &&
        validator.isEmail(emailEntryOne) &&
        emailEntryOne === emailEntryTwo;

    const inputComponents = [
        {
            labelText: 'New Email',
            value: emailEntryOne,
            onChangeAction: setEmailEntryOne,
            isValid: emailEntryOne.length === 0 || validator.isEmail(emailEntryOne),
            validationText: 'Enter a valid email',
        },
        {
            labelText: 'Repeat New Email',
            value: emailEntryTwo,
            onChangeAction: setEmailEntryTwo,
            isValid: emailEntryOne === emailEntryTwo,
            validationText: "Email don't match",
        },
    ];

    return (
        <>
            <div
                className={`change-email__container${
                    deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                }`}
            >
                {inputComponents.map((comp) => (
                    <LabelledInput
                        key={comp.labelText}
                        labelText={comp.labelText}
                        value={comp.value}
                        onChangeAction={comp.onChangeAction}
                        withValidation
                        isValid={comp.isValid}
                        validationText={comp.validationText}
                    />
                ))}
            </div>

            <div className="change-email__button__container">
                {!emailChangePending ? (
                    // BUTTON
                    <div
                        className={`standard-button${bothEmailsValid() ? '' : '--disabled'}${
                            deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                        }`}
                        onClick={bothEmailsValid() ? () => submitEmailChange() : null}
                    >
                        SAVE
                    </div>
                ) : (
                    // SPINNER - WHILE SUBMITTING
                    <PulseLoader color="#0e76bc" />
                )}
            </div>
        </>
    );
};

export default ChangeEmail;
