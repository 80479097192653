import { SYSTEM_SETTINGS } from '@wx/common';
import { InputNumber, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PayrollApi from '../../api/payroll/payroll';
import SystemSettingsApi from '../../api/systemSettings/systemSettings';
import { TODO_ACTION_TYPES } from '../../store/constants/todoActionTypes';
import { beautifyTextValue } from '../../util/formUtil';
import {
    addSupplementalPayDataErrorText,
    overnightSettingMissingText,
    settingsFetchErrorText,
} from '../../util/longText';
import { fetchTrucksNeedingSupPay } from '../dashboard/ToDo/dataFetcher';
import { displaySimpleErrorModal, InfoModalHeader } from './ModalController';

const SupplementalPayModal = ({ modalData, onSubmitSuccess }) => {
    const dispatch = useDispatch();
    const [overnightRateExists, setOvernightRateExists] = useState(false);
    const [supplementalPayDays, setSupplementalPayDays] = useState(1);
    const [submittingSupplementalData, setSubmittingSupplementalData] = useState(false);

    useEffect(() => {
        // Check if the overnight rate setting exists on mount
        fetchSystemSettings();
    }, []);

    const fetchSystemSettings = () => {
        SystemSettingsApi.getSystemSettings()
            .then((data) => {
                setOvernightRateExists(
                    data.some((setting) => setting.key === SYSTEM_SETTINGS.OVERNIGHT_RATE)
                );
            })
            .catch(() => {
                displaySimpleErrorModal(dispatch, settingsFetchErrorText);
            });
    };

    const addSupplementalPayData = () => {
        setSubmittingSupplementalData(true);

        PayrollApi.addSupplementalPayData(modalData.truckId, supplementalPayDays)
            .then(() => {
                // Run callback if provided
                if (onSubmitSuccess) {
                    onSubmitSuccess();
                }

                fetchTrucksNeedingSupPay(dispatch);
                setSubmittingSupplementalData(false);
                setSupplementalPayDays(1);
                dispatch({
                    type: TODO_ACTION_TYPES.SET_DISPLAY_SUPPLEMENTAL_DATA_MODAL,
                    payload: null,
                });
            })
            .catch(() => {
                setSubmittingSupplementalData(false);
                displaySimpleErrorModal(dispatch, addSupplementalPayDataErrorText);
            });
    };

    const isSupplementalPayValid = () => {
        return !!supplementalPayDays && overnightRateExists;
    };

    const getSupplementalPayModalContent = () => {
        const truckData = modalData;

        return (
            <div className="todo-list__truck-supplemental-pay__modal__container">
                <div className="todo-list__truck-supplemental-pay__modal__info">
                    {overnightRateExists ? (
                        // TRUCK DETAILS
                        <>
                            <div className="todo-list__truck-supplemental-pay__modal__info--section">
                                <div>
                                    <span style={{ fontWeight: 'bold' }}>Truck Date: </span>
                                    {truckData.date}
                                </div>
                                <div>
                                    <span style={{ fontWeight: 'bold' }}>Load Type: </span>
                                    {beautifyTextValue(truckData.loadType)}
                                </div>
                                <div>
                                    <span style={{ fontWeight: 'bold' }}>Trailer ID: </span>
                                    {truckData.trailerId}
                                </div>
                            </div>
                            <div className="todo-list__truck-supplemental-pay__modal__info--section">
                                <div>
                                    <span style={{ fontWeight: 'bold' }}>Driver: </span>
                                    {`${truckData.driver.contactInfo.firstName} ${truckData.driver.contactInfo.lastName}`}
                                </div>
                                <div>
                                    <span style={{ fontWeight: 'bold' }}>TDM: </span>
                                    {truckData.tdm.routeName}
                                </div>
                            </div>
                            {truckData.helpers && truckData.helpers.length ? (
                                <div className="todo-list__truck-supplemental-pay__modal__info--section">
                                    <div>
                                        <span style={{ fontWeight: 'bold' }}>Helper(s): </span>
                                        {truckData.helpers.map(
                                            ({ helper }, idx) =>
                                                `${idx > 0 ? ', ' : ''}${
                                                    helper.contactInfo.firstName
                                                } ${helper.contactInfo.lastName}`
                                        )}
                                    </div>
                                </div>
                            ) : null}
                        </>
                    ) : (
                        <div className="error-text" style={{ textAlign: 'center' }}>
                            {overnightSettingMissingText}
                        </div>
                    )}
                </div>
                <div className="page-break" style={{ margin: '15px 0' }} />
                <div className="todo-list__truck-supplemental-pay__modal__input">
                    <div className="input-label" style={{ marginRight: 10 }}>
                        # of days driver eligable for:{' '}
                    </div>
                    <InputNumber
                        min={1}
                        value={supplementalPayDays}
                        onChange={setSupplementalPayDays}
                        size="large"
                        style={{ width: '30%' }}
                    />
                </div>
            </div>
        );
    };

    return (
        <Modal
            title={InfoModalHeader('Supplemental Pay')}
            open
            onOk={() => addSupplementalPayData()}
            onCancel={() => {
                setSupplementalPayDays(1);
                dispatch({
                    type: TODO_ACTION_TYPES.SET_DISPLAY_SUPPLEMENTAL_DATA_MODAL,
                    payload: null,
                });
            }}
            closable={false}
            keyboard={false}
            maskClosable={false}
            confirmLoading={submittingSupplementalData}
            okButtonProps={isSupplementalPayValid() ? {} : { disabled: true }}
            cancelButtonProps={submittingSupplementalData ? { disabled: true } : {}}
        >
            {getSupplementalPayModalContent()}
        </Modal>
    );
};
export default SupplementalPayModal;
