/* To get the executeRecaptcha variable:
   1. In the file using recaptcha, add:
        import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
   2. In the component using recaptcha, add:
        const { executeRecaptcha } = useGoogleReCaptcha();
   3. Call function:
        handleReCaptchaVerify(executeRecaptcha)
            .then((token) => {})
            .catch() */

export const handleReCaptchaVerify = async (executeRecaptcha) => {
    return new Promise(async (resolve, reject) => {
        if (!executeRecaptcha) {
            console.error('Execute recaptcha not yet available');
            reject();
        }

        const token = await executeRecaptcha('signup');
        resolve(token);
    });
};
