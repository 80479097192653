import { Modal, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReportsApi from '../../../api/reports/reports';
import UsersApi from '../../../api/userManagement/users';
import { APP_ACTION_TYPES, TRIGGER_FETCH_TYPES } from '../../../store/constants/appActionTypes';
import { beautifyTextValue } from '../../../util/formUtil';
import {
    addReportEmailConfigErrorText,
    addReportEmailConfigSuccessText,
    reportEmailConfigsFetchErrorText,
    userDataFetchErrorText,
} from '../../../util/longText';
import { shouldSee } from '../../../util/permissionsUtil';
import {
    displaySimpleErrorModal,
    displaySimpleSuccessModal,
    InfoModalHeader,
} from '../../global/ModalController';
import EmailConfigTable from './EmailConfigTable';

const { Option } = Select;

const ReportEmailConfig = () => {
    const dispatch = useDispatch();
    const fetchTrigger = useSelector((state) => state.app.triggerFetch);

    const CONFIG_TYPES = ['delivery_charge_report', 'customer_invoice'];

    const [emailConfigs, setEmailConfigs] = useState([]);
    const [users, setUsers] = useState([]);

    const [displayAddConfigModal, setDisplayAddConfigModal] = useState(null);
    const [selectedNewUser, setSelectedNewUser] = useState(undefined);
    const [savingNewReportEmailConfig, setSavingNewReportEmailConfig] = useState(false);

    // Fetch configs and users on mount
    useEffect(() => {
        fetchEmailConfigurations();
        fetchUsers();
    }, []);

    // Observe store changes and fetch configs if triggered after delete action, reset trigger
    useEffect(() => {
        if (fetchTrigger === TRIGGER_FETCH_TYPES.REPORT_EMAIL_CONFIGURATION) {
            fetchEmailConfigurations();
            dispatch({
                type: APP_ACTION_TYPES.SET_TRIGGER_FETCH,
                payload: null,
            });
        }
    }, [fetchTrigger]);

    const fetchEmailConfigurations = () => {
        if (shouldSee(['USER_MANAGE_UPDATE'])) {
            ReportsApi.getReportEmailConfigs()
                .then((data) => {
                    const configs = [];

                    // Sort email configurations by report type
                    if (data && Array.isArray(data)) {
                        data.forEach((config) => {
                            if (
                                !configs.find(
                                    (existingConfig) =>
                                        existingConfig.reportType === config.reportType
                                )
                            ) {
                                configs.push({
                                    reportType: config.reportType,
                                    users: [{ ...config.user, configId: config.configId }],
                                });
                            } else {
                                const existingConfigIndex = configs.findIndex(
                                    (existingConfig) =>
                                        existingConfig.reportType === config.reportType
                                );
                                configs[existingConfigIndex].users.push({
                                    ...config.user,
                                    configId: config.configId,
                                });
                            }
                        });
                    }
                    setEmailConfigs([...configs]);
                })
                .catch(() => {
                    displaySimpleErrorModal(dispatch, reportEmailConfigsFetchErrorText);
                });
        }
    };

    const fetchUsers = () => {
        UsersApi.getUsers('', true, 0, 1000000)
            .then(({ data }) => {
                setUsers([...data]);
            })
            .catch(() => {
                displaySimpleErrorModal(dispatch, userDataFetchErrorText);
            });
    };

    const saveReportEmailConfig = () => {
        setSavingNewReportEmailConfig(true);

        const userToRegisterId = users.find((user) => user.email === selectedNewUser).userId;
        ReportsApi.addReportEmailConfigs(userToRegisterId, displayAddConfigModal)
            .then(() => {
                setSavingNewReportEmailConfig(false);
                setSelectedNewUser(undefined);
                setDisplayAddConfigModal(null);

                displaySimpleSuccessModal(dispatch, addReportEmailConfigSuccessText);
                fetchEmailConfigurations();
            })
            .catch(() => {
                setSavingNewReportEmailConfig(false);
                displaySimpleErrorModal(dispatch, addReportEmailConfigErrorText);
            });
    };

    const getAddConfigModalContent = () => {
        // Filter out users already registered for currently selected config
        const selectedConfig = emailConfigs.find(
            (config) => config.reportType === displayAddConfigModal
        );
        const selectedConfigRegisteredUsers = selectedConfig ? selectedConfig.users : [];
        const usersNotOnMailingList = users.filter(
            (user) =>
                !selectedConfigRegisteredUsers.some(
                    (registeredUser) => registeredUser.userId === user.userId
                )
        );

        return (
            <div style={{ width: '100%' }}>
                <Select
                    size="large"
                    style={{ width: '100%' }}
                    placeholder="Select user email to add to mailing list"
                    value={selectedNewUser}
                    status={selectedNewUser ? null : 'error'}
                    onChange={(value) => {
                        setSelectedNewUser(value);
                    }}
                >
                    {usersNotOnMailingList.map((newUser) => (
                        <Option key={newUser.email}>{newUser.email}</Option>
                    ))}
                </Select>
            </div>
        );
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {CONFIG_TYPES.map((configType) => (
                <EmailConfigTable
                    key={`repot-email-config-${configType}`}
                    configs={emailConfigs}
                    displayAddConfigModal={() => setDisplayAddConfigModal(configType)}
                    configType={configType}
                />
            ))}

            {displayAddConfigModal ? (
                <Modal
                    title={InfoModalHeader(
                        `Add ${beautifyTextValue(displayAddConfigModal)} Configuration`
                    )}
                    visible
                    onOk={saveReportEmailConfig}
                    onCancel={() => {
                        setSelectedNewUser(undefined);
                        setDisplayAddConfigModal(null);
                    }}
                    closable={false}
                    keyboard={false}
                    maskClosable={false}
                    confirmLoading={savingNewReportEmailConfig}
                    okButtonProps={selectedNewUser ? {} : { disabled: true }}
                    cancelButtonProps={savingNewReportEmailConfig ? { disabled: true } : {}}
                >
                    {getAddConfigModalContent()}
                </Modal>
            ) : null}
        </div>
    );
};
export default ReportEmailConfig;
