export const downloadFile = (data, fileType, fileName, isArrayBuffer = false) => {
    return new Promise((resolve, reject) => {
        try {
            const blob = new Blob([isArrayBuffer ? data : toArrayBuffer(data)], { type: fileType });

            const a = document.createElement('a');
            a.download = fileName;
            a.href = window.URL.createObjectURL(blob);
            const clickEvt = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true,
            });
            a.dispatchEvent(clickEvt);
            a.remove();

            resolve();
        } catch (e) {
            console.error(e);
            reject();
        }
    });
};

const toArrayBuffer = (buf) => {
    const ab = new ArrayBuffer(buf.length);
    const view = new Uint8Array(ab);
    for (let i = 0; i < buf.length; ++i) {
        view[i] = buf[i];
    }
    return ab;
};

export const FILE_TYPE = Object.freeze({
    CSV: 'text/csv',
});
