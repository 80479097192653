import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, Modal, Select, Tooltip } from 'antd';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import PulseLoader from 'react-spinners/PulseLoader';

import { DEVICE_TYPE } from '../../store/constants/appActionTypes';
import LabelledInput from '../reusable/LabelledInput';
import { beautifyTextValue } from '../../util/formUtil';
import Navigation from './Navigation';
import { InfoModalHeader } from '../global/ModalController';
import TrucksApi from '../../api/dataEntry/trucks';
import { onAPIError } from '../../util/apiUtil';
import {
    truckDateAndTdmSearchErrorText,
    truckStatusHardLockedHelperText,
    truckStatusLockedHelperText,
    truckStatusPayrollSelectedHelperText,
    truckStatusSpecialPayHelperText,
    truckStatusUnlockedHelperText,
} from '../../util/longText';

const { Option } = Select;
const LOAD_TYPES = ['team_load', 'dc_preload'];

const TruckForm = ({
    formData,
    truckRecords,
    initialTruckDataFetchComplete,
    availableDrivers,
    availableTdms,
    setFieldValue,
    updateHelpersList,
    clearTruckForm,
    truckDataEntered,
    savingTruck,
    saveTruck,
    selectedTruckIndex,
    setSelectedTruckIndex,
    isEditingTruck,
    setIsEditingTruck,
    userHasEditAccess,
}) => {
    const dispatch = useDispatch();
    const deviceType = useSelector((state) => state.app.device);
    const formStyle = deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : '';
    let firstElementRef = null;

    // MODAL
    const [displaySearchModal, setDisplaySearchModal] = useState(false);
    const [searchDate, setSearchDate] = useState(new Date());
    const [searchTdm, setSearchTdm] = useState('');
    const [searchingForTruck, setSearchingForTruck] = useState(false);

    useEffect(() => {
        firstElementRef.focus();
    }, []);

    // Save new truck on ctrl+enter
    const handleEnterKey = useCallback(
        (e) => {
            const { keyCode, ctrlKey } = e;
            if (ctrlKey && keyCode === 13) {
                if (
                    userHasEditAccess &&
                    selectedTruckIndex === null &&
                    truckDataEntered &&
                    !savingTruck
                ) {
                    saveTruck();
                }
            }
        },
        [formData]
    );

    useEffect(() => {
        window.addEventListener('keydown', handleEnterKey);
        return () => {
            window.removeEventListener('keydown', handleEnterKey);
        };
    }, [handleEnterKey]);

    const getDriverFullName = (driver) =>
        driver.hasOwnProperty('helper')
            ? `${driver.helper.contactInfo.firstName} ${driver.helper.contactInfo.lastName}`
            : `${driver.contactInfo.firstName} ${driver.contactInfo.lastName}`;

    const searchForTruck = () => {
        setSearchingForTruck(true);

        const searchableDate = moment(searchDate).format('YYYY-MM-DD');
        const searchableTdmId = availableTdms.find((tdm) => tdm.routeName === searchTdm).tdmId;

        TrucksApi.getTruckByDateAndTdm(searchableDate, searchableTdmId)
            .then((data) => {
                const foundIndex = truckRecords.findIndex(
                    (truck) => truck.truckId === data.truckId
                );
                setSelectedTruckIndex(foundIndex);
                setDisplaySearchModal(false);
                setSearchDate(new Date());
                setSearchTdm('');
                setSearchingForTruck(false);
            })
            .catch(() => {
                setDisplaySearchModal(false);
                setSearchDate(new Date());
                setSearchTdm('');
                setSearchingForTruck(false);
                onAPIError(dispatch, truckDateAndTdmSearchErrorText);
            });
    };

    const renderAdditionalHelper = (helper, idx) => {
        return (
            <div
                key={`helperfield_${idx}`}
                style={
                    deviceType === DEVICE_TYPE.MOBILE
                        ? {
                              width: '100%',
                              display: 'flex',
                              alignItems: 'flex-end',
                          }
                        : {
                              width: '24%',
                              display: 'flex',
                              alignItems: 'flex-end',
                              marginRight: '25px',
                          }
                }
            >
                <div style={{ width: '100%' }}>
                    <div className="input-label" style={{ marginTop: 25 }}>
                        {`Helper ${idx + 1}`}
                    </div>
                    <Select
                        size="large"
                        value={helper.helperId || helper.driverId ? getDriverFullName(helper) : ''}
                        style={{ width: '100%' }}
                        onChange={(value) =>
                            updateHelpersList(
                                availableDrivers.find(
                                    (driver) => getDriverFullName(driver) === value
                                ),
                                idx
                            )
                        }
                        disabled={
                            (!isEditingTruck && selectedTruckIndex !== null) || !userHasEditAccess
                        }
                    >
                        {availableDrivers.map((driver, idx) => (
                            <Option
                                key={`truckadditionalhelper_${idx}`}
                                value={getDriverFullName(driver)}
                            >
                                {getDriverFullName(driver)}
                            </Option>
                        ))}
                    </Select>
                </div>
                {(selectedTruckIndex === null || isEditingTruck) && userHasEditAccess ? (
                    <Tooltip title="Remove helper">
                        <DeleteOutlined
                            style={{
                                fontSize: 25,
                                cursor: 'pointer',
                                margin: '0 0 10px 5px',
                                color: 'red',
                            }}
                            onClick={() => updateHelpersList(null, idx)}
                        />
                    </Tooltip>
                ) : null}
            </div>
        );
    };

    const getSearchModalContent = () => (
        <div
            className={`truck-form__search-modal__container${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
        >
            {/* DATE */}
            <div style={deviceType === DEVICE_TYPE.MOBILE ? { width: '100%' } : { width: '29%' }}>
                <div className="input-label">Date</div>
                <DatePicker
                    size="large"
                    style={{ width: '100%' }}
                    value={moment(searchDate)}
                    onChange={(date) => setSearchDate(date)}
                    allowClear={false}
                />
            </div>

            {/* TDM */}
            <div style={deviceType === DEVICE_TYPE.MOBILE ? { width: '100%' } : { width: '69%' }}>
                <div className="input-label">TDM*</div>
                <Select
                    size="large"
                    value={searchTdm}
                    style={{ width: '100%' }}
                    onChange={(value) => setSearchTdm(value)}
                    status={searchTdm.length ? null : 'error'}
                >
                    {availableTdms.map((tdm, idx) => (
                        <Option key={`trucktdm-search_${idx}`} value={tdm.routeName}>
                            {tdm.routeName}
                        </Option>
                    ))}
                </Select>
            </div>
        </div>
    );

    const getStatusBanner = () => {
        if (formData.status === 'data_entry') return null;

        return (
            <div
                className="truck-form__status-banner"
                style={
                    ['special_pay_required', 'selected_for_payroll', 'unlocked'].includes(
                        formData.status
                    )
                        ? { backgroundColor: '#edb334' }
                        : { backgroundColor: '#ee2824' }
                }
            >
                {formData.status === 'special_pay_required'
                    ? truckStatusSpecialPayHelperText
                    : formData.status === 'selected_for_payroll'
                    ? truckStatusPayrollSelectedHelperText
                    : formData.status === 'locked'
                    ? truckStatusLockedHelperText
                    : formData.status === 'unlocked'
                    ? truckStatusUnlockedHelperText
                    : truckStatusHardLockedHelperText}
            </div>
        );
    };

    return (
        <div
            className={`truck-form__container${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
        >
            {/* FORM HEADING */}
            {deviceType === DEVICE_TYPE.NOT_MOBILE ? (
                <div className="form-header">Truck</div>
            ) : (
                <div className="input-label" style={{ fontSize: 30 }}>
                    Truck
                </div>
            )}
            {initialTruckDataFetchComplete ? (
                <Navigation
                    records={truckRecords.map((truck) => ({
                        recordId: truck.truckId,
                        name: `${truck.tdm.routeName} | ${truck.driver.nickname} | ${truck.trailerId}`,
                    }))}
                    selectedRecord={selectedTruckIndex}
                    setSelectedRecord={setSelectedTruckIndex}
                    deviceType={deviceType}
                    searchButtonText="FIND INVOICE"
                    searchButtonAction={() => setDisplaySearchModal(true)}
                />
            ) : (
                <div className="truck-form__loading-container">
                    <PulseLoader color="#0e76bc" />
                </div>
            )}

            <div className="truck-form__form-container">
                {/* TRUCK STATUS BANNER */}
                {formData.status && getStatusBanner()}

                <div className={`truck-form__line${formStyle}`}>
                    {/* DATE */}
                    <div
                        style={
                            deviceType === DEVICE_TYPE.MOBILE ? { width: '100%' } : { width: '29%' }
                        }
                    >
                        <div className="input-label" style={{ marginTop: 25 }}>
                            Date*
                        </div>
                        <DatePicker
                            size="large"
                            style={{ width: '100%' }}
                            value={moment(formData.date)}
                            onChange={(date) => setFieldValue({ date })}
                            allowClear={false}
                            disabled={
                                (!isEditingTruck && selectedTruckIndex !== null) ||
                                !userHasEditAccess
                            }
                        />
                    </div>

                    {/* TDM */}
                    <div
                        style={
                            deviceType === DEVICE_TYPE.MOBILE ? { width: '100%' } : { width: '29%' }
                        }
                    >
                        <div className="input-label" style={{ marginTop: 25 }}>
                            TDM*
                        </div>
                        <Select
                            size="large"
                            value={formData.tdm.routeName}
                            style={{ width: '100%' }}
                            onChange={(value) =>
                                setFieldValue({
                                    tdm: availableTdms.find((tdm) => tdm.routeName === value),
                                })
                            }
                            disabled={
                                (!isEditingTruck && selectedTruckIndex !== null) ||
                                !userHasEditAccess
                            }
                            status={formData.tdm.hasOwnProperty('tdmId') ? null : 'error'}
                            ref={(r) => {
                                firstElementRef = r;
                            }}
                            showAction="focus"
                        >
                            {availableTdms.map((tdm, idx) => (
                                <Option key={`trucktdm_${idx}`} value={tdm.routeName}>
                                    {tdm.routeName}
                                </Option>
                            ))}
                        </Select>
                    </div>

                    {/* Driver */}
                    <div
                        style={
                            deviceType === DEVICE_TYPE.MOBILE ? { width: '100%' } : { width: '29%' }
                        }
                    >
                        <div className="input-label" style={{ marginTop: 25 }}>
                            Driver*
                        </div>
                        <Select
                            size="large"
                            value={getDriverFullName(formData.driver)}
                            style={{ width: '100%' }}
                            onChange={(value) =>
                                setFieldValue({
                                    driver: availableDrivers.find(
                                        (driver) => getDriverFullName(driver) === value
                                    ),
                                })
                            }
                            disabled={
                                (!isEditingTruck && selectedTruckIndex !== null) ||
                                !userHasEditAccess
                            }
                            status={formData.driver.hasOwnProperty('driverId') ? null : 'error'}
                            showAction="focus"
                        >
                            {availableDrivers.map((driver, idx) => (
                                <Option
                                    key={`truckdriver_${idx}`}
                                    value={getDriverFullName(driver)}
                                >
                                    {getDriverFullName(driver)}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>

                <div className={`truck-form__line${formStyle}`}>
                    {/* LOAD TYPE */}
                    <div
                        style={
                            deviceType === DEVICE_TYPE.MOBILE ? { width: '100%' } : { width: '24%' }
                        }
                    >
                        <div className="input-label" style={{ marginTop: 25 }}>
                            Load Type
                        </div>
                        <Select
                            size="large"
                            value={formData.loadType ? beautifyTextValue(formData.loadType) : ''}
                            style={{ width: '100%' }}
                            onChange={(value) => setFieldValue({ loadType: value })}
                            disabled={
                                (!isEditingTruck && selectedTruckIndex !== null) ||
                                !userHasEditAccess
                            }
                            status={formData.loadType.length ? null : 'error'}
                            showAction="focus"
                        >
                            {LOAD_TYPES.map((type, idx) => (
                                <Option key={`truckloadtype_${idx}`} value={type}>
                                    {beautifyTextValue(type)}
                                </Option>
                            ))}
                        </Select>
                    </div>

                    {/* TRAILER ID */}
                    <div
                        style={
                            deviceType === DEVICE_TYPE.MOBILE ? { width: '100%' } : { width: '24%' }
                        }
                    >
                        <LabelledInput
                            labelText="Trailer ID"
                            value={formData.trailerId}
                            onChangeAction={(value) => setFieldValue({ trailerId: value })}
                            isDisabled={
                                (!isEditingTruck && selectedTruckIndex !== null) ||
                                !userHasEditAccess
                            }
                            withValidation
                            isValid={formData.trailerId.length}
                        />
                    </div>

                    {/* FIRST HELPER */}
                    <div
                        style={
                            deviceType === DEVICE_TYPE.MOBILE
                                ? {
                                      width: '100%',
                                      display: 'flex',
                                      alignItems: 'flex-end',
                                      marginTop: 15,
                                  }
                                : !isEditingTruck && selectedTruckIndex !== null
                                ? {
                                      width: '24%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginTop: 10,
                                  }
                                : { width: '24%', display: 'flex', alignItems: 'flex-end' }
                        }
                    >
                        <div style={{ width: '100%' }}>
                            <div className="input-label">Helper(s)</div>
                            <Select
                                size="large"
                                value={
                                    formData.helpers.length &&
                                    (formData.helpers[0].helperId || formData.helpers[0].driverId)
                                        ? getDriverFullName(formData.helpers[0])
                                        : ''
                                }
                                style={{ width: '100%' }}
                                onChange={(value) =>
                                    updateHelpersList(
                                        availableDrivers.find(
                                            (driver) => getDriverFullName(driver) === value
                                        ),
                                        0
                                    )
                                }
                                disabled={
                                    (!isEditingTruck && selectedTruckIndex !== null) ||
                                    !userHasEditAccess
                                }
                            >
                                {availableDrivers.map((driver, idx) => (
                                    <Option
                                        key={`truckhelper_${idx}`}
                                        value={getDriverFullName(driver)}
                                    >
                                        {getDriverFullName(driver)}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        {(selectedTruckIndex === null || isEditingTruck) && userHasEditAccess ? (
                            <Tooltip title="Add helper">
                                <PlusCircleOutlined
                                    style={{
                                        fontSize: 25,
                                        cursor: 'pointer',
                                        marginBottom: '10px',
                                        marginLeft: '10px',
                                        color: '#88bee2',
                                    }}
                                    onClick={() =>
                                        updateHelpersList('', formData.helpers.length + 1)
                                    }
                                />
                            </Tooltip>
                        ) : null}
                    </div>
                </div>

                {/* ADDITIONAL HELPERS */}
                {formData.helpers.length > 1 ? (
                    <div
                        className={`truck-form__line${formStyle}`}
                        style={{ justifyContent: 'flex-start' }}
                    >
                        {formData.helpers.map((helper, idx) => {
                            if (!idx) return null;
                            return renderAdditionalHelper(helper, idx);
                        })}
                    </div>
                ) : null}

                <div className="truck-form__save-button__container">
                    {selectedTruckIndex === null && userHasEditAccess ? (
                        // Display save button if creating new record
                        <>
                            <div
                                className={`standard-button--empty${
                                    deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                                }`}
                                style={{ marginRight: 20 }}
                                onClick={() => clearTruckForm()}
                            >
                                CLEAR FORM
                            </div>
                            <div
                                className={`standard-button--double${
                                    !truckDataEntered || savingTruck ? '--disabled' : ''
                                }${deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''}`}
                                onClick={() =>
                                    !truckDataEntered || savingTruck ? null : saveTruck()
                                }
                            >
                                <span>SAVE TRUCK</span>
                                <span>(CTRL + ENTER)</span>
                            </div>
                        </>
                    ) : isEditingTruck && userHasEditAccess ? (
                        // Display save button if editing
                        <div
                            className={`standard-button${
                                !truckDataEntered || savingTruck ? '--disabled' : ''
                            }${deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''}`}
                            onClick={() => (!truckDataEntered || savingTruck ? null : saveTruck())}
                        >
                            SAVE TRUCK
                        </div>
                    ) : userHasEditAccess ? (
                        // Display create & edit buttons if viewing existing records
                        <div className={`truck-form__double-buttons-container${formStyle}`}>
                            {formData.status !== 'hard_locked' ? (
                                <div
                                    className={`standard-button${
                                        deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                                    }`}
                                    style={{ margin: '0 10px 10px 0' }}
                                    onClick={() => setIsEditingTruck(true)}
                                >
                                    EDIT TRUCK
                                </div>
                            ) : null}
                            <div
                                className={`standard-button${
                                    deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                                }`}
                                style={{ margin: '0 10px 10px 0' }}
                                onClick={() => setSelectedTruckIndex(null)}
                            >
                                CREATE NEW TRUCK
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>

            {/* SEARCH MODAL */}
            <Modal
                title={InfoModalHeader('Search by TDM & Date')}
                visible={displaySearchModal}
                onOk={() => searchForTruck()}
                onCancel={() => {
                    setDisplaySearchModal(false);
                    setSearchDate(new Date());
                    setSearchTdm('');
                }}
                closable={false}
                keyboard={false}
                maskClosable={false}
                confirmLoading={searchingForTruck}
                okButtonProps={searchTdm.length ? {} : { disabled: true }}
                cancelButtonProps={searchingForTruck ? { disabled: true } : {}}
            >
                {getSearchModalContent()}
            </Modal>
        </div>
    );
};
export default TruckForm;
