import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Button, Modal, Tooltip } from 'antd';
import { MdOutlineOpenInNew } from 'react-icons/md';

import { APP_ACTION_TYPES } from '../../store/constants/appActionTypes';
import { beautifyTextValue, convertToTwoDecimals } from '../../util/formUtil';
import { InfoModalHeader } from '../global/ModalController';
import { noInvoiceServicesInfoText } from '../../util/longText';

const EmployeePayrollDetails = ({ payrollDetails, asModal = true }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const closeModal = () => {
        dispatch({
            type: APP_ACTION_TYPES.SET_VIEWING_EMPLOYEE_PAYROLL_DETAILS,
            payload: null,
        });
    };

    const onViewTruckClick = (truck) => {
        dispatch({
            type: APP_ACTION_TYPES.SET_EDITING_INVOICE,
            payload: {
                truckId: truck.truckId,
            },
        });
        closeModal();
        history.push('/dataentry');
    };

    const onViewInvoiceClick = (invoice) => {
        dispatch({
            type: APP_ACTION_TYPES.SET_EDITING_INVOICE,
            payload: {
                truckId: invoice.truck.truckId,
                invoiceId: invoice.invoiceId,
            },
        });
        closeModal();
        history.push('/dataentry');
    };

    const getModalContent = () => {
        return (
            <div className="employee-payroll-details__container">
                {/* INVOICE SERVICES */}
                {payrollDetails.invoiceServices.length ? (
                    <>
                        <div className="employee-payroll-details__header">
                            Services Contributing to Payroll
                        </div>

                        {payrollDetails.invoiceServices.map((invoiceService) => (
                            <div
                                key={`payroll-details-service-${invoiceService.invoiceServiceId}`}
                                className="employee-payroll-details__services__container"
                            >
                                {/* TRUCK DETAILS */}
                                {invoiceService.invoice
                                    ? getTruckDetailRow(invoiceService.invoice.truck)
                                    : null}
                                {/* INVOICE DETAILS */}
                                {getInvoiceDetailRow(invoiceService)}
                                {/* INVOICE SERVICE DETAILS */}
                                {getInvoiceServiceRow(invoiceService)}
                            </div>
                        ))}
                    </>
                ) : (
                    <div className="employee-payroll-details__info-text">
                        {noInvoiceServicesInfoText}
                    </div>
                )}

                {/* TRUCK HELPER */}
                {payrollDetails.helper.length ? (
                    <>
                        <div className="employee-payroll-details__header" style={{ marginTop: 15 }}>
                            {`Trucks with ${getDriverName()} as a helper`}
                        </div>
                        {payrollDetails.helper.map((truck) => (
                            <div
                                key={`payroll-details-helper-${truck.truckId}`}
                                className="employee-payroll-details__services__container"
                            >
                                {/* TRUCK DETAILS */}
                                {getTruckDetailRow(truck, true)}
                            </div>
                        ))}
                    </>
                ) : null}
            </div>
        );
    };

    const getTruckDetailRow = (truck, showActionButton) => {
        return (
            <div className="employee-payroll-details__services__service-detail-container">
                <div className="employee-payroll-details__column" style={{ width: '33%' }}>
                    <span className="input-label-bold">Truck Date:</span>
                    {` ${truck.date}`}
                </div>
                <div className="employee-payroll-details__column" style={{ width: '33%' }}>
                    <span className="input-label-bold">Load Type:</span>
                    {` ${beautifyTextValue(truck.loadType)}`}
                </div>
                <div
                    className="employee-payroll-details__column"
                    style={showActionButton ? { width: '20%' } : { width: '33%' }}
                >
                    <span className="input-label-bold">Trailer ID:</span>
                    {` ${truck.trailerId}`}
                </div>
                {showActionButton ? (
                    <div
                        className="employee-payroll-details__column"
                        style={{ width: '13%', justifyContent: 'flex-end', alignItems: 'center' }}
                    >
                        <Tooltip title="View Truck">
                            <MdOutlineOpenInNew
                                style={{
                                    fontSize: 18,
                                    cursor: 'pointer',
                                }}
                                onClick={() => onViewTruckClick(truck)}
                            />
                        </Tooltip>
                    </div>
                ) : null}
            </div>
        );
    };

    const getInvoiceDetailRow = (invoiceService) => {
        const { invoice } = invoiceService;
        return (
            <div className="employee-payroll-details__services__service-detail-container">
                <div className="employee-payroll-details__column" style={{ width: '33%' }}>
                    <span className="input-label-bold">Invoice Ref:</span>
                    {` ${invoice.invoiceReference}`}
                </div>
                <div className="employee-payroll-details__column" style={{ width: '33%' }}>
                    <span className="input-label-bold">Line Haul:</span>
                    {` ${invoice.invoiceLineHaulStatus}`}
                </div>
                <div className="employee-payroll-details__column" style={{ width: '33%' }}>
                    <span className="input-label-bold">Customer:</span>
                    {` ${invoice.customerName}`}
                </div>
            </div>
        );
    };

    const getInvoiceServiceRow = (invoiceService) => {
        return (
            <div className="employee-payroll-details__services__service-detail-container">
                <div className="employee-payroll-details__column" style={{ width: '33%' }}>
                    <span className="input-label-bold">Service:</span>
                    {` ${invoiceService.label}`}
                </div>
                <div className="employee-payroll-details__column" style={{ width: '17%' }}>
                    <span className="input-label-bold">Qty:</span>
                    {` ${invoiceService.quantity}`}
                </div>
                <div className="employee-payroll-details__column" style={{ width: '16%' }}>
                    <span className="input-label-bold">Rate:</span>
                    {` $${convertToTwoDecimals(invoiceService.settledRate)}`}
                </div>
                <div
                    className="employee-payroll-details__column input-label-bold"
                    style={{ width: '20%', color: '#0e76bc' }}
                >
                    {`Total: $${convertToTwoDecimals(`${getServiceTotal(invoiceService)}`)}`}
                </div>
                <div
                    className="employee-payroll-details__column"
                    style={{ width: '13%', justifyContent: 'flex-end', alignItems: 'center' }}
                >
                    <Tooltip title="View Invoice">
                        <MdOutlineOpenInNew
                            style={{
                                fontSize: 18,
                                cursor: 'pointer',
                            }}
                            onClick={() => onViewInvoiceClick(invoiceService.invoice)}
                        />
                    </Tooltip>
                </div>
            </div>
        );
    };

    const getServiceTotal = (invoiceService) => {
        const qty = parseFloat(invoiceService.quantity);
        const rate = parseFloat(invoiceService.settledRate);
        return qty * rate;
    };

    const getDriverName = () =>
        `${payrollDetails.driver.contactInfo.firstName} ${payrollDetails.driver.contactInfo.lastName}`;

    return asModal ? (
        <Modal
            title={InfoModalHeader(`${getDriverName()}'s Pay Details`)}
            open
            footer={[
                <Button key="submit" type="primary" onClick={closeModal}>
                    OK
                </Button>,
            ]}
            closable={false}
            keyboard={false}
            maskClosable={false}
            width={800}
        >
            {getModalContent()}
        </Modal>
    ) : (
        getModalContent()
    );
};
export default EmployeePayrollDetails;
