/* eslint-disable no-unused-vars */
import { useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import validator from 'validator';
import { Input, Select, Switch, Tooltip } from 'antd';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';

import LabelledInput from '../../../component/reusable/LabelledInput';

import { DEVICE_TYPE } from '../../../store/constants/appActionTypes';
import AddressForm from '../../../component/reusable/AddressForm';
import { capitalizeWord } from '../../../util/formUtil';

const { Option } = Select;

const CustomerForm = ({
    formData,
    customerRates,
    organizationOptions,
    managerOptions,
    setFormData,
    isEditing,
    formStyle,
}) => {
    const deviceType = useSelector((state) => state.app.device);

    const updateEmailsList = (newValue, position) => {
        const updatedEmails = formData.contactEmails ? [...formData.contactEmails] : [];
        if (newValue === null) {
            updatedEmails.splice(position, 1);
        } else {
            updatedEmails[position] = newValue;
        }
        setFormData({ ...formData, contactEmails: updatedEmails });
    };

    const extractAddressData = () => {
        const addressData = {
            addressLineOne: formData.contactInfo.addressLineOne,
            addressLineTwo: formData.contactInfo.addressLineTwo,
            city: formData.contactInfo.city,
            province: formData.contactInfo.province,
            country: formData.contactInfo.country,
            postalCode: formData.contactInfo.postalCode,
        };

        return addressData;
    };

    return (
        <div className="customer-form__container">
            {/* ****** ORGANIZATION ****** */}
            {/* ORGANIZATION HEADING (NON-MOBILE) */}
            {deviceType === DEVICE_TYPE.NOT_MOBILE ? (
                <div className="form-header">Organization</div>
            ) : null}

            {/* ORGANIZATION DETAILS */}
            <div className={`customer-form__organization-section${formStyle}`}>
                <div style={formStyle.includes('mobile') ? { width: '100%' } : { width: '45%' }}>
                    <div className="input-label" style={{ marginTop: 25 }}>
                        Organization Name
                    </div>
                    <CreatableSelect
                        isClearable
                        value={{ label: formData.orgName, value: formData.orgName }}
                        onChange={(newValue) =>
                            newValue
                                ? setFormData({ ...formData, orgName: newValue.value })
                                : setFormData({ ...formData, orgName: '' })
                        }
                        placeholder="Select or type..."
                        isDisabled={!isEditing}
                        styles={{
                            control: (base) => ({
                                ...base,
                                borderColor: formData.orgName.length <= 1 ? '#ff0000' : '#ccc',
                            }),
                        }}
                        options={organizationOptions}
                    />
                </div>
                <div style={formStyle.includes('mobile') ? { width: '100%' } : { width: '45%' }}>
                    <div className="input-label" style={{ marginTop: 25 }}>
                        Manager
                    </div>
                    <CreatableSelect
                        isClearable
                        value={{ label: formData.manager, value: formData.manager }}
                        onChange={(newValue) =>
                            newValue
                                ? setFormData({ ...formData, manager: newValue.value })
                                : setFormData({ ...formData, manager: '' })
                        }
                        placeholder="Select or type..."
                        isDisabled={!isEditing}
                        styles={{
                            control: (base) => ({
                                ...base,
                                borderColor: formData.manager.length <= 1 ? '#ff0000' : '#ccc',
                            }),
                        }}
                        options={managerOptions}
                    />
                </div>
            </div>

            {/* ****** CUSTOMER ****** */}
            {/* CUSTOMER HEADING (NON-MOBILE) */}
            {deviceType === DEVICE_TYPE.NOT_MOBILE ? (
                <div className="form-header">Customer</div>
            ) : null}

            {/* NAMES & PHONE */}
            <div className={`customer-form__namesandphone-section${formStyle}`}>
                <div className={`customer-form__namesandphone-section--left${formStyle}`}>
                    <LabelledInput
                        labelText="First Name"
                        value={formData.contactInfo.firstName}
                        onChangeAction={(value) =>
                            setFormData({
                                ...formData,
                                contactInfo: { ...formData.contactInfo, firstName: value },
                            })
                        }
                        withValidation={isEditing}
                        isValid={formData.contactInfo.firstName.length > 1}
                        validationText="Enter a valid name"
                        customWidth={formStyle.includes('mobile') ? '100%' : '49%'}
                        isDisabled={!isEditing}
                    />
                    <LabelledInput
                        labelText="Last Name"
                        value={formData.contactInfo.lastName}
                        onChangeAction={(value) =>
                            setFormData({
                                ...formData,
                                contactInfo: { ...formData.contactInfo, lastName: value },
                            })
                        }
                        withValidation={isEditing}
                        isValid={formData.contactInfo.lastName.length > 1}
                        validationText="Enter a valid name"
                        customWidth={formStyle.includes('mobile') ? '100%' : '49%'}
                        isDisabled={!isEditing}
                    />
                </div>
                <div className={`customer-form__namesandphone-section--right${formStyle}`}>
                    <LabelledInput
                        labelText="Phone #"
                        value={formData.contactInfo.phoneNumber}
                        onChangeAction={(value) =>
                            setFormData({
                                ...formData,
                                contactInfo: { ...formData.contactInfo, phoneNumber: value },
                            })
                        }
                        withValidation={isEditing}
                        isValid={validator.isMobilePhone(
                            formData.contactInfo.phoneNumber,
                            ['en-CA', 'en-US'],
                            { strictMode: true }
                        )}
                        validationText="Enter a valid phone number"
                        customWidth="100%"
                        isDisabled={!isEditing}
                    />
                </div>
            </div>

            {/* EMAILS */}
            <div className={`customer-form__emails-section${formStyle}`}>
                <div className={`customer-form__emails-section--left${formStyle}`}>
                    {/* FIRST EMAIL */}
                    <LabelledInput
                        labelText="Email"
                        value={formData.contactEmails ? formData.contactEmails[0] : null}
                        onChangeAction={(value) => updateEmailsList(value, 0)}
                        withValidation={isEditing}
                        isValid={
                            formData.contactEmails
                                ? validator.isEmail(formData.contactEmails[0])
                                : false
                        }
                        validationText="Enter a valid email"
                        isDisabled={!isEditing}
                    />

                    {/* OTHER EMAILS */}
                    {formData.contactEmails
                        ? formData.contactEmails.map((email, idx) => {
                              if (!idx) return null;
                              return (
                                  <div key={`customeremail_${idx}`} style={{ width: '100%' }}>
                                      <div className="customer-form__emails-section__additional-email">
                                          <Input
                                              size="large"
                                              style={
                                                  isEditing
                                                      ? { width: '100%' }
                                                      : { width: '100%', marginTop: '15.7px' } // Same as validation text
                                              }
                                              value={email}
                                              onChange={(e) =>
                                                  updateEmailsList(e.target.value, idx)
                                              }
                                              disabled={!isEditing}
                                              status={
                                                  isEditing
                                                      ? !validator.isEmail(
                                                            formData.contactEmails[idx]
                                                        )
                                                          ? 'error'
                                                          : null
                                                      : null
                                              }
                                          />
                                          {/* REMOVE EMAIL FIELD */}
                                          {isEditing ? (
                                              <Tooltip title="Delete email">
                                                  <DeleteOutlined
                                                      onClick={() => updateEmailsList(null, idx)}
                                                      style={{
                                                          fontSize: 20,
                                                          cursor: 'pointer',
                                                          marginLeft: '20px',
                                                          color: 'red',
                                                      }}
                                                  />
                                              </Tooltip>
                                          ) : null}
                                      </div>
                                      {/* EMAIL VALIDATION */}
                                      {isEditing ? (
                                          !validator.isEmail(formData.contactEmails[idx]) ? (
                                              <div className="labelled-input__validation-error">
                                                  Enter a valid email
                                              </div>
                                          ) : (
                                              // Need to prevent button from jumping up/down when
                                              // validation text appears
                                              <div className="labelled-input__validation-error">
                                                  {' '}
                                                  &nbsp;
                                              </div>
                                          )
                                      ) : null}
                                  </div>
                              );
                          })
                        : null}

                    {/* ADD EMAIL */}
                    {isEditing ? (
                        <Tooltip title="Add email">
                            <PlusCircleOutlined
                                style={{
                                    fontSize: 25,
                                    cursor: 'pointer',
                                    color: '#88bee2',
                                }}
                                onClick={() => updateEmailsList('', formData.contactEmails.length)}
                            />
                        </Tooltip>
                    ) : null}
                </div>
            </div>

            {/* FAX & ACCOUNT DETAILS */}
            <div className={`customer-form__otherdetails-section${formStyle}`}>
                <div className={`customer-form__otherdetails-section--left${formStyle}`}>
                    <LabelledInput
                        labelText="Fax #"
                        value={formData.faxNumber}
                        onChangeAction={(value) => setFormData({ ...formData, faxNumber: value })}
                        isDisabled={!isEditing}
                        withValidation={isEditing}
                        isValid={validator.isMobilePhone(formData.faxNumber, ['en-CA', 'en-US'], {
                            strictMode: true,
                        })}
                        validationText="Enter a valid fax number"
                    />
                </div>
                <div className={`customer-form__otherdetails-section--right${formStyle}`}>
                    {/* ACTIVE SWITCH */}
                    <div
                        className={`customer-form__otherdetails-section__switch__container${formStyle}`}
                    >
                        <div className="input-label" style={{ marginRight: 10 }}>
                            Active
                        </div>
                        <Switch
                            checkedChildren="YES"
                            unCheckedChildren="NO"
                            checked={formData.active}
                            onChange={(checked) => setFormData({ ...formData, active: checked })}
                            disabled={!isEditing}
                        />
                    </div>

                    {/* BILLING FREQUENCY */}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="input-label" style={{ marginTop: 25 }}>
                            Billing Frequency
                        </div>
                        <Select
                            size="large"
                            placeholder="Select one"
                            value={formData.billingFrequency}
                            status={formData.billingFrequency ? null : 'error'}
                            style={
                                formStyle.includes('mobile') ? { width: '100%' } : { width: 250 }
                            }
                            onChange={(value) => {
                                setFormData({
                                    ...formData,
                                    billingFrequency: value.split('_')[1],
                                });
                            }}
                            disabled={!isEditing}
                        >
                            {['daily', 'weekly', 'monthly'].map((frequency) => (
                                <Option key={`customer-billing-frequency_${frequency}`}>
                                    {frequency}
                                </Option>
                            ))}
                        </Select>
                    </div>

                    {/* RATE PROFILE */}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="input-label" style={{ marginTop: 25 }}>
                            Rate Profile
                        </div>
                        <Select
                            size="large"
                            placeholder="Select one"
                            value={formData.rateProfile ? formData.rateProfile.profileName : null}
                            status={formData.rateProfile ? null : 'error'}
                            style={
                                formStyle.includes('mobile') ? { width: '100%' } : { width: 250 }
                            }
                            onChange={(value) => {
                                const rateId = value.split('_')[1];
                                const newRate = customerRates.find(
                                    (rate) =>
                                        `${rate.customerRateProfile.customerRateProfileId}` ===
                                        rateId
                                );
                                setFormData({
                                    ...formData,
                                    rateProfile: { ...newRate.customerRateProfile },
                                });
                            }}
                            disabled={!isEditing}
                        >
                            {customerRates.map((rate) => (
                                <Option
                                    key={`customer-rate-type_${rate.customerRateProfile.customerRateProfileId}`}
                                >
                                    {rate.customerRateProfile.profileName}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
            </div>

            {/* ADDRESS */}
            <AddressForm
                formData={extractAddressData()}
                setFieldValue={(newFieldValue) =>
                    setFormData({
                        ...formData,
                        contactInfo: { ...formData.contactInfo, ...newFieldValue },
                    })
                }
                formStyle={formStyle}
                isEditing={isEditing}
            />
        </div>
    );
};
export default CustomerForm;
