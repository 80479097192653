import { useSelector } from 'react-redux';
import { DEVICE_TYPE } from '../../store/constants/appActionTypes';
import { beautifyTextValue } from '../../util/formUtil';

const TruckInvoiceTemplate = ({ invoiceData, customerData }) => {
    const deviceType = useSelector((state) => state.app.device);
    const formStyle = deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : '';

    const getLabelledData = (label, data) => (
        <div style={{ display: 'flex' }}>
            <div className="input-label" style={{ fontWeight: 'bold', marginRight: 5 }}>
                {`${label}:`}
            </div>
            {data}
        </div>
    );

    const getDetailedCustomerData = () => (
        <>
            <div>{`${customerData.contactInfo.firstName} ${customerData.contactInfo.lastName} | ${customerData.orgName}`}</div>
            <div>{customerData.contactInfo.addressLineOne}</div>
            <div>{customerData.contactInfo.addressLineTwo}</div>
            <div>{`${customerData.contactInfo.city} ${customerData.contactInfo.province} ${customerData.contactInfo.postalCode}`}</div>
            <div>{customerData.contactInfo.country}</div>
            <div>{customerData.contactEmails ? customerData.contactEmails[0] : ''}</div>
            <div>{`Phone: ${customerData.contactInfo.phoneNumber}`}</div>
            <div>{`Fax: ${customerData.faxNumber}`}</div>
        </>
    );

    const getBasicCustomerData = () => (
        <>
            <div>{invoiceData.customerName}</div>
            <div>{invoiceData.customerAddress}</div>
        </>
    );

    return (
        <div
            className={`truck-invoice-template__container${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
        >
            {invoiceData ? (
                <>
                    {/* HEADER */}
                    <div
                        className={`truck-invoice-template__header__container${
                            deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                        }`}
                    >
                        {/* BILLING DETAILS */}
                        <div
                            className={`truck-invoice-template__billing-details__container${formStyle}`}
                        >
                            <div
                                className="input-label"
                                style={{ fontSize: 20, fontWeight: 'bold' }}
                            >
                                Billed To
                            </div>

                            {/* Customer data fetch depends on user permissions */}
                            {customerData ? getDetailedCustomerData() : getBasicCustomerData()}
                        </div>

                        {/* INVOICE DETAILS */}
                        <div
                            className={`truck-invoice-template__invoice-details__container${formStyle}`}
                        >
                            {getLabelledData(
                                'Invoice Date',
                                new Date(invoiceData.createdTs).toDateString()
                            )}
                            {getLabelledData('Invoice Reference', invoiceData.invoiceReference)}

                            {getLabelledData('Incomplete Reason', invoiceData.incompleteReason)}
                        </div>
                    </div>

                    {/* CONTENT */}
                    <div
                        className={`truck-invoice-template__content__container${
                            deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                        }`}
                    >
                        {/* LINE HAUL */}

                        <div
                            className={`truck-invoice-template__service__container${
                                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                            }`}
                        >
                            <div
                                className="input-label"
                                style={{ fontSize: 20, fontWeight: 'bold' }}
                            >
                                Line Haul
                            </div>
                            {beautifyTextValue(invoiceData.invoiceLineHaulStatus)}
                        </div>

                        {/* SPECIAL SERVICE */}
                        {invoiceData.specialService ? (
                            <div
                                className={`truck-invoice-template__service__container${
                                    deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                                }`}
                            >
                                <div
                                    className="input-label"
                                    style={{ fontSize: 20, fontWeight: 'bold' }}
                                >
                                    Special Service
                                </div>
                                {invoiceData.specialService}
                            </div>
                        ) : null}

                        {/* OTHER SERVICES */}
                        <div
                            className={`truck-invoice-template__service__container${
                                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                            }`}
                        >
                            <div
                                className="input-label"
                                style={{ fontSize: 20, fontWeight: 'bold' }}
                            >
                                Other Services
                            </div>
                            <div
                                className={`truck-invoice-template__service-invoice-line${
                                    deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                                }`}
                                style={{ fontWeight: 'bold' }}
                            >
                                <div
                                    style={
                                        deviceType === DEVICE_TYPE.NOT_MOBILE
                                            ? { width: '70%' }
                                            : { width: '50%' }
                                    }
                                >
                                    Service Type
                                </div>
                                <div
                                    className="truck-invoice-template__service-invoice-line-item--centered"
                                    style={
                                        deviceType === DEVICE_TYPE.NOT_MOBILE
                                            ? { width: '15%' }
                                            : { width: '25%' }
                                    }
                                >
                                    Quantity
                                </div>
                                <div
                                    className="truck-invoice-template__service-invoice-line-item--centered"
                                    style={
                                        deviceType === DEVICE_TYPE.NOT_MOBILE
                                            ? { width: '15%' }
                                            : { width: '25%' }
                                    }
                                >
                                    Settled Rate
                                </div>
                            </div>
                            {invoiceData.invoiceServices.map((service, idx) => (
                                <div
                                    key={`invoice-service-${service.invoiceServiceId}`}
                                    className={`truck-invoice-template__service-invoice-line${
                                        deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                                    }`}
                                    style={idx % 2 === 0 ? {} : { backgroundColor: '#88bee2' }}
                                >
                                    <div
                                        style={
                                            deviceType === DEVICE_TYPE.NOT_MOBILE
                                                ? { width: '70%' }
                                                : { width: '50%' }
                                        }
                                    >
                                        {service.label}
                                    </div>
                                    <div
                                        className="truck-invoice-template__service-invoice-line-item--centered"
                                        style={
                                            deviceType === DEVICE_TYPE.NOT_MOBILE
                                                ? { width: '15%' }
                                                : { width: '25%' }
                                        }
                                    >
                                        {service.quantity}
                                    </div>
                                    <div
                                        className="truck-invoice-template__service-invoice-line-item--centered"
                                        style={
                                            deviceType === DEVICE_TYPE.NOT_MOBILE
                                                ? { width: '15%' }
                                                : { width: '25%' }
                                        }
                                    >
                                        {service.settledRate}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* FOOTER */}
                    <div className="page-break" />
                    <div
                        className={`truck-invoice-template__footer__container${
                            deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                        }`}
                    >
                        {/* REVENUE */}
                        <div className="input-label" style={{ fontSize: 20, fontWeight: 'bold' }}>
                            {`Revenue Collected: $${(
                                Math.round(invoiceData.revenueCollected * 100) / 100
                            ).toFixed(2)}`}
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    );
};
export default TruckInvoiceTemplate;
