/* eslint-disable dot-notation */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import TrucksApi from '../../../api/dataEntry/trucks';

import Breadcrumbs from '../../../component/reusable/Breadcrumbs';
import TableComponent from '../../../component/reusable/TableComponent';
import { APP_ACTION_TYPES, DEVICE_TYPE } from '../../../store/constants/appActionTypes';
import { beautifyTextValue } from '../../../util/formUtil';
import { shouldSee } from '../../../util/permissionsUtil';
import { TRUCK_COLUMNS } from '../../../util/tableColumns';

const BREADCRUMB_LINKS = [
    { title: 'Data Management', href: `${(window.location, origin)}/datamanagement` },
    { title: 'Trucks', href: null },
];

const TrucksTable = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const deviceType = useSelector((state) => state.app.device);

    const [currentPage, setCurrentPage] = useState(1);
    const [truckData, setTruckData] = useState([]);
    const [truckCount, setTruckCount] = useState(0);

    useEffect(() => {
        getTruckData();
    }, [currentPage]);

    const getTruckData = async () => {
        const skip = (currentPage - 1) * 10;
        const trucks = await TrucksApi.getTrucks(true, skip);

        setTruckCount(trucks.count);
        setTruckData([...trucks.data]);
    };

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const goToTruckRecord = (record) => {
        // Mobile list click will not contain truckId required to navigate to selected truck
        if (!record.hasOwnProperty('truckId')) {
            record['truckId'] = record.id;
        }

        dispatch({
            type: APP_ACTION_TYPES.SET_EDITING_INVOICE,
            payload: {
                ...record,
            },
        });
        history.push('/dataentry');
    };

    const getMobileData = () => {
        const filteredData = [...truckData];
        const mobileData = [];
        filteredData.forEach((record) => {
            const { firstName, lastName } = record.driver.contactInfo;
            const { nickname } = record.driver;

            mobileData.push({
                id: record.truckId,
                title: `${record.tdm.routeName} ${beautifyTextValue(record.loadType)} | ${
                    record.date
                }`,

                subtitle: `${firstName} ${lastName} (${nickname})`,
            });
        });
        return mobileData;
    };

    return (
        <div
            className={`drivers-table__container${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
        >
            <Breadcrumbs links={BREADCRUMB_LINKS} />

            {/* TABLE */}
            <TableComponent
                rightButtonText={shouldSee(['TRUCK_CREATE']) ? 'CREATE TRUCK' : null}
                rightButtonAction={() => history.push('dataentry')}
                tableColumns={TRUCK_COLUMNS}
                tableData={truckData}
                mobileListData={getMobileData()}
                currentPage={currentPage}
                onPageChange={onPageChange}
                totalPageCount={truckCount}
                onRowClick={(record) => goToTruckRecord(record)}
            />
        </div>
    );
};
export default TrucksTable;
