/* eslint-disable no-unreachable */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';

import Breadcrumbs from '../../../component/reusable/Breadcrumbs';

import DriversLogo from '../../../images/driver.png';
import { DEVICE_TYPE } from '../../../store/constants/appActionTypes';
import DriverForm from './DriverForm';
import DriversApi from '../../../api/dataManagement/drivers';
import DriverRatesApi from '../../../api/rateProfiles/driverRates';
import EntityFormComponent from '../../../component/reusable/EntityFormComponent';
import {
    driverRateFetchDataErrorText,
    driversFetchDataErrorText,
    driversSaveDataErrorText,
    driversSaveDataSuccessText,
    driversUpdateDataErrorText,
    driversUpdateDataSuccessText,
} from '../../../util/longText';
import { TITLE_OPTIONS } from '../../../util/formUtil';
import { onAPIError, onAPISuccess } from '../../../util/apiUtil';
import { displaySimpleErrorModal } from '../../../component/global/ModalController';
import { shouldSee } from '../../../util/permissionsUtil';
import { CANADA_IDENTIFIERS } from '../../../component/reusable/AddressForm';

const BREADCRUMB_LINKS = [
    { title: 'Data Management', href: `${(window.location, origin)}/datamanagement` },
    { title: 'Drivers', href: `${(window.location, origin)}/drivers` },
    { title: 'Driver', href: null },
];

const DEFAULT_FORM_DATA = {
    title: TITLE_OPTIONS[0],
    contactInfo: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        addressLineOne: '',
        addressLineTwo: '',
        city: '',
        province: '',
        postalCode: '',
        country: 'CANADA',
    },
    nickname: '',
    rateProfile: null,
    workPhoneNumber: '',
    email: '',
    active: true,
};

const Driver = ({ match }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const deviceType = useSelector((state) => state.app.device);
    const newDriver = match.params.id === 'new';

    const [formData, setFormData] = useState(DEFAULT_FORM_DATA);
    const [driverRates, setDriverRates] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        // If displaying existing driver, fetch data
        if (!newDriver) {
            setDriverData();
        }
        if (shouldSee(['DRP_READ_MANY'])) {
            DriverRatesApi.getDriverRates('', false, 0, 10000)
                .then((driverRates) => {
                    setDriverRates(driverRates.data);
                })
                .catch(() => displaySimpleErrorModal(dispatch, driverRateFetchDataErrorText));
        }
    }, []);

    const setDriverData = () => {
        setIsFetching(true);

        DriversApi.getDriverById(match.params.id)
            .then((driverData) => {
                setFormData({ ...driverData });
                setIsFetching(false);
            })
            .catch(() =>
                displaySimpleErrorModal(dispatch, driversFetchDataErrorText, () =>
                    history.push('/drivers')
                )
            );
    };

    const isRequiredDataEntered = () => {
        if (formData.contactInfo.firstName.length <= 1) {
            return false;
        }
        if (formData.contactInfo.lastName.length <= 1) {
            return false;
        }
        if (formData.nickname.length <= 1) {
            return false;
        }
        if (
            !validator.isMobilePhone(formData.workPhoneNumber, ['en-CA', 'en-US'], {
                strictMode: true,
            })
        ) {
            return false;
        }
        if (!formData.email || !validator.isEmail(formData.email)) {
            return false;
        }
        if (formData.contactInfo.addressLineOne.length <= 2) {
            return false;
        }
        if (formData.contactInfo.city.length <= 2) {
            return false;
        }
        if (formData.contactInfo.province === '') {
            return false;
        }
        if (
            !validator.isPostalCode(
                formData.contactInfo.postalCode,
                CANADA_IDENTIFIERS.includes(formData.contactInfo.country) ? 'CA' : 'US'
            )
        ) {
            return false;
        }
        if (formData.rateProfile === null) {
            return false;
        }

        return true;
    };

    const saveDriver = (isNewDriver) => {
        setIsSaving(true);
        // Extract rate profile ID
        formData.driverRateProfileId = parseInt(formData.rateProfile.driverRateProfileId, 10);

        if (isNewDriver) {
            // Flow to save new driver record
            DriversApi.createDriver(formData)
                .then(() => {
                    setIsSaving(false);
                    onAPISuccess(dispatch, driversSaveDataSuccessText);
                    history.push('/drivers');
                })
                .catch(() => {
                    setIsSaving(false);
                    onAPIError(dispatch, driversSaveDataErrorText);
                });
        } else {
            // Flow to update record

            DriversApi.updateDriver(formData)
                .then(() => {
                    setIsSaving(false);
                    onAPISuccess(dispatch, driversUpdateDataSuccessText);
                    history.push('/drivers');
                })
                .catch(() => {
                    setIsSaving(false);
                    onAPIError(dispatch, driversUpdateDataErrorText);
                });
        }
    };

    return (
        <div className={`driver__container${deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''}`}>
            <Breadcrumbs links={BREADCRUMB_LINKS} />

            <EntityFormComponent
                logo={DriversLogo}
                title={
                    newDriver
                        ? 'New Driver'
                        : isFetching
                        ? 'Loading...'
                        : `${formData.contactInfo.firstName} ${formData.contactInfo.lastName}`
                }
                editAction={
                    newDriver || isEditing || !shouldSee(['DRIVER_UPDATE'])
                        ? null
                        : () => setIsEditing(true)
                }
                isFetching={isFetching}
                isEditing={isEditing}
                isSaving={isSaving}
                formHeader="Driver"
                formComponent={
                    <DriverForm
                        formData={formData}
                        driverRates={driverRates}
                        setFormData={setFormData}
                        isEditing={newDriver || isEditing}
                        formStyle={deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''}
                    />
                }
                newEntity={newDriver}
                onCancelClick={
                    newDriver
                        ? () => history.push('/datamanagement')
                        : () => {
                              setIsEditing(false);
                              setDriverData();
                          }
                }
                onSaveClick={() => saveDriver(newDriver)}
                formValid={isRequiredDataEntered()}
            />
        </div>
    );
};
export default Driver;
