import { useDispatch, useSelector } from 'react-redux';

import TableComponent from '../../reusable/TableComponent';
import { TRUCKS_NEEDING_SUP_PAY_COLUMNS } from '../../../util/tableColumns';
import { DEVICE_TYPE } from '../../../store/constants/appActionTypes';
import { TODO_ACTION_TYPES } from '../../../store/constants/todoActionTypes';
import SupplementalPayModal from '../../global/SupplementalPayModal';

const TrucksNeedingSupPay = () => {
    const dispatch = useDispatch();
    const deviceType = useSelector((state) => state.app.device);
    const trucksNeedingSupPay = useSelector((state) => state.todo.trucksNeedingSupPay);
    const displaySupplementalDataModal = useSelector((state) => state.todo.supplementalDataModal);

    const getMobileData = () => {
        const filteredData = [...trucksNeedingSupPay];
        const mobileData = [];
        filteredData.forEach((record) => {
            mobileData.push({
                id: record.truckId,
                title: `${record.date} | ${record.tdm.routeName}`,

                subtitle: `Driver: ${record.driver.contactInfo.firstName} ${record.driver.contactInfo.lastName}`,
            });
        });
        return mobileData;
    };

    const handleRowClick = (record) => {
        dispatch({
            type: TODO_ACTION_TYPES.SET_DISPLAY_SUPPLEMENTAL_DATA_MODAL,
            payload: record,
        });
    };

    return (
        <div
            className={`todo-list__truck-supplemental-pay__container${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
        >
            <div className="heading--smaller">The trucks below need supplemental pay details</div>

            <TableComponent
                tableColumns={TRUCKS_NEEDING_SUP_PAY_COLUMNS}
                tableData={trucksNeedingSupPay}
                noPagination
                mobileListData={getMobileData()}
                onRowClick={(record) => handleRowClick(record)}
                onMobileActionClick={(record) => handleRowClick(record)}
                customWidth={800}
            />

            {displaySupplementalDataModal ? (
                <SupplementalPayModal modalData={displaySupplementalDataModal} />
            ) : null}
        </div>
    );
};
export default TrucksNeedingSupPay;
