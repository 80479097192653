import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Modal, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { DEVICE_TYPE } from '../../store/constants/appActionTypes';
import { InfoModalHeader } from '../global/ModalController';
import LabelledInput from '../reusable/LabelledInput';

const TierRates = ({
    entityKey,
    rateDetails,
    rates,
    displayUpdateModal,
    setDisplayUpdateModal,
    updateRate,
    submittingRateUpdate,
    archiveRate,
}) => {
    const deviceType = useSelector((state) => state.app.device);
    const [updatedRates, setUpdatedRates] = useState([...rates]);

    const getModalContent = () => (
        <>
            {updatedRates.map((rate) => (
                <LabelledInput
                    key={`rate-update-input-${rate.rateId}`}
                    labelText={rate.label}
                    value={rate.activeRate}
                    onChangeAction={(value) => setNewRateValue(rate, value)}
                    noTopMargin
                    withValidation
                    isValid={rate.activeRate.length > 0}
                    validationText="Enter a value"
                />
            ))}
        </>
    );

    const setNewRateValue = (rate, newValue) => {
        const currentRates = [...updatedRates];
        const indexToUpdate = currentRates.findIndex(
            (currentRate) => currentRate.rateId === rate.rateId
        );
        currentRates[indexToUpdate] = { ...currentRates[indexToUpdate], activeRate: newValue };

        setUpdatedRates([...currentRates]);
    };

    return (
        <div
            className={`tier-rates__container${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
        >
            {/* TIER NAME */}
            <div className="tier-rates__name">{rateDetails.profileName}</div>

            {/* TIER RATES */}
            {rates.map((rate) => (
                <div
                    key={`individual-rate-${rateDetails.profileName}-${rate.label}`}
                    className="tier-rates__rate-line"
                >
                    <div className="tier-rates__rate-line__type">{rate.label}</div>
                    <div className="tier-rates__rate-line__value">{rate.activeRate}</div>
                </div>
            ))}

            {/* ACTIONS */}
            <div className="tier-rates__actions">
                {archiveRate ? (
                    <Tooltip title="Delete Rate">
                        <DeleteOutlined
                            style={{
                                fontSize: 20,
                                cursor: 'pointer',
                            }}
                            onClick={() => archiveRate(rateDetails[`${entityKey}Id`])}
                        />
                    </Tooltip>
                ) : null}
                {updateRate ? (
                    <Tooltip title="Edit Rates">
                        <EditOutlined
                            style={{
                                fontSize: 20,
                                cursor: 'pointer',
                            }}
                            onClick={() => setDisplayUpdateModal(true)}
                        />
                    </Tooltip>
                ) : null}
            </div>

            {/* EDIT MODAL */}
            <Modal
                title={InfoModalHeader(`Update ${rateDetails.profileName} Rate`)}
                visible={displayUpdateModal}
                onOk={() => updateRate(rateDetails, updatedRates)}
                onCancel={() => {
                    setDisplayUpdateModal(false);
                    setUpdatedRates([...rates]);
                }}
                closable={false}
                keyboard={false}
                maskClosable={false}
                confirmLoading={submittingRateUpdate}
                okButtonProps={
                    updatedRates.every((rate) => rate.activeRate.length > 0)
                        ? {}
                        : { disabled: true }
                }
                cancelButtonProps={submittingRateUpdate ? { disabled: true } : {}}
            >
                {getModalContent()}
            </Modal>
        </div>
    );
};

export default TierRates;

TierRates.propTypes = {
    // The details of the tier inc.: id, name, active, created, updated
    rateDetails: PropTypes.objectOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
            PropTypes.instanceOf(Date),
        ])
    ).isRequired,
    // Rate data to be displayed in the tier
    rates: PropTypes.arrayOf(
        PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
    ).isRequired,
};
