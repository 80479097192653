import axios from 'axios';
import { getAuthHeader, getErrorMessage, getHost } from '../../util/apiUtil';
import { shouldSee } from '../../util/permissionsUtil';

const PAYROLL_URL = `${getHost()}/payroll`;

class PayrollApi {
    static getAllPayrollData(skip = 0, take = 10) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['PAYROLL_READ'])) {
                console.error('API ERROR | Payroll: User not permitted');
                reject();
            }

            axios
                .get(`${PAYROLL_URL}/all?take=${take}&skip=${skip}`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Payroll: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static getSinglePayrollData(payrollId) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['PAYROLL_READ'])) {
                console.error('API ERROR | Payroll: User not permitted');
                reject();
            }

            axios
                .get(`${PAYROLL_URL}/${payrollId}`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Payroll: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static getUnavailableDates() {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['PAYROLL_READ'])) {
                console.error('API ERROR | Payroll: User not permitted');
                reject();
            }

            axios
                .get(`${PAYROLL_URL}/unavailableDays`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Payroll: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static createPayroll(startDate, endDate) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['PAYROLL_CREATE'])) {
                console.error('API ERROR | Payroll: User not permitted');
                reject();
            }

            axios
                .post(
                    `${PAYROLL_URL}/create`,
                    {
                        startDate,
                        endDate,
                    },
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Payroll: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static deletePayroll(payrollId) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['PAYROLL_READ'])) {
                console.error('API ERROR | Payroll: User not permitted');
                reject();
            }

            axios
                .delete(`${PAYROLL_URL}/${payrollId}`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Payroll: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static getEligibleTrucks(payrollId) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['PAYROLL_READ'])) {
                console.error('API ERROR | Payroll: User not permitted');
                reject();
            }

            axios
                .get(`${PAYROLL_URL}/eligibleTrucks/${payrollId}`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Payroll: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static adjustPayrollTrucks(payrollId, truckIds) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['PAYROLL_ADD_TRUCKS'])) {
                console.error('API ERROR | Payroll: User not permitted');
                reject();
            }

            axios
                .patch(
                    `${PAYROLL_URL}/adjustTrucks`,
                    {
                        payrollId,
                        truckIds,
                    },
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => {
                    // API will return 206 if partial operation was complete
                    if (data.status === 206) {
                        resolve(data.data);
                    }

                    resolve();
                })
                .catch((error) => {
                    console.error(`API ERROR | Payroll: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static addSupplementalPayData(truckId, specialPayDayCount) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['TRUCK_UPDATE'])) {
                console.error('API ERROR | Payroll: User not permitted');
                reject();
            }

            axios
                .post(
                    `${PAYROLL_URL}/addSupplementalData`,
                    {
                        truckId,
                        specialPayDayCount,
                    },
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    console.error(`API ERROR | Payroll: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static generatePayroll(payrollId) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['PAYROLL_GENERATE'])) {
                console.error('API ERROR | Payroll: User not permitted');
                reject();
            }

            axios
                .post(
                    `${PAYROLL_URL}/generate/${payrollId}`,
                    {},
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Payroll: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static generateSpecialPayrollPreview(driverId, truckIds) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['PAYROLL_GENERATE'])) {
                console.error('API ERROR | Payroll: User not permitted');
                reject();
            }

            axios
                .post(
                    `${PAYROLL_URL}/generateSpecialPreview`,
                    { driverId, truckIds },
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Payroll: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static finalizePayroll(payrollId) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['PAYROLL_GENERATE'])) {
                console.error('API ERROR | Payroll: User not permitted');
                reject();
            }

            axios
                .post(
                    `${PAYROLL_URL}/finalize/${payrollId}`,
                    {},
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Payroll: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static finalizeSpecialPayroll(driverId, truckIds) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['PAYROLL_GENERATE'])) {
                console.error('API ERROR | Payroll: User not permitted');
                reject();
            }

            axios
                .post(
                    `${PAYROLL_URL}/finalizeSpecialPayroll`,
                    { driverId, truckIds },
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Payroll: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static getPayrollDetails(payrollId) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['PAYROLL_READ'])) {
                console.error('API ERROR | Payroll: User not permitted');
                reject();
            }

            axios
                .get(`${PAYROLL_URL}/payrollDetails/${payrollId}`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Payroll: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static sendDriverPayrollReport(payrollId, employeePayrollId) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['PAYROLL_READ'])) {
                console.error('API ERROR | Payroll: User not permitted');
                reject();
            }

            axios
                .post(
                    `${PAYROLL_URL}/payrollReport`,
                    {
                        payrollId,
                        employeePayrollId,
                    },
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Payroll: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }
}
export default PayrollApi;
