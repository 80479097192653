/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { getAuthHeader, getErrorMessage, getHost } from '../util/apiUtil';

const CONFIG_URL = `${getHost()}/config`;
const AUTH_URL = `${getHost()}/account/auth`;
const PASSWORD_RESET_URL = `${getHost()}/account/password/reset`;
const PASSWORD_CHANGE_URL = `${getHost()}/account/user/password/change`;

class AuthenticationApi {
    static getcaptchaToken() {
        return new Promise((resolve, reject) => {
            axios
                .get(`${CONFIG_URL}/captchaToken`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Authentication: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static login(username, password, captchaToken) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${AUTH_URL}/login`, { email: username, password, captcha: captchaToken })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Authentication: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static ssoLogin() {
        return new Promise((resolve, reject) => {
            axios
                .post(`${AUTH_URL}/ssoLogin`, {})
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Authentication: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static refreshSession() {
        return new Promise((resolve, reject) => {
            axios
                .get(`${AUTH_URL}/refresh`, { headers: getAuthHeader() })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Authentication: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static changePassword(email, currentPassword, newPassword) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${PASSWORD_CHANGE_URL}`, {
                    email,
                    password: currentPassword,
                    newPassword,
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Authentication: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static getResetPasswordToken(email, captchaToken) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${PASSWORD_RESET_URL}/request`, {
                    email,
                    captcha: captchaToken,
                })
                .then(() => resolve())
                .catch((error) => {
                    console.error(`API ERROR | Authentication: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static getPasswordTokenDetails(resetPasswordToken) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${PASSWORD_RESET_URL}/${resetPasswordToken}`)
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Authentication: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static submitPasswordChange(captchaToken, passwordToken, email, newPassword) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${PASSWORD_RESET_URL}/change`, {
                    captcha: captchaToken,
                    passwordToken,
                    email,
                    newPassword,
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Authentication: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }
}
export default AuthenticationApi;
