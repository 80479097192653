/* eslint-disable max-len */
import { Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PulseLoader from 'react-spinners/PulseLoader';
import { MdOutlineLock } from 'react-icons/md';

import TrucksApi from '../../api/dataEntry/trucks';
import PayrollApi from '../../api/payroll/payroll';
import {
    displaySimpleErrorModal,
    getUserConfirmation,
} from '../../component/global/ModalController';
import PayrollTrucks from '../../component/payroll/PayrollTrucks';
import { DEVICE_TYPE } from '../../store/constants/appActionTypes';
import { onAPIError } from '../../util/apiUtil';
import { beautifyTextValue } from '../../util/formUtil';
import {
    generatePayrollDownloadErrorText,
    payrollAlreadyGeneratedAdjustTrucksHelperText,
    payrollDataFetchErrorText,
    payrollEligibleTrucksDontExistErrorText,
    payrollFinalizeErrorText,
    payrollFinalizeHelperText,
    payrollGenerateConfirmText,
    payrollGenerateDefaultErrorText,
    payrollGenerateWrongTruckStatusErrorText,
    payrollRegenerateHelperText,
    payrollRegenerateUnlockedTruckHelperText,
    payrollRelockErrorText,
} from '../../util/longText';
import { shouldSee } from '../../util/permissionsUtil';
import {
    PAYROLL_EMPLOYEES_COLUMNS,
    PAYROLL_TRUCKS_COLUMNS,
    PAYROLL_TRUCKS_NOT_GENERATED_COLUMNS,
} from '../../util/tableColumns';
import SupplementalPayModal from '../../component/global/SupplementalPayModal';
import EmployeePayrollDetails from '../../component/payroll/EmployeePayrollDetails';
import Breadcrumbs from '../../component/reusable/Breadcrumbs';
import ReportsApi from '../../api/reports/reports';
import { downloadFile, FILE_TYPE } from '../../util/fileDownloader';

const BREADCUMB_LINKS = [
    { title: 'All Payroll', href: `${(window.location, origin)}/invoices#payroll` },
    { title: 'Single Payroll', href: null },
];

const SinglePayroll = ({ match }) => {
    const dispatch = useDispatch();
    const payrollId = match.params.id;
    const deviceType = useSelector((state) => state.app.device);
    const displaySupplementalDataModal = useSelector((state) => state.todo.supplementalDataModal);
    const displayPayrollDetails = useSelector((state) => state.app.viewingEmployeePayrollDetails);

    const [payrollData, setPayrollData] = useState(null);
    const [payrollDetails, setPayrollDetails] = useState(null);
    const [eligibleTrucks, setEligibleTrucks] = useState([]);
    const [displayTruckEditModal, setDisplayTruckEditModal] = useState(false);

    useEffect(() => {
        getPayrollData();
    }, []);

    useEffect(() => {
        // If payroll already generated, get payroll details (charges included in payroll)
        if (payrollData && !['initialized', 'truck_selection'].includes(payrollData.status)) {
            getPayrollDetails();
        }
    }, [payrollData]);

    const getPayrollData = async () => {
        const payrollData = await PayrollApi.getSinglePayrollData(payrollId).catch(() =>
            displaySimpleErrorModal(dispatch, payrollDataFetchErrorText)
        );
        setPayrollData({ ...payrollData.payroll });

        PayrollApi.getEligibleTrucks(payrollId)
            .then((data) => {
                setEligibleTrucks([
                    ...data.additionalTrucks,
                    ...data.trucksInRange,
                    ...payrollData.payroll.trucks,
                ]);
            })
            .catch((e) => {
                const errMsg = e.includes('Cannot find truck')
                    ? payrollEligibleTrucksDontExistErrorText
                    : payrollDataFetchErrorText;
                displaySimpleErrorModal(dispatch, errMsg);
            });
    };

    const getPayrollDetails = async () => {
        PayrollApi.getPayrollDetails(payrollData.payrollId)
            .then((data) => {
                setPayrollDetails(data);
            })
            .catch(() => {
                displaySimpleErrorModal(dispatch, payrollDataFetchErrorText);
            });
    };

    const addPayrollStatusToTruckRecords = () => {
        return eligibleTrucks.map((truck) => ({
            ...truck,
            onPayroll: payrollData.trucks.some(
                (payrollTruck) => payrollTruck.truckId === truck.truckId
            ),
        }));
    };

    const generatePayroll = () => {
        setPayrollData(null);

        PayrollApi.generatePayroll(payrollId)
            .then(() => {
                getPayrollData();
            })
            .catch((e) => {
                getPayrollData();

                const errMsg = e.includes('have incorrect status')
                    ? payrollGenerateWrongTruckStatusErrorText
                    : payrollGenerateDefaultErrorText;
                onAPIError(dispatch, errMsg);
            });
    };

    const finalizePayroll = () => {
        setPayrollData(null);

        PayrollApi.finalizePayroll(payrollId)
            .then(() => {
                getPayrollData();
            })
            .catch(() => {
                getPayrollData();

                onAPIError(dispatch, payrollFinalizeErrorText);
            });
    };

    const relockAllTrucks = async () => {
        const payrollTrucks = [...payrollData.trucks];
        setPayrollData(null);

        let failedRelock = false;
        await Promise.all(
            payrollTrucks.map(async (truck) => {
                if (truck.status === 'unlocked') {
                    await TrucksApi.relockPayrollTruck(truck.truckId).catch(() => {
                        failedRelock = true;
                    });
                }
            })
        );

        if (failedRelock) {
            onAPIError(dispatch, payrollRelockErrorText);
        }

        getPayrollData();
    };

    const isPayrollNotYetGenerated = () =>
        payrollData && ['initialized', 'truck_selection'].includes(payrollData.status);

    const hasUnlockedTrucks = () =>
        payrollData && payrollData.trucks.some((truck) => truck.status === 'unlocked');

    const downloadEmployeePayroll = (employeeRecord) => {
        const { employeePayrollId, driver } = employeeRecord;

        ReportsApi.generateEmployeePayrollCSV(employeePayrollId)
            .then(async (data) => {
                // Generate file name
                const fileName = `${driver.contactInfo.firstName}_${driver.contactInfo.lastName}_${payrollData.startDate}-${payrollData.endDate}_payroll_summary.csv`;

                await downloadFile(data.data, FILE_TYPE.CSV, fileName).catch(() =>
                    onAPIError(dispatch, generatePayrollDownloadErrorText)
                );
            })
            .catch(() => {
                onAPIError(dispatch, generatePayrollDownloadErrorText);
            });
    };

    return (
        <div className="payroll-single__outer-container">
            <Breadcrumbs links={BREADCUMB_LINKS} />
            <div className="payroll-single__container">
                {!payrollData ? (
                    // SPINNER IF DATA LOADING
                    <div className="heading--smaller">
                        <span style={{ marginRight: 20 }}>Loading payroll data</span>
                        <PulseLoader color="#0e76bc" />
                    </div>
                ) : (
                    <div className="payroll-single__inner-container">
                        <div
                            className={`payroll-single__header${
                                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                            }`}
                        >
                            {/* PAYROLL DATES */}
                            <div>
                                <div className="payroll-single__title">{`Payroll: ${payrollData.startDate} - ${payrollData.endDate}`}</div>
                                <div className="payroll-single__sub-title">
                                    {`Created on: ${moment(payrollData.createdTs).format(
                                        'YYYY-MM-DD'
                                    )}`}
                                </div>
                            </div>

                            {/* ACTION BUTTONS */}
                            <div className="payroll-single__action-buttons__container">
                                {payrollData.status === 'sent' ? (
                                    <div className="payroll-single__finalized__container">
                                        <MdOutlineLock
                                            style={{
                                                fontSize: 28,
                                                cursor: 'pointer',
                                                marginRight: 10,
                                            }}
                                        />
                                        <span className="payroll-single__title">
                                            PAYROLL FINALIZED
                                        </span>
                                    </div>
                                ) : null}
                                {shouldSee(['PAYROLL_ADD_TRUCKS']) &&
                                payrollData &&
                                payrollData.status !== 'sent' ? (
                                    <div
                                        className="standard-button"
                                        onClick={
                                            payrollData.alreadyGenerated
                                                ? () =>
                                                      getUserConfirmation(
                                                          dispatch,
                                                          payrollAlreadyGeneratedAdjustTrucksHelperText,
                                                          () => setDisplayTruckEditModal(true)
                                                      )
                                                : () => setDisplayTruckEditModal(true)
                                        }
                                    >
                                        ADD / REMOVE TRUCKS
                                    </div>
                                ) : null}
                                {shouldSee(['PAYROLL_FINALIZE']) &&
                                payrollData.status === 'generated' ? (
                                    <div
                                        className="standard-button"
                                        style={{ marginLeft: 10 }}
                                        onClick={() =>
                                            getUserConfirmation(
                                                dispatch,
                                                payrollFinalizeHelperText,
                                                () => finalizePayroll()
                                            )
                                        }
                                    >
                                        FINALIZE PAYROLL
                                    </div>
                                ) : null}
                            </div>
                        </div>

                        {/* STATUS & TOTALS */}
                        <div
                            className={`payroll-single__sub-header${
                                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                            }`}
                            style={{ marginTop: 45 }}
                        >
                            <div className="payroll-single__title--smaller">
                                {`Status: ${beautifyTextValue(payrollData.status)}`}
                            </div>
                            <div className="payroll-single__title--smaller">
                                {`Total Gross Pay Due: $${(
                                    Math.round(payrollData.sumOfGrossPayDue * 100) / 100
                                ).toFixed(2)}`}
                            </div>
                            <div className="payroll-single__title--smaller">
                                {`Sum of Special Pay: $${(
                                    Math.round(payrollData.sumOfSpecialPay * 100) / 100
                                ).toFixed(2)}`}
                            </div>
                        </div>

                        {/* PAYROLL TRUCKS */}
                        {!payrollData.isSpecialPayroll ? (
                            <div className="payroll-single__table">
                                <div className="payroll-single__table__header">
                                    <div className="payroll-single__title--smaller">
                                        Trucks on this payroll
                                    </div>
                                    {hasUnlockedTrucks() ? (
                                        <div
                                            className="standard-button--mini"
                                            onClick={() => relockAllTrucks()}
                                        >
                                            RE-LOCK ALL TRUCKS
                                        </div>
                                    ) : null}
                                </div>
                                <Table
                                    style={{ width: '100%' }}
                                    columns={
                                        isPayrollNotYetGenerated()
                                            ? PAYROLL_TRUCKS_NOT_GENERATED_COLUMNS
                                            : PAYROLL_TRUCKS_COLUMNS
                                    }
                                    dataSource={payrollData.trucks}
                                    pagination={false}
                                    rowKey={() => `payroll-truck_${Math.random()}`}
                                />
                            </div>
                        ) : (
                            <div
                                className="payroll-single__title"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    margin: '50px 0',
                                    color: '#eecc24',
                                }}
                            >
                                {`This is a special payroll record generated outside of regular payroll schedule for ${payrollData.employeePayrolls[0].driver.contactInfo.firstName}  ${payrollData.employeePayrolls[0].driver.contactInfo.lastName}`}
                            </div>
                        )}

                        {shouldSee(['PAYROLL_GENERATE']) && isPayrollNotYetGenerated() ? (
                            <div className="payroll-single__generate__container">
                                {parseFloat(payrollData.sumOfGrossPayDue) > 0 ? (
                                    <div className="payroll-single__generate__text">
                                        {hasUnlockedTrucks()
                                            ? payrollRegenerateUnlockedTruckHelperText
                                            : payrollRegenerateHelperText}
                                    </div>
                                ) : null}
                                <div
                                    className={`standard-button${
                                        hasUnlockedTrucks() ? '--disabled' : ''
                                    }`}
                                    onClick={
                                        hasUnlockedTrucks()
                                            ? null
                                            : () =>
                                                  getUserConfirmation(
                                                      dispatch,
                                                      payrollGenerateConfirmText,
                                                      () => generatePayroll()
                                                  )
                                    }
                                >
                                    GENERATE PAYROLL
                                </div>
                            </div>
                        ) : null}

                        {/* PAYROLL EMPLOYEES */}
                        {payrollData.employeePayrolls.length ? (
                            <div className="payroll-single__table">
                                <div className="payroll-single__title--smaller">
                                    {payrollData.isSpecialPayroll
                                        ? 'Special Payroll Details'
                                        : 'Employees on this payroll'}
                                </div>
                                <Table
                                    style={{ width: '100%' }}
                                    columns={PAYROLL_EMPLOYEES_COLUMNS(downloadEmployeePayroll)}
                                    dataSource={payrollData.employeePayrolls}
                                    pagination={false}
                                    rowKey={() => `payroll-employee_${Math.random()}`}
                                />
                            </div>
                        ) : null}
                    </div>
                )}

                {/* ADD/REMOVE TRUCK MODAL */}
                {displayTruckEditModal ? (
                    <PayrollTrucks
                        onCancel={() => setDisplayTruckEditModal(false)}
                        payrollTrucks={addPayrollStatusToTruckRecords()}
                        payrollData={payrollData}
                        fetchPayrollData={getPayrollData}
                    />
                ) : null}

                {/* SUPPLEMENTAL PAY MODAL */}
                {displaySupplementalDataModal ? (
                    <SupplementalPayModal
                        modalData={displaySupplementalDataModal}
                        onSubmitSuccess={getPayrollData}
                    />
                ) : null}

                {/* EMPLOYEE PAYROLL DETAILS */}
                {displayPayrollDetails ? (
                    <EmployeePayrollDetails
                        payrollDetails={payrollDetails[displayPayrollDetails.employeePayrollId]}
                    />
                ) : null}
            </div>
        </div>
    );
};
export default SinglePayroll;
