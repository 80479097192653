import axios from 'axios';
import { getAuthHeader, getErrorMessage, getHost } from '../../util/apiUtil';
import { shouldSee } from '../../util/permissionsUtil';

const CUSTOMER_URL = `${getHost()}/customer`;

class CustomersApi {
    static getCustomers(orgName = '', includeInactive = true, skip = 0, take = 10) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['CUSTOMER_READ_MANY'])) {
                console.error('API ERROR | Customers: User not permitted');
                reject();
            }

            let queriedUrl = `${CUSTOMER_URL}?take=${take}&skip=${skip}&includeInactive=${includeInactive}`;
            if (orgName.length) {
                queriedUrl += `&orgNameLike=${orgName}`;
            }

            axios
                .get(queriedUrl, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Customers: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static getCustomerById(id) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['CUSTOMER_READ'])) {
                console.error('API ERROR | Customers: User not permitted');
                reject();
            }

            axios
                .get(`${CUSTOMER_URL}/${id}`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Customers: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static getIncompleteCustomers() {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['CUSTOMER_READ_MANY'])) {
                console.error('API ERROR | Customers: User not permitted');
                reject();
            }
            axios
                .get(`${CUSTOMER_URL}?take=1000000&skip=0&showIncompleteOnly=true`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Customers: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static createCustomer(customerData) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['CUSTOMER_CREATE'])) {
                console.error('API ERROR | Customers: User not permitted');
                reject();
            }

            axios
                .post(
                    `${CUSTOMER_URL}`,
                    { ...customerData },
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Customers: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static updateCustomer(customerData) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['CUSTOMER_UPDATE'])) {
                console.error('API ERROR | Customers: User not permitted');
                reject();
            }

            axios
                .patch(CUSTOMER_URL, { ...customerData }, { headers: getAuthHeader() })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Customers: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static archiveCustomer(customerId) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['CUSTOMER_DELETE'])) {
                console.error('API ERROR | Customers: User not permitted');
                reject();
            }

            axios
                .delete(`${CUSTOMER_URL}/${customerId}`, { headers: getAuthHeader() })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Customers: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static getOrganizations() {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['CUSTOMER_LIST_ORGS'])) {
                console.error('API ERROR | Customers: User not permitted');
                reject();
            }

            const url = `${CUSTOMER_URL}/organizations`;

            axios
                .get(url, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Customers: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static getManagers() {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['CUSTOMER_LIST_MANAGERS'])) {
                console.error('API ERROR | Customers: User not permitted');
                reject();
            }

            const url = `${CUSTOMER_URL}/managers`;

            axios
                .get(url, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Customers: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }
}
export default CustomersApi;
