import { Input, Modal, Switch } from 'antd';
import { InfoModalHeader } from '../global/ModalController';

const TdmModal = ({
    editingTdmId,
    displayTdmModal,
    submitAddNewTdm,
    submitUpdateTdm,
    onCancel,
    submittingTdm,
    routeName,
    setRouteName,
    specialPayEligible,
    setSpecialPayEligible,
}) => {
    const getTdmModalContent = () => (
        <>
            <Input
                size="large"
                style={{ width: '100%' }}
                value={routeName}
                onChange={(e) => setRouteName(e.target.value)}
            />

            <div className="tdms-table__modal__special-pay-switch__container">
                <div className="input-label">Special Pay Eligible</div>
                <Switch
                    checkedChildren="YES"
                    unCheckedChildren="NO"
                    checked={specialPayEligible}
                    onChange={(checked) => setSpecialPayEligible(checked)}
                />
            </div>
        </>
    );

    return (
        <Modal
            title={InfoModalHeader(editingTdmId ? 'Update TDM' : 'Add New TDM')}
            visible={displayTdmModal}
            onOk={editingTdmId ? () => submitUpdateTdm() : () => submitAddNewTdm()}
            onCancel={onCancel}
            closable={false}
            keyboard={false}
            maskClosable={false}
            confirmLoading={submittingTdm}
            okButtonProps={routeName.length > 0 ? {} : { disabled: true }}
            cancelButtonProps={submittingTdm ? { disabled: true } : {}}
        >
            {getTdmModalContent()}
        </Modal>
    );
};
export default TdmModal;
