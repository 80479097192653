import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PulseLoader from 'react-spinners/PulseLoader';
import { Tooltip } from 'antd';

import AuditsApi from '../../api/audit/audits';
import { onAPIError } from '../../util/apiUtil';
import { auditDataFetchErrorText } from '../../util/longText';

const RatesHistory = ({ rateType }) => {
    const dispatch = useDispatch();

    const [audits, setAudits] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        setAuditData();
    }, []);

    const setAuditData = async () => {
        setIsFetching(true);

        const auditData = await AuditsApi.getAudits({ subCategory: [`${rateType}_rate`] }).catch(
            () => onAPIError(dispatch, auditDataFetchErrorText)
        );

        setAudits([...auditData]);
        setIsFetching(false);
    };

    const getExtraDetailsContent = (extraDetails) => {
        return (
            <div>
                {Object.entries(extraDetails).map(([title, detail]) => (
                    <div key={`audit-detail-${title}`}>{`${title}: ${detail}`}</div>
                ))}
            </div>
        );
    };

    const getHistoryTable = () => {
        return (
            <div className="tier-rates__history__table">
                {audits.map((audit) => (
                    <div key={`audit-line-${audit.auditId}`} className="tier-rates__history__row">
                        {`${audit.user.firstName} ${audit.user.lastName} ${audit.action} ${
                            audit.extraDetails.profileName || ''
                        } on ${moment(audit.auditTs).format('YYYY-MM-DD')} @ ${moment(audit.auditTs)
                            .subtract(4, 'h')
                            .format('HH:mm')}`}
                        {audit.extraDetails && Object.keys(audit.extraDetails).length ? (
                            <Tooltip title={getExtraDetailsContent(audit.extraDetails)}>
                                <span className="tier-rates__history__details">DETAILS</span>
                            </Tooltip>
                        ) : null}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="tier-rates__history__container">
            {isFetching ? <PulseLoader color="#0e76bc" size="10" /> : getHistoryTable()}
        </div>
    );
};
export default RatesHistory;
