import { DEVICE_TYPE } from '../../store/constants/appActionTypes';
import TruckServicesEntries from './TruckServicesEntries';
import TruckServicesSelector from './TruckServicesSelector';

const TruckServices = ({
    deviceType,
    invoiceData,
    payTypes,
    serviceTypes,
    updateInvoiceServices,
    updateInvoiceServiceValue,
    readOnly,
}) => {
    return (
        <>
            {/* PAY TYPE */}
            <div style={deviceType === DEVICE_TYPE.MOBILE ? { width: '100%' } : { width: '49%' }}>
                {/* SELECTOR (only for new invoice) */}
                {!readOnly ? (
                    <TruckServicesSelector
                        invoiceData={invoiceData}
                        updateInvoiceServices={updateInvoiceServices}
                        currentType="pay_type"
                        availableServicesArray={payTypes}
                    />
                ) : (
                    <div className="input-label" style={{ fontSize: 25, marginTop: 25 }}>
                        Pay Types
                    </div>
                )}

                {/* INDIVIDUAL ENTRIES */}
                <TruckServicesEntries
                    invoiceData={invoiceData}
                    deviceType={deviceType}
                    updateInvoiceServiceValue={updateInvoiceServiceValue}
                    currentType="pay_type"
                    availableServicesArray={payTypes}
                    readOnly={readOnly}
                />
            </div>

            {/* SERVICE TYPE */}
            <div style={deviceType === DEVICE_TYPE.MOBILE ? { width: '100%' } : { width: '49%' }}>
                {/* SELECTOR (only for new invoice) */}
                {!readOnly ? (
                    <TruckServicesSelector
                        invoiceData={invoiceData}
                        updateInvoiceServices={updateInvoiceServices}
                        currentType="service_type"
                        availableServicesArray={serviceTypes}
                    />
                ) : (
                    <div className="input-label" style={{ fontSize: 25, marginTop: 25 }}>
                        Service Types
                    </div>
                )}

                {/* INDIVIDUAL ENTRIES */}
                <TruckServicesEntries
                    invoiceData={invoiceData}
                    deviceType={deviceType}
                    updateInvoiceServiceValue={updateInvoiceServiceValue}
                    currentType="service_type"
                    availableServicesArray={serviceTypes}
                    readOnly={readOnly}
                />
            </div>
        </>
    );
};
export default TruckServices;
