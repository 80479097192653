import { Select } from 'antd';
import {
    LeftOutlined,
    VerticalLeftOutlined,
    RightOutlined,
    VerticalRightOutlined,
} from '@ant-design/icons';
import { DEVICE_TYPE } from '../../store/constants/appActionTypes';

const { Option } = Select;

/** Records must be passed in the following format:
 *  [{
 *    recordId: int,
 *    name: string
 *  }]
 */
const Navigation = ({
    records,
    selectedRecord,
    setSelectedRecord,
    deviceType,
    searchButtonText,
    searchButtonAction,
}) => {
    return (
        <div
            className={`data-entry-navigation__container${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
        >
            <div
                className={`data-entry-navigation__container--left${
                    deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                }`}
            >
                {/* SELECT MENU */}
                <div
                    className={`data-entry-navigation__select-menu__container${
                        deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                    }`}
                >
                    <Select
                        size="large"
                        value={
                            selectedRecord !== null && records.length
                                ? records[selectedRecord].name
                                : 'New'
                        }
                        style={{ width: '100%' }}
                        onChange={(value) =>
                            setSelectedRecord(
                                records.findIndex((record) => record.name === value) >= 0
                                    ? records.findIndex((record) => record.name === value)
                                    : null
                            )
                        }
                    >
                        {records.map((record, idx) => (
                            <Option key={`selectrecord_${idx}`} value={record.name}>
                                {record.name}
                            </Option>
                        ))}
                        <Option value="New">New</Option>
                    </Select>
                </div>

                {/* SEARCH (NON-MOBILE) */}
                {deviceType === DEVICE_TYPE.NOT_MOBILE && searchButtonText ? (
                    <div className="data-entry-navigation__search-menu__container">
                        <div className="standard-button" onClick={() => searchButtonAction()}>
                            {searchButtonText}
                        </div>
                    </div>
                ) : null}
            </div>

            {deviceType === DEVICE_TYPE.NOT_MOBILE ? (
                <div className="data-entry-navigation__container--right">
                    {/* ARROW MENU (NON-MOBILE) */}
                    <div className="data-entry-navigation__arrow-menu__container">
                        <div>
                            <VerticalRightOutlined
                                style={{ cursor: 'pointer', marginRight: 10 }}
                                onClick={() => setSelectedRecord(0)}
                            />
                            <LeftOutlined
                                style={{ cursor: 'pointer', marginRight: 10 }}
                                onClick={
                                    selectedRecord === null
                                        ? () => setSelectedRecord(records.length - 1)
                                        : selectedRecord > 0
                                        ? () => setSelectedRecord(selectedRecord - 1)
                                        : null
                                }
                            />
                            {`${
                                selectedRecord !== null ? selectedRecord + 1 : records.length + 1
                            } / ${records.length + 1}`}
                            <RightOutlined
                                style={{ cursor: 'pointer', marginLeft: 10 }}
                                onClick={
                                    selectedRecord === null
                                        ? null
                                        : selectedRecord + 1 < records.length
                                        ? () => setSelectedRecord(selectedRecord + 1)
                                        : () => setSelectedRecord(null)
                                }
                            />
                            <VerticalLeftOutlined
                                style={{ cursor: 'pointer', marginLeft: 10 }}
                                onClick={() => setSelectedRecord(null)}
                            />
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Navigation;
