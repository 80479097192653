import { displaySimpleErrorModal } from '../../global/ModalController';
import CustomersApi from '../../../api/dataManagement/customers';
import DriversApi from '../../../api/dataManagement/drivers';
import { TODO_ACTION_TYPES } from '../../../store/constants/todoActionTypes';
import {
    incompleteEntitiesFetchErrorText,
    invoicesFetchErrorText,
    trucksFetchErrorText,
    customerInvoicesFetchErrorText,
} from '../../../util/longText';
import { shouldSee } from '../../../util/permissionsUtil';
import ReportsApi from '../../../api/reports/reports';
import TrucksApi from '../../../api/dataEntry/trucks';

export const fetchIncompleteEntities = (dispatch) => {
    if (shouldSee(['DRIVER_READ_MANY'])) {
        const incompleteDrivers = DriversApi.getIncompleteDrivers();
        const incompleteCustomers = CustomersApi.getIncompleteCustomers();

        Promise.all([incompleteDrivers, incompleteCustomers])
            .then(([incompleteDrivers, incompleteCustomers]) => {
                const incompleteEntities = [
                    ...incompleteDrivers.data.map((driver) => ({
                        ...driver,
                        entityId: driver.driverId, // add entityId for the table
                    })),
                    ...incompleteCustomers.data.map((customer) => ({
                        ...customer,
                        entityId: customer.customerId, // add entityId for the table
                    })),
                ];

                dispatch({
                    type: TODO_ACTION_TYPES.SET_INCOMPLETE_ENTITIES,
                    payload: incompleteEntities,
                });
            })
            .catch(() => displaySimpleErrorModal(dispatch, incompleteEntitiesFetchErrorText));
    }
};

export const fetchInvoicesNeedingApproval = async (dispatch) => {
    if (shouldSee(['INVOICE_UPDATE'])) {
        const invoicesNeedingApproval = await ReportsApi.getCustomerInvoices(
            0,
            1000000,
            'generated'
        ).catch(() => displaySimpleErrorModal(dispatch, invoicesFetchErrorText));

        dispatch({
            type: TODO_ACTION_TYPES.SET_INVOICES_NEEDING_APPROVAL,
            payload: invoicesNeedingApproval.data,
        });
    }
};

export const fetchTrucksNeedingSupPay = async (dispatch) => {
    if (shouldSee(['TRUCK_UPDATE'])) {
        const trucksNeedingSupPay = await TrucksApi.getTruckNeedingSupPay().catch(() =>
            displaySimpleErrorModal(dispatch, trucksFetchErrorText)
        );

        dispatch({
            type: TODO_ACTION_TYPES.SET_TRUCKS_NEEDING_SUP_PAY,
            payload: trucksNeedingSupPay.data,
        });
    }
};

export const fetchCustomerInvoicesFailedToSend = async (dispatch) => {
    if (shouldSee(['INVOICE_READ_MANY'])) {
        const failedToSendInvoices = await ReportsApi.getCustomerInvoices(
            0,
            1000000,
            'failed_to_send'
        ).catch(() => displaySimpleErrorModal(dispatch, customerInvoicesFetchErrorText));

        dispatch({
            type: TODO_ACTION_TYPES.SET_INVOICES_FAILED_TO_SEND,
            payload: failedToSendInvoices.data,
        });
    }
};

export const fetchInvoicesOnHold = async (dispatch) => {
    if (shouldSee(['INVOICE_UPDATE'])) {
        const invoicesOnHold = await ReportsApi.getCustomerInvoices(0, 1000000, 'on_hold').catch(
            () => displaySimpleErrorModal(dispatch, invoicesFetchErrorText)
        );

        dispatch({
            type: TODO_ACTION_TYPES.SET_INVOICES_ON_HOLD,
            payload: invoicesOnHold.data,
        });
    }
};
