import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Modal, Switch, Checkbox } from 'antd';
import validator from 'validator';
import { Roles } from '@wx/common';

import TableComponent from '../reusable/TableComponent';

import { DEVICE_TYPE } from '../../store/constants/appActionTypes';

import UsersApi from '../../api/userManagement/users';
import { archiveUserAccount, USER_COLUMNS } from '../../util/tableColumns';
import { InfoModalHeader } from '../global/ModalController';
import LabelledInput from '../reusable/LabelledInput';
import { onAPIError, onAPISuccess } from '../../util/apiUtil';
import {
    newUserInviteSendError,
    newUserInviteSendSuccess,
    userDataFetchErrorText,
    userEditErrorText,
    userEditSuccessText,
} from '../../util/longText';
import { beautifyTextValue } from '../../util/formUtil';
import { shouldSee } from '../../util/permissionsUtil';

const Users = ({ match }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const deviceType = useSelector((state) => state.app.device);
    const pathName = window.location.pathname;
    const editRecordId = match.params.id;
    const loggedInUserId = useSelector((state) => state.app.userData.userId);

    const [userData, setUserData] = useState([]);
    const [userCount, setUserCount] = useState(0);
    const [assignableRoles, setAssignableRoles] = useState(
        Object.values(Roles).map((role) => ({
            label: beautifyTextValue(role),
            value: role,
        }))
    );

    const [currentPage, setCurrentPage] = useState(1);
    const [searchKey, setSearchKey] = useState('');
    const [showInactive, setShowInactive] = useState(true);

    // ADD MODAL
    const [displayNewUserModal, setDisplayNewUserModal] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [userFirstName, setUserFirstName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [userRoles, setUserRoles] = useState([]);

    const [submittingNewUser, setSubmittingNewUser] = useState(false);

    // EDIT MODAL
    const [displayEditUserModal, setDisplayEditUserModal] = useState(false);
    const [editingUser, setEditingUser] = useState(null);
    const [submittingEditUser, setSubmittingEditUser] = useState(false);

    useEffect(() => {
        getUserData();
    }, [searchKey, showInactive, currentPage]);

    useEffect(() => {
        // Reset page selection on show inactive toggle (causes errors otherwise)
        setCurrentPage(1);
    }, [showInactive]);

    // If path includes 'editing' set the editing record and change path back
    useEffect(() => {
        if (pathName.includes('editing')) {
            setDisplayEditUserModal(true);
            const editingUser = userData.find((user) => user.userId.toString() === editRecordId);
            setEditingUser(editingUser);
            setUserRoles(editingUser.roles.map((role) => role.role));
            history.push('/users');
        }
    }, [pathName]);

    // Dynamically disable assignable roles based on user selection, either read-only, or other
    useEffect(() => {
        let newAssignedRoles;
        if (userRoles.length === 0) {
            newAssignedRoles = Object.values(Roles).map((role) => ({
                label: beautifyTextValue(role),
                value: role,
            }));
        } else if (userRoles.includes(Roles.READ_ONLY)) {
            newAssignedRoles = Object.values(Roles).map((role) => ({
                label: beautifyTextValue(role),
                value: role,
                disabled: role !== Roles.READ_ONLY,
            }));
        } else {
            newAssignedRoles = Object.values(Roles).map((role) => ({
                label: beautifyTextValue(role),
                value: role,
                disabled: role === Roles.READ_ONLY,
            }));
        }

        // If user is edditing them self, disable the admin & read-only role checkbox
        // to prevent them from blocking them selves
        if (editingUser && loggedInUserId === editingUser.userId) {
            const indexOfAdminRole = newAssignedRoles.findIndex(
                (role) => role.value === Roles.ADMIN
            );
            const indexOfReadOnlyRole = newAssignedRoles.findIndex(
                (role) => role.value === Roles.READ_ONLY
            );
            newAssignedRoles[indexOfAdminRole] = {
                label: beautifyTextValue(Roles.ADMIN),
                value: Roles.ADMIN,
                disabled: true,
            };
            newAssignedRoles[indexOfReadOnlyRole] = {
                label: beautifyTextValue(Roles.READ_ONLY),
                value: Roles.READ_ONLY,
                disabled: true,
            };
        }

        setAssignableRoles(newAssignedRoles);
    }, [userRoles]);

    const getUserData = async () => {
        // Amount of records to be skipped based on page number, 0 if searching for something
        const skip = searchKey.length ? 0 : (currentPage - 1) * 10;
        const users = await UsersApi.getUsers(searchKey, showInactive, skip).catch(() =>
            onAPIError(dispatch, userDataFetchErrorText)
        );

        setUserCount(users.count);
        setUserData([...users.data]);
    };

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const getMobileData = () => {
        const filteredData = [...userData];
        const mobileData = [];
        filteredData.forEach((record) => {
            mobileData.push({
                id: record.userId,
                title: `${record.firstName} ${record.lastName}`,

                subtitle: record.email,
                active: record.active,
            });
        });
        return mobileData;
    };

    const isNewUserDataValid = () => {
        if (!validator.isEmail(userEmail)) return false;
        if (userFirstName.length < 2) return false;
        if (userLastName.length < 2) return false;
        if (!userRoles.length) return false;
        return true;
    };

    const isEditedUserDataValid = () => {
        if (!editingUser) return true;
        if (editingUser.firstName.length < 2) return false;
        if (editingUser.lastName.length < 2) return false;
        if (!userRoles.length) return false;
        return true;
    };

    const submitCreateUserInvite = () => {
        setSubmittingNewUser(true);

        UsersApi.createUserInvite({
            email: userEmail,
            firstName: userFirstName,
            lastName: userLastName,
            roles: userRoles,
        })
            .then(() => {
                setSubmittingNewUser(false);
                setDisplayNewUserModal(false);
                resetAddUserForm();

                onAPISuccess(dispatch, newUserInviteSendSuccess);
            })
            .catch(() => {
                setSubmittingNewUser(false);

                onAPIError(dispatch, newUserInviteSendError);
            });
    };

    const resetAddUserForm = () => {
        setUserEmail('');
        setUserFirstName('');
        setUserLastName('');
        setUserRoles([]);
    };

    const submitEditUser = () => {
        setSubmittingEditUser(true);

        UsersApi.updateUserAccount({
            userId: editingUser.userId,
            firstName: editingUser.firstName,
            lastName: editingUser.lastName,
            active: editingUser.active,
            roles: [...userRoles],
        })
            .then(() => {
                setSubmittingEditUser(false);
                setDisplayEditUserModal(false);
                setEditingUser(null);
                getUserData();
                setUserRoles([]);

                onAPISuccess(dispatch, userEditSuccessText);
            })
            .catch(() => {
                setSubmittingEditUser(false);

                onAPIError(dispatch, userEditErrorText);
            });
    };

    const getRolesSelector = () => (
        <div className="users__add-modal__roles__container">
            <div className="input-label" style={{ marginBottom: 5 }}>
                User Roles
            </div>
            <div className="users__add-modal__role-options__container">
                <Checkbox.Group
                    options={assignableRoles}
                    onChange={(checkedValues) => setUserRoles(checkedValues)}
                    value={[...userRoles]}
                />
            </div>
            {editingUser && loggedInUserId === editingUser.userId ? (
                <div style={{ fontStyle: 'italic' }}>
                    You cannot revoke admin privileges from your account
                </div>
            ) : null}
            {!userRoles.length ? (
                <div className="labelled-input__validation-error">Please select a role</div>
            ) : null}
        </div>
    );

    const getNewUserModalContent = () => (
        <>
            <LabelledInput
                noTopMargin
                labelText="User Email"
                value={userEmail}
                onChangeAction={(value) => setUserEmail(value)}
                withValidation
                isValid={validator.isEmail(userEmail)}
                validationText="Enter a valid email"
            />
            <LabelledInput
                noTopMargin
                labelText="First Name"
                value={userFirstName}
                onChangeAction={(value) => setUserFirstName(value)}
                withValidation
                isValid={userFirstName.length > 1}
                validationText="Enter a valid name"
            />
            <LabelledInput
                noTopMargin
                labelText="Last Name"
                value={userLastName}
                onChangeAction={(value) => setUserLastName(value)}
                withValidation
                isValid={userLastName.length > 1}
                validationText="Enter a valid name"
            />
            {getRolesSelector()}
        </>
    );

    const getEditUserModalContent = () =>
        editingUser ? (
            <>
                <LabelledInput
                    noTopMargin
                    labelText="First Name"
                    value={editingUser.firstName}
                    onChangeAction={(value) => setEditingUser({ ...editingUser, firstName: value })}
                    withValidation
                    isValid={editingUser.firstName.length > 1}
                    validationText="Enter a valid name"
                />
                <LabelledInput
                    noTopMargin
                    labelText="Last Name"
                    value={editingUser.lastName}
                    onChangeAction={(value) => setEditingUser({ ...editingUser, lastName: value })}
                    withValidation
                    isValid={editingUser.lastName.length > 1}
                    validationText="Enter a valid name"
                />
                {getRolesSelector()}
                <div className="users__edit-modal__active__container">
                    <div className="input-label">Active: </div>
                    <Switch
                        checkedChildren="YES"
                        unCheckedChildren="NO"
                        checked={editingUser.active}
                        onChange={() =>
                            setEditingUser({ ...editingUser, active: !editingUser.active })
                        }
                        style={{ marginLeft: 10 }}
                        disabled={loggedInUserId === editingUser.userId}
                    />
                    {loggedInUserId === editingUser.userId ? (
                        <div style={{ marginLeft: 10, fontStyle: 'italic' }}>
                            You cannot archive your own account
                        </div>
                    ) : null}
                </div>
            </>
        ) : null;

    return (
        <div className={`users__container${deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''}`}>
            {/* TABLE */}
            <TableComponent
                rightButtonText={shouldSee(['USER_MANAGE_INVITE']) ? 'ADD USER' : null}
                rightButtonAction={() => setDisplayNewUserModal(true)}
                searchValue={searchKey}
                searchOnChange={setSearchKey}
                searchPlaceholder="Search by email"
                switchValue={showInactive}
                switchOnChange={setShowInactive}
                switchHelpText="Show Inactive"
                tableColumns={USER_COLUMNS}
                tableData={userData}
                mobileListData={getMobileData()}
                currentPage={currentPage}
                onPageChange={onPageChange}
                totalPageCount={userCount}
                onRowClick={() => null}
                onMobileActionClick={
                    shouldSee(['USER_MANAGE_DELETE'])
                        ? (record) => archiveUserAccount(dispatch, history, record.id)
                        : null
                }
            />

            {/* ADD USER MODAL */}
            <Modal
                title={InfoModalHeader('Add New User')}
                visible={displayNewUserModal}
                onOk={() => submitCreateUserInvite()}
                onCancel={() => {
                    setDisplayNewUserModal(false);
                    resetAddUserForm();
                }}
                closable={false}
                keyboard={false}
                maskClosable={false}
                confirmLoading={submittingNewUser}
                okButtonProps={isNewUserDataValid() ? {} : { disabled: true }}
                cancelButtonProps={submittingNewUser ? { disabled: true } : {}}
            >
                {getNewUserModalContent()}
            </Modal>

            {/* EDIT USER MODAL */}
            <Modal
                title={InfoModalHeader(
                    editingUser ? `Edit ${editingUser.firstName} ${editingUser.lastName}` : ''
                )}
                visible={displayEditUserModal}
                onOk={() => submitEditUser()}
                onCancel={() => {
                    setDisplayEditUserModal(false);
                    setEditingUser(null);
                }}
                closable={false}
                keyboard={false}
                maskClosable={false}
                confirmLoading={submittingEditUser}
                okButtonProps={isEditedUserDataValid() ? {} : { disabled: true }}
                cancelButtonProps={submittingEditUser ? { disabled: true } : {}}
            >
                {getEditUserModalContent()}
            </Modal>
        </div>
    );
};
export default Users;
