import { Popover } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import TrucksApi from '../../api/dataEntry/trucks';
import DriversApi from '../../api/dataManagement/drivers';
import TdmsApi from '../../api/dataManagement/tdms';
import CustomersApi from '../../api/dataManagement/customers';
import InvoicesForm from '../../component/dataEntry/InvoicesForm';
import TruckForm from '../../component/dataEntry/TruckForm';
import { displaySimpleErrorModal } from '../../component/global/ModalController';
import { DEVICE_TYPE } from '../../store/constants/appActionTypes';
import { onAPIError, onAPISuccess } from '../../util/apiUtil';
import {
    customerRateFetchDataErrorText,
    customersFetchErrorText,
    driverRateFetchDataErrorText,
    driversFetchErrorText,
    organizationsFetchDataErrorText,
    tdmsFetchErrorText,
    trucksFetchErrorText,
    trucksSaveDataErrorText,
    trucksSaveDataSuccessText,
    trucksUpdateDataSuccessText,
} from '../../util/longText';
import { PERMISSION_CONDITION, shouldSee } from '../../util/permissionsUtil';
import QuickCreateItems from '../../component/dataEntry/QuickCreateItems';
import DriverRatesApi from '../../api/rateProfiles/driverRates';
import CustomerRatesApi from '../../api/rateProfiles/customerRates';

const DEFAULT_FORM_DATA = {
    truckId: null,
    date: new Date(),
    tdm: { routeName: '' },
    driver: { contactInfo: { firstName: '', lastName: '' } },
    helpers: [{}],
    loadType: '',
    trailerId: '',
};

const DataEntry = () => {
    const dispatch = useDispatch();
    const deviceType = useSelector((state) => state.app.device);
    const editingInvoice = useSelector((state) => state.app.editingInvoice);
    const [formData, setFormData] = useState({ ...DEFAULT_FORM_DATA });
    const [initialTruckDataFetchComplete, setInitialTruckDataFetchComplete] = useState(false);

    // Truck related
    const [truckRecords, setTruckRecords] = useState([]);
    const [allDrivers, setAllDrivers] = useState([]);
    const [availableDrivers, setAvailableDrivers] = useState([]);
    const [availableTdms, setAvailableTdms] = useState([]);
    const [savingTruck, setSavingTruck] = useState(false);
    const [savedNewTruck, setSavedNewTruck] = useState(false);
    const [selectedTruckIndex, setSelectedTruckIndex] = useState(null);
    const [isEditingTruck, setIsEditingTruck] = useState(false);
    const [driverRates, setDriverRates] = useState([]);
    const [customerRates, setCustomerRates] = useState([]);
    const [organizationOptions, setOrganizationOptions] = useState([]);

    const [customerRecords, setCustomerRecords] = useState([]);

    // Quick create modals
    const [quickCreateMenuVisible, setQuickCreateMenuVisible] = useState(false);
    const [displayQuickCreateDriver, setDisplayQuickCreateDriver] = useState(false);
    const [displayQuickCreateCustomer, setDisplayQuickCreateCustomer] = useState(false);
    const [displayQuickCreateTdm, setDisplayQuickCreateTdm] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        // Update list of available drivers when form updates to prevent
        // user picking the same driver/helper twice
        const takenDriverIds = [];
        if (formData.driver.driverId) {
            takenDriverIds.push(formData.driver.driverId);
        }

        formData.helpers.forEach((helper) => {
            if (helper.driverId) {
                takenDriverIds.push(helper.driverId);
            }
        });
        const updatedAvailableDrivers = [...allDrivers].filter(
            (driver) => !takenDriverIds.includes(driver.driverId)
        );

        setAvailableDrivers(updatedAvailableDrivers);
    }, [formData, isEditingTruck]);

    useEffect(() => {
        // On navigation action or truck data re-fetch
        if (selectedTruckIndex !== null) {
            // Populate form with truck data
            const currentTruck = truckRecords[selectedTruckIndex];
            setFormData(currentTruck);
        } else {
            // If created new truck, stay on it
            if (savedNewTruck) {
                setSelectedTruckIndex(truckRecords.length - 1);
                setSavedNewTruck(false);
            }
            setFormData({ ...DEFAULT_FORM_DATA });
        }

        // Cancel edit mode if user was editing
        setIsEditingTruck(false);
    }, [selectedTruckIndex, truckRecords]);

    useEffect(() => {
        /* If redirected from invoices or payroll, store will hold details
           of invoice which the user wants to edit */
        if (editingInvoice) {
            setPreselectedTruckRecord();
        }
    }, [editingInvoice]);

    const fetchData = () => {
        fetchDriverData();
        fetchDriverRateData();
        fetchTDMData();
        fetchTruckData();
        fetchCustomerData();
        fetchCustomerRateData();
        fetchCustomerOrganizationsData();
    };

    const fetchDriverData = () => {
        if (shouldSee(['DRIVER_READ_MANY'])) {
            DriversApi.getDrivers(undefined, false, 0, 100000)
                .then(({ data }) => {
                    const availDrivers = [];
                    data.forEach((driver) => {
                        if (driver.active) {
                            availDrivers.push(driver);
                        }
                    });
                    setAllDrivers(availDrivers);
                    setAvailableDrivers(availDrivers);
                })
                .catch(() => displaySimpleErrorModal(dispatch, driversFetchErrorText));
        }
    };

    const fetchDriverRateData = () => {
        if (shouldSee(['DRP_READ_MANY'])) {
            DriverRatesApi.getDriverRates('', false, 0, 10000)
                .then((driverRates) => {
                    setDriverRates(driverRates.data);
                })
                .catch(() => displaySimpleErrorModal(dispatch, driverRateFetchDataErrorText));
        }
    };

    const fetchTDMData = () => {
        if (shouldSee(['TDM_READ_MANY'])) {
            TdmsApi.getTdms(undefined, false, 0, 100000)
                .then(({ data }) => {
                    const availTdms = [];
                    data.forEach((tdm) => {
                        if (tdm.active) {
                            availTdms.push(tdm);
                        }
                    });
                    setAvailableTdms(availTdms);
                })
                .catch(() => displaySimpleErrorModal(dispatch, tdmsFetchErrorText));
        }
    };

    const fetchTruckData = () => {
        if (shouldSee(['TRUCK_READ_MANY'])) {
            TrucksApi.getTrucks(false, 0, 10000)
                .then(({ data }) => {
                    setTruckRecords(data);
                    setInitialTruckDataFetchComplete(true);
                })
                .catch(() => displaySimpleErrorModal(dispatch, trucksFetchErrorText));
        }
    };

    const fetchCustomerData = () => {
        if (shouldSee(['CUSTOMER_READ_MANY'])) {
            CustomersApi.getCustomers('', false, 0, 10000)
                .then(({ data }) => {
                    setCustomerRecords(data);
                })
                .catch(() => displaySimpleErrorModal(dispatch, customersFetchErrorText));
        }
    };

    const fetchCustomerRateData = () => {
        if (shouldSee(['CRP_READ_MANY'])) {
            CustomerRatesApi.getCustomerRates('', false, 0, 10000)
                .then((customerRates) => {
                    setCustomerRates(customerRates.data);
                })
                .catch(() => displaySimpleErrorModal(dispatch, customerRateFetchDataErrorText));
        }
    };

    const fetchCustomerOrganizationsData = () => {
        if (shouldSee(['CUSTOMER_LIST_ORGS'])) {
            CustomersApi.getOrganizations()
                .then((organizations) => {
                    const orgOptions = organizations.map((org) => ({ value: org, label: org }));
                    orgOptions.unshift({
                        value: null,
                        label: 'Select option or type...',
                        isDisabled: true,
                    });
                    setOrganizationOptions(orgOptions);
                })
                .catch(() => displaySimpleErrorModal(dispatch, organizationsFetchDataErrorText));
        }
    };

    const setPreselectedTruckRecord = () => {
        // Pre-select the truck record based on editing invoice truck ID
        if (shouldSee(['TRUCK_READ_MANY'])) {
            TrucksApi.getTrucks(false, 0, 10000)
                .then(({ data }) => {
                    setSelectedTruckIndex(
                        data.findIndex((record) => record.truckId === editingInvoice.truckId) >= 0
                            ? data.findIndex((record) => record.truckId === editingInvoice.truckId)
                            : null
                    );
                })
                .catch(() => displaySimpleErrorModal(dispatch, trucksFetchErrorText));
        }
    };

    const updateHelpersList = (newValue, position) => {
        const updatedHelpers = [...formData.helpers];
        if (newValue === null) {
            updatedHelpers.splice(position, 1);
        } else {
            if (position > updatedHelpers.length) {
                updatedHelpers.push({});
            } else {
                updatedHelpers[position] = newValue;
            }
        }
        setFormData({ ...formData, helpers: updatedHelpers });
    };

    const clearTruckForm = () => {
        setFormData({ ...DEFAULT_FORM_DATA });
    };

    const isTruckDataEntered = () => {
        if (!formData.tdm.hasOwnProperty('tdmId')) {
            return false;
        }
        if (!formData.driver.hasOwnProperty('driverId')) {
            return false;
        }
        if (!formData.loadType.length) {
            return false;
        }
        if (!formData.trailerId.length) {
            return false;
        }
        return true;
    };

    const saveTruck = () => {
        setSavingTruck(true);
        const truckData = {
            date: moment(formData.date).format('YYYY-MM-DD'),
            tdmId: formData.tdm.tdmId,
            driverId: formData.driver.driverId,
            trailerId: formData.trailerId,
            loadType: formData.loadType,
            helperIds: [],
        };

        formData.helpers.forEach((helper) => {
            if (helper.hasOwnProperty('driverId')) {
                truckData.helperIds.push(helper.driverId);
            }
        });

        if (!isEditingTruck) {
            // Truck create
            TrucksApi.createTruck(truckData)
                .then(() => {
                    setSavedNewTruck(true);
                    setSavingTruck(false);
                    setIsEditingTruck(false);
                    onAPISuccess(dispatch, trucksSaveDataSuccessText);
                    fetchData();
                })
                .catch(() => {
                    setSavingTruck(false);
                    onAPIError(dispatch, trucksSaveDataErrorText);
                });
        } else {
            // Truck update
            truckData.truckId = truckRecords.find(
                (truck) => truck.date === truckData.date && truck.tdmId === truckData.tdmId
            ).truckId;
            TrucksApi.updateTruck(truckData)
                .then(() => {
                    setSavingTruck(false);
                    setIsEditingTruck(false);
                    onAPISuccess(dispatch, trucksUpdateDataSuccessText);
                    fetchData();
                })
                .catch(() => {
                    setSavingTruck(false);
                    onAPIError(dispatch, trucksSaveDataErrorText);
                });
        }
    };

    const handleQuickCreateMenuVisibleChange = () => {
        setQuickCreateMenuVisible(!quickCreateMenuVisible);
    };

    return (
        <div
            className={`data-entry__container${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
        >
            <TruckForm
                formData={formData}
                truckRecords={truckRecords}
                initialTruckDataFetchComplete={initialTruckDataFetchComplete}
                availableDrivers={availableDrivers}
                availableTdms={availableTdms}
                setFieldValue={(newFieldValue) => setFormData({ ...formData, ...newFieldValue })}
                updateHelpersList={updateHelpersList}
                clearTruckForm={clearTruckForm}
                truckDataEntered={isTruckDataEntered()}
                savingTruck={savingTruck}
                saveTruck={saveTruck}
                selectedTruckIndex={selectedTruckIndex}
                setSelectedTruckIndex={setSelectedTruckIndex}
                isEditingTruck={isEditingTruck}
                setIsEditingTruck={setIsEditingTruck}
                userHasEditAccess={shouldSee(['TRUCK_CREATE', 'TRUCK_UPDATE'])}
            />

            <InvoicesForm
                truckId={
                    selectedTruckIndex === null
                        ? formData.truckId
                        : truckRecords[selectedTruckIndex].truckId
                }
                truckStatus={formData.status}
                customerRecords={customerRecords}
                userHasEditAccess={shouldSee(['INVOICE_CREATE', 'INVOICE_UPDATE'])}
            />

            {/* QUICK CREATE */}
            {shouldSee(
                ['CUSTOMER_CREATE', 'DRIVER_CREATE', 'TDM_CREATE'],
                PERMISSION_CONDITION.OR
            ) ? (
                <div className="data-entry__quick-create__container">
                    <Popover
                        content={
                            <QuickCreateMenu
                                setQuickCreateMenuVisible={setQuickCreateMenuVisible}
                                setDisplayQuickCreateDriver={setDisplayQuickCreateDriver}
                                setDisplayQuickCreateCustomer={setDisplayQuickCreateCustomer}
                                setDisplayQuickCreateTdm={setDisplayQuickCreateTdm}
                            />
                        }
                        trigger="click"
                        placement="topRight"
                        visible={quickCreateMenuVisible}
                        onVisibleChange={handleQuickCreateMenuVisibleChange}
                    >
                        <div className="data-entry__quick-create__button">+</div>
                    </Popover>
                </div>
            ) : null}

            {/* QUICK CREATE MODALS */}
            <QuickCreateItems
                displayQuickCreateDriver={displayQuickCreateDriver}
                setDisplayQuickCreateDriver={setDisplayQuickCreateDriver}
                driverRates={driverRates}
                fetchDriverData={fetchDriverData}
                displayQuickCreateCustomer={displayQuickCreateCustomer}
                setDisplayQuickCreateCustomer={setDisplayQuickCreateCustomer}
                customerRates={customerRates}
                organizationOptions={organizationOptions}
                fetchCustomerData={() => {
                    fetchCustomerData();
                    fetchCustomerOrganizationsData();
                }}
                displayQuickCreateTdm={displayQuickCreateTdm}
                setDisplayQuickCreateTdm={setDisplayQuickCreateTdm}
                fetchTDMData={fetchTDMData}
            />
        </div>
    );
};
export default DataEntry;

const QuickCreateMenu = ({
    setQuickCreateMenuVisible,
    setDisplayQuickCreateDriver,
    setDisplayQuickCreateCustomer,
    setDisplayQuickCreateTdm,
}) => {
    const OPTIONS = [
        {
            label: 'ADD CUSTOMER',
            locked: !shouldSee(['CUSTOMER_CREATE']),
            onClick: () => setDisplayQuickCreateCustomer(true),
        },
        {
            label: 'ADD DRIVER',
            locked: !shouldSee(['DRIVER_CREATE']),
            onClick: () => setDisplayQuickCreateDriver(true),
        },
        {
            label: 'ADD TDM',
            locked: !shouldSee(['TDM_CREATE']),
            onClick: () => setDisplayQuickCreateTdm(true),
        },
    ];
    return (
        <div className="quick-create-menu__container">
            {OPTIONS.map((option) =>
                option.locked ? null : (
                    <div
                        key={option.label}
                        className="quick-create-menu__cell"
                        onClick={() => {
                            option.onClick();
                            setQuickCreateMenuVisible(false);
                        }}
                    >
                        {option.label}
                    </div>
                )
            )}
        </div>
    );
};
