import { useSelector } from 'react-redux';
import { Input, Switch } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { DEVICE_TYPE } from '../../store/constants/appActionTypes';

const TableSearchAndFilter = ({
    searchValue,
    searchOnChange,
    searchPlaceholder,
    switchValue,
    switchOnChange,
    switchHelpText,
}) => {
    const deviceType = useSelector((state) => state.app.device);

    return (
        <div
            className={`table-search-and-filter__container${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
        >
            {searchOnChange ? (
                <Input
                    placeholder={searchPlaceholder}
                    value={searchValue}
                    onChange={(e) => searchOnChange(e.target.value)}
                    prefix={<SearchOutlined />}
                    style={deviceType === DEVICE_TYPE.MOBILE ? { width: '100%' } : { width: '25%' }}
                    allowClear
                />
            ) : null}

            {switchOnChange ? (
                <div className="table-search-and-filter__active-filter__container">
                    <div className="input-label">{switchHelpText}</div>
                    <Switch
                        checkedChildren="YES"
                        unCheckedChildren="NO"
                        checked={switchValue}
                        onChange={() => switchOnChange(!switchValue)}
                        style={{ marginLeft: 10 }}
                    />
                </div>
            ) : null}
        </div>
    );
};
export default TableSearchAndFilter;
