import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import TableComponent from '../../reusable/TableComponent';
import { INCOMPLETE_ENTITIES_COLUMNS } from '../../../util/tableColumns';
import { DEVICE_TYPE } from '../../../store/constants/appActionTypes';

const IncompleteEntities = () => {
    const history = useHistory();
    const deviceType = useSelector((state) => state.app.device);
    const incompleteEntities = useSelector((state) => state.todo.incompleteEntities);

    const getMobileData = () => {
        const filteredData = [...incompleteEntities];
        const mobileData = [];
        filteredData.forEach((record) => {
            mobileData.push({
                id: record.entityId,
                title: `${record.contactInfo.firstName} ${record.contactInfo.lastName}`,

                subtitle: `${
                    record.nickname && record.nickname.length
                        ? `(${record.nickname})`
                        : record.orgName
                        ? record.orgName
                        : ''
                }`,
                additionalDetails: record.hasOwnProperty('driverId')
                    ? {
                          driverId: record.entityId,
                      }
                    : record.hasOwnProperty('customerId')
                    ? { customerId: record.entityId }
                    : null,
            });
        });
        return mobileData;
    };

    const handleRowClick = (record) => {
        if (record.hasOwnProperty('driverId')) {
            // Web
            history.push(`/drivers/${record.driverId}`);
        } else if (record.hasOwnProperty('customerId')) {
            // Web
            history.push(`/customers/${record.customerId}`);
        } else if (record.hasOwnProperty('additionalDetails')) {
            // Mobile
            if (record.additionalDetails.hasOwnProperty('driverId')) {
                history.push(`/drivers/${record.additionalDetails.driverId}`);
            } else if (record.additionalDetails.hasOwnProperty('customerId')) {
                history.push(`/customers/${record.additionalDetails.customerId}`);
            }
        }
    };

    return (
        <div
            className={`todo-list__incomplete-entities__container${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
        >
            <div className="heading--smaller">
                The entities below are incomplete and require additional data
            </div>

            <TableComponent
                tableColumns={INCOMPLETE_ENTITIES_COLUMNS}
                tableData={incompleteEntities}
                noPagination
                mobileListData={getMobileData()}
                onRowClick={(record) => handleRowClick(record)}
                onMobileActionClick={(record) => handleRowClick(record)}
                customWidth={800}
            />
        </div>
    );
};
export default IncompleteEntities;
