import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Reports from '../component/dashboard/Reports/Reports';
import ToDoList from '../component/dashboard/ToDo/ToDoList';

import Tabs from '../component/layout/Tabs';
import { PERMISSION_CONDITION, shouldSee } from '../util/permissionsUtil';

const Dashboard = () => {
    const incompleteEntities = useSelector((state) => state.todo.incompleteEntities);
    const invoicesNeedingApproval = useSelector((state) => state.todo.invoicesNeedingApproval);
    const trucksNeedingSupPay = useSelector((state) => state.todo.trucksNeedingSupPay);
    const invoicesFailedToSend = useSelector((state) => state.todo.invoicesFailedToSend);

    const TODO_VIEW = {
        viewName: 'To Do',
        key: 'to-do',
        component: <ToDoList />,
        locked: !shouldSee(['DRIVER_UPDATE', 'CUSTOMER_UPDATE'], PERMISSION_CONDITION.OR),
        displayBadge: false,
    };
    const REPORTS_VIEW = {
        viewName: 'Reports',
        key: 'reports',
        component: <Reports />,
        // Add permissions here for different reports
        locked: !shouldSee(['PAYROLL_GENERATE', 'SALES_GENERATE'], PERMISSION_CONDITION.OR),
    };

    const [views, setViews] = useState([TODO_VIEW, REPORTS_VIEW]);
    const [currentView, setCurrentView] = useState(views[0].viewName);

    useEffect(() => {
        const total =
            incompleteEntities.length +
            invoicesNeedingApproval.length +
            trucksNeedingSupPay.length +
            invoicesFailedToSend.length;

        if (total) {
            setViews([{ ...TODO_VIEW, displayBadge: true }, REPORTS_VIEW]);
        } else {
            setViews([TODO_VIEW, REPORTS_VIEW]);
        }
    }, [incompleteEntities, invoicesNeedingApproval, trucksNeedingSupPay, invoicesFailedToSend]);

    return <Tabs tabViews={views} selectedTab={currentView} onTabChange={setCurrentView} />;
};

export default Dashboard;
