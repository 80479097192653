import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';

import SystemSettingsApi from '../../../api/systemSettings/systemSettings';
import { DEVICE_TYPE } from '../../../store/constants/appActionTypes';
import { beautifyTextValue, convertToTwoDecimals } from '../../../util/formUtil';
import { settingsUpdateErrorText } from '../../../util/longText';
import { displaySimpleErrorModal, InfoModalHeader } from '../../global/ModalController';
import LabelledInput from '../../reusable/LabelledInput';

const SettingConfiguration = ({ settingName, settingValue, isDollarValue, onSettingUpdate }) => {
    const dispatch = useDispatch();
    const deviceType = useSelector((state) => state.app.device);

    const [settingVal, setSettingVal] = useState(settingValue);
    const [displayEditModal, setDisplayEditModal] = useState(false);
    const [submittingNewValue, setSubmittingNewValue] = useState(false);

    useEffect(() => {
        setSettingVal(settingValue);
    }, [settingValue]);

    const getEditModalContent = () => {
        return (
            <LabelledInput
                labelText="Enter new value for the setting"
                noTopMargin
                value={settingVal}
                onChangeAction={setSettingVal}
                withValidation
                isValid={isValid()}
                validationText={isDollarValue ? 'Enter dollar value' : 'Enter a value'}
            />
        );
    };

    const submitValueUpdate = () => {
        setSubmittingNewValue(true);

        SystemSettingsApi.updateSystemSettings(settingName, settingVal)
            .then(() => {
                onSettingUpdate(settingVal);
                setSubmittingNewValue(false);
                setDisplayEditModal(false);
            })
            .catch(() => {
                displaySimpleErrorModal(dispatch, settingsUpdateErrorText);
                setSubmittingNewValue(false);
            });
    };

    const isValid = () => {
        if (isDollarValue) {
            return validator.isCurrency(settingVal);
        } else {
            return !!settingVal.length;
        }
    };

    return (
        <div className="setting-configuration__outer-container">
            <div className="setting-configuration__container">
                <div
                    className={`setting-configuration__title${
                        deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                    }`}
                >
                    <div>{beautifyTextValue(settingName)}</div>
                    <div>
                        {isDollarValue ? `$${convertToTwoDecimals(settingValue)}` : settingValue}
                    </div>
                </div>

                <div className="setting-configuration__button">
                    <div className="standard-button" onClick={() => setDisplayEditModal(true)}>
                        EDIT
                    </div>
                </div>
            </div>

            {displayEditModal ? (
                <Modal
                    title={InfoModalHeader(`Update ${beautifyTextValue(settingName)} Value`)}
                    open
                    onOk={submitValueUpdate}
                    onCancel={() => {
                        setSettingVal(settingValue);
                        setDisplayEditModal(false);
                    }}
                    closable={false}
                    keyboard={false}
                    maskClosable={false}
                    confirmLoading={submittingNewValue}
                    okButtonProps={isValid() ? {} : { disabled: true }}
                    cancelButtonProps={submittingNewValue ? { disabled: true } : {}}
                >
                    {getEditModalContent()}
                </Modal>
            ) : null}
        </div>
    );
};
export default SettingConfiguration;
