import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Tooltip } from 'antd';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import CreatePayroll from './CreatePayroll';
import CreateSpecialPayroll from './CreateSpecialPayroll';
import { displaySimpleErrorModal } from '../global/ModalController';
import TableComponent from '../reusable/TableComponent';
import PayrollApi from '../../api/payroll/payroll';
import {
    APP_ACTION_TYPES,
    DEVICE_TYPE,
    TRIGGER_FETCH_TYPES,
} from '../../store/constants/appActionTypes';
import { capitalizeWord } from '../../util/formUtil';
import { payrollDataFetchErrorText } from '../../util/longText';
import { shouldSee } from '../../util/permissionsUtil';
import { PAYROLL_LIST_COLUMNS } from '../../util/tableColumns';

const PayrollTable = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const deviceType = useSelector((state) => state.app.device);
    const fetchTrigger = useSelector((state) => state.app.triggerFetch);

    const [payrollData, setPayrollData] = useState([]);
    const [payrollCount, setPayrollCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [displayCreateNewPayrollModal, setDisplayCreateNewPayrollModal] = useState(false);
    const [displayCreateSpecialPayrollModal, setDisplayCreateSpecialPayrollModal] = useState(false);

    useEffect(() => {
        getPayrollData();
    }, [currentPage]);

    // Observe store changes and fetch configs if triggered after delete action, reset trigger
    useEffect(() => {
        if (fetchTrigger === TRIGGER_FETCH_TYPES.PAYROLL_LIST) {
            getPayrollData();
            dispatch({
                type: APP_ACTION_TYPES.SET_TRIGGER_FETCH,
                payload: null,
            });
        }
    }, [fetchTrigger]);

    const getPayrollData = async () => {
        // Amount of recrods to be skipped based on page number
        const skip = (currentPage - 1) * 10;
        const payrollData = await PayrollApi.getAllPayrollData(skip, 10).catch(() =>
            displaySimpleErrorModal(dispatch, payrollDataFetchErrorText)
        );

        setPayrollCount(payrollData.count);
        setPayrollData([...payrollData.data]);
    };

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const getMobileData = () => {
        const filteredData = [...payrollData];
        const mobileData = [];
        filteredData.forEach((record) => {
            mobileData.push({
                id: record.payrollId,
                title: `From: ${record.startDate} To: ${record.endDate}`,

                subtitle: `${capitalizeWord(record.status)}`,
            });
        });
        return mobileData;
    };

    return (
        <div
            className={`invoices-table__container${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
        >
            {/* TABLE */}
            <TableComponent
                tableColumns={PAYROLL_LIST_COLUMNS}
                tableData={payrollData}
                searchPlaceholder="Search by invoice status"
                rightButtonText={
                    deviceType === DEVICE_TYPE.MOBILE || !shouldSee(['PAYROLL_CREATE'])
                        ? null
                        : 'CREATE NEW PAYROLL'
                }
                rightButtonAction={() => setDisplayCreateNewPayrollModal(true)}
                leftButtonText={
                    deviceType === DEVICE_TYPE.MOBILE || !shouldSee(['PAYROLL_CREATE']) ? null : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            CREATE SPECIAL PAYROLL
                            <Tooltip title="Generate payroll for a single driver outside of regular payroll schedule">
                                <AiOutlineQuestionCircle style={{ fontSize: 22, marginLeft: 5 }} />
                            </Tooltip>
                        </div>
                    )
                }
                leftButtonAction={() => setDisplayCreateSpecialPayrollModal(true)}
                mobileListData={getMobileData()}
                currentPage={currentPage}
                onPageChange={onPageChange}
                totalPageCount={payrollCount}
                onRowClick={(record) =>
                    record.payrollId
                        ? history.push(`/invoices/payroll/${record.payrollId}`)
                        : history.push(`/invoices/payroll/${record.id}`)
                }
            />

            {/* REGULAR PAYROLL MODAL */}
            {displayCreateNewPayrollModal ? (
                <CreatePayroll
                    onCancel={() => setDisplayCreateNewPayrollModal(false)}
                    fetchPayrollData={() => getPayrollData()}
                />
            ) : null}

            {/* SPECIAL PAYROLL MODAL */}
            {displayCreateSpecialPayrollModal ? (
                <CreateSpecialPayroll
                    onCancel={() => setDisplayCreateSpecialPayrollModal(false)}
                    fetchPayrollData={() => getPayrollData()}
                />
            ) : null}
        </div>
    );
};

export default PayrollTable;
