import { DeleteOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { beautifyTextValue } from '../../util/formUtil';

const NOTIFICATION_DETAILS_MAP = {
    customerName: 'Customer Name',
    dueDate: 'Due Date',
    truckDate: 'Truck Date',
    driver: 'Driver',
    tdm: 'TDM',
    action: 'Action',
    profileName: 'Profile Name',
    rateType: 'Rate Type',
};

const Notifications = ({ notifications, dismissNotification, dismissingNotification }) => {
    const getNotificationRow = (notification) => {
        return (
            <div
                key={`notification-row-${notification.desktopNotificationId}`}
                className="notifications__row__container"
            >
                {/* NOTIFICATION TITLE (EVENT) */}
                <div className="notifications__row__title">
                    {beautifyTextValue(notification.event)}
                </div>

                {/* DISMISS ICON */}
                <div className="notifications__row__details">
                    <div className="notifications__row__dismiss">
                        <Tooltip title="Dismiss">
                            <DeleteOutlined
                                style={
                                    dismissingNotification
                                        ? {
                                              fontSize: 15,
                                              cursor: 'not-allowed',
                                              color: '#c96363',
                                          }
                                        : {
                                              fontSize: 15,
                                              cursor: 'pointer',
                                              color: '#cf0000',
                                          }
                                }
                                onClick={
                                    dismissingNotification
                                        ? null
                                        : () =>
                                              dismissNotification(
                                                  notification.desktopNotificationId
                                              )
                                }
                            />
                        </Tooltip>
                    </div>

                    {/* NOTIFICATION DETAILS */}
                    {Object.entries(notification.notificationDetails).map(
                        ([detailKey, detailValue]) =>
                            NOTIFICATION_DETAILS_MAP[detailKey] ? (
                                <div
                                    key={`notification-detail-${detailKey}`}
                                    className="notifications__row__detail"
                                >
                                    <span style={{ fontWeight: 300 }}>
                                        {`${NOTIFICATION_DETAILS_MAP[detailKey]}: `}
                                    </span>
                                    {`${detailValue}`}
                                </div>
                            ) : null
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="notifications__container">
            {notifications.map((notification) => getNotificationRow(notification))}
        </div>
    );
};
export default Notifications;
