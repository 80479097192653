import { Modal, Select } from 'antd';
import CreatableSelect from 'react-select/creatable';

import { InfoModalHeader } from '../global/ModalController';
import LabelledInput from '../reusable/LabelledInput';

const { Option } = Select;

/** Used to quick create a customer record with minmum
 * required fields
 */
const CustomerModal = ({
    displayCustomerModal,
    customerData,
    setCustomerData,
    submitAddNewCustomer,
    onCancel,
    submittingCustomer,
    customerRates,
    organizationOptions,
}) => {
    const getCustomerModalContent = () => (
        <>
            {/* NAMES */}
            <div className="customer-modal__input-container">
                <LabelledInput
                    labelText="First Name"
                    withValidation
                    noTopMargin
                    value={customerData.firstName}
                    onChangeAction={(value) =>
                        setCustomerData({ ...customerData, firstName: value })
                    }
                    isValid={customerData.firstName.length > 1}
                    validationText="Enter a valid name"
                />
                <LabelledInput
                    labelText="Last Name"
                    withValidation
                    noTopMargin
                    value={customerData.lastName}
                    onChangeAction={(value) =>
                        setCustomerData({ ...customerData, lastName: value })
                    }
                    isValid={customerData.lastName.length > 1}
                    validationText="Enter a valid name"
                />
            </div>

            {/* ORGANIZATION */}
            <div style={{ width: '100%' }}>
                <div className="input-label" style={{ marginTop: 25 }}>
                    Organization Name
                </div>
                <CreatableSelect
                    isClearable
                    value={{ label: customerData.orgName, value: customerData.orgName }}
                    onChange={(newValue) =>
                        newValue
                            ? setCustomerData({ ...customerData, orgName: newValue.value })
                            : setCustomerData({ ...customerData, orgName: '' })
                    }
                    placeholder="Select or type..."
                    styles={{
                        control: (base) => ({
                            ...base,
                            borderColor: customerData.orgName.length <= 1 ? '#ff0000' : '#ccc',
                        }),
                    }}
                    options={organizationOptions}
                />
            </div>

            {/* RATE PROFILE */}
            <div>
                <div className="input-label" style={{ marginTop: 25 }}>
                    Rate Profile
                </div>
                <Select
                    size="large"
                    placeholder="Select one"
                    value={customerData.rateProfile ? customerData.rateProfile.profileName : null}
                    style={{ width: '100%' }}
                    status={customerData.rateProfile.customerRateProfileId ? null : 'error'}
                    onChange={(value) => {
                        const rateId = value.split('_')[1];
                        const newRate = customerRates.find(
                            (rate) =>
                                rate.customerRateProfile.customerRateProfileId.toString() === rateId
                        );
                        setCustomerData({
                            ...customerData,
                            rateProfile: { ...newRate.customerRateProfile },
                        });
                    }}
                >
                    {customerRates.map((rate) => (
                        <Option
                            key={`customer-rate-type_${rate.customerRateProfile.customerRateProfileId}`}
                        >
                            {rate.customerRateProfile.profileName}
                        </Option>
                    ))}
                </Select>
            </div>
        </>
    );

    const allDataEntered = () =>
        customerData.firstName.length > 1 &&
        customerData.lastName.length > 1 &&
        customerData.orgName.length > 1 &&
        customerData.rateProfile.customerRateProfileId;

    return (
        <Modal
            title={InfoModalHeader('Add New Customer')}
            visible={displayCustomerModal}
            onOk={() => submitAddNewCustomer()}
            onCancel={onCancel}
            closable={false}
            keyboard={false}
            maskClosable={false}
            confirmLoading={submittingCustomer}
            okButtonProps={allDataEntered() ? {} : { disabled: true }}
            cancelButtonProps={submittingCustomer ? { disabled: true } : {}}
        >
            {getCustomerModalContent()}
        </Modal>
    );
};
export default CustomerModal;
