import { useState } from 'react';
import { useSelector } from 'react-redux';

import SalesReport from './SalesReport';
import { generateDeliveryChargeTile, generateSalesTile } from './ReportTiles';
import DeliveryChargeReport from './DeliveryChargeReport';
import { shouldSee } from '../../../util/permissionsUtil';
import { DEVICE_TYPE } from '../../../store/constants/appActionTypes';

const Reports = () => {
    const deviceType = useSelector((state) => state.app.device);
    const [salesReportTileClicked, setSalesReportTileClicked] = useState(false);
    const [deliveryChargeTileClicked, setDeliveryChargeTileClicked] = useState(false);

    const reportComponents = [
        {
            reportTile: generateSalesTile,
            reportTileClickAction: setSalesReportTileClicked,
            reportComponent: (
                <SalesReport
                    key="report-component-salesreport"
                    displayGenerateSalesReportModal={salesReportTileClicked}
                    setDisplayGenerateSalesReportModal={setSalesReportTileClicked}
                />
            ),
            requiredPermission: ['SALES_GENERATE'],
        },
        {
            reportTile: generateDeliveryChargeTile,
            reportTileClickAction: setDeliveryChargeTileClicked,
            reportComponent: (
                <DeliveryChargeReport
                    key="report-component-deliverychargereport"
                    displayDeliveryChargeReportModal={deliveryChargeTileClicked}
                    setDisplayDeliveryChargeReportModal={setDeliveryChargeTileClicked}
                />
            ),
            requiredPermission: ['DELIVERY_CHARGE_GENERATE'],
        },
    ];

    return (
        <div className={`reports__container${deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''}`}>
            <div
                className={`reports__tile-container${
                    deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                }`}
            >
                {reportComponents.map((report, idx) =>
                    shouldSee(report.requiredPermission) ? (
                        <div key={`report-tiles-${idx}`}>
                            {report.reportTile(report.reportTileClickAction, deviceType)}
                        </div>
                    ) : null
                )}
            </div>

            <div className="reports__report-container">
                {reportComponents.map((report) => report.reportComponent)}
            </div>
        </div>
    );
};

export default Reports;
