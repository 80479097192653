import { Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const LabelledInput = ({
    labelText,
    value,
    onChangeAction,
    withValidation,
    isValid,
    validationText,
    isPassword,
    customWidth,
    isDisabled,
    noTopMargin,
    addonBefore,
    addonAfter,
    setRef,
}) => {
    return (
        <div
            className="labelled-input__container"
            style={customWidth ? { width: customWidth } : {}}
        >
            <div className="input-label" style={!noTopMargin ? { marginTop: 25 } : {}}>
                {labelText}
            </div>
            {isPassword ? (
                <Input.Password
                    size="large"
                    style={{ width: '100%' }}
                    value={value}
                    onChange={(e) => onChangeAction(e.target.value)}
                    disabled={isDisabled}
                    status={withValidation ? (!isValid ? 'error' : null) : null}
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    ref={(r) => {
                        if (setRef) {
                            setRef(r);
                        }
                    }}
                />
            ) : (
                <Input
                    size="large"
                    style={{ width: '100%' }}
                    value={value}
                    onChange={(e) => onChangeAction(e.target.value)}
                    disabled={isDisabled}
                    status={withValidation ? (!isValid ? 'error' : null) : null}
                    addonBefore={addonBefore}
                    addonAfter={addonAfter}
                    ref={(r) => {
                        if (setRef) {
                            setRef(r);
                        }
                    }}
                />
            )}
            {withValidation ? (
                !isValid ? (
                    <div className="labelled-input__validation-error">{validationText}</div>
                ) : (
                    // Need to prevent button from jumping up/down when validation text appears
                    <div className="labelled-input__validation-error"> &nbsp;</div>
                )
            ) : null}
        </div>
    );
};

export default LabelledInput;
