import SalesLogo from '../../../images/sales.png';
import DeliveryChargeLogo from '../../../images/deliveryCharge.png';
import { DEVICE_TYPE } from '../../../store/constants/appActionTypes';

export const generateSalesTile = (setsalesReportTileClicked, deviceType) => (
    <div
        className={`report-tiles__tile__container${
            deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
        }`}
        onClick={() => setsalesReportTileClicked(true)}
    >
        <div className="report-tiles__tile__title">SALES REPORT</div>
        <div className="report-tiles__tile__icon">
            <img src={SalesLogo} alt="" style={{ width: '100%' }} />
        </div>
    </div>
);

export const generateDeliveryChargeTile = (setDeliveryChargeTileClicked, deviceType) => (
    <div
        className={`report-tiles__tile__container${
            deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
        }`}
        onClick={() => setDeliveryChargeTileClicked(true)}
    >
        <div className="report-tiles__tile__title" style={{ fontSize: 16 }}>
            DELIVERY CHARGE REPORT
        </div>
        <div className="report-tiles__tile__icon">
            <img src={DeliveryChargeLogo} alt="" style={{ width: '100%' }} />
        </div>
    </div>
);
