import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GrHistory } from 'react-icons/gr';
import { Tooltip } from 'antd';

import DriverRatesApi from '../../api/rateProfiles/driverRates';
import { DEVICE_TYPE } from '../../store/constants/appActionTypes';
import { onAPIError, onAPISuccess } from '../../util/apiUtil';
import {
    driverRateArchivedErrorText,
    driverRateArchivedSuccessText,
    driverRateFetchDataErrorText,
    driverRateUpdateErrorText,
    driverRateUpdateSuccessText,
    newDriverRateAddedErrorText,
    newDriverRateAddedSuccessText,
} from '../../util/longText';
import { shouldSee } from '../../util/permissionsUtil';
import { displaySimpleErrorModal, getUserConfirmation } from '../global/ModalController';
import EntityRatesComponent from './EntityRatesComponent';

const EmployeeRates = ({ viewHistory }) => {
    const dispatch = useDispatch();
    const deviceType = useSelector((state) => state.app.device);

    const [driverRates, setDriverRates] = useState([]);

    const [displayAddModal, setDisplayAddModal] = useState(false);
    const [newRateName, setNewRateName] = useState('');

    const [displayUpdateModal, setDisplayUpdateModal] = useState(false);

    const [submittingNewRate, setSubmittingNewRate] = useState(false);
    const [submittingRateUpdate, setSubmittingRateUpdate] = useState(false);

    useEffect(() => {
        getDriverRateData();
    }, []);

    const getDriverRateData = async () => {
        DriverRatesApi.getDriverRates('', true, 0, 1000)
            .then((driverRatesData) => setDriverRates(driverRatesData.data))
            .catch(() => displaySimpleErrorModal(dispatch, driverRateFetchDataErrorText));
    };

    const submitNewRate = () => {
        setSubmittingNewRate(true);

        DriverRatesApi.createDriverRate({
            profileName: newRateName,
            active: true,
        })
            .then(() => {
                setSubmittingNewRate(false);
                setDisplayAddModal(false);
                setNewRateName('');

                onAPISuccess(dispatch, newDriverRateAddedSuccessText);
                getDriverRateData();
            })
            .catch(() => {
                setSubmittingNewRate(false);
                onAPIError(dispatch, newDriverRateAddedErrorText);
            });
    };

    const updateRate = (rate, updatedValues) => {
        setSubmittingRateUpdate(true);
        const updateRates = updatedValues.map((value) => ({
            rateId: value.rateId,
            label: value.label,
            rate: value.activeRate,
        }));

        DriverRatesApi.updateDriverRate({
            ...rate,
            payTypes: [...updateRates],
        })
            .then(() => {
                setSubmittingRateUpdate(false);
                setDisplayUpdateModal(false);

                onAPISuccess(dispatch, driverRateUpdateSuccessText);
                getDriverRateData();
            })
            .catch(() => {
                setSubmittingRateUpdate(false);
                onAPIError(dispatch, driverRateUpdateErrorText);
            });
    };

    const archiveRate = (rateId) => {
        const proceedWithArchive = () =>
            DriverRatesApi.archiveDriverRate(rateId)
                .then(() => {
                    onAPISuccess(dispatch, driverRateArchivedSuccessText);
                    getDriverRateData();
                })
                .catch(() => {
                    onAPIError(dispatch, driverRateArchivedErrorText);
                });

        getUserConfirmation(
            dispatch,
            'Are you sure you want to delete this rate?',
            proceedWithArchive
        );
    };

    return (
        <>
            {/* AUDIT VIEW */}
            {deviceType === DEVICE_TYPE.NOT_MOBILE && shouldSee(['USER_MANAGE_VIEW_AUDITS']) ? (
                <div className="tier-rates__audit__container">
                    <Tooltip title="View history of rate changes" placement="topRight">
                        <GrHistory className="tier-rates__audit__icon" onClick={viewHistory} />
                    </Tooltip>
                </div>
            ) : null}

            <EntityRatesComponent
                rates={driverRates}
                entityKey="driverRateProfile"
                typesKey="payTypes"
                displayAddModal={displayAddModal}
                setDisplayAddModal={setDisplayAddModal}
                newRateName={newRateName}
                setNewRateName={setNewRateName}
                submitNewRate={submitNewRate}
                submittingNewRate={submittingNewRate}
                displayUpdateModal={displayUpdateModal}
                setDisplayUpdateModal={setDisplayUpdateModal}
                updateRate={shouldSee(['DRP_UPDATE']) ? updateRate : null}
                submittingRateUpdate={submittingRateUpdate}
                archiveRate={shouldSee(['DRP_DELETE']) ? archiveRate : null}
                canCreate={shouldSee(['DRP_CREATE'])}
            />
        </>
    );
};
export default EmployeeRates;
