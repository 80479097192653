import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from './reducers/master';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['app', 'todo'],
};

const persistingReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
    persistingReducer,
    composeEnhancers(applyMiddleware(thunk, createLogger({ collapsed: true })))
);
export const persistor = persistStore(store);
