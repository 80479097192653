import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import appReducer from './app.reducer';
import todoReducer from './todo.reducer';

const appPersistConfig = {
    key: 'app',
    storage,
    blacklist: [
        'displayModal',
        'modalActionLoading',
        'viewingDriverRates',
        'viewingCustomerRates',
        'viewingEmployeePayrollDetails',
        'notifications',
    ],
};

const todoPersistConfig = {
    key: 'todo',
    storage,
    blacklist: [
        'incompleteEntities',
        'invoicesNeedingApproval',
        'trucksNeedingSupPay',
        'invoicesFailedToSend',
        'supplementalDataModal',
    ],
};

const rootReducer = combineReducers({
    app: persistReducer(appPersistConfig, appReducer),
    todo: persistReducer(todoPersistConfig, todoReducer),
});

export default rootReducer;
