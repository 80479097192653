import axios from 'axios';
import { getAuthHeader, getErrorMessage, getHost } from '../../util/apiUtil';
import { shouldSee } from '../../util/permissionsUtil';

const SETTINGS_URL = `${getHost()}/systemsettings`;

class SystemSettingsApi {
    static getSystemSettings() {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['SYSTEM_SETTINGS_UPDATE'])) {
                console.error('API ERROR | System Settings: User not permitted');
                reject();
            }

            axios
                .get(SETTINGS_URL, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | System Settings: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static updateSystemSettings(settingsKey, newValue) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['SYSTEM_SETTINGS_UPDATE'])) {
                console.error('API ERROR | System Settings: User not permitted');
                reject();
            }

            axios
                .patch(
                    SETTINGS_URL,
                    {
                        settingsKey,
                        value: newValue,
                    },
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | System Settings: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }
}
export default SystemSettingsApi;
