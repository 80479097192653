import { store } from '../store/configureStore';

/** Reutrns boolean value if user should see a component based on their
 * permissions and required permissions. Must have ALL or AT LEAST ONE
 * permissions in the required permissions array based on the condition param
 */
export const shouldSee = (requiredPermissions, condition = PERMISSION_CONDITION.AND) => {
    const { userPermissions } = store.getState().app;

    if (condition === PERMISSION_CONDITION.AND) {
        return requiredPermissions.every((requiredPermission) =>
            userPermissions.includes(requiredPermission)
        );
    }

    return requiredPermissions.some((requiredPermission) =>
        userPermissions.includes(requiredPermission)
    );
};

export const PERMISSION_CONDITION = Object.freeze({
    OR: 'or',
    AND: 'and',
});

export const NAVIGATION_PERMISSIONS = Object.freeze({
    DATA_ENTRY: ['TRUCK_READ', 'INVOICE_READ'],
    DATA_MANAGEMENT: ['DRIVER_READ', 'CUSTOMER_READ', 'TDM_READ'],
    RATE_PROFILES: ['DRP_READ', 'CRP_READ', 'PAY_TYPES_READ', 'SERVICE_TYPES_READ'],
    USER_MANAGEMENT: ['USER_MANAGE_USER_READ'],
    INVOICES: ['TRUCK_READ_MANY', 'INVOICE_READ_MANY'],
    PAYROLL: ['PAYROLL_READ'],
});
