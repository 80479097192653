import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from 'antd';
import moment from 'moment';
import { FiDownload } from 'react-icons/fi';
import { Excel } from 'antd-table-saveas-excel';

import ReportsApi from '../../../api/reports/reports';
import { onAPIError } from '../../../util/apiUtil';
import { reportNoDataErrorText, salesReportGenerateErrorText } from '../../../util/longText';
import GenerateReportModal from './GenerateReportModal';
import { SALES_REPORT_COLUMNS } from '../../../util/tableColumns';
import { formatCurrency } from '../../../util/formUtil';
import { DEVICE_TYPE } from '../../../store/constants/appActionTypes';
import TableComponent from '../../reusable/TableComponent';

const { RangePicker } = DatePicker;

const SalesReport = ({ displayGenerateSalesReportModal, setDisplayGenerateSalesReportModal }) => {
    const dispatch = useDispatch();
    const myRef = useRef(null);
    const detailRef = useRef(null);
    const deviceType = useSelector((state) => state.app.device);

    const [selectedDateRange, setSelectedDateRange] = useState(['', '']);
    const [generatingReport, setGeneratingReport] = useState(false);
    const [reportData, setReportData] = useState(null);
    const [selectedReportGroup, setSelectedReportGroup] = useState(null);

    useEffect(() => {
        setSelectedReportGroup(null);
    }, [reportData]);

    useEffect(() => {
        if (selectedReportGroup) {
            detailRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [selectedReportGroup]);

    const generateSalesReport = () => {
        setGeneratingReport(true);

        ReportsApi.generateSalesReport({
            startDate: selectedDateRange[0],
            endDate: selectedDateRange[1],
        })
            .then((data) => {
                setDisplayGenerateSalesReportModal(false);
                setGeneratingReport(false);
                setReportData({
                    ...data,
                    startDate: selectedDateRange[0],
                    endDate: selectedDateRange[1],
                });
                setSelectedDateRange(['', '']);
                myRef.current.scrollIntoView({ behavior: 'smooth' });
            })
            .catch((e) => {
                let apiErrorText = salesReportGenerateErrorText;
                if (e.toUpperCase().includes('CANNOT FIND TRUCK WITH DATE')) {
                    apiErrorText = reportNoDataErrorText;
                }
                setGeneratingReport(false);
                onAPIError(dispatch, apiErrorText);
            });
    };

    const getTableDataBasedOnSelectedGroup = () => {
        switch (selectedReportGroup) {
            case 'All':
                return reportData.sales;
            case selectedReportGroup:
                return reportData.sales.filter((sale) => sale.deliveryType === selectedReportGroup);
            default:
                return [];
        }
    };

    const getMobileDataBasedOnSelectedGroup = () => {
        console.log(reportData.sales);

        const mapToMobileFormat = (rowData) => {
            const { date, deliveryCharge, deliveryType, invoice } = rowData;
            return {
                id: `${date}-${deliveryType}-${deliveryCharge}`,
                title: `${date}, ${deliveryType}: ${formatCurrency(deliveryCharge)}`,
                subtitle: `${invoice.customerName}, ${invoice.invoiceReference}`,
            };
        };

        switch (selectedReportGroup) {
            case 'All':
                return reportData.sales.map((sale) => mapToMobileFormat(sale));
            case selectedReportGroup:
                return reportData.sales
                    .filter((sale) => sale.deliveryType === selectedReportGroup)
                    .map((sale) => mapToMobileFormat(sale));
            default:
                return [];
        }
    };

    const getGenerateSalesReportModalContent = () => {
        return (
            <div>
                <div className="input-label">Date Range</div>
                <RangePicker
                    size="middle"
                    style={{ width: '100%' }}
                    value={[
                        selectedDateRange[0].length ? moment(selectedDateRange[0]) : null,
                        selectedDateRange[1].length ? moment(selectedDateRange[1]) : null,
                    ]}
                    onCalendarChange={(val, dateStrings) => setSelectedDateRange(dateStrings)}
                    status={
                        selectedDateRange[0].length && selectedDateRange[1].length ? null : 'error'
                    }
                />
            </div>
        );
    };

    const getGroupedRows = () => {
        return (
            <div className="sales-report__data__grouped-rows__container">
                {Object.entries(reportData.groupedSales).map(([key, value]) => (
                    <div
                        key={`grouped-sales-row-${key}`}
                        className="sales-report__data__grouped-rows__row"
                        onClick={() => setSelectedReportGroup(key)}
                    >
                        <div className="sales-report__data__grouped-rows__row--key">{key}</div>
                        <div>{`${formatCurrency(value)}`}</div>
                    </div>
                ))}
                <div className="sales-report__data__grouped-rows__info-text">
                    Click on a row to view details or{' '}
                    <span
                        className="sales-report__data__grouped-rows__info-text__link"
                        onClick={() => setSelectedReportGroup('All')}
                    >
                        click here
                    </span>{' '}
                    to view full report details
                </div>
            </div>
        );
    };

    const downloadTableData = () => {
        const fileName = `${selectedReportGroup === 'All' ? '' : selectedReportGroup}SalesReport${
            reportData.startDate
        }To${reportData.endDate}`;
        const tableHeader = `${
            selectedReportGroup === 'All' ? '' : selectedReportGroup
        } Sales Report - ${reportData.startDate} - ${reportData.endDate}, Total: $${
            selectedReportGroup === 'All'
                ? reportData.totalSales
                : reportData.groupedSales[selectedReportGroup]
        }`;

        const excel = new Excel();
        excel
            .setTHeadStyle({ background: '0E76BC', color: 'FFFFFF', border: false })
            .addSheet('Sales Report')
            .drawCell(0, 0, {
                hMerge: 4,
                vMerge: 2,
                value: tableHeader,
                style: {
                    bold: true,
                    v: 'center',
                    h: 'center',
                },
            })
            .addColumns(SALES_REPORT_COLUMNS)
            .addDataSource(getTableDataBasedOnSelectedGroup())
            .saveAs(`${fileName}.xlsx`);
    };

    const getTableHeader = () => (
        <div className="heading--smaller payroll-report__table__header">
            {`${selectedReportGroup} sales: ${
                selectedReportGroup === 'All'
                    ? formatCurrency(reportData.totalSales)
                    : formatCurrency(reportData.groupedSales[selectedReportGroup])
            }`}
            {deviceType === DEVICE_TYPE.NOT_MOBILE ? (
                <div className="standard-button--empty" onClick={() => downloadTableData()}>
                    DOWNLOAD <FiDownload style={{ marginLeft: 5 }} />
                </div>
            ) : null}
        </div>
    );

    return (
        <>
            {/* SALES REPORT */}
            <div className="sales-report__container">
                {reportData ? (
                    <>
                        <div className="heading" ref={myRef}>
                            Sales Report
                        </div>

                        {/* GROUPED REPORT */}
                        <div className="sales-report__data__container">
                            <div className="heading--smaller">
                                {`Total Sales: ${formatCurrency(reportData.totalSales)} (Between ${
                                    reportData.startDate
                                } - ${reportData.endDate})`}
                            </div>
                            {getGroupedRows()}
                        </div>

                        {/* GROUP DETAILS */}
                        {selectedReportGroup ? (
                            <div
                                className="sales-report__data__container--full-width"
                                style={{ alignItems: 'flex-end' }}
                                ref={detailRef}
                            >
                                <TableComponent
                                    tableColumns={SALES_REPORT_COLUMNS}
                                    tableData={getTableDataBasedOnSelectedGroup()}
                                    noPagination
                                    tableHeader={getTableHeader()}
                                    customWidth="100%"
                                    customStyle={{ margin: 0 }}
                                    mobileListData={getMobileDataBasedOnSelectedGroup()}
                                />
                            </div>
                        ) : null}
                    </>
                ) : null}
            </div>

            {/* GENERATE SALES REPORT MODAL */}
            <GenerateReportModal
                title="Generate Sales Report"
                visible={displayGenerateSalesReportModal}
                onOkAction={() => generateSalesReport()}
                onCancelAction={() => {
                    setDisplayGenerateSalesReportModal(false);
                    setSelectedDateRange(['', '']);
                }}
                confirmLoading={generatingReport}
                okButtonEnabled={selectedDateRange[0].length && selectedDateRange[1].length}
                content={getGenerateSalesReportModalContent()}
            />
        </>
    );
};
export default SalesReport;
