import axios from 'axios';

import { getAuthHeader, getErrorMessage, getHost } from '../../util/apiUtil';
import { shouldSee } from '../../util/permissionsUtil';

const TRUCK_URL = `${getHost()}/truck`;

class TrucksApi {
    static getTrucks(includeInactive = true, skip = 0, take = 10) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['TRUCK_READ_MANY'])) {
                console.error('API ERROR | Trucks: User not permitted');
                reject();
            }

            const url = `${TRUCK_URL}/all?take=${take}&skip=${skip}&includeInactive=${includeInactive}`;

            axios
                .get(url, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Trucks: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static getTruckById(id) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['TRUCK_READ'])) {
                console.error('API ERROR | Trucks: User not permitted');
                reject();
            }

            axios
                .get(`${TRUCK_URL}/${id}`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Trucks: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static getTruckByDriver(
        driverId,
        dataEntryOnly = false,
        includeWhereHelper = false,
        excludeSpecialPayroll = false
    ) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['TRUCK_READ'])) {
                console.error('API ERROR | Trucks: User not permitted');
                reject();
            }

            const queryParams = new URLSearchParams();
            queryParams.append('dataEntryOnly', dataEntryOnly);
            queryParams.append('includeWhereHelper', includeWhereHelper);
            queryParams.append('excludeSpecialPayroll', excludeSpecialPayroll);
            const url = `${TRUCK_URL}/driver/${driverId}?${queryParams.toString()}`;

            axios
                .get(url, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Trucks: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static getTruckNeedingSupPay() {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['TRUCK_READ'])) {
                console.error('API ERROR | Trucks: User not permitted');
                reject();
            }

            axios
                .get(`${TRUCK_URL}/all?onlyReqSupPay=true`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Trucks: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static getTruckByDateAndTdm(date, tdmId) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['TRUCK_SEARCH'])) {
                console.error('API ERROR | Trucks: User not permitted');
                reject();
            }

            axios
                .get(`${TRUCK_URL}?date=${date}&tdmId=${tdmId}`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Trucks: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static createTruck(truckData) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['TRUCK_CREATE'])) {
                console.error('API ERROR | Trucks: User not permitted');
                reject();
            }

            axios
                .post(
                    `${TRUCK_URL}`,
                    { ...truckData },
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Trucks: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static updateTruck(truckData) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['TRUCK_UPDATE'])) {
                console.error('API ERROR | Trucks: User not permitted');
                reject();
            }

            axios
                .patch(TRUCK_URL, { ...truckData }, { headers: getAuthHeader() })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Trucks: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static getInvoices(skip = 0, take = 10, truckIdFilter = null, customerNameFilter = '') {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['INVOICE_READ_MANY'])) {
                console.error('API ERROR | Trucks: User not permitted');
                reject();
            }

            let queriedUrl = `${TRUCK_URL}/invoice/all?take=${take}&skip=${skip}`;
            if (truckIdFilter) {
                queriedUrl += `&truckId=${truckIdFilter}`;
            }
            if (customerNameFilter.length) {
                queriedUrl += `&customerNameLike=${customerNameFilter}`;
            }

            axios
                .get(queriedUrl, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Trucks: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static getInvoiceById(id) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['INVOICE_READ'])) {
                console.error('API ERROR | Trucks: User not permitted');
                reject();
            }

            axios
                .get(`${TRUCK_URL}/invoice/${id}`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Trucks: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static createInvoice(invoiceData) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['INVOICE_CREATE'])) {
                console.error('API ERROR | Trucks: User not permitted');
                reject();
            }

            axios
                .post(
                    `${TRUCK_URL}/invoice`,
                    { ...invoiceData },
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Trucks: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static updateInvoice(invoiceData) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['INVOICE_UPDATE'])) {
                console.error('API ERROR | Trucks: User not permitted');
                reject();
            }

            axios
                .patch(
                    `${TRUCK_URL}/invoice`,
                    { ...invoiceData },
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Trucks: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static relockPayrollTruck(truckId) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['TRUCK_UPDATE'])) {
                console.error('API ERROR | Trucks: User not permitted');
                reject();
            }

            axios
                .patch(`${TRUCK_URL}/relockTruck/${truckId}`, {}, { headers: getAuthHeader() })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Trucks: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }
}
export default TrucksApi;
