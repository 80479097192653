import validator from 'validator';

import { DEVICE_TYPE } from '../../store/constants/appActionTypes';
import LabelledInput from '../reusable/LabelledInput';

const TruckServicesEntries = ({
    invoiceData,
    deviceType,
    updateInvoiceServiceValue,
    currentType,
    availableServicesArray,
    readOnly,
}) => {
    const formStyle = deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : '';

    return (
        <div
            className={`single-invoice-form__invoice-details__invoice-services__container${formStyle}`}
        >
            {/* Filter to display only services based on passed type: pay_type || service_type */}
            {invoiceData.invoiceServices
                .filter((service) => service.type === currentType)
                .map((service, idx) => {
                    let isDollarType;
                    let label;
                    if (service.serviceOrPayTypeId) {
                        // These details are only available if creating a new invoice
                        // Get details of the pay/service type based on ID
                        const serviceDetails = availableServicesArray.find(
                            (type) =>
                                type[currentType === 'pay_type' ? 'payTypeId' : 'serviceTypeId'] ===
                                service.serviceOrPayTypeId
                        );
                        label = serviceDetails.label;
                        isDollarType = serviceDetails.dataType === 'dollar_number';
                    } else {
                        label = service.label;
                    }

                    return (
                        <LabelledInput
                            key={`invoiceservice-${currentType}-${label}-${idx}`}
                            labelText={label}
                            customWidth={deviceType === DEVICE_TYPE.MOBILE ? '100%' : 200}
                            value={service.quantity}
                            onChangeAction={(value) =>
                                updateInvoiceServiceValue(
                                    value,
                                    service.serviceOrPayTypeId,
                                    currentType
                                )
                            }
                            withValidation={!readOnly}
                            isValid={
                                isDollarType
                                    ? validator.isCurrency(service.quantity)
                                    : service.quantity.length > 0
                            }
                            validationText="Enter a valid amount"
                            addonBefore={isDollarType ? '$' : null}
                            isDisabled={readOnly}
                        />
                    );
                })}
        </div>
    );
};
export default TruckServicesEntries;
