import { useHistory } from 'react-router';

import EntityTile from '../../component/dataManagement/EntityTile';

import DriversLogo from '../../images/driver.png';
import CustomerLogo from '../../images/customer.png';
import TDMLogo from '../../images/route.png';
import TruckLogo from '../../images/truck.png';
import { shouldSee } from '../../util/permissionsUtil';

const DataManagement = () => {
    const history = useHistory();

    // Used to cancel both onClick events from firing when "+" ic clicke on the tile
    const cancelEventPropagation = (e) => {
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();
    };

    const entities = [
        {
            title: 'Drivers',
            icon: DriversLogo,
            tileAction: () => history.push('/drivers'),
            createButtonAction: (e) => {
                cancelEventPropagation(e);
                history.push('/drivers/new');
            },
            locked: !shouldSee(['DRIVER_READ_MANY']),
            canCreate: shouldSee(['DRIVER_CREATE']),
        },
        {
            title: 'Customers',
            icon: CustomerLogo,
            tileAction: () => history.push('/customers'),
            createButtonAction: (e) => {
                cancelEventPropagation(e);
                history.push('/customers/new');
            },
            locked: !shouldSee(['CUSTOMER_READ_MANY']),
            canCreate: shouldSee(['CUSTOMER_CREATE']),
        },
        {
            title: 'TDMs',
            icon: TDMLogo,
            tileAction: () => history.push('/tdms'),
            createButtonAction: (e) => {
                cancelEventPropagation(e);
                // This route still opens TDMs list but with the create modal open
                history.push('/tdms/new');
            },
            locked: !shouldSee(['TDM_READ_MANY']),
            canCreate: shouldSee(['TDM_CREATE']),
        },
        {
            title: 'Trucks',
            icon: TruckLogo,
            tileAction: () => history.push('/trucks'),
            createButtonAction: (e) => {
                cancelEventPropagation(e);
                history.push('dataentry');
            },
            locked: !shouldSee(['TRUCK_READ', 'INVOICE_READ']),
            canCreate: shouldSee(['TRUCK_CREATE']),
        },
    ];

    return (
        <div className="data-management__container">
            {entities.map((entity) => (
                <EntityTile
                    key={entity.title}
                    title={entity.title}
                    icon={entity.icon}
                    tileAction={entity.tileAction}
                    createButtonAction={entity.createButtonAction}
                    locked={entity.locked}
                    canCreate={entity.canCreate}
                />
            ))}
        </div>
    );
};

export default DataManagement;
