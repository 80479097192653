import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { DEVICE_TYPE } from '../../store/constants/appActionTypes';
import { InfoModalHeader } from '../global/ModalController';
import LabelledInput from '../reusable/LabelledInput';
import { addButton } from '../reusable/SmallElemets';
import TierRates from './TierRates';

const EntityRatesComponent = ({
    rates,
    entityKey,
    typesKey,
    displayAddModal,
    setDisplayAddModal,
    newRateName,
    setNewRateName,
    submitNewRate,
    submittingNewRate,
    displayUpdateModal,
    setDisplayUpdateModal,
    updateRate,
    submittingRateUpdate,
    archiveRate,
    canCreate,
}) => {
    const deviceType = useSelector((state) => state.app.device);

    const getModalContent = () => (
        <>
            <LabelledInput
                labelText="Label"
                value={newRateName}
                onChangeAction={(value) => setNewRateName(value)}
                noTopMargin
            />
        </>
    );

    const sortRatesAlphabetically = (a, b) => a.label.localeCompare(b.label);

    return (
        <div
            className={`employee-rates__outer-container${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
        >
            {rates.map((rate) => {
                if (rate[entityKey].active) {
                    return (
                        <TierRates
                            key={`tier-rate-${rate[entityKey].profileName}`}
                            entityKey={entityKey}
                            rateDetails={rate[entityKey]}
                            rates={rate[typesKey].sort(sortRatesAlphabetically)}
                            displayUpdateModal={displayUpdateModal}
                            setDisplayUpdateModal={setDisplayUpdateModal}
                            updateRate={updateRate}
                            submittingRateUpdate={submittingRateUpdate}
                            archiveRate={archiveRate}
                        />
                    );
                } else return null;
            })}

            {/* ADD TIER */}
            {canCreate ? (
                <div
                    className={`tier-rates__container${
                        deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                    }`}
                >
                    <div className="tier-rates__name">ADD RATE TIER</div>
                    <div className="tier-rates__add">
                        {addButton(() => setDisplayAddModal(true))}
                    </div>
                </div>
            ) : null}

            {/* ADD MODAL */}
            <Modal
                title={InfoModalHeader(
                    `Add new ${entityKey === 'driverRateProfile' ? 'employee' : 'customer'} rate`
                )}
                visible={displayAddModal}
                onOk={() => submitNewRate()}
                onCancel={() => {
                    setDisplayAddModal(false);
                    setNewRateName('');
                }}
                closable={false}
                keyboard={false}
                maskClosable={false}
                confirmLoading={submittingNewRate}
                okButtonProps={newRateName.length > 0 ? {} : { disabled: true }}
                cancelButtonProps={submittingNewRate ? { disabled: true } : {}}
            >
                {getModalContent()}
            </Modal>
        </div>
    );
};
export default EntityRatesComponent;
