import axios from 'axios';
import { getAuthHeader, getErrorMessage, getHost } from '../../util/apiUtil';

const NOTIFICATIONS_URL = `${getHost()}/notifications`;

class NotificationsApi {
    static getUserNotifications(userId) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${NOTIFICATIONS_URL}/${userId}`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Notifications: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static dismissNotification(notificationId) {
        return new Promise((resolve, reject) => {
            axios
                .patch(
                    `${NOTIFICATIONS_URL}/${notificationId}`,
                    {},
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Notifications: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }
}
export default NotificationsApi;
