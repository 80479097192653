import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Badge, Tooltip } from 'antd';
import {
    HomeOutlined,
    TableOutlined,
    DiffOutlined,
    DashboardOutlined,
    UsergroupAddOutlined,
    DollarOutlined,
    SettingOutlined,
    DoubleRightOutlined,
    DoubleLeftOutlined,
} from '@ant-design/icons';
import { GrLock } from 'react-icons/gr';

import { APP_ACTION_TYPES, DEVICE_TYPE, SIDEBAR_VIEWS } from '../../store/constants/appActionTypes';

import { isLoggedIn } from '../../util/authentication';
import {
    NAVIGATION_PERMISSIONS,
    PERMISSION_CONDITION,
    shouldSee,
} from '../../util/permissionsUtil';

const ICON_STYLE = { fontSize: 30, margin: '10px 0', cursor: 'pointer' };
export const EXPAND_ICON_STYLE = {
    ...ICON_STYLE,
    fontSize: 15,
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 5,
};

const Sidebar = () => {
    /* Have to use a small delay for displaying titles next to icons on sidebar
       expand due to 0.7s expansion transition animation, otherwise the titles
       jump lines as the effect of sidebar growing in width */
    const [displayDelay, setDisplayDelay] = useState(false);

    const location = useLocation();
    const history = useHistory();
    useEffect(() => {
        // Forced re-render
    }, [location]);

    const dispatch = useDispatch();
    const sidebarState = useSelector((state) => state.app.sidebarView);
    const deviceType = useSelector((state) => state.app.device);
    // Any update to this must also be made to the same component in
    // src>component>layout>Navbar.js
    const SIDEBAR_COMPONENTS = [
        {
            component: HomeOutlined,
            componentTitle: 'Dashboard',
            onClickAction: () => history.push('/'),
        },
        {
            component: TableOutlined,
            componentTitle: 'Data Entry',
            onClickAction: () => history.push('/dataentry'),
            locked: !shouldSee(NAVIGATION_PERMISSIONS.DATA_ENTRY),
        },
        {
            component: DiffOutlined,
            componentTitle: 'Data Management',
            onClickAction: () => history.push('/datamanagement'),
            locked: !shouldSee(NAVIGATION_PERMISSIONS.DATA_MANAGEMENT, PERMISSION_CONDITION.OR),
        },
        {
            component: DashboardOutlined,
            componentTitle: 'Rate Profiles',
            onClickAction: () => history.push('/rateprofiles'),
            locked: !shouldSee(NAVIGATION_PERMISSIONS.RATE_PROFILES, PERMISSION_CONDITION.OR),
        },
        {
            component: UsergroupAddOutlined,
            componentTitle: 'User Management',
            onClickAction: () => history.push('/users'),
            locked: !shouldSee(NAVIGATION_PERMISSIONS.USER_MANAGEMENT),
        },
        {
            component: DollarOutlined,
            componentTitle: 'Invoices & Payroll',
            onClickAction: () => history.push('/invoices'),
            locked: !shouldSee(NAVIGATION_PERMISSIONS.INVOICES),
        },
    ];

    const toggleSidebarState = () => {
        setDisplayDelay(true);
        dispatch({
            type: APP_ACTION_TYPES.TOGGLE_SIDEBAR_VIEW,
            payload:
                sidebarState === SIDEBAR_VIEWS.EXPANDED
                    ? SIDEBAR_VIEWS.COLLAPSED
                    : SIDEBAR_VIEWS.EXPANDED,
        });

        setTimeout(() => {
            setDisplayDelay(false);
        }, 350);
    };

    const getSidebarComponenet = (
        element,
        tooltipText,
        componentTitle,
        onClickAction,
        locked = false
    ) => {
        const icon = React.createElement(element, {
            style: locked ? { ...ICON_STYLE, cursor: 'not-allowed', color: '#b3b3b3' } : ICON_STYLE,
            onClick: locked ? null : onClickAction,
        });
        return (
            <div className="sidebar__component__container">
                <Tooltip
                    title={sidebarState === SIDEBAR_VIEWS.COLLAPSED ? tooltipText : undefined}
                    placement="right"
                >
                    {locked ? (
                        <Badge offset={[0, 10]} count={<GrLock />}>
                            {icon}
                        </Badge>
                    ) : (
                        icon
                    )}
                </Tooltip>
                {sidebarState === SIDEBAR_VIEWS.EXPANDED && !displayDelay ? (
                    <div
                        className="sidebar__component__title"
                        onClick={onClickAction}
                        style={locked ? { color: '#b3b3b3' } : {}}
                    >
                        {componentTitle}
                    </div>
                ) : null}
            </div>
        );
    };

    return isLoggedIn() && deviceType !== DEVICE_TYPE.MOBILE ? (
        <div
            className="sidebar"
            style={{ width: sidebarState === SIDEBAR_VIEWS.EXPANDED ? 250 : 70 }}
        >
            <div className="sidebar__content">
                <div className="sidebar__content--top">
                    {SIDEBAR_COMPONENTS.map((comp) => (
                        <div key={`${comp.componentTitle}`}>
                            {getSidebarComponenet(
                                comp.component,
                                comp.componentTitle,
                                comp.componentTitle,
                                comp.onClickAction,
                                comp.locked
                            )}
                        </div>
                    ))}
                </div>
                <div className="sidebar__content--center" />
                <div className="sidebar__content--bottom">
                    {getSidebarComponenet(SettingOutlined, 'Settings', 'Settings', () =>
                        history.push('/settings')
                    )}

                    <div>
                        {sidebarState === SIDEBAR_VIEWS.EXPANDED ? (
                            <Tooltip title="Collapse" placement="right">
                                <DoubleLeftOutlined
                                    style={EXPAND_ICON_STYLE}
                                    onClick={toggleSidebarState}
                                />
                            </Tooltip>
                        ) : (
                            <Tooltip title="Expand" placement="right">
                                <DoubleRightOutlined
                                    style={EXPAND_ICON_STYLE}
                                    onClick={toggleSidebarState}
                                />
                            </Tooltip>
                        )}
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default Sidebar;
