import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Input, Modal, Tooltip } from 'antd';
import PulseLoader from 'react-spinners/PulseLoader';
import validator from 'validator';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { getUsersRolePermissions } from '@wx/common';

import AuthenticationApi from '../api/authentication';

import logo from '../images/logo.png';
import { setAccessToken } from '../util/authentication';
import { APP_ACTION_TYPES, MODAL_TYPES } from '../store/constants/appActionTypes';
import { handleReCaptchaVerify } from '../util/recaptcha';
import {
    captchaErrorText,
    changeEmailConfirmSuccessText,
    passwordResetErrorText,
    passwordResetModalText,
} from '../util/longText';
import {
    displaySimpleErrorModal,
    displaySimpleSuccessModal,
    InfoModalHeader,
} from '../component/global/ModalController';
import LabelledInput from '../component/reusable/LabelledInput';
import { onAPIError } from '../util/apiUtil';

const REDIRECT_OPTIONS = Object.freeze({
    HOME: '/',
    SSO_LOGIN: '/oauthLogin',
});

const LoginCaptchaWrapper = ({ match }) => {
    const dispatch = useDispatch();
    const [captchaToken, setCaptchaToken] = useState(null);

    const { infoMessage } = match.params;

    useEffect(() => {
        AuthenticationApi.getcaptchaToken()
            .then((token) => setCaptchaToken(token))
            .catch(() => {
                onAPIError(dispatch, captchaErrorText);
            });
    }, []);

    return !captchaToken ? (
        <div className="login__outer-container">Loading...</div>
    ) : (
        <GoogleReCaptchaProvider reCaptchaKey={captchaToken}>
            <Login infoMessage={infoMessage} />
        </GoogleReCaptchaProvider>
    );
};

const Login = ({ infoMessage }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');

    const [displayForgottenPasswordModal, setDisplayForgottenPasswordModal] = useState(false);
    const [submittingForgottenPassword, setSubmittingForgottenPassword] = useState(false);
    const [forgottenPasswordEmail, setForgottenPasswordEmail] = useState('');

    const [pending, setPending] = useState(false);
    const [error, setError] = useState(null);

    const [redirect, setRedirect] = useState(null);

    // If infoMessage variable from the path exists on mount
    useEffect(() => {
        if (infoMessage) {
            // If redirected from email change
            if (infoMessage === 'emailChangeSuccess') {
                displaySimpleSuccessModal(dispatch, changeEmailConfirmSuccessText, () =>
                    history.push('/login')
                );
            }
        }
    }, []);

    const handleEnterKey = useCallback(
        (e) => {
            const { keyCode } = e;
            if (keyCode === 13) {
                logUserIn();
            }
        },
        [userName, password]
    );

    useEffect(() => {
        window.addEventListener('keydown', handleEnterKey);
        return () => {
            window.removeEventListener('keydown', handleEnterKey);
        };
    }, [handleEnterKey]);

    const validateUserDetails = () => {
        if (userName.length < 1 || password.length < 1) {
            setError('Missing Login Details');
            return false;
        } else {
            setError(null);
            return true;
        }
    };
    const toggleLoggedIn = (token, expirySeconds) => {
        setAccessToken(token, expirySeconds);
        setRedirect(REDIRECT_OPTIONS.HOME);
        dispatch({
            type: APP_ACTION_TYPES.TOGGLE_LOGGED_IN,
            payload: true,
        });
    };

    const logUserIn = () => {
        // Check for errors
        if (!validateUserDetails()) return;

        // Login API
        setPending(true);

        handleReCaptchaVerify(executeRecaptcha)
            .then((verificationToken) => {
                AuthenticationApi.login(userName, password, verificationToken)
                    .then((loginDetails) => {
                        setPending(false);
                        dispatch({
                            type: APP_ACTION_TYPES.SET_USER_DATA,
                            payload: { ...loginDetails.user },
                        });

                        const userPermissions = getUsersRolePermissions(loginDetails.user.roles);
                        dispatch({
                            type: APP_ACTION_TYPES.SET_USER_PERMISSIONS,
                            payload: [...userPermissions],
                        });
                        toggleLoggedIn(loginDetails.token, loginDetails.expiry_seconds);
                    })
                    .catch((error) => {
                        setPending(false);
                        setError(error);
                    });
            })
            .catch(() => {
                onAPIError(dispatch, captchaErrorText);
            });
    };

    const getForgottenPasswordModalContent = () => (
        <div>
            {passwordResetModalText}
            <Input
                size="large"
                style={{ width: '100%' }}
                value={forgottenPasswordEmail}
                onChange={(e) => setForgottenPasswordEmail(e.target.value)}
            />
        </div>
    );

    const submitForgottenPassword = () => {
        setSubmittingForgottenPassword(true);

        handleReCaptchaVerify(executeRecaptcha).then((verificationToken) => {
            AuthenticationApi.getResetPasswordToken(forgottenPasswordEmail, verificationToken)
                .then(() => {
                    setForgottenPasswordEmail('');
                    setDisplayForgottenPasswordModal(false);
                    setSubmittingForgottenPassword(false);

                    dispatch({
                        type: APP_ACTION_TYPES.SET_DISPLAY_MODAL,
                        payload: {
                            type: MODAL_TYPES.SUCCESS,
                            hideCancelButton: true,
                            okAction: () => {
                                dispatch({
                                    type: APP_ACTION_TYPES.SET_DISPLAY_MODAL,
                                    payload: null,
                                });
                            },
                            content: <div>A password reset link has been sent to your email.</div>,
                        },
                    });
                })
                .catch(() => {
                    setForgottenPasswordEmail('');
                    setDisplayForgottenPasswordModal(false);
                    setSubmittingForgottenPassword(false);
                    displaySimpleErrorModal(dispatch, passwordResetErrorText);
                });
        });
    };

    return redirect ? (
        <Redirect to={{ pathname: redirect }} />
    ) : (
        <>
            <div className="login__outer-container">
                <div className="login__container">
                    <div className="login__title">LOGIN</div>

                    {/* FORM */}
                    <div className="login__details__container">
                        {/* SSO BUTTON */}
                        <div
                            className="login__details__button"
                            onClick={() => setRedirect(REDIRECT_OPTIONS.SSO_LOGIN)}
                        >
                            LOG IN WITH EMAIL
                            <Tooltip title="Click here to log in with your Western Xpress email account">
                                <AiOutlineQuestionCircle style={{ fontSize: 20, marginLeft: 5 }} />
                            </Tooltip>
                        </div>
                        <div className="page-break" style={{ margin: '25px 0' }} />

                        {/* EMAIL */}
                        <LabelledInput
                            labelText="Email"
                            value={userName}
                            onChangeAction={setUserName}
                            withValidation
                            isValid={!error}
                        />

                        {/* PASSWORD */}
                        <LabelledInput
                            labelText="Password"
                            value={password}
                            onChangeAction={setPassword}
                            withValidation
                            isValid={!error}
                            isPassword
                        />
                        <div
                            className="login__details__input__label--forgotPassword"
                            onClick={() => setDisplayForgottenPasswordModal(true)}
                        >
                            Forgot Password?
                        </div>

                        {/* BUTTON */}
                        <div className="login__details__button" onClick={() => logUserIn()}>
                            LOG IN
                        </div>

                        {/* SPINNER */}
                        <div className="login__details__pending">
                            {pending ? <PulseLoader color="#0e76bc" /> : null}
                        </div>

                        {/* ERROR */}
                        <div className="login__details__error">{error}</div>
                    </div>

                    <div className="page-break" style={{ margin: '25px 0' }} />

                    <div className="login__logo__container">
                        <img src={logo} alt="logo" style={{ height: '100%' }} />
                    </div>
                </div>
            </div>
            <Modal
                title={InfoModalHeader('Password Reset')}
                visible={displayForgottenPasswordModal}
                onOk={submitForgottenPassword}
                onCancel={() => {
                    setForgottenPasswordEmail('');
                    setDisplayForgottenPasswordModal(false);
                }}
                closable={false}
                keyboard={false}
                maskClosable={false}
                confirmLoading={submittingForgottenPassword}
                okButtonProps={validator.isEmail(forgottenPasswordEmail) ? {} : { disabled: true }}
                cancelButtonProps={submittingForgottenPassword ? { disabled: true } : {}}
            >
                {getForgottenPasswordModalContent()}
            </Modal>
        </>
    );
};

export default LoginCaptchaWrapper;
