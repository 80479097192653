import { useState } from 'react';

import Users from '../../component/userManagement/Users';
import Audits from '../../component/userManagement/Audits';
import { shouldSee } from '../../util/permissionsUtil';
import Tabs from '../../component/layout/Tabs';

const UserManagement = ({ match }) => {
    const [views] = useState([
        {
            viewName: 'Users',
            key: 'users',
            component: <Users key="users" match={match} />,
            locked: !shouldSee(['USER_MANAGE_USER_READ']),
        },
        {
            viewName: 'Audit Log',
            key: 'audit_log',
            component: <Audits />,
            locked: !shouldSee(['USER_MANAGE_VIEW_AUDITS']),
        },
    ]);
    const [currentView, setCurrentView] = useState(views.find((view) => !view.locked).viewName);

    return <Tabs tabViews={views} selectedTab={currentView} onTabChange={setCurrentView} />;
};
export default UserManagement;
