import AddressForm from './AddressForm';
import LabelledInput from './LabelledInput';

const SimpleDetailsForm = ({ formData, formStyle }) => {
    return (
        <div className="simple-details-form__container">
            <div className={`simple-details-form__line${formStyle}`}>
                <LabelledInput
                    labelText="First Name"
                    value={formData.firstName}
                    isDisabled
                    customWidth={formStyle.includes('mobile') ? '100%' : '33%'}
                />
                <LabelledInput
                    labelText="Last Name"
                    value={formData.lastName}
                    isDisabled
                    customWidth={formStyle.includes('mobile') ? '100%' : '33%'}
                />
                <LabelledInput
                    labelText="Phone #"
                    value={formData.phoneNumber}
                    isDisabled
                    customWidth={formStyle.includes('mobile') ? '100%' : '33%'}
                />
            </div>
            {/* Set isEditing to false to disable fields */}
            <AddressForm formData={formData} formStyle={formStyle} isEditing={false} />
        </div>
    );
};

export default SimpleDetailsForm;
