import { Checkbox, Select, Table } from 'antd';
import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PulseLoader from 'react-spinners/PulseLoader';
import { FiDownload } from 'react-icons/fi';
import { Excel } from 'antd-table-saveas-excel';

import CustomersApi from '../../../api/dataManagement/customers';
import ReportsApi from '../../../api/reports/reports';
import GenerateReportModal from './GenerateReportModal';
import { displaySimpleErrorModal } from '../../global/ModalController';
import {
    customersFetchErrorText,
    deliveryChargeReportGenerateErrorText,
    deliveryChargeReportNoDataErrorText,
} from '../../../util/longText';
import { shouldSee } from '../../../util/permissionsUtil';
import { onAPIError } from '../../../util/apiUtil';
import { DELIVERY_CHARGE_REPORT_COLUMNS } from '../../../util/tableColumns';

const DeliveryChargeReport = ({
    displayDeliveryChargeReportModal,
    setDisplayDeliveryChargeReportModal,
}) => {
    const dispatch = useDispatch();
    const myRef = useRef(null);

    const [allCustomersSelected, setAllCustomersSelected] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [modalSelectionStep, setModalSelectionStep] = useState(1);

    const [reports, setReports] = useState(null);
    const [reportData, setReportData] = useState(null);

    useEffect(() => {
        if (shouldSee(['CUSTOMER_READ_MANY'])) {
            CustomersApi.getCustomers('', false, 0, 10000)
                .then(({ data }) => {
                    setCustomers(data);
                })
                .catch(() => displaySimpleErrorModal(dispatch, customersFetchErrorText));
        }
    }, []);

    useEffect(() => {
        if (modalSelectionStep === 2) {
            getDeliveryChargeReports();
        }
    }, [modalSelectionStep]);

    useEffect(() => {
        if (myRef.current) {
            myRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [myRef]);

    const resetForm = () => {
        setAllCustomersSelected(false);
        setSelectedCustomer(null);
        setModalSelectionStep(1);
        setReports(null);
    };

    const getDeliveryChargeReports = () => {
        ReportsApi.getDeliveryChargeReports(selectedCustomer)
            .then((data) => {
                setReports([...data]);
            })
            .catch((e) => {
                let apiErrorText = deliveryChargeReportGenerateErrorText;
                if (
                    e.toUpperCase().includes('DOES NOT EXIST') ||
                    e.toUpperCase().includes('NO DELIVERY CHARGE REPORTS')
                ) {
                    apiErrorText = deliveryChargeReportNoDataErrorText;
                }
                setModalSelectionStep(1);
                onAPIError(dispatch, apiErrorText);
            });
    };

    const getCustomerNameFromId = (customerId) => {
        const customer = customers.find((cust) => `${cust.customerId}` === `${customerId}`);

        if (customer) {
            return `${customer.contactInfo.firstName} ${customer.contactInfo.lastName}`;
        }

        return '';
    };

    const modalStepOneContent = () => (
        <>
            <Checkbox.Group
                options={[
                    {
                        label: 'All Customers',
                        value: 'all-customers',
                    },
                ]}
                onChange={(checkedValues) => {
                    if (checkedValues.includes('all-customers')) {
                        setAllCustomersSelected(true);
                        setSelectedCustomer(null);
                    } else {
                        setAllCustomersSelected(false);
                    }
                }}
                value={allCustomersSelected ? ['all-customers'] : []}
            />

            <div className="delivery-charge-report__modal__line-break__container">
                <span className="delivery-charge-report__modal__line-break__text">OR</span>
            </div>

            <Select
                size="large"
                style={{ width: '100%' }}
                placeholder="Select customer"
                disabled={allCustomersSelected}
                value={selectedCustomer ? getCustomerNameFromId(selectedCustomer) : undefined}
                onChange={(value) => {
                    setSelectedCustomer(value);
                }}
            >
                {customers.map((customer) => (
                    <Select.Option key={customer.customerId}>
                        {getCustomerNameFromId(customer.customerId)}
                    </Select.Option>
                ))}
            </Select>

            <div className="delivery-charge-report__modal__select-button__container">
                <div
                    className={`standard-button${
                        allCustomersSelected || selectedCustomer ? '' : '--disabled'
                    }`}
                    onClick={
                        allCustomersSelected || selectedCustomer
                            ? () => setModalSelectionStep(2)
                            : null
                    }
                >
                    SELECT REPORT
                </div>
            </div>
        </>
    );

    const modalStepTwoContent = () => {
        return (
            <div className="delivery-charge-report__modal__report-select__container">
                <div className="delivery-charge-report__modal__report-select__options">
                    {reports ? (
                        <>
                            <div style={{ marginBottom: 10 }}>Click on report to view details</div>
                            {reports.map((report) => (
                                <div
                                    key={`report-link-${report.deliveryChargeReportId}`}
                                    className="delivery-charge-report__modal__report-select__option"
                                    onClick={() => {
                                        setReportData({ ...report });
                                        setDisplayDeliveryChargeReportModal(false);
                                        resetForm();
                                    }}
                                >
                                    {`Generated on ${report.generatedDate}, dates included: ${report.reportDateRange.from} - ${report.reportDateRange.to}`}
                                </div>
                            ))}
                        </>
                    ) : (
                        <PulseLoader color="#0e76bc" />
                    )}
                </div>
            </div>
        );
    };

    const getDeliveryChargeReportModalContent = () => (
        <div className="delivery-charge-report__modal__container">
            {modalSelectionStep === 1 ? modalStepOneContent() : modalStepTwoContent()}
        </div>
    );

    const getReportHeader = () => (
        <div className="delivery-charge-report__report-header__container">
            <div>Generated On: {reportData.generatedDate}</div>
            <div>{`Dates Included: ${reportData.reportDateRange.from} - ${reportData.reportDateRange.to}`}</div>
            <div className="standard-button--empty" onClick={() => downloadTableData()}>
                DOWNLOAD <FiDownload style={{ marginLeft: 5 }} />
            </div>
        </div>
    );

    const downloadTableData = () => {
        const fileName = `DeliveryChargeReport_${
            allCustomersSelected ? 'FULL' : reportData.charges[0].customerName
        }_${reportData.reportDateRange.from}-${reportData.reportDateRange.to}`;

        const excel = new Excel();
        excel
            .setTHeadStyle({ background: '0E76BC', color: 'FFFFFF', border: false })
            .addSheet('Delivery Charge Report')
            .drawCell(0, 0, {
                hMerge: 4,
                vMerge: 2,
                value: fileName,
                style: {
                    bold: true,
                    v: 'center',
                    h: 'center',
                },
            })
            .addColumns(DELIVERY_CHARGE_REPORT_COLUMNS)
            .addDataSource(reportData.charges)
            .saveAs(`${fileName}.xlsx`);
    };

    return (
        <>
            {/* REPORT */}
            {reportData ? (
                <div className="delivery-charge-report__container">
                    <div className="heading" ref={myRef}>
                        Delivery Charge Report
                    </div>

                    <div className="delivery-charge-report__data__container">
                        {getReportHeader()}
                        <Table
                            style={{ width: '100%' }}
                            columns={DELIVERY_CHARGE_REPORT_COLUMNS}
                            dataSource={reportData.charges}
                            pagination={false}
                            rowKey={() => `table_record_${Math.random()}`}
                        />
                    </div>
                </div>
            ) : null}

            {/* GENERATE REPORT MODAL */}
            <GenerateReportModal
                title="Generate Delivery Charge Report"
                visible={displayDeliveryChargeReportModal}
                onCancelAction={() => {
                    setDisplayDeliveryChargeReportModal(false);
                    resetForm();
                }}
                content={getDeliveryChargeReportModalContent()}
                hideButtons
            />
        </>
    );
};
export default DeliveryChargeReport;
