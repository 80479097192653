import axios from 'axios';
import { getAuthHeader, getErrorMessage, getHost } from '../../util/apiUtil';
import { shouldSee } from '../../util/permissionsUtil';

const TDM_URL = `${getHost()}/tdm`;

class TdmsApi {
    static getTdms(routeName = '', includeInactive = true, skip = 0, take = 10) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['TDM_READ_MANY'])) {
                console.error('API ERROR | TDMs: User not permitted');
                reject();
            }

            let queriedUrl = `${TDM_URL}?take=${take}&skip=${skip}&includeInactive=${includeInactive}`;
            if (routeName.length) {
                queriedUrl += `&routeNameLike=${routeName}`;
            }

            axios
                .get(queriedUrl, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | TDMs: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static getTdById(id) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['TDM_READ'])) {
                console.error('API ERROR | TDMs: User not permitted');
                reject();
            }

            axios
                .get(`${TDM_URL}/${id}`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | TDMs: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static createTdm(tdmData) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['TDM_CREATE'])) {
                console.error('API ERROR | TDMs: User not permitted');
                reject();
            }

            axios
                .post(
                    `${TDM_URL}`,
                    { ...tdmData },
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | TDMs: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static updateTdm(tdmData) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['TDM_UPDATE'])) {
                console.error('API ERROR | TDMs: User not permitted');
                reject();
            }

            axios
                .patch(TDM_URL, { ...tdmData }, { headers: getAuthHeader() })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | TDMs: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static archiveTdm(tdmId) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['TDM_DELETE'])) {
                console.error('API ERROR | TDMs: User not permitted');
                reject();
            }

            axios
                .delete(`${TDM_URL}/${tdmId}`, { headers: getAuthHeader() })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | TDMs: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }
}
export default TdmsApi;
