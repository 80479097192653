import { Breadcrumb } from 'antd';

const Breadcrumbs = ({ links }) => {
    return (
        <Breadcrumb separator=">">
            {links.map((link) => (
                <Breadcrumb.Item key={`${link.title}-breadcrumb`} href={link.href}>
                    {link.title}
                </Breadcrumb.Item>
            ))}
        </Breadcrumb>
    );
};
export default Breadcrumbs;
