import { useState } from 'react';
import { Modal } from 'antd';

import Tabs from '../../component/layout/Tabs';
import CustomerRates from '../../component/rateProfiles/CustomerRates';
import EmployeeRates from '../../component/rateProfiles/EmployeeRates';
import RateTypes from '../../component/rateProfiles/RateTypes';
import { shouldSee } from '../../util/permissionsUtil';
import { InfoModalHeader } from '../../component/global/ModalController';
import { beautifyTextValue } from '../../util/formUtil';
import RatesHistory from '../../component/rateProfiles/RatesHistory';

const RateProfiles = () => {
    const [views] = useState([
        {
            viewName: 'Customer Rates',
            key: 'customer-rates',
            component: (
                <CustomerRates
                    key="rate-profiles-customer-rates"
                    viewHistory={() => setDisplayAudits('customer')}
                />
            ),
            locked: !shouldSee(['CRP_READ_MANY']),
        },
        {
            viewName: 'Employee Rates',
            key: 'employee-rates',
            component: (
                <EmployeeRates
                    key="rate-profiles-employee-rates"
                    viewHistory={() => setDisplayAudits('driver')}
                />
            ),
            locked: !shouldSee(['DRP_READ_MANY']),
        },
        {
            viewName: 'Rate Types',
            key: 'rate-types',
            component: <RateTypes key="rate-profiles-rate-types" />,
            locked: !shouldSee(['PAY_TYPES_READ_MANY', 'SERVICE_TYPES_READ_MANY']),
        },
        {
            viewName: 'Fuel Rates',
            key: 'fuel-rates',
            isDisabled: true,
            component: null,
        },
    ]);
    const [currentView, setCurrentView] = useState(views.find((view) => !view.locked).viewName);
    const [displayAudits, setDisplayAudits] = useState(null);

    return (
        <>
            {displayAudits ? (
                <Modal
                    title={InfoModalHeader(`${beautifyTextValue(displayAudits)} Rates History`)}
                    visible
                    footer={null}
                    maskClosable
                    onCancel={() => setDisplayAudits(null)}
                    closable={false}
                    width={700}
                >
                    <RatesHistory rateType={displayAudits} />
                </Modal>
            ) : null}
            <Tabs
                tabViews={views}
                selectedTab={currentView}
                onTabChange={setCurrentView}
                verticalContainer
            />
        </>
    );
};
export default RateProfiles;
