import { useEffect, useState } from 'react';

import Tabs from '../../component/layout/Tabs';
import TruckInvoicesTable from '../../component/invoices/TruckInvoicesTable';
import { shouldSee } from '../../util/permissionsUtil';
import CustomerInvoicesTable from '../../component/invoices/CustomerInvoicesTable';
import PayrollTable from '../../component/payroll/PayrollTable';

const Invoices = ({ match }) => {
    const [views] = useState([
        {
            viewName: 'Truck Invoices',
            key: 'truck-invoices',
            component: <TruckInvoicesTable key="truck-invoices-table" match={match} />,
            locked: !shouldSee(['TRUCK_READ_MANY', 'INVOICE_READ_MANY']),
        },
        {
            viewName: 'Customer Invoices',
            key: 'customer-invoices',
            component: <CustomerInvoicesTable key="customer-invoices-table" />,
            locked: !shouldSee(['INVOICE_READ_MANY']),
        },
        {
            viewName: 'Payroll',
            key: 'payroll',
            component: <PayrollTable key="payroll-table" />,
            locked: !shouldSee(['PAYROLL_READ']),
        },
    ]);
    const [currentView, setCurrentView] = useState(views.find((view) => !view.locked).viewName);

    // If path includes search, open truck invoices with table filter
    const pathName = window.location.pathname;
    useEffect(() => {
        if (pathName.includes('search')) {
            setCurrentView('Truck Invoices');
        }
    }, [pathName]);

    return <Tabs tabViews={views} selectedTab={currentView} onTabChange={setCurrentView} />;
};
export default Invoices;
