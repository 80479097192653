import { useState } from 'react';

import Tabs from '../component/layout/Tabs';
import NotificationSettings from '../component/settings/NotificationSettings/NotificationSettings';
import ReportEmailConfig from '../component/settings/ReportEmailConfig/ReportEmailConfig';
import Credentials from '../component/settings/SystemSettings/Credentials';
import SystemSettings from '../component/settings/SystemSettings/SystemSettings';
import { shouldSee } from '../util/permissionsUtil';

const Settings = () => {
    const TABS = [
        {
            viewName: 'Credentials',
            key: 'credentials',
            component: <Credentials />,
            locked: false,
        },
        {
            viewName: 'Report Email Configuration',
            key: 'report-email-configuration',
            component: <ReportEmailConfig />,
            locked: !shouldSee(['USER_MANAGE_UPDATE']),
        },
        {
            viewName: 'System Settings',
            key: 'system-settings',
            component: <SystemSettings />,
            locked: !shouldSee(['SYSTEM_SETTINGS_UPDATE']),
        },
        {
            viewName: 'Notifications',
            key: 'notifications',
            component: <NotificationSettings />,
            locked: !shouldSee(['USER_MANAGE_USER_READ']),
        },
    ];

    const [currentView, setCurrentView] = useState(TABS[0].viewName);

    return <Tabs tabViews={TABS} selectedTab={currentView} onTabChange={setCurrentView} />;
};

export default Settings;
