import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import PulseLoader from 'react-spinners/PulseLoader';

import { displaySimpleErrorModal } from '../../component/global/ModalController';

import UsersApi from '../../api/userManagement/users';

import logo from '../../images/logo.png';
import { changeEmailConfirmErrorText } from '../../util/longText';

const ConfirmEmailUpdate = ({ match }) => {
    const dispatch = useDispatch();
    const emailUpdateToken = match.params.token;

    const [emailChangeDone, setEmailChangeDone] = useState(false);

    // Send email change finalize request
    useEffect(() => {
        UsersApi.confirmChangeEmail(emailUpdateToken)
            .then(() => {
                setEmailChangeDone(true);
            })
            .catch(() => displaySimpleErrorModal(dispatch, changeEmailConfirmErrorText));
    }, []);

    return emailChangeDone ? (
        <Redirect to={{ pathname: '/login/emailChangeSuccess' }} />
    ) : (
        <div className="confirm-email-update__container">
            <div className="heading">Please wait while we finish the email change process...</div>

            <div className="confirm-email-update__spinner__container">
                <PulseLoader color="#0e76bc" />
            </div>

            <div className="confirm-email-update__logo__container">
                <img src={logo} alt="logo" style={{ height: '100%' }} />
            </div>
        </div>
    );
};
export default ConfirmEmailUpdate;
