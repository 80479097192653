import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import TableComponent from '../reusable/TableComponent';

import { DEVICE_TYPE } from '../../store/constants/appActionTypes';

import TrucksApi from '../../api/dataEntry/trucks';
import { TRUCK_INVOICE_COLUMNS } from '../../util/tableColumns';
import { displaySimpleErrorModal } from '../global/ModalController';
import { invoiceDataFetchErrorText } from '../../util/longText';

const TruckInvoicesTable = ({ match }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const deviceType = useSelector((state) => state.app.device);
    const pathName = window.location.pathname;

    const [invoicesData, setInvoicesData] = useState([]);
    const [invoicesCount, setInvoicesCount] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchKey, setSearchKey] = useState('');

    useEffect(() => {
        getInvoices();
    }, [searchKey, currentPage]);

    // If path includes 'search' set the searchKey and change path back
    useEffect(() => {
        if (pathName.includes('search')) {
            const customerNameSearchKey = match.params.customerLike;
            setSearchKey(customerNameSearchKey);

            setTimeout(() => {
                history.push('/invoices');
            }, 500);
        }
    }, [pathName]);

    const getInvoices = async () => {
        // Amount of recrods to be skipped based on page number, 0 if searching for something
        const skip = searchKey.length ? 0 : (currentPage - 1) * 10;
        const invoices = await TrucksApi.getInvoices(skip, 10, null, searchKey).catch(() =>
            displaySimpleErrorModal(dispatch, invoiceDataFetchErrorText)
        );

        setInvoicesCount(invoices.count);
        setInvoicesData([...invoices.data]);
    };

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const goToInvoiceRecord = (invoiceId) => {
        history.push(`/invoices/truck/${invoiceId}`);
    };
    const getMobileData = () => {
        const filteredData = [...invoicesData];
        const mobileData = [];
        filteredData.forEach((record) => {
            mobileData.push({
                id: record.invoiceId,
                title: `${record.invoiceReference} | ${record.customerName}`,

                subtitle: `${new Date(record.createdTs).toDateString()} | $${(
                    Math.round(record.revenueCollected * 100) / 100
                ).toFixed(2)}`,
                active: true,
            });
        });
        return mobileData;
    };

    return (
        <div
            className={`invoices-table__container${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
        >
            {/* TABLE */}
            <TableComponent
                tableColumns={TRUCK_INVOICE_COLUMNS}
                tableData={invoicesData}
                searchValue={searchKey}
                searchOnChange={setSearchKey}
                searchPlaceholder="Search by customer"
                mobileListData={getMobileData()}
                currentPage={currentPage}
                onPageChange={onPageChange}
                totalPageCount={invoicesCount}
                onRowClick={(record) =>
                    record.invoiceId
                        ? goToInvoiceRecord(record.invoiceId)
                        : goToInvoiceRecord(record.id)
                }
            />
        </div>
    );
};
export default TruckInvoicesTable;
