import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import PulseLoader from 'react-spinners/PulseLoader';

import LabelledInput from '../../component/reusable/LabelledInput';

import AuthenticationApi from '../../api/authentication';

import logo from '../../images/logo.png';

import { handleReCaptchaVerify } from '../../util/recaptcha';
import {
    captchaErrorText,
    newUserInviteDetailsFetchErrorText,
    newUserSignUpSuccessText,
    newUserSignUpErrorText,
} from '../../util/longText';
import { onAPIError, onAPISuccess } from '../../util/apiUtil';
import UsersApi from '../../api/userManagement/users';

const NewUserSignUpCaptchaWrapper = ({ match }) => {
    const dispatch = useDispatch();
    const [captchaToken, setCaptchaToken] = useState(null);
    const [inviteDetails, setInviteDetails] = useState(null);

    const inviteToken = match.params.token;

    useEffect(() => {
        AuthenticationApi.getcaptchaToken()
            .then((token) => setCaptchaToken(token))
            .catch(() => {
                onAPIError(dispatch, captchaErrorText);
            });

        UsersApi.getInviteDetails(inviteToken)
            .then((details) => setInviteDetails(details))
            .catch(() => {
                onAPIError(dispatch, newUserInviteDetailsFetchErrorText);
            });
    }, []);

    return !captchaToken || !inviteDetails ? (
        <div className="new-user-sign-up__outer-container">Loading...</div>
    ) : (
        <GoogleReCaptchaProvider reCaptchaKey={captchaToken}>
            <NewUserSignUp inviteDetails={inviteDetails} inviteToken={inviteToken} />
        </GoogleReCaptchaProvider>
    );
};

const NewUserSignUp = ({ inviteDetails, inviteToken }) => {
    const dispatch = useDispatch();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [userDetails, setUserDetails] = useState({ email: '', firstName: '', lastName: '' });
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [submittingAccountCreate, setSubmittingAccountCreate] = useState(false);

    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        if (inviteDetails) {
            setUserDetails({ ...inviteDetails });
        }
    }, [inviteDetails]);

    const arePasswordsValid = () => {
        if (password.length < 6) return false;
        if (confirmPassword !== password) return false;
        return true;
    };

    const submitCreateUserAccount = () => {
        setSubmittingAccountCreate(true);

        handleReCaptchaVerify(executeRecaptcha)
            .then((verificationToken) => {
                UsersApi.createUserAccount({
                    password,
                    inviteToken,
                    captcha: verificationToken,
                })
                    .then(() => {
                        setSubmittingAccountCreate(false);
                        setUserDetails({ email: '', firstName: '', lastName: '' });
                        setPassword('');
                        setConfirmPassword('');

                        onAPISuccess(dispatch, newUserSignUpSuccessText);
                        setRedirect(true);
                    })
                    .catch(() => {
                        setSubmittingAccountCreate(false);

                        onAPIError(dispatch, newUserSignUpErrorText);
                    });
            })
            .catch(() => {
                onAPIError(dispatch, captchaErrorText);
            });
    };

    return redirect ? (
        <Redirect to={{ pathname: '/login' }} />
    ) : (
        <>
            <div className="new-user-sign-up__outer-container">
                <div className="new-user-sign-up__container">
                    <div className="new-user-sign-up__title">SIGN UP</div>

                    {/* FORM */}
                    <div className="new-user-sign-up__details__container">
                        {/* EMAIL */}
                        <LabelledInput
                            labelText="Email"
                            value={userDetails.email}
                            noTopMargin
                            isDisabled
                        />

                        {/* FIRST NAME */}
                        <LabelledInput
                            labelText="First Name"
                            value={userDetails.firstName}
                            noTopMargin
                            isDisabled
                        />

                        {/* LAST NAME */}
                        <LabelledInput
                            labelText="Last Name"
                            value={userDetails.lastName}
                            noTopMargin
                            isDisabled
                        />

                        {/* PASSWORD */}
                        <LabelledInput
                            labelText="Password"
                            value={password}
                            onChangeAction={setPassword}
                            withValidation
                            validationText="Password must be at least 6 characters long"
                            isValid={password.length >= 6}
                            isPassword
                        />

                        {/* CONFIRM PASSWORD */}
                        <LabelledInput
                            labelText="Confirm Password"
                            noTopMargin
                            value={confirmPassword}
                            onChangeAction={setConfirmPassword}
                            withValidation
                            validationText="Passwords don't match"
                            isValid={confirmPassword === password}
                            isPassword
                        />

                        {/* BUTTON */}
                        <div
                            className={`standard-button${
                                !arePasswordsValid() || submittingAccountCreate ? '--disabled' : ''
                            }`}
                            style={{ width: '100%', marginTop: 15 }}
                            onClick={() => submitCreateUserAccount()}
                        >
                            SUBMIT
                        </div>

                        {/* SPINNER */}
                        {submittingAccountCreate ? (
                            <div className="new-user-sign-up__details__pending">
                                <PulseLoader color="#0e76bc" />
                            </div>
                        ) : null}
                    </div>

                    <div className="page-break" style={{ margin: '25px 0' }} />

                    <div className="new-user-sign-up__logo__container">
                        <img src={logo} alt="logo" style={{ height: '100%' }} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewUserSignUpCaptchaWrapper;
