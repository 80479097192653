import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'antd';

import { displaySimpleErrorModal, InfoModalHeader } from '../global/ModalController';
import { APP_ACTION_TYPES } from '../../store/constants/appActionTypes';
import DriverRatesApi from '../../api/rateProfiles/driverRates';
import CustomerRatesApi from '../../api/rateProfiles/customerRates';
import { customerRateFetchDataErrorText, driverRateFetchDataErrorText } from '../../util/longText';

const RatesView = () => {
    const dispatch = useDispatch();
    const viewingDriverRates = useSelector((state) => state.app.viewingDriverRates);
    const viewingCustomerRates = useSelector((state) => state.app.viewingCustomerRates);

    const [driverRates, setDriverRates] = useState([]);
    const [customerRates, setCustomerRates] = useState([]);

    useEffect(() => {
        if (viewingDriverRates) {
            fetchDriverRatesData();
        } else if (viewingCustomerRates) {
            fetchCustomerRatesData();
        }
    }, [viewingDriverRates, viewingCustomerRates]);

    const fetchDriverRatesData = () => {
        DriverRatesApi.getDriverRateById(viewingDriverRates.rateProfileId)
            .then((data) => {
                setDriverRates([...data.payTypes]);
            })
            .catch(() => displaySimpleErrorModal(dispatch, driverRateFetchDataErrorText));
    };

    const fetchCustomerRatesData = () => {
        CustomerRatesApi.getCustomerRateById(viewingCustomerRates.rateProfileId)
            .then((data) => {
                setCustomerRates([...data.serviceTypes]);
            })
            .catch(() => displaySimpleErrorModal(dispatch, customerRateFetchDataErrorText));
    };

    const closeModal = () => {
        dispatch({
            type: viewingDriverRates
                ? APP_ACTION_TYPES.SET_VIEWING_DRIVER_RATES
                : APP_ACTION_TYPES.SET_VIEWING_CUSTOMER_RATES,
            payload: null,
        });
    };

    const getModalHeader = () =>
        viewingDriverRates
            ? `${viewingDriverRates.contactInfo.firstName} ${viewingDriverRates.contactInfo.lastName}'s Rates`
            : `${viewingCustomerRates.contactInfo.firstName} ${viewingCustomerRates.contactInfo.lastName}'s Rates`;

    const getModalContent = () => {
        const ratesToDisplay = viewingDriverRates ? driverRates : customerRates;
        return (
            <div className="rates-view__container">
                {ratesToDisplay.map((rate) => (
                    <div key={rate.label} className="rates-view__rate__container">
                        <div className="rates-view__rate__title">{rate.label}</div>
                        <div className="rates-view__rate__title">{rate.activeRate}</div>
                    </div>
                ))}
            </div>
        );
    };

    return viewingDriverRates || viewingCustomerRates ? (
        <Modal
            title={InfoModalHeader(getModalHeader())}
            visible
            footer={[
                <Button key="submit" type="primary" onClick={closeModal}>
                    OK
                </Button>,
            ]}
            closable={false}
            keyboard={false}
            maskClosable={false}
            width={400}
        >
            {getModalContent()}
        </Modal>
    ) : null;
};
export default RatesView;
