/* *************** GENERAL *************** */
export const refreshSessionErrorText =
    'We encountered an error while trying to refresh your session. Please try again.';

export const captchaErrorText =
    "Couldn't validate the Captcha token with our server. Please refresh the page. If the error persists, contact support.";

export const userNotPermittedText =
    "Looks like you're trying to access a resource you do not have access to. If you think you should have access to this resource, please contact support.";

export const dismissNotificationErrorText =
    'We encountered an error while trying to dismiss a notification. Please try again, if the error persists, contact support.';

/* *************** REPORTS *************** */
export const payrollReportGenerateErrorText =
    'Payroll report generation failed. Please try again. If the error persists, contact support.';

export const salesReportGenerateErrorText =
    'Payroll report generation failed. Please try again. If the error persists, contact support.';

export const reportNoDataErrorText =
    'No truck records found for the provided date range, please try again using different dates. If you think that these dates should yield a result, please contact support.';

export const customerInvoicesFetchErrorText =
    'We encountered an error while trying to fetch customer invoices. Please refresh the page.';

export const deliveryChargeReportGenerateErrorText =
    'Delivery charge report generation failed. Please try again. If the error persists, contact support.';

export const deliveryChargeReportNoDataErrorText =
    'No reports found for the provided customer, please try again selecting a different customer. If you think that this customer should have reports in the system, please contact support.';

export const reportEmailConfigsFetchErrorText =
    'We encountered an error while trying to fetch report email configurations. Please refresh the page.';

export const removeReportEmailConfigConfirmText =
    'If you remove this user from the mailing list, they will no longer receive this report. Do you want to proceed?';

export const removeReportEmailConfigSuccessText =
    'User successfully removed from the mailing list.';

export const removeReportEmailConfigErrorText =
    'We encountered an error while trying to remove the user from mailing list. Please try again.';

export const addReportEmailConfigSuccessText = 'User successfully added to the mailing list.';

export const addReportEmailConfigErrorText =
    'We encountered an error while trying to add user to the mailing list. Please try again.';

/* *************** DATA ENTRY *************** */
export const driversFetchErrorText =
    'We encountered an error while trying to fetch available drivers. Please refresh the page.';

export const tdmsFetchErrorText =
    'We encountered an error while trying to fetch available TDMs. Please refresh the page.';

export const trucksFetchErrorText =
    'We encountered an error while trying to fetch truck records. Please refresh the page.';

export const customersFetchErrorText =
    'We encountered an error while trying to fetch customer records. Please refresh the page.';

export const truckDateAndTdmSearchErrorText =
    "We couldn't find a truck matching your search criteria.";

export const trucksSaveDataSuccessText = 'New truck record saved successfully.';

export const trucksUpdateDataSuccessText = 'Truck record updated successfully.';

export const trucksSaveDataErrorText =
    'We encountered an error while trying to save the truck record. Please try again. If the error persists, contact support.';

export const invoicesFetchErrorText =
    'We encountered an error while trying to fetch saved invoices. Please refresh the page.';

export const invoiceSaveSuccessText = 'New invoice saved successfully.';

export const invoiceUpdateSuccessText = 'Invoice updated successfully.';

export const invoiceSaveErrorText =
    'We encountered an error while trying to save the invoice. Please try again. If the error persists, contact support.';

export const incompleteEntitiesFetchErrorText =
    'We encountered an error while trying to fetch the incomplete records. Please refresh the page.';

export const truckStatusSpecialPayHelperText =
    'Truck has been selected for payroll and requires special pay data.';

export const truckStatusPayrollSelectedHelperText =
    'Truck has been selected for payroll. Once the payroll is generated you will no longer be able to edit this truck.';

export const truckStatusLockedHelperText =
    'This truck has been assigned to a generated payroll. Editing this truck will require the payroll it is included on to be regenerated.';

export const truckStatusUnlockedHelperText =
    'This truck has been assigned to a payroll and has been unlocked to amend data. Once done, the payroll it is inlcuded on will have to be regenerated.';

export const truckStatusHardLockedHelperText =
    'This truck has been included on a finalized payroll. You can no longer edit it.';

export const editLockedInvoiceHelperText =
    'This invoice belongs to a truck on a generated payroll. Editing it will require the payroll to be regenerated. Do you want to continue?';

export const saveLockedInvoiceHelperText =
    'This invoice belongs to a truck on a generated payroll. Saving it will require the payroll to be regenerated. Do you want to continue?';
/* *************** DATA MANAGEMENT *************** */
export const driversFetchDataErrorText =
    'We encountered an error while trying to fetch driver data.  If the error persists, contact support.';

export const driversSaveDataSuccessText = 'New driver record saved successfully.';

export const driversSaveDataErrorText =
    'We encountered an error while trying to save new driver record. Please try again. If the error persists, contact support.';

export const driversUpdateDataSuccessText = 'Driver record updated successfully.';

export const driversUpdateDataErrorText =
    'We encountered an error while trying to update the driver record. Please try again. If the error persists, contact support.';

export const driversArchiveDataSuccessText =
    'Driver record archived successfully. This page will reload automatically.';

export const driversArchiveDataErrorText =
    'We encountered an error while trying to archive the driver record. Please try again. If the error persists, contact support.';

export const customersFetchDataErrorText =
    'We encountered an error while trying to fetch customer data. If the error persists, contact support.';

export const organizationsFetchDataErrorText =
    'We encountered an error while trying to fetch available organizations. Please refresh the page.';

export const managersFetchDataErrorText =
    'We encountered an error while trying to fetch available managers. Please refresh the page.';

export const customerDataFetchErrorText =
    'We encountered an error while trying to fetch customer data. Please refresh the page. If the error persists, contact support.';

export const customersSaveDataSuccessText = 'New customer record saved successfully.';

export const customersSaveDataErrorText =
    'We encountered an error while trying to save new customer record. Please try again. If the error persists, contact support.';

export const customersUpdateDataSuccessText = 'Customer record updated successfully.';

export const customersUpdateDataErrorText =
    'We encountered an error while trying to update the customer record. Please try again. If the error persists, contact support.';

export const customersArchiveDataSuccessText =
    'Customer record archived successfully. This page will reload automatically.';

export const customersArchiveDataErrorText =
    'We encountered an error while trying to archive the customer record. Please try again. If the error persists, contact support.';

export const newTdmFailErrorText =
    'We encountered an error while trying to create a new TDM, please try again. If the issue persists, please contact support.';

export const tdmUpdateFailErrorText =
    'We encountered an error while trying to update the TDM, please try again. If the issue persists, please contact support.';

export const tdmsArchiveDataSuccessText =
    'TDM record archived successfully. This page will reload automatically.';

export const tdmsArchiveDataErrorText =
    'We encountered an error while trying to archive the TDM record. Please try again. If the error persists, contact support.';

/* *************** RATE PROFILES *************** */
export const driverRateFetchDataErrorText =
    'We encountered an error while trying to fetch employee rates. Please refresh the page.';

export const newDriverRateAddedSuccessText = 'New driver rate has been added successfully';

export const newDriverRateAddedErrorText =
    'We encountered an error while trying to add the new driver rate. Please try again. If the error persists, contact support.';

export const driverRateUpdateSuccessText = 'Driver rate has been updated successfully';

export const driverRateUpdateErrorText =
    'We encountered an error while trying to update the driver rate. Please try again. If the error persists, contact support.';

export const driverRateArchivedSuccessText = 'Driver rate has been removed successfully';

export const driverRateArchivedErrorText =
    'We encountered an error while trying to remove the driver rate. Please try again. If the error persists, contact support.';

export const newRateTypeAddedSuccessText = 'New rate type has been added successfully';

export const newRateTypeAddedErrorText =
    'We encountered an error while trying to add the new rate type. Please try again. If the error persists, contact support.';

export const customerRateFetchDataErrorText =
    'We encountered an error while trying to fetch customer rates. Please refresh the page.';

export const newCustomerRateAddedSuccessText = 'New customer rate has been added successfully';

export const newCustomerRateAddedErrorText =
    'We encountered an error while trying to add the new customer rate. Please try again. If the error persists, contact support.';

export const customerRateUpdateSuccessText = 'Customer rate has been updated successfully';

export const customerRateUpdateErrorText =
    'We encountered an error while trying to update the customer rate. Please try again. If the error persists, contact support.';

export const customerRateArchivedSuccessText = 'Customer rate has been removed successfully';

export const customerRateArchivedErrorText =
    'We encountered an error while trying to remove the customer rate. Please try again. If the error persists, contact support.';

/* *************** USER MANAGEMENT *************** */
export const userDataFetchErrorText =
    'We encountered an error while trying to fetch user data. Please refresh the page. If the error persists, contact support.';

export const newUserInviteSendSuccess =
    'An invite has been sent to the email provided, which will allow the new user to finish the sign up process before the time expires.';

export const newUserInviteSendError =
    'We encountered an error while trying to send the invite to the email provided. Please try again. If the error persists, contact support.';

export const newUserInviteDetailsFetchErrorText =
    'We encountered an error while trying to get your details from the server. Please refresh the page. If the error persists, contact support.';

export const newUserSignUpSuccessText =
    'You have successfully created an account. Please use your email and password to log in.';

export const newUserSignUpErrorText =
    'We encountered an error while trying to create your account. Please try again. If the error persists, contact support.';

export const userEditSuccessText = 'You have successfully updated the user account.';

export const userEditErrorText =
    'We encountered an error while trying to update the user account. Please try again. If the error persists, contact support.';

export const userArchiveSuccessText = 'You have successfully removed the user from the system.';

export const userArchiveErrorText =
    'We encountered an error while trying to remove the user account. Please try again. If the error persists, contact support.';

export const auditDataFetchErrorText =
    'We encountered an error while trying to fetch audit data. Please refresh the page. If the error persists, contact support.';

/* *************** INVOICES *************** */
export const invoiceDataFetchErrorText =
    'We encountered an error while trying to fetch invoice data. Please refresh the page. If the error persists, contact support.';

export const generateCustomerInvoiceErrorText =
    'We encountered an error while trying to download the customer invoice. Please refresh the page. If the error persists, contact support.';

export const generateCustomerInvoiceBadRangeErrorText =
    "We couldn't find any invoices or invoices with chargable services for this customer in the selected date range.";

export const invoiceApprovedSuccessText = 'Invoice has been approved successfully';

export const invoiceOnHoldSuccessText = 'Invoice has been put on hold successfully';

export const invoiceApprovedErrorText =
    'We encountered an error while trying to approve the invoice. Please try again. If the error persists, contact support.';

export const invoiceOnHoldErrorText =
    'We encountered an error while trying to put this invoice on hold. Please try again. If the error persists, contact support.';

export const invoiceRecalculateSuccessText = 'Invoice values have been re-calculated successfully.';

export const invoiceRecalculateErrorText =
    'We encountered an error while trying to re-calculate invoice values. Please try again. If the error persists, contact support.';

export const invoiceSendSuccessText =
    'Invoice sent to customer successfully. Please refresh the page to check email status.';

export const invoiceSendErrorText =
    'We encountered an error while trying to send invoice to the customer. Please try again. If the error persists, contact support.';

export const invoiceServicesModifedInfoText =
    'Service(s) on this invoice have been modifed and the invoice requires re-calculating.';

export const invoiceFailedToSendInfoText =
    'The system encountered an issue when trying to send this invoice to the customer. Please try to send it again using the actions menu.';

/* *************** SETTINGS *************** */
export const changeEmailSuccessText =
    'Your request to change the email associated with your account has been received. Follow the instructions sent to the current email you use to log in, to complete the email change process.';

export const changeEmailErrorText =
    'We encountered an error while trying to submit your email change request. Please try again. If the error persists, contact support.';

export const changeEmailConfirmSuccessText =
    'Your email has been changed successfully. Please use it to log in.';

export const changeEmailConfirmErrorText =
    'We encountered an error while trying to confirm your email change. Please try again. If the error persists, contact support.';

export const passwordResetModalText =
    'Please enter the email associated with your account. A password reset link will be sent there.';

export const passwordResetErrorText =
    "We couldn't send the password reset link. Ensure the email is associated with an active account and try again.";

export const passwordResetTokenFetchErrorText =
    "We couldn't verify the details of your password reset request. Please refresh the page. If the issue persist, please contact support.";

export const passwordResetExpiredErrorText =
    'Looks like your password reset request has expired. Please submit another through the login page.';

export const passwordResetFailErrorText =
    'We encountered an error while trying to change your password, please try again. If the issue persists, please contact support.';

export const passwordChangeInvalidCurrentPasswordText =
    "Looks like the current password you provided doesn't match the one set on the account, please try again.";

export const passwordChangeSuccessText =
    'Your new password has been set for your account, use it next time you log in.';

export const reportEmailConfigHelperText = {
    delivery_charge_report:
        'Users on this mailing list will receive a weekly delivery charge report',
    customer_invoice:
        "Users on this mailing list will receive a copy of a customer invoice when it's emailed out",
};

export const notificationSettingsHelperText =
    'Users listed below will receive desktop and/or email notifications about configured events';

export const notificationSettingsFetchErrorText =
    'We encountered an error while trying to fetch notification settings. Please refresh the page. If the issue persist, please contact support.';

export const notificationSettingsSaveErrorText =
    'We encountered an error while trying to save new notification setting. Please try again. If the issue persist, please contact support.';

export const notificationSettingsUpdateErrorText =
    'We encountered an error while trying to update notification setting. Please try again. If the issue persist, please contact support.';

export const notificationSettingsDeleteErrorText =
    'We encountered an error while trying to delete the notification setting. Please try again. If the issue persist, please contact support.';

/* *************** PAYROLL *************** */
export const payrollDataFetchErrorText =
    'We encountered an error while trying to fetch payroll data. Please refresh the page. If the error persists, contact support.';

export const payrollCreateHelperText =
    "Select payroll dates and click OK. Once the payroll has been created, you'll be able to add trucks and review the payroll details.";

export const payrollTrucksHelperText =
    'Select or un-select trucks you wish to be part of this payroll. Click OK to save changes.';

export const payrollCreateErrorText =
    'We encountered an error while trying to create new payroll. Please try again. If the error persists, contact support.';

export const payrollCreateAlreadyExistsErrorText =
    'Payroll for selected date range (or part of it) already exists. Please select new dates ensuring there is no payroll records between selected dates.';

export const payrollDeleteSuccessText = 'Payroll has been deleted successfully.';

export const payrollDeleteErrorText =
    'We encountered an error while trying to delete the payroll. Please try again. If the error persists, contact support.';

export const payrollDeleteTruckExistsErrorText =
    'This payroll cannot be deleted as there are already trucks assigned to it. Remove trucks from this payroll before removing the payroll.';

export const payrollEligibleTrucksDontExistErrorText =
    'There are no trucks with dates in range of this payroll. Add truck records first before adding trucks to this payroll.';

export const payrollTrucksAdjustErrorText =
    'We encountered an error while trying to add/remove payroll trucks. If adding new trucks, ensure they do not require special pay data and try again. If the error persists, contact support.';

export const payrollTrucksAdjustEmptyErrorText =
    'You cannot remove all trucks from a payroll which has already been generated. Please keep at least one truck on this payroll.';

export const payrollPartialTrucksAdjustErrorText =
    'Some trucks failed to be added or removed from this payroll, see details below. Please try this operation again, and if the error persist, contact support.';

export const payrollGenerateConfirmText =
    'You are about to generate payroll for selected date range. This will soft lock all the trucks included in this payroll and generate a payroll report. Are you sure you want to proceed?';

export const payrollGenerateDefaultErrorText =
    'We encountered an error while trying to generate this payroll. Please try again. If the error persists, contact support.';

export const payrollGenerateWrongTruckStatusErrorText =
    'Cannot generate payroll. Ensure none of the selected trucks are awaitng special pay details.';

export const payrollRegenerateUnlockedTruckHelperText =
    'There is one or more unlocked trucks on this payroll. Re-lock the truck(s) to re-generate the payroll.';

export const payrollRegenerateHelperText =
    'Looks like this payroll has had some data changes, click the GENERATE PAYROLL button to update the numbers.';

export const payrollRelockErrorText =
    'One or more trucks failed to be re-locked. Please try again. If the error persists, contact support.';

export const payrollAlreadyGeneratedAdjustTrucksHelperText =
    'This payroll has already been generated, adding or removing trucks from it will cause the system to automatically re-generate the payroll and update the totals. Do you want to proceed?';

export const payrollFinalizeHelperText =
    'You are about to finalize this payroll. Once finalized, the data or totals can no longer be modified, and all trucks which are part of this payroll will be locked forever. Each employee on this payroll will be emailed a copy of their payroll report automatically.';

export const payrollFinalizeErrorText =
    'We encountered an error while trying to finalize this payroll. Please try again. If the error persists, contact support.';

export const addSupplementalPayDataErrorText =
    'We encountered an error while trying to save supplemental pay data. Please try again. If the error persists, contact support.';

export const noInvoiceServicesInfoText =
    'There are no trucks where this driver is listed as the primary driver. Their payroll was calculated as a result of the driver being assigned as a helper on the trucks listed below.';

export const invalidDriverEmailErrorText =
    'Missing or invalid email address. This driver will not be able to receive a copy of their payroll report unless their email is updated.';

export const sendDriverReportErrorText =
    "We encountered an error while trying to send driver's payroll report. Please try again. If the error persists, contact support.";

export const generateSpecialPayrollErrorText =
    'We encountered an error while trying to generate special payroll. Please try again. If the error persists, contact support.';

export const generatePayrollDownloadErrorText =
    'We encountered an error while trying to download the employee payroll. Please try again. If the error persists, contact support.';

/* *************** SYSTEM SETTINGS *************** */
export const settingsFetchErrorText =
    'We encountered an error while trying to fetch system settings. Please refresh the page. If the error persists, contact support.';

export const settingsUpdateErrorText =
    'We encountered an error while trying to update the system setting. Please try again. If the error persists, contact support.';

export const overnightSettingMissingText =
    'It looks like the daily rate for supplemental pay is not set in the system. Please get an administrator of the system to set it in the settings section. Once set, you will be able to add supplemental pay data to the truck.';
