import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    FileTextOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';
import {
    MdOutlineOpenInNew,
    MdError,
    MdOutlineAttachMoney,
    MdPreview,
    MdOutlineLockOpen,
    MdAddCircleOutline,
    MdOutlineEmail,
    MdAlarm,
    MdWarning,
} from 'react-icons/md';
import { BiRefresh } from 'react-icons/bi';
import { FiDownload } from 'react-icons/fi';
import validator from 'validator';

import CustomersApi from '../api/dataManagement/customers';
import DriversApi from '../api/dataManagement/drivers';
import TdmsApi from '../api/dataManagement/tdms';
import UsersApi from '../api/userManagement/users';
import {
    displaySimpleErrorModal,
    displaySimpleSuccessModal,
    getUserConfirmation,
} from '../component/global/ModalController';
import { APP_ACTION_TYPES, TRIGGER_FETCH_TYPES } from '../store/constants/appActionTypes';
import { onAPISuccess, onAPIError } from './apiUtil';
import { beautifyTextValue, convertToTwoDecimals, formatCurrency } from './formUtil';
import {
    customersArchiveDataErrorText,
    customersArchiveDataSuccessText,
    driversArchiveDataErrorText,
    driversArchiveDataSuccessText,
    invalidDriverEmailErrorText,
    invoiceFailedToSendInfoText,
    invoiceRecalculateErrorText,
    invoiceRecalculateSuccessText,
    invoiceSendErrorText,
    invoiceSendSuccessText,
    invoiceServicesModifedInfoText,
    payrollDeleteErrorText,
    payrollDeleteSuccessText,
    payrollDeleteTruckExistsErrorText,
    removeReportEmailConfigConfirmText,
    removeReportEmailConfigErrorText,
    removeReportEmailConfigSuccessText,
    sendDriverReportErrorText,
    tdmsArchiveDataErrorText,
    tdmsArchiveDataSuccessText,
    userArchiveErrorText,
    userArchiveSuccessText,
} from './longText';
import { shouldSee } from './permissionsUtil';
import ReportsApi from '../api/reports/reports';
import PayrollApi from '../api/payroll/payroll';
import { TODO_ACTION_TYPES } from '../store/constants/todoActionTypes';

/* ********** TABLE COLUMNS ********* */
export const DRIVER_COLUMNS = [
    {
        title: 'ID',
        dataIndex: 'driverId',
        key: 'driverId',
        align: 'center',
        render: (text, record) => {
            // If record is missing details, display warning icon
            if (record.contactInfo.city.length) {
                return text;
            }
            return (
                <Tooltip title="Missing Details">
                    <MdError
                        style={{
                            fontSize: 20,
                            cursor: 'pointer',
                            color: '#ee2824',
                        }}
                    />
                </Tooltip>
            );
        },
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        sorter: (a, b) => a.contactInfo.lastName.localeCompare(b.contactInfo.lastName),
        render: (_text, record) => {
            if (record.contactInfo.firstName && record.contactInfo.lastName && record.nickname) {
                return (
                    <div>
                        <Tooltip title={driversDetailsTooltip(record)}>
                            {`${record.contactInfo.firstName} ${record.contactInfo.lastName} (${record.nickname})`}
                        </Tooltip>
                    </div>
                );
            }
            return <div>UNAVAILABLE</div>;
        },
    },
    {
        title: 'Work Phone',
        dataIndex: 'workPhoneNumber',
        key: 'workPhoneNumber',
        align: 'center',
        render: (text) => {
            return <div>{formatPhoneNumber(text)}</div>;
        },
    },
    {
        title: 'Rate Profile',
        dataIndex: 'rateProfile',
        key: 'rateProfile',
        align: 'center',
        sorter: (a, b) => a.rateProfile.profileName.localeCompare(b.rateProfile.profileName),
        render: (_text, record) => record.rateProfile.profileName,
    },
    {
        title: 'Active',
        dataIndex: 'active',
        key: 'active',
        align: 'center',
        render: (val) => {
            if (val) {
                return (
                    <CheckCircleOutlined
                        style={{
                            fontSize: 20,
                            color: '#2dcf00',
                        }}
                    />
                );
            }
            return (
                <CloseCircleOutlined
                    style={{
                        fontSize: 20,
                        color: '#cf0000',
                    }}
                />
            );
        },
    },
    {
        title: 'Actions',
        key: 'actions',
        align: 'center',
        render: (_val, record) => <DriversActions record={record} />,
    },
];

export const CUSTOMER_COLUMNS = [
    {
        title: 'ID',
        dataIndex: 'customerId',
        key: 'customerId',
        align: 'center',
        render: (text, record) => {
            // If record is missing details, display warning icon
            if (record.contactInfo.city.length) {
                return text;
            }
            return (
                <Tooltip title="Missing Details">
                    <MdError
                        style={{
                            fontSize: 20,
                            cursor: 'pointer',
                            color: '#ee2824',
                        }}
                    />
                </Tooltip>
            );
        },
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        sorter: (a, b) => a.contactInfo.lastName.localeCompare(b.contactInfo.lastName),
        render: (_text, record) => {
            if (record.contactInfo.firstName && record.contactInfo.lastName) {
                return (
                    <div>
                        <Tooltip title={customerDetailsTooltip(record)}>
                            {`${record.contactInfo.firstName} ${record.contactInfo.lastName}`}
                        </Tooltip>
                    </div>
                );
            }
            return <div>UNAVAILABLE</div>;
        },
    },
    {
        title: 'Organization',
        dataIndex: 'orgName',
        key: 'orgName',
        align: 'center',
        sorter: (a, b) => a.orgName.localeCompare(b.orgName),
    },
    {
        title: 'Phone',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        align: 'center',
        render: (_text, record) => {
            return <div>{formatPhoneNumber(record.contactInfo.phoneNumber)}</div>;
        },
    },
    {
        title: 'Rate Profile',
        dataIndex: 'rateProfile',
        key: 'rateProfile',
        align: 'center',
        sorter: (a, b) => a.rateProfile.profileName.localeCompare(b.rateProfile.profileName),
        render: (_text, record) => record.rateProfile.profileName,
    },
    {
        title: 'Active',
        dataIndex: 'active',
        key: 'active',
        align: 'center',
        render: (val) => {
            if (val) {
                return (
                    <CheckCircleOutlined
                        style={{
                            fontSize: 20,
                            color: '#2dcf00',
                        }}
                    />
                );
            }
            return (
                <CloseCircleOutlined
                    style={{
                        fontSize: 20,
                        color: '#cf0000',
                    }}
                />
            );
        },
    },
    {
        title: 'Actions',
        key: 'actions',
        align: 'center',
        render: (_val, record) => <CustomersActions record={record} />,
    },
];

export const TDM_COLUMNS = [
    {
        title: 'ID',
        dataIndex: 'tdmId',
        key: 'tdmId',
        align: 'center',
    },
    {
        title: 'Route Name',
        dataIndex: 'routeName',
        key: 'routeName',
        align: 'center',
        sorter: (a, b) => a.routeName.localeCompare(b.routeName),
    },
    {
        title: 'Special Pay Eligible',
        dataIndex: 'specialPayEligible',
        key: 'specialPayEligible',
        align: 'center',
        render: (val) => {
            if (val) {
                return (
                    <CheckCircleOutlined
                        style={{
                            fontSize: 20,
                            color: '#2dcf00',
                        }}
                    />
                );
            }
            return (
                <CloseCircleOutlined
                    style={{
                        fontSize: 20,
                        color: '#cf0000',
                    }}
                />
            );
        },
    },
    {
        title: 'Active',
        dataIndex: 'active',
        key: 'active',
        align: 'center',
        render: (val) => {
            if (val) {
                return (
                    <CheckCircleOutlined
                        style={{
                            fontSize: 20,
                            color: '#2dcf00',
                        }}
                    />
                );
            }
            return (
                <CloseCircleOutlined
                    style={{
                        fontSize: 20,
                        color: '#cf0000',
                    }}
                />
            );
        },
    },
    {
        title: 'Actions',
        key: 'actions',
        align: 'center',
        render: (_val, record) => <TdmsActions record={record} />,
    },
];

export const TRUCK_COLUMNS = [
    {
        title: 'TDM',
        align: 'center',
        render: (_text, record) => record.tdm.routeName,
    },
    {
        title: 'Load Type',
        align: 'center',
        sorter: (a, b) => a.loadType.localeCompare(b.loadType),
        render: (_text, record) => beautifyTextValue(record.loadType),
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        align: 'center',
        sorter: (a, b) => a.date.localeCompare(b.date),
    },
    {
        title: 'Driver',
        align: 'center',
        render: (_text, record) => {
            const { firstName, lastName } = record.driver.contactInfo;
            const { nickname } = record.driver;

            return <div>{`${firstName} ${lastName} (${nickname})`}</div>;
        },
    },
    {
        title: 'Helpers',
        align: 'center',
        render: (_text, record) =>
            record.helpers.map(
                ({ helper }) => `${helper.contactInfo.firstName} ${helper.contactInfo.lastName}, `
            ),
    },
    {
        title: 'Status',
        align: 'center',
        render: (_text, record) => beautifyTextValue(record.status),
    },
];

export const RATE_TYPES_COLUMNS = [
    {
        title: 'Label',
        dataIndex: 'label',
        key: 'label',
        align: 'center',
        width: '65%',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.label.localeCompare(b.label),
    },
    {
        title: 'Default Rate',
        dataIndex: 'defaultRate',
        key: 'defaultRate',
        align: 'center',
        width: '35%',
    },
];

export const USER_COLUMNS = [
    {
        title: 'ID',
        dataIndex: 'userId',
        key: 'userId',
        align: 'center',
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        render: (_text, record) => {
            if (record.firstName && record.lastName) {
                return (
                    <div>
                        <Tooltip title={usersDetailsTooltip(record)}>
                            {`${record.firstName} ${record.lastName}`}
                        </Tooltip>
                    </div>
                );
            }
            return <div>UNAVAILABLE</div>;
        },
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        align: 'center',
        sorter: (a, b) => a.email.localeCompare(b.email),
        render: (_text, record) => record.email,
    },
    {
        title: 'Active',
        dataIndex: 'active',
        key: 'active',
        align: 'center',
        render: (val) => {
            if (val) {
                return (
                    <CheckCircleOutlined
                        style={{
                            fontSize: 20,
                            color: '#2dcf00',
                        }}
                    />
                );
            }
            return (
                <CloseCircleOutlined
                    style={{
                        fontSize: 20,
                        color: '#cf0000',
                    }}
                />
            );
        },
    },
    {
        title: 'Actions',
        key: 'actions',
        align: 'center',
        render: (_val, record) => <UserActions record={record} />,
    },
];

export const AUDIT_COLUMNS = [
    {
        title: 'Date',
        align: 'center',
        width: '20%',
        render: (_text, record) => moment(record.auditTs).format('YYYY-MM-DD HH:mm'),
        sorter: (a, b) => +moment(a.auditTs).isAfter(moment(b.auditTs)),
    },
    {
        title: 'Category',
        align: 'center',
        width: '20%',
        render: (_text, record) => beautifyTextValue(record.category),
    },
    {
        title: 'Sub Category',
        align: 'center',
        width: '20%',
        render: (_text, record) => beautifyTextValue(record.subCategory),
    },
    {
        title: 'Action',
        align: 'center',
        width: '20%',
        render: (_text, record) => (
            <div
                style={{
                    color:
                        record.action === 'deleted'
                            ? '#ee2824'
                            : record.action === 'created'
                            ? '#44c423'
                            : '#eecc24',
                }}
            >
                {beautifyTextValue(record.action)}
            </div>
        ),
    },
    {
        title: 'By',
        align: 'center',
        width: '20%',
        render: (_text, record) => `${record.user.firstName} ${record.user.lastName}`,
    },
];

export const TRUCK_INVOICE_COLUMNS = [
    {
        title: 'ID',
        dataIndex: 'invoiceId',
        key: 'invoiceId',
        align: 'center',
    },
    {
        title: 'Invoice Reference',
        dataIndex: 'invoiceReference',
        key: 'invoiceReference',
        align: 'center',
        sorter: (a, b) => a.invoiceReference.localeCompare(b.invoiceReference),
        render: (_text, record) => record.invoiceReference,
    },
    {
        title: 'Invoice Date',
        key: 'createdTs',
        align: 'center',
        sorter: (a, b) =>
            new Date(a.truck.date)
                .toDateString()
                .localeCompare(new Date(b.truck.date).toDateString()),
        render: (_text, record) => record.truck.date,
    },
    {
        title: 'Billed To',
        dataIndex: 'customerName',
        key: 'customerName',
        align: 'center',
        sorter: (a, b) => a.customerName.localeCompare(b.customerName),
        render: (_text, record) => {
            return (
                <div>
                    <Tooltip title={invoiceDetailsTooltip(record)}>{record.customerName}</Tooltip>
                </div>
            );
        },
    },

    {
        title: 'Revenue',
        dataIndex: 'revenueCollected',
        key: 'revenueCollected',
        align: 'center',
        sorter: (a, b) => a.revenueCollected.localeCompare(b.revenueCollected),
        render: (_text, record) =>
            `$${(Math.round(record.revenueCollected * 100) / 100).toFixed(2)}`,
    },
    {
        title: 'Actions',
        key: 'actions',
        align: 'center',
        render: (_val, record) => <TruckInvoiceActions record={record} />,
    },
];

export const CUSTOMER_INVOICE_COLUMNS = [
    {
        title: 'ID',
        dataIndex: 'customerInvoiceId',
        key: 'customerInvoiceId',
        align: 'center',
        render: (_text, record) => {
            return record.requiresUpdate || record.status === 'failed_to_send' ? (
                <div>
                    <Tooltip
                        title={
                            record.requiresUpdate
                                ? invoiceServicesModifedInfoText
                                : invoiceFailedToSendInfoText
                        }
                    >
                        <MdError
                            style={{
                                fontSize: 20,
                                cursor: 'pointer',
                                color: '#ee2824',
                            }}
                        />
                    </Tooltip>
                </div>
            ) : (
                <div>{record.customerInvoiceId}</div>
            );
        },
    },
    {
        title: 'Customer',
        align: 'center',
        sorter: (a, b) => a.billingAddress.lastName.localeCompare(b.billingAddress.lastName),
        render: (_text, record) => {
            return (
                <div>
                    <Tooltip title={customerInvoiceDetailsTooltip(record)}>
                        {`${record.billingAddress.firstName} ${record.billingAddress.lastName} | ${record.customer.orgName}`}
                    </Tooltip>
                </div>
            );
        },
    },
    {
        title: 'Due Date',
        dataIndex: 'dueDate',
        key: 'dueDate',
        align: 'center',
    },
    {
        title: 'Subtotal',
        key: 'subtotal',
        align: 'center',
        render: (_text, record) => `$${(Math.round(record.subtotal * 100) / 100).toFixed(2)}`,
    },
    {
        title: 'FSC',
        key: 'fuelRateServiceCharge',
        align: 'center',
        render: (_text, record) =>
            `$${(Math.round(record.fuelRateServiceCharge * 100) / 100).toFixed(2)}`,
    },
    {
        title: 'Balance Due',
        key: 'balanceDue',
        align: 'center',
        render: (_text, record) => (
            <div>
                <Tooltip title="Sum of subtotal, FSC and 5% GST">
                    {`$${(Math.round(record.balanceDue * 100) / 100).toFixed(2)}`}
                </Tooltip>
            </div>
        ),
    },
    {
        title: 'Invoice Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        sorter: (a, b) => a.status.localeCompare(b.status),
        render: (_text, record) =>
            record.status === 'approved' ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {beautifyTextValue(record.status.toUpperCase())}
                    <Tooltip title="Invoice scheduled to be emailed to customer at 5:00am">
                        <MdAlarm
                            style={{
                                fontSize: 20,
                                cursor: 'help',
                                marginLeft: 15,
                                color: '#ffa630',
                            }}
                        />
                    </Tooltip>
                </div>
            ) : record.status === 'on_hold' ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {beautifyTextValue(record.status.toUpperCase())}
                    <Tooltip title="Invoice on hold">
                        <MdError
                            style={{
                                fontSize: 20,
                                cursor: 'help',
                                marginLeft: 15,
                                color: '#d93c20',
                            }}
                        />
                    </Tooltip>
                </div>
            ) : (
                <div>{beautifyTextValue(record.status.toUpperCase())}</div>
            ),
    },
    {
        title: 'Actions',
        key: 'actions',
        align: 'center',
        width: '100px',
        render: (_val, record) => <CustomerInvoiceActions record={record} />,
    },
];

export const INCOMPLETE_ENTITIES_COLUMNS = [
    {
        title: 'Entity',
        render: (_text, record) => {
            if (record.hasOwnProperty('driverId')) {
                return <div>{`DRIVER (ID: ${record.driverId})`}</div>;
            } else if (record.hasOwnProperty('customerId')) {
                return <div>{`CUSTOMER (ID: ${record.customerId})`}</div>;
            }
            return <div>UNAVAILABLE</div>;
        },
        align: 'center',
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        width: '500px',
        sorter: (a, b) => a.contactInfo.lastName.localeCompare(b.contactInfo.lastName),
        render: (_text, record) => {
            if (record.contactInfo.firstName && record.contactInfo.lastName) {
                return (
                    <div>{`${record.contactInfo.firstName} ${record.contactInfo.lastName}`}</div>
                );
            }
            return <div>UNAVAILABLE</div>;
        },
    },
    {
        title: 'Actions',
        key: 'actions',
        align: 'center',
        render: (_val, record) => <IncompleteEntityActions record={record} />,
    },
];

export const INVOICES_FOR_APPROVAL_COLUMNS = [
    {
        title: 'Customer',
        align: 'center',
        render: (_text, record) => {
            return (
                <div>
                    <Tooltip title={customerInvoiceDetailsTooltip(record)}>
                        {`${record.billingAddress.firstName} ${record.billingAddress.lastName} | ${record.customer.orgName}`}
                    </Tooltip>
                </div>
            );
        },
    },
    {
        title: 'Balance Due',
        key: 'balanceDue',
        align: 'center',
        render: (_text, record) => (
            <div>
                <Tooltip title="Sum of subtotal, FSC and 5% GST">
                    {`$${(Math.round(record.balanceDue * 100) / 100).toFixed(2)}`}
                </Tooltip>
            </div>
        ),
    },
    {
        title: 'Actions',
        key: 'actions',
        align: 'center',
        render: (_val, record) => <InvoicesForApprovalActions record={record} />,
    },
];

export const TRUCKS_NEEDING_SUP_PAY_COLUMNS = [
    {
        title: 'Truck Date',
        dataIndex: 'date',
        key: 'date',
        align: 'center',
    },
    {
        title: 'TDM',
        align: 'center',
        render: (_text, record) => <div>{record.tdm.routeName}</div>,
    },
    {
        title: 'Driver',
        align: 'center',
        render: (_text, record) => (
            <div>
                {`${record.driver.contactInfo.firstName} ${record.driver.contactInfo.lastName}`}
            </div>
        ),
    },
    {
        title: 'Actions',
        key: 'actions',
        align: 'center',
        render: (_val, record) => <SupplementalPayActions record={record} />,
    },
];

export const FAILED_TO_SEND_INVOICES_COLUMNS = [
    {
        title: 'Customer',
        align: 'center',
        render: (_text, record) => {
            return (
                <div>
                    <Tooltip title={customerInvoiceDetailsTooltip(record)}>
                        {`${record.billingAddress.firstName} ${record.billingAddress.lastName} | ${record.customer.orgName}`}
                    </Tooltip>
                </div>
            );
        },
    },
    {
        title: 'Created On',
        key: 'createdTs',
        align: 'center',
        render: (_text, record) => <div>{`${moment(record.createdTs).format('yyyy-MM-DD')}`}</div>,
    },
    {
        title: 'Actions',
        key: 'actions',
        align: 'center',
        render: () => <div>TO DO</div>,
    },
];

export const SALES_REPORT_COLUMNS = [
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        align: 'center',
    },
    {
        title: 'Invoice',
        dataIndex: 'invoice',
        key: 'invoice',
        align: 'center',
        sorter: (a, b) => a.invoice.invoiceReference.localeCompare(b.invoice.invoiceReference),
        render: (_text, record) => {
            return (
                <Tooltip title={invoiceDetailsTooltip(record.invoice)}>
                    {record.invoice.invoiceReference}
                </Tooltip>
            );
        },
    },
    {
        title: 'Bill To',
        dataIndex: 'billTo',
        key: 'billTo',
        align: 'center',
        sorter: (a, b) => a.billTo.orgName.localeCompare(b.billTo.orgName),
        render: (_text, record) => {
            return (
                <Tooltip title={billToDetailsTooltip(record.billTo)}>
                    {record.billTo.orgName}
                </Tooltip>
            );
        },
    },
    {
        title: 'Delivery Type',
        dataIndex: 'deliveryType',
        key: 'deliveryType',
        align: 'center',
    },
    {
        title: 'Delivery Charge',
        dataIndex: 'deliveryCharge',
        key: 'deliveryCharge',
        align: 'center',
        render: (_text, record) => {
            return <div>{`${formatCurrency(convertToTwoDecimals(record.deliveryCharge))}`}</div>;
        },
    },
];

export const REPORT_MAIL_LIST_COLUMNS = [
    {
        title: 'User Name',
        align: 'center',
        render: (_text, record) => {
            return <div>{`${record.firstName} ${record.lastName}`}</div>;
        },
    },
    {
        title: 'User Email',
        dataIndex: 'email',
        key: 'email',
        align: 'center',
    },
    {
        title: 'Actions',
        key: 'actions',
        align: 'center',
        render: (_val, record) => <MailListReportActions record={record} />,
    },
];

export const DELIVERY_CHARGE_REPORT_COLUMNS = [
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        align: 'center',
        sorter: (a, b) => a.date.localeCompare(b.date),
    },
    {
        title: 'Invoice Reference',
        dataIndex: 'invoiceReference',
        key: 'invoiceReference',
        align: 'center',
    },
    {
        title: 'Customer Name',
        dataIndex: 'customerName',
        key: 'customerName',
        align: 'center',
        sorter: (a, b) => a.customerName.localeCompare(b.customerName),
    },
    {
        title: 'Delivery Type',
        dataIndex: 'deliveryType',
        key: 'deliveryType',
        align: 'center',
        sorter: (a, b) => a.deliveryType.localeCompare(b.deliveryType),
    },
    {
        title: 'Revenue Collected',
        dataIndex: 'revenueCollected',
        key: 'revenueCollected',
        align: 'center',
        sorter: (a, b) => a.revenueCollected - b.revenueCollected,
        render: (_text, record) => {
            return <div>{`$${(Math.round(record.revenueCollected * 100) / 100).toFixed(2)}`}</div>;
        },
    },
    {
        title: 'Charge',
        dataIndex: 'charge',
        key: 'charge',
        align: 'center',
        sorter: (a, b) => a.charge - b.charge,
        render: (_text, record) => {
            return <div>{`$${(Math.round(record.charge * 100) / 100).toFixed(2)}`}</div>;
        },
    },
    {
        title: 'Difference',
        dataIndex: 'difference',
        key: 'difference',
        align: 'center',
        sorter: (a, b) => a.difference - b.difference,
        render: (_text, record) => {
            return <div>{`$${(Math.round(record.difference * 100) / 100).toFixed(2)}`}</div>;
        },
    },
];

export const PAYROLL_LIST_COLUMNS = [
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        sorter: (a, b) => a.status.localeCompare(b.status),
        render: (_text, record) => {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {beautifyTextValue(record.status)}
                    {record.isSpecialPayroll ? (
                        <Tooltip
                            title={`Special payroll generated for ${record.employeePayrolls[0].driver.email}`}
                        >
                            <MdWarning
                                style={{
                                    fontSize: 18,
                                    cursor: 'help',
                                    marginLeft: 10,
                                    color: '#ffa630',
                                }}
                            />
                        </Tooltip>
                    ) : null}
                </div>
            );
        },
    },
    {
        title: 'Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
        align: 'center',
        sorter: (a, b) => a.startDate.localeCompare(b.startDate),
    },
    {
        title: 'End Date',
        dataIndex: 'endDate',
        key: 'endDate',
        align: 'center',
        sorter: (a, b) => a.endDate.localeCompare(b.endDate),
    },
    {
        title: 'Total Gross Pay Due',
        dataIndex: 'sumOfGrossPayDue',
        key: 'sumOfGrossPayDue',
        align: 'center',
        render: (_text, record) => {
            return <div>{`$${(Math.round(record.sumOfGrossPayDue * 100) / 100).toFixed(2)}`}</div>;
        },
    },
    {
        title: 'Sum Of Speical Pay',
        dataIndex: 'sumOfSpecialPay',
        key: 'sumOfSpecialPay',
        align: 'center',
        render: (_text, record) => {
            return <div>{`$${(Math.round(record.sumOfSpecialPay * 100) / 100).toFixed(2)}`}</div>;
        },
    },
    {
        title: 'Actions',
        key: 'actions',
        align: 'center',
        render: (_text, record) => <PayrollListActions record={record} />,
    },
];

export const PAYROLL_TRUCKS_COLUMNS = [
    {
        title: 'Truck Date',
        align: 'center',
        sorter: (a, b) => a.date.localeCompare(b.date),
        render: (_text, record) => {
            return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {record.status === 'unlocked' ? (
                        <Tooltip title="Truck has been unlocked to amend the data">
                            <MdOutlineLockOpen
                                style={{
                                    fontSize: 20,
                                    cursor: 'pointer',
                                    color: 'red',
                                    marginRight: 10,
                                }}
                            />
                        </Tooltip>
                    ) : null}
                    <span>{record.date}</span>
                </div>
            );
        },
    },
    {
        title: 'TDM',
        align: 'center',
        sorter: (a, b) => a.tdm.routeName.localeCompare(b.tdm.routeName),
        render: (_text, record) => {
            return <div>{record.tdm.routeName}</div>;
        },
    },
    {
        title: 'Driver',
        align: 'center',
        render: (_text, record) => {
            return (
                <div>{`${record.driver.contactInfo.firstName} ${record.driver.contactInfo.lastName}`}</div>
            );
        },
    },
    {
        title: 'Actions',
        key: 'actions',
        align: 'center',
        width: '100px',
        render: (_text, record) => <PayrollTrucksActions record={record} />,
    },
];

export const PAYROLL_TRUCKS_NOT_GENERATED_COLUMNS = [
    {
        title: 'Awaiting Special Pay?',
        align: 'center',
        width: '200px',
        render: (_text, record) => {
            return record.status === 'special_pay_required' ||
                (record.status === 'unlocked' && record.tdm.specialPayEligible) ? (
                <PayrollTruckSpecialPayAction record={record} />
            ) : null;
        },
    },
    ...PAYROLL_TRUCKS_COLUMNS,
];

export const PAYROLL_EMPLOYEES_COLUMNS = (onPayrollDownload) => [
    {
        title: 'Employee Name',
        align: 'center',
        render: (_text, record) => {
            return (
                <div className="centered-container">
                    {!driverEmailValid(record) ? (
                        <Tooltip title={invalidDriverEmailErrorText}>
                            <MdError
                                style={{
                                    fontSize: 20,
                                    cursor: 'help',
                                    color: '#ee2824',
                                    marginRight: 10,
                                }}
                            />
                        </Tooltip>
                    ) : null}
                    {`${record.driver.contactInfo.firstName} ${record.driver.contactInfo.lastName}`}
                </div>
            );
        },
    },
    {
        title: 'Gross Pay Due',
        align: 'center',
        sorter: (a, b) => a.grossPayDue.localeCompare(b.grossPayDue),
        render: (_text, record) => {
            return <div>{`$${(Math.round(record.grossPayDue * 100) / 100).toFixed(2)}`}</div>;
        },
    },
    {
        title: 'Special Pay',
        align: 'center',
        sorter: (a, b) => a.specialPay.localeCompare(b.specialPay),
        render: (_text, record) => {
            return <div>{`$${(Math.round(record.specialPay * 100) / 100).toFixed(2)}`}</div>;
        },
    },
    {
        title: 'Actions',
        key: 'actions',
        align: 'center',
        width: '100px',
        render: (_text, record) => (
            <PayrollEmployeesActions record={record} onPayrollDownload={onPayrollDownload} />
        ),
    },
];

/* ********** VALUE FORMATTERS ********* */
const formatPhoneNumber = (phoneNumberString) => {
    if (!phoneNumberString) return 'N/A';

    const cleaned = phoneNumberString.toString().replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return 'N/A';
};

/* ********** TABLE COMPONENTS ********* */
const DriversActions = ({ record }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    return (
        <>
            {record.active ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {shouldSee(['DRP_READ']) ? (
                        <Tooltip title="View Rates">
                            <MdOutlineAttachMoney
                                style={{
                                    fontSize: 20,
                                    cursor: 'pointer',
                                    marginRight: 25,
                                }}
                                onClick={(e) => {
                                    cancelEventPropagation(e);
                                    dispatch({
                                        type: APP_ACTION_TYPES.SET_VIEWING_DRIVER_RATES,
                                        payload: {
                                            ...record,
                                        },
                                    });
                                }}
                            />
                        </Tooltip>
                    ) : null}
                    {shouldSee(['DRIVER_DELETE']) ? (
                        <Tooltip title="Delete">
                            <DeleteOutlined
                                style={{
                                    fontSize: 20,
                                    cursor: 'pointer',
                                    color: '#cf0000',
                                }}
                                onClick={(e) => {
                                    cancelEventPropagation(e);
                                    archiveDriver(dispatch, history, record.driverId);
                                }}
                            />
                        </Tooltip>
                    ) : null}
                </div>
            ) : null}
        </>
    );
};

const driversDetailsTooltip = (record) => (
    <div>
        <div>
            {`${record.contactInfo.firstName} ${record.contactInfo.lastName} (${
                record.nickname || ''
            })`}
        </div>
        <div>{`Work Phone: ${formatPhoneNumber(record.workPhoneNumber)}`}</div>
        <div>{`Phone: ${formatPhoneNumber(record.contactInfo.phoneNumber)}`}</div>
        <div>{`Email: ${record.email}`}</div>

        <div>{`${record.contactInfo.addressLineOne || ''}`}</div>
        <div>{`${record.contactInfo.addressLineTwo || ''}`}</div>
        <div>{`${record.contactInfo.city || ''} ${record.contactInfo.postalCode || ''}`}</div>
        <div>{`${record.contactInfo.province || ''} ${record.contactInfo.country || ''}`}</div>
    </div>
);

const CustomersActions = ({ record }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {shouldSee(['INVOICE_READ_MANY']) ? (
                <Tooltip title="View Invoices">
                    <FileTextOutlined
                        style={{
                            fontSize: 20,
                            cursor: 'pointer',
                        }}
                        onClick={(e) => {
                            cancelEventPropagation(e);
                            history.push(
                                `/invoices/search/${record.contactInfo.firstName}%20${record.contactInfo.lastName}`
                            );
                        }}
                    />
                </Tooltip>
            ) : null}
            {record.active ? (
                <div>
                    {shouldSee(['CRP_READ']) ? (
                        <Tooltip title="View Rates">
                            <MdOutlineAttachMoney
                                style={{
                                    fontSize: 20,
                                    cursor: 'pointer',
                                    margin: '0 20px',
                                }}
                                onClick={(e) => {
                                    cancelEventPropagation(e);
                                    dispatch({
                                        type: APP_ACTION_TYPES.SET_VIEWING_CUSTOMER_RATES,
                                        payload: {
                                            ...record,
                                        },
                                    });
                                }}
                            />
                        </Tooltip>
                    ) : null}
                    {shouldSee(['DRIVER_DELETE']) ? (
                        <Tooltip title="Delete">
                            <DeleteOutlined
                                style={{
                                    fontSize: 20,
                                    cursor: 'pointer',
                                    color: '#cf0000',
                                }}
                                onClick={(e) => {
                                    cancelEventPropagation(e);
                                    archiveCustomer(dispatch, history, record.customerId);
                                }}
                            />
                        </Tooltip>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

const customerDetailsTooltip = (record) => (
    <div>
        <div>{`${record.contactInfo.firstName} ${record.contactInfo.lastName}`}</div>
        <div>{`${record.orgName}`}</div>
        <div>{`Manager: ${record.manager}`}</div>
        <div>{`Billing Frequency: ${record.billingFrequency}`}</div>
        <div>{`Phone: ${formatPhoneNumber(record.contactInfo.phoneNumber)}`}</div>
        <div>{`Fax: ${record.faxNumber ? formatPhoneNumber(record.faxNumber) : ''}`}</div>
        <div>{`Email(s): ${record.contactEmails ? record.contactEmails.join(', ') : ''}`}</div>
        <div>{`${record.contactInfo.addressLineOne || ''}`}</div>
        <div>{`${record.contactInfo.addressLineTwo || ''}`}</div>
        <div>{`${record.contactInfo.city || ''} ${record.contactInfo.postalCode || ''}`}</div>
        <div>{`${record.contactInfo.province || ''} ${record.contactInfo.country || ''}`}</div>
    </div>
);

const TdmsActions = ({ record }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    return (
        <>
            {record.active ? (
                <div>
                    {shouldSee(['TDM_DELETE']) ? (
                        <Tooltip title="Delete">
                            <DeleteOutlined
                                style={{
                                    fontSize: 20,
                                    cursor: 'pointer',
                                    color: '#cf0000',
                                }}
                                onClick={(e) => {
                                    cancelEventPropagation(e);
                                    archiveTdm(dispatch, history, record.tdmId);
                                }}
                            />
                        </Tooltip>
                    ) : null}
                </div>
            ) : null}
        </>
    );
};

const usersDetailsTooltip = (record) => (
    <div>
        <div>{`First Name: ${record.firstName}`}</div>
        <div>{`Last Name: ${record.lastName}`}</div>
        <div>{`Email: ${record.email}`}</div>
        <div>{`Created Date: ${new Date(record.createdTs).toDateString()}`}</div>
        <div>{`Last Updated: ${new Date(record.updatedTs).toDateString()}`}</div>
        <div>{`Roles: ${record.roles.map((role) => `${beautifyTextValue(role.role)}`)}`}</div>
    </div>
);

const UserActions = ({ record }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const loggedInUserId = useSelector((state) => state.app.userData.userId);

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            {shouldSee(['USER_MANAGE_UPDATE']) ? (
                <Tooltip title="Edit">
                    <EditOutlined
                        style={{
                            fontSize: 20,
                            cursor: 'pointer',
                        }}
                        onClick={(e) => {
                            cancelEventPropagation(e);
                            setEdditingUserRecord(history, record.userId);
                        }}
                    />
                </Tooltip>
            ) : null}
            {record.active && record.userId !== loggedInUserId ? (
                <div>
                    {shouldSee(['USER_MANAGE_DELETE']) ? (
                        <Tooltip title="Delete">
                            <DeleteOutlined
                                style={{
                                    fontSize: 20,
                                    marginLeft: 30,
                                    cursor: 'pointer',
                                    color: '#cf0000',
                                }}
                                onClick={(e) => {
                                    cancelEventPropagation(e);
                                    archiveUserAccount(dispatch, history, record.userId);
                                }}
                            />
                        </Tooltip>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

const invoiceDetailsTooltip = (record) => (
    <div>
        <div>{`Billed To: ${record.customerName}`}</div>
        <div>{record.customerAddress}</div>
        {record.incompleteReason ? (
            <div>{`Incomplete Reason: ${record.incompleteReason}`}</div>
        ) : null}
        {record.specialService ? <div>{`Special Service: ${record.specialService}`}</div> : null}
    </div>
);

const customerInvoiceDetailsTooltip = (record) => (
    <div>
        <div>{`Billing Frequency: ${record.customer.billingFrequency}`}</div>
        <div>{`Phone No.: ${record.billingAddress.phoneNumber}`}</div>
        {Array.isArray(record.customer.contactEmails) && record.customer.contactEmails.length ? (
            <div>{`Email: ${record.customer.contactEmails[0]}`}</div>
        ) : null}
        <div>{`Manager: ${record.customer.manager}`}</div>
    </div>
);

const billToDetailsTooltip = (record) => (
    <div>
        <div>{`Billed To: ${record.contactInfo.firstName} ${record.contactInfo.lastName}`}</div>
        <div>{`Manager: ${record.manager}`}</div>
        <div>{`Tel: ${record.contactInfo.phoneNumber}`}</div>
    </div>
);

const TruckInvoiceActions = ({ record }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Tooltip title="Edit">
                {shouldSee(['INVOICE_UPDATE']) ? (
                    <EditOutlined
                        style={{
                            fontSize: 20,
                            cursor: 'pointer',
                        }}
                        onClick={(e) => {
                            cancelEventPropagation(e);
                            dispatch({
                                type: APP_ACTION_TYPES.SET_EDITING_INVOICE,
                                payload: {
                                    ...record,
                                },
                            });
                            history.push('/dataentry');
                        }}
                    />
                ) : null}
            </Tooltip>
        </div>
    );
};

const IncompleteEntityActions = ({ record }) => {
    const history = useHistory();
    return (
        <Tooltip title="Open Record">
            <MdOutlineOpenInNew
                style={{
                    fontSize: 20,
                    cursor: 'pointer',
                }}
                onClick={(e) => {
                    cancelEventPropagation(e);
                    if (record.hasOwnProperty('driverId')) {
                        history.push(`/drivers/${record.driverId}`);
                    } else if (record.hasOwnProperty('customerId')) {
                        history.push(`/customers/${record.customerId}`);
                    }
                }}
            />
        </Tooltip>
    );
};

const InvoicesForApprovalActions = ({ record }) => {
    const history = useHistory();
    return (
        <Tooltip title="Review Invoices">
            <MdPreview
                style={{
                    fontSize: 20,
                    cursor: 'pointer',
                }}
                onClick={(e) => {
                    cancelEventPropagation(e);
                    history.push(`/invoices/customer/${record.customerInvoiceId}`);
                }}
            />
        </Tooltip>
    );
};

const SupplementalPayActions = ({ record }) => {
    const dispatch = useDispatch();
    return (
        <Tooltip title="Add Supplemntal Pay Details">
            <MdAddCircleOutline
                style={{
                    fontSize: 20,
                    cursor: 'pointer',
                }}
                onClick={(e) => {
                    cancelEventPropagation(e);
                    dispatch({
                        type: TODO_ACTION_TYPES.SET_DISPLAY_SUPPLEMENTAL_DATA_MODAL,
                        payload: record,
                    });
                }}
            />
        </Tooltip>
    );
};

const MailListReportActions = ({ record }) => {
    const dispatch = useDispatch();

    return (
        <Tooltip title="Remove from mailing list">
            <DeleteOutlined
                style={{
                    fontSize: 20,
                    cursor: 'pointer',
                }}
                onClick={(e) => {
                    cancelEventPropagation(e);
                    getUserConfirmation(dispatch, removeReportEmailConfigConfirmText, () =>
                        ReportsApi.removeReportEmailConfigs(record.configId)
                            .then(() => {
                                displaySimpleSuccessModal(
                                    dispatch,
                                    removeReportEmailConfigSuccessText,
                                    () =>
                                        dispatch({
                                            type: APP_ACTION_TYPES.SET_TRIGGER_FETCH,
                                            payload: TRIGGER_FETCH_TYPES.REPORT_EMAIL_CONFIGURATION,
                                        })
                                );
                            })
                            .catch(() => {
                                displaySimpleErrorModal(dispatch, removeReportEmailConfigErrorText);
                            })
                    );
                }}
            />
        </Tooltip>
    );
};

const PayrollListActions = ({ record }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const deletePayroll = () => {
        PayrollApi.deletePayroll(record.payrollId)
            .then(() => {
                // Display success modal and reload the page to refetch data
                onAPISuccess(dispatch, payrollDeleteSuccessText);
                dispatch({
                    type: APP_ACTION_TYPES.SET_TRIGGER_FETCH,
                    payload: TRIGGER_FETCH_TYPES.PAYROLL_LIST,
                });
            })
            .catch((e) => {
                const errMsg = e.includes('it has trucks assigned')
                    ? payrollDeleteTruckExistsErrorText
                    : payrollDeleteErrorText;
                onAPIError(dispatch, errMsg);
            });
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            {shouldSee(['PAYROLL_READ']) ? (
                <Tooltip title="View Details">
                    <MdPreview
                        style={{
                            fontSize: 20,
                            marginRight: 15,
                            cursor: 'pointer',
                        }}
                        onClick={(e) => {
                            cancelEventPropagation(e);
                            history.push(`/invoices/payroll/${record.payrollId}`);
                        }}
                    />
                </Tooltip>
            ) : null}
            {shouldSee(['PAYROLL_DELETE']) && record.status === 'initialized' ? (
                <Tooltip title="Delete">
                    <DeleteOutlined
                        style={{
                            fontSize: 20,
                            color: 'red',
                            cursor: 'pointer',
                        }}
                        onClick={(e) => {
                            cancelEventPropagation(e);
                            deletePayroll();
                        }}
                    />
                </Tooltip>
            ) : null}
        </div>
    );
};

const PayrollTruckSpecialPayAction = ({ record }) => {
    const dispatch = useDispatch();
    const canAddSpecialPay = shouldSee(['TRUCK_UPDATE']);

    return canAddSpecialPay ? (
        <div
            style={{ color: '#ffa630', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={(e) => {
                cancelEventPropagation(e);
                dispatch({
                    type: TODO_ACTION_TYPES.SET_DISPLAY_SUPPLEMENTAL_DATA_MODAL,
                    payload: record,
                });
            }}
        >
            ADD SPECIAL PAY
        </div>
    ) : (
        <div style={{ color: '#ffa630' }}>YES</div>
    );
};

const PayrollTrucksActions = ({ record }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Tooltip title="View Truck">
                {shouldSee(['TRUCK_READ']) ? (
                    <MdPreview
                        style={{
                            fontSize: 20,
                            cursor: 'pointer',
                        }}
                        onClick={(e) => {
                            cancelEventPropagation(e);
                            dispatch({
                                type: APP_ACTION_TYPES.SET_EDITING_INVOICE,
                                payload: {
                                    ...record,
                                },
                            });
                            history.push('/dataentry');
                        }}
                    />
                ) : null}
            </Tooltip>
        </div>
    );
};

const PayrollEmployeesActions = ({ record, onPayrollDownload }) => {
    const dispatch = useDispatch();

    const sendDriverPayrollReport = () => {
        PayrollApi.sendDriverPayrollReport(record.payrollId, record.employeePayrollId)
            .then(() => {
                displaySimpleSuccessModal(dispatch, 'Report sent successfully');
            })
            .catch(() => {
                displaySimpleErrorModal(dispatch, sendDriverReportErrorText);
            });
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Tooltip title="View Pay Details">
                <MdPreview
                    style={{
                        fontSize: 20,
                        cursor: 'pointer',
                    }}
                    onClick={(e) => {
                        cancelEventPropagation(e);
                        dispatch({
                            type: APP_ACTION_TYPES.SET_VIEWING_EMPLOYEE_PAYROLL_DETAILS,
                            payload: {
                                ...record,
                            },
                        });
                    }}
                />
            </Tooltip>
            {driverEmailValid(record) ? (
                <Tooltip title="Send generated payroll report">
                    <MdOutlineEmail
                        style={{
                            fontSize: 20,
                            cursor: 'pointer',
                            marginLeft: 15,
                        }}
                        onClick={(e) => {
                            cancelEventPropagation(e);
                            sendDriverPayrollReport();
                        }}
                    />
                </Tooltip>
            ) : null}
            <Tooltip title="Donwload payroll summary">
                <FiDownload
                    style={{
                        fontSize: 20,
                        cursor: 'pointer',
                        marginLeft: 15,
                    }}
                    onClick={(e) => {
                        cancelEventPropagation(e);
                        onPayrollDownload(record);
                    }}
                />
            </Tooltip>
        </div>
    );
};

const CustomerInvoiceActions = ({ record }) => {
    const dispatch = useDispatch();
    const recalculateInvoice = () => {
        ReportsApi.recalculateInvoice(record.customerInvoiceId)
            .then(() => {
                const msg = `${invoiceRecalculateSuccessText} Please refresh the page to see results.`;
                displaySimpleSuccessModal(dispatch, msg);
            })
            .catch(() => {
                displaySimpleErrorModal(dispatch, invoiceRecalculateErrorText);
            });
    };

    const sendInvoice = () => {
        ReportsApi.sendInvoice(record.customerInvoiceId)
            .then(() => {
                displaySimpleSuccessModal(dispatch, invoiceSendSuccessText);
            })
            .catch(() => {
                displaySimpleErrorModal(dispatch, invoiceSendErrorText);
            });
    };

    return (
        <>
            {shouldSee(['INVOICE_UPDATE']) ? (
                <Tooltip title="Re-calculate invoice">
                    <BiRefresh
                        style={{
                            fontSize: 20,
                            cursor: 'pointer',
                        }}
                        onClick={(e) => {
                            cancelEventPropagation(e);
                            recalculateInvoice();
                        }}
                    />
                </Tooltip>
            ) : null}
            {shouldSee(['INVOICE_CREATE']) &&
            ['sent_to_customer', 'failed_to_send', 'approved'].includes(record.status) ? (
                <Tooltip title="Send to customer">
                    <MdOutlineEmail
                        style={{
                            fontSize: 20,
                            cursor: 'pointer',
                            marginLeft: 15,
                        }}
                        onClick={(e) => {
                            cancelEventPropagation(e);
                            sendInvoice();
                        }}
                    />
                </Tooltip>
            ) : null}
        </>
    );
};

/* ********** UTIL ********* */
export const archiveDriver = (dispatch, history, driverId) => {
    DriversApi.archiveDriver(driverId)
        .then(() => {
            // Display success modal and reload the page to refetch data
            onAPISuccess(dispatch, driversArchiveDataSuccessText);
            history.go(0);
        })
        .catch(() => {
            onAPIError(dispatch, driversArchiveDataErrorText);
        });
};

export const archiveCustomer = (dispatch, history, customerId) => {
    CustomersApi.archiveCustomer(customerId)
        .then(() => {
            // Display success modal and reload the page to refetch data
            onAPISuccess(dispatch, customersArchiveDataSuccessText);
            history.go(0);
        })
        .catch(() => {
            onAPIError(dispatch, customersArchiveDataErrorText);
        });
};

export const archiveTdm = (dispatch, history, tdmId) => {
    TdmsApi.archiveTdm(tdmId)
        .then(() => {
            // Display success modal and reload the page to refetch data
            onAPISuccess(dispatch, tdmsArchiveDataSuccessText);
            history.go(0);
        })
        .catch(() => {
            onAPIError(dispatch, tdmsArchiveDataErrorText);
        });
};

export const setEdditingUserRecord = (history, userId) => {
    // Passing callbacks to columns is messy, as a workaround manipulate the path
    // and capture it from the page displaying the table to trigger action
    history.push(`/users/editing/${userId}`);
};

export const archiveUserAccount = (dispatch, history, userId) => {
    const archiveUser = () =>
        UsersApi.archiveUserAccount(userId)
            .then(() => {
                // Display success modal and reload the page to refetch data
                onAPISuccess(dispatch, userArchiveSuccessText);
                history.go(0);
            })
            .catch(() => {
                onAPIError(dispatch, userArchiveErrorText);
            });

    getUserConfirmation(
        dispatch,
        'Are you sure you want to remove this user from the system?',
        archiveUser
    );
};

/** Prevents clicked row's onClick from firing when row action icon is clicked */
const cancelEventPropagation = (e) => {
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
};

const driverEmailValid = (record) =>
    record &&
    record.driver &&
    record.driver.email &&
    record.driver.email.length &&
    validator.isEmail(record.driver.email);
