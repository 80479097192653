import { Select } from 'antd';

const { Option } = Select;

const TruckServicesSelector = ({
    invoiceData,
    updateInvoiceServices,
    currentType,
    availableServicesArray,
}) => {
    // If creating a new invoice the label has to be obtained based on available pay/service types,
    // if invoice already exists, label is part of each service
    const getSelectValuesForNewInvoice = () =>
        invoiceData.invoiceServices
            .filter(
                (service) =>
                    service.type === currentType && (service.serviceOrPayTypeId || service.label)
            )
            .map((service) =>
                service.label
                    ? service.label
                    : availableServicesArray.find(
                          (type) =>
                              type[currentType === 'pay_type' ? 'payTypeId' : 'serviceTypeId'] ===
                              service.serviceOrPayTypeId
                      ).label
            );

    return (
        <>
            <div className="input-label" style={{ marginTop: 25 }}>
                {currentType === 'pay_type' ? 'Pay Types' : 'Service Types'}
            </div>
            <Select
                size="large"
                mode="multiple"
                placeholder="Select one or more service"
                value={getSelectValuesForNewInvoice()}
                style={{ width: '100%' }}
                onChange={(value) => {
                    updateInvoiceServices(value, currentType);
                }}
            >
                {availableServicesArray.map((service, idx) => {
                    if (service.hasOwnProperty('dataLabel') && service.hasOwnProperty('dataType')) {
                        return (
                            <Option
                                key={`${currentType}-${service.label}-${idx}`}
                                value={service.label}
                            >
                                {service.label}
                            </Option>
                        );
                    }
                    return null;
                })}
            </Select>
        </>
    );
};
export default TruckServicesSelector;
