import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Breadcrumbs from '../../../component/reusable/Breadcrumbs';
import { DEVICE_TYPE } from '../../../store/constants/appActionTypes';
import { archiveDriver, DRIVER_COLUMNS } from '../../../util/tableColumns';
import TableComponent from '../../../component/reusable/TableComponent';

import DriversApi from '../../../api/dataManagement/drivers';
import { shouldSee } from '../../../util/permissionsUtil';
import RatesView from '../../../component/dataManagement/RatesView';

const BREADCRUMB_LINKS = [
    { title: 'Data Management', href: `${(window.location, origin)}/datamanagement` },
    { title: 'Drivers', href: null },
];

const DriversTable = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const deviceType = useSelector((state) => state.app.device);

    const [driverData, setDriverData] = useState([]);
    const [driverCount, setDriverCount] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchKey, setSearchKey] = useState('');
    const [showInactive, setShowInactive] = useState(true);

    useEffect(() => {
        getDriverData();
    }, [searchKey, showInactive, currentPage]);

    useEffect(() => {
        // Reset page selection on show inactive toggle (causes errors otherwise)
        setCurrentPage(1);
    }, [showInactive]);

    const getDriverData = async () => {
        // Amount of records to be skipped based on page number, 0 if searching for something
        const skip = searchKey.length ? 0 : (currentPage - 1) * 10;
        const drivers = await DriversApi.getDrivers(searchKey, showInactive, skip);

        setDriverCount(drivers.count);
        setDriverData([...drivers.data]);
    };

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const goToDriverRecord = (driverId) => {
        history.push(`/drivers/${driverId}`);
    };

    const getMobileData = () => {
        const filteredData = [...driverData];
        const mobileData = [];
        filteredData.forEach((record) => {
            mobileData.push({
                id: record.driverId,
                title: `${record.contactInfo.firstName} ${record.contactInfo.lastName}`,

                subtitle: `${
                    record.nickname && record.nickname.length ? `(${record.nickname})` : ''
                } ${record.workPhoneNumber}`,
                active: record.active,
            });
        });
        return mobileData;
    };

    const handleMobileArchiveClick = (recordId) => {
        archiveDriver(dispatch, history, recordId);
    };

    return (
        <div
            className={`drivers-table__container${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
        >
            <Breadcrumbs links={BREADCRUMB_LINKS} />

            {/* TABLE */}
            <TableComponent
                rightButtonText={shouldSee(['DRIVER_CREATE']) ? 'ADD DRIVER' : null}
                rightButtonAction={() => history.push('/drivers/new')}
                searchValue={searchKey}
                searchOnChange={setSearchKey}
                searchPlaceholder="Search by nickname"
                switchValue={showInactive}
                switchOnChange={setShowInactive}
                switchHelpText="Show Inactive"
                tableColumns={DRIVER_COLUMNS}
                tableData={driverData}
                mobileListData={getMobileData()}
                currentPage={currentPage}
                onPageChange={onPageChange}
                totalPageCount={driverCount}
                onRowClick={(record) =>
                    record.driverId
                        ? goToDriverRecord(record.driverId)
                        : goToDriverRecord(record.id)
                }
                onMobileActionClick={
                    shouldSee(['DRIVER_DELETE'])
                        ? (record) => handleMobileArchiveClick(record.id)
                        : null
                }
            />

            {/* RATE VIEW MODAL */}
            <RatesView />
        </div>
    );
};
export default DriversTable;
