import { useEffect } from 'react';
import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { APP_ACTION_TYPES, INFO_MESSAGE_TYPES } from '../../store/constants/appActionTypes';

/** Universal info message component (toast). To display, dispatch an action:
 * dispatch({
        type: APP_ACTION_TYPES.SET_DISPLAY_INFO_MESSAGE,
        payload: {
            type: INFO_MESSAGE_TYPES.SUCCESS || WARNING || ERROR || INFO || LOADING,
            message: [STRING TO DISPLAY],
        },
    })
 * The message will close automatically after 3s
 */
const InfoMessageController = () => {
    const dispatch = useDispatch();
    const displayInfoMessage = useSelector((state) => state.app.displayInfoMessage);

    useEffect(() => {
        if (displayInfoMessage) {
            dispatch({ type: APP_ACTION_TYPES.SET_DISPLAY_INFO_MESSAGE, payload: null });
        }
    }, [displayInfoMessage]);

    return displayInfoMessage ? (
        <>
            {displayInfoMessage.type === INFO_MESSAGE_TYPES.SUCCESS
                ? notification.success({
                      key: displayInfoMessage.message,
                      message: displayInfoMessage.message,
                      placement: 'top',
                      duration: 3,
                  })
                : displayInfoMessage.type === INFO_MESSAGE_TYPES.WARNING
                ? notification.warning({
                      key: displayInfoMessage.message,
                      message: displayInfoMessage.message,
                      placement: 'top',
                      duration: 3,
                  })
                : displayInfoMessage.type === INFO_MESSAGE_TYPES.ERROR
                ? notification.error({
                      key: displayInfoMessage.message,
                      message: displayInfoMessage.message,
                      placement: 'top',
                      duration: 3,
                  })
                : displayInfoMessage.type === INFO_MESSAGE_TYPES.INFO ||
                  displayInfoMessage.type === INFO_MESSAGE_TYPES.LOADING
                ? notification.info({
                      key: displayInfoMessage.message,
                      message: displayInfoMessage.message,
                      placement: 'top',
                      duration: 3,
                  })
                : null}
        </>
    ) : null;
};
export default InfoMessageController;
