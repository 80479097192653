import { useHistory } from 'react-router';
import { Modal, Select, DatePicker } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { BiShow, BiHide } from 'react-icons/bi';

import { displaySimpleErrorModal, InfoModalHeader } from '../global/ModalController';
import TableComponent from '../reusable/TableComponent';
import CustomersApi from '../../api/dataManagement/customers';
import ReportsApi from '../../api/reports/reports';
import { DEVICE_TYPE } from '../../store/constants/appActionTypes';
import { shouldSee } from '../../util/permissionsUtil';
import { CUSTOMER_INVOICE_COLUMNS } from '../../util/tableColumns';
import { onAPIError } from '../../util/apiUtil';
import { downloadFile, FILE_TYPE } from '../../util/fileDownloader';
import {
    customerInvoicesFetchErrorText,
    customersFetchErrorText,
    generateCustomerInvoiceBadRangeErrorText,
    generateCustomerInvoiceErrorText,
} from '../../util/longText';

const { Option } = Select;
const { RangePicker } = DatePicker;

const CustomerInvoicesTable = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const deviceType = useSelector((state) => state.app.device);

    const [invoicesData, setInvoicesData] = useState([]);
    const [invoicesCount, setInvoicesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [hideSentInvoices, setHideSentInvoices] = useState(true);

    const [displayGenerateInvoiceModal, setDisplayGenerateInvoiceModal] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState(['', '']);
    const [submittingGenerateInvoice, setSubmittingGenerateInvoice] = useState(false);

    useEffect(() => {
        if (shouldSee(['CUSTOMER_READ_MANY'])) {
            CustomersApi.getCustomers('', false, 0, 10000)
                .then(({ data }) => {
                    setCustomers(data);
                })
                .catch(() => displaySimpleErrorModal(dispatch, customersFetchErrorText));
        }
    }, []);

    useEffect(() => {
        getInvoices();
    }, [currentPage, hideSentInvoices]);

    const getInvoices = async () => {
        // Amount of recrods to be skipped based on page number
        const skip = (currentPage - 1) * 10;
        const hideSentFilter = hideSentInvoices ? 'sent_to_customer' : null;
        const invoices = await ReportsApi.getCustomerInvoices(skip, 10, null, hideSentFilter).catch(
            () => displaySimpleErrorModal(dispatch, customerInvoicesFetchErrorText)
        );

        setInvoicesCount(invoices.count);
        setInvoicesData([...invoices.data]);
    };

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const getMobileData = () => {
        const filteredData = [...invoicesData];
        const mobileData = [];
        filteredData.forEach((record) => {
            mobileData.push({
                id: record.customerInvoiceId,
                title: `${record.billingAddress.firstName} ${record.billingAddress.lastName} | ${record.customer.orgName}`,

                subtitle: `$${(Math.round(record.balanceDue * 100) / 100).toFixed(2)}`,
            });
        });
        return mobileData;
    };

    const getGenerateInvoiceModalContent = () => {
        return (
            <div>
                <div className="invoices-table__invoice-modal__customer-select">
                    <div className="input-label">Customer</div>

                    <Select
                        size="large"
                        placeholder="Select customer..."
                        value={selectedCustomerId}
                        status={selectedCustomerId ? null : 'error'}
                        style={{ width: '100%' }}
                        onChange={(value) => setSelectedCustomerId(value)}
                    >
                        {customers.map((customer) => (
                            <Option
                                key={`generate-invoice-customer_${customer.customerId}`}
                                value={customer.customerId}
                            >
                                {`${customer.contactInfo.firstName} ${customer.contactInfo.lastName} | ${customer.orgName}`}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div>
                    <div className="input-label">Date Range</div>
                    <RangePicker
                        size="middle"
                        style={{ width: '100%' }}
                        value={[
                            selectedDateRange[0].length ? moment(selectedDateRange[0]) : null,
                            selectedDateRange[1].length ? moment(selectedDateRange[1]) : null,
                        ]}
                        onCalendarChange={(val, dateStrings) => setSelectedDateRange(dateStrings)}
                        status={
                            selectedDateRange[0].length && selectedDateRange[1].length
                                ? null
                                : 'error'
                        }
                    />
                </div>
            </div>
        );
    };

    const generateInvoiceDataValid = () => {
        if (!selectedCustomerId) return false;
        if (!selectedDateRange[0].length) return false;
        if (!selectedDateRange[1].length) return false;
        return true;
    };

    const downloadCustomerInvoice = () => {
        setSubmittingGenerateInvoice(true);

        ReportsApi.generateCustomerInvoiceCSV(
            selectedCustomerId,
            selectedDateRange[0],
            selectedDateRange[1]
        )
            .then(async (data) => {
                if (data === null) {
                    // No invoices in range
                    setSubmittingGenerateInvoice(false);
                    onAPIError(dispatch, generateCustomerInvoiceBadRangeErrorText);
                    return;
                }

                // Generate file name
                const selectedCustomer = customers.find(
                    (customer) => customer.customerId === selectedCustomerId
                );
                const fileName = `${`${selectedCustomer.contactInfo.firstName}${selectedCustomer.contactInfo.lastName}${selectedCustomer.orgName}`}_${
                    selectedDateRange[0]
                }to${selectedDateRange[1]}_INVOICE.csv`;

                setSubmittingGenerateInvoice(false);
                setSelectedCustomerId(null);
                setSelectedDateRange(['', '']);
                setDisplayGenerateInvoiceModal(false);

                await downloadFile(data.data, FILE_TYPE.CSV, fileName).catch(() =>
                    onAPIError(dispatch, generateCustomerInvoiceErrorText)
                );
            })
            .catch(() => {
                setSubmittingGenerateInvoice(false);
                onAPIError(dispatch, generateCustomerInvoiceErrorText);
            });
    };

    const goToInvoiceRecord = (invoiceId) => {
        history.push(`/invoices/customer/${invoiceId}`);
    };

    return (
        <div
            className={`invoices-table__container${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
        >
            {/* TABLE */}
            <TableComponent
                tableColumns={CUSTOMER_INVOICE_COLUMNS}
                tableData={invoicesData}
                searchPlaceholder="Search by invoice status"
                leftButtonText={
                    hideSentInvoices ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <BiShow style={{ marginRight: 5 }} />
                            SHOW SENT INVOICES
                        </div>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <BiHide style={{ marginRight: 5 }} />
                            HIDE SENT INVOICES
                        </div>
                    )
                }
                leftButtonAction={() => {
                    setHideSentInvoices(!hideSentInvoices);
                }}
                rightButtonText={
                    deviceType === DEVICE_TYPE.MOBILE || !shouldSee(['INVOICE_READ_MANY'])
                        ? null
                        : 'GENERATE CUSTOMER INVOICE (PREVIEW)'
                }
                rightButtonAction={() => setDisplayGenerateInvoiceModal(true)}
                mobileListData={getMobileData()}
                currentPage={currentPage}
                onPageChange={onPageChange}
                totalPageCount={invoicesCount}
                onRowClick={(record) =>
                    record.customerInvoiceId
                        ? goToInvoiceRecord(record.customerInvoiceId)
                        : goToInvoiceRecord(record.id)
                }
            />

            {/* GENERATE INVOICE MODAL */}
            <Modal
                title={InfoModalHeader('Generate Customer Invoice (preview)')}
                visible={displayGenerateInvoiceModal}
                onOk={() => downloadCustomerInvoice()}
                onCancel={() => {
                    setDisplayGenerateInvoiceModal(false);
                    setSelectedCustomerId(null);
                    setSelectedDateRange(['', '']);
                }}
                closable={false}
                keyboard={false}
                maskClosable={false}
                confirmLoading={submittingGenerateInvoice}
                okButtonProps={generateInvoiceDataValid() ? {} : { disabled: true }}
                cancelButtonProps={submittingGenerateInvoice ? { disabled: true } : {}}
            >
                {getGenerateInvoiceModalContent()}
            </Modal>
        </div>
    );
};
export default CustomerInvoicesTable;
