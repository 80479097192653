import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';

import { DEVICE_TYPE } from '../../../store/constants/appActionTypes';
import { REPORT_MAIL_LIST_COLUMNS } from '../../../util/tableColumns';
import { beautifyTextValue } from '../../../util/formUtil';
import { reportEmailConfigHelperText } from '../../../util/longText';

const EmailConfigTable = ({ configs, displayAddConfigModal, configType }) => {
    const deviceType = useSelector((state) => state.app.device);
    const [registeredUsers, setRegisteredUsers] = useState([]);

    useEffect(() => {
        const config = configs.find((config) => config.reportType === configType);
        if (config) {
            setRegisteredUsers([...config.users]);
        } else {
            setRegisteredUsers([]);
        }
    }, [configs]);

    return (
        <div className="email-config-table__outer-container">
            <div className="email-config-table__container">
                <div
                    className={`email-config-table__title${
                        deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                    }`}
                >
                    <div>{beautifyTextValue(configType)}</div>
                    <div className="standard-button" onClick={displayAddConfigModal}>
                        ADD USER TO MAILING LIST
                    </div>
                </div>

                <div className="email-config-table__helper-text">
                    {reportEmailConfigHelperText[configType]}
                </div>

                <div className="email-config-table__table">
                    <Table
                        style={{ width: '80%' }}
                        columns={REPORT_MAIL_LIST_COLUMNS}
                        dataSource={registeredUsers}
                        pagination={false}
                        rowKey={() => `table_record_${Math.random()}`}
                    />
                </div>
            </div>
        </div>
    );
};
export default EmailConfigTable;
