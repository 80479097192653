import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { DateTime } from 'luxon';
import validator from 'validator';
import PulseLoader from 'react-spinners/PulseLoader';

import AuthenticationApi from '../api/authentication';
import LabelledInput from '../component/reusable/LabelledInput';

import { APP_ACTION_TYPES, MODAL_TYPES } from '../store/constants/appActionTypes';
import {
    passwordResetExpiredErrorText,
    passwordResetFailErrorText,
    passwordResetTokenFetchErrorText,
} from '../util/longText';
import { handleReCaptchaVerify } from '../util/recaptcha';

import logo from '../images/logo.png';
import { displaySimpleErrorModal } from '../component/global/ModalController';

const ForgotPasswordCaptchaWrapper = ({ match }) => {
    const [captchaToken, setCaptchaToken] = useState(null);
    const resetPasswordToken = match.params.token;

    useEffect(() => {
        AuthenticationApi.getcaptchaToken()
            .then((token) => setCaptchaToken(token))
            .catch(() => {
                // TODO: Implement error handling
            });
    });

    return !captchaToken ? (
        <div className="login__outer-container">Loading...</div>
    ) : (
        <GoogleReCaptchaProvider reCaptchaKey={captchaToken}>
            <ForgotPassword resetPasswordToken={resetPasswordToken} />
        </GoogleReCaptchaProvider>
    );
};

const ForgotPassword = ({ resetPasswordToken }) => {
    const dispatch = useDispatch();

    const [redirect, setRedirect] = useState(false);
    const [submittingPasswordChange, setSubmittingPasswordChange] = useState(false);

    const [userEmail, setUserEmail] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const { executeRecaptcha } = useGoogleReCaptcha();

    // Check if token still valid on mount
    useEffect(() => {
        checkIfResetTokenStillValid();
    }, []);

    const checkIfResetTokenStillValid = () => {
        AuthenticationApi.getPasswordTokenDetails(resetPasswordToken)
            .then((data) => {
                const now = DateTime.now();
                const expiryDate = DateTime.fromISO(data.expired);
                if (now > expiryDate) {
                    displaySimpleErrorModal(dispatch, passwordResetExpiredErrorText, () =>
                        setRedirect(true)
                    );
                }
            })
            .catch(() => {
                displaySimpleErrorModal(dispatch, passwordResetTokenFetchErrorText);
            });
    };

    const submitPasswordChange = () => {
        setSubmittingPasswordChange(true);

        handleReCaptchaVerify(executeRecaptcha).then((verificationToken) => {
            AuthenticationApi.submitPasswordChange(
                verificationToken,
                resetPasswordToken,
                userEmail,
                password1
            )
                .then(() => {
                    setSubmittingPasswordChange(false);

                    dispatch({
                        type: APP_ACTION_TYPES.SET_DISPLAY_MODAL,
                        payload: {
                            type: MODAL_TYPES.SUCCESS,
                            hideCancelButton: true,
                            okAction: () => {
                                dispatch({
                                    type: APP_ACTION_TYPES.SET_DISPLAY_MODAL,
                                    payload: null,
                                });
                                setRedirect(true);
                            },
                            content: <div>Your password has been reset successfully.</div>,
                        },
                    });
                })
                .catch(() => {
                    setSubmittingPasswordChange(false);
                    displaySimpleErrorModal(dispatch, passwordResetFailErrorText);
                });
        });
    };

    const isFormValid = () =>
        validator.isEmail(userEmail) && password1.length > 5 && password1 === password2;

    return redirect ? (
        <Redirect to={{ pathname: '/login' }} />
    ) : (
        <>
            <div className="login__outer-container">
                <div className="login__container">
                    <div className="login__title">Password Reset</div>

                    {/* FORM */}
                    <div className="login__details__container">
                        {/* EMAIL */}
                        <LabelledInput
                            labelText="Verify your email"
                            value={userEmail}
                            onChangeAction={setUserEmail}
                            withValidation
                            isValid={validator.isEmail(userEmail)}
                            validationText="Enter a valid email"
                        />

                        {/* PASSWORD 1 */}
                        <LabelledInput
                            labelText="Password"
                            value={password1}
                            onChangeAction={setPassword1}
                            withValidation
                            isValid={password1.length > 5}
                            validationText="Enter a valid password"
                            isPassword
                        />

                        {/* PASSWORD 2 */}
                        <LabelledInput
                            labelText="Re-enter password"
                            value={password2}
                            onChangeAction={setPassword2}
                            withValidation
                            isValid={password1 === password2}
                            validationText="Passwords must match"
                            isPassword
                        />

                        {/* BUTTON */}
                        <div
                            className={`standard-button${isFormValid() ? '' : '--disabled'}`}
                            style={{ width: '100%', marginTop: 25 }}
                            onClick={
                                isFormValid()
                                    ? () => {
                                          submitPasswordChange();
                                      }
                                    : null
                            }
                        >
                            SUBMIT
                        </div>

                        {/* SPINNER */}
                        <div className="login__details__pending">
                            {submittingPasswordChange ? <PulseLoader color="#0e76bc" /> : null}
                        </div>
                    </div>

                    <div className="page-break" style={{ margin: '25px 0' }} />

                    <div className="login__logo__container">
                        <img src={logo} alt="logo" style={{ height: '100%' }} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ForgotPasswordCaptchaWrapper;
