import { Modal, Table } from 'antd';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LabelledInput from '../reusable/LabelledInput';
import MobileList from '../reusable/MobileList';
import { mobileAddButton } from '../reusable/SmallElemets';

import { DEVICE_TYPE } from '../../store/constants/appActionTypes';

import { capitalizeWord } from '../../util/formUtil';
import { RATE_TYPES_COLUMNS } from '../../util/tableColumns';
import { InfoModalHeader } from '../global/ModalController';
import PayTypesApi from '../../api/rateProfiles/payTypes';
import { onAPIError, onAPISuccess } from '../../util/apiUtil';
import { newRateTypeAddedErrorText, newRateTypeAddedSuccessText } from '../../util/longText';
import ServiceTypesApi from '../../api/rateProfiles/serviceTypes';
import { shouldSee } from '../../util/permissionsUtil';

const RateType = ({ type, data, refetchData }) => {
    const dispatch = useDispatch();
    const deviceType = useSelector((state) => state.app.device);

    const [displayAddModal, setDisplayAddModal] = useState(false);
    const [newRateLabel, setNewRateLabel] = useState('');
    const [newRateDefaultRate, setNewRateDefaultRate] = useState('');

    const [submittingNewRateType, setSubmittingNewRateType] = useState(false);

    const getMobileData = () => {
        const filteredData = [...data];
        const mobileData = [];
        filteredData.forEach((record) => {
            mobileData.push({
                id: record.label,
                title: record.label,
                subtitle: record.defaultRate,
            });
        });
        return mobileData;
    };

    const getModalContent = () => (
        <>
            <LabelledInput
                labelText="Label"
                value={newRateLabel}
                onChangeAction={(value) => setNewRateLabel(value)}
                noTopMargin
            />
            <LabelledInput
                labelText="Default Rate"
                value={newRateDefaultRate}
                onChangeAction={(value) => setNewRateDefaultRate(value)}
            />
        </>
    );

    const submitNewRateType = () => {
        const onSuccessfulSubmit = () => {
            setSubmittingNewRateType(false);
            setDisplayAddModal(false);
            setNewRateLabel('');
            setNewRateDefaultRate('');

            onAPISuccess(dispatch, newRateTypeAddedSuccessText);
            refetchData();
        };

        const onFailedSubmit = () => {
            setSubmittingNewRateType(false);
            onAPIError(dispatch, newRateTypeAddedErrorText);
        };

        if (type === 'employee') {
            // Add employee rate
            setSubmittingNewRateType(true);

            PayTypesApi.createPayType({
                label: newRateLabel,
                defaultRate: newRateDefaultRate,
            })
                .then(() => {
                    onSuccessfulSubmit();
                })
                .catch(() => {
                    onFailedSubmit();
                });
        } else {
            // Add customer rate
            setSubmittingNewRateType(true);

            ServiceTypesApi.createServiceType({
                label: newRateLabel,
                defaultRate: newRateDefaultRate,
            })
                .then(() => {
                    onSuccessfulSubmit();
                })
                .catch(() => {
                    onFailedSubmit();
                });
        }
    };

    return (
        <div
            className={`rate-types__container${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
        >
            {/* HEADING */}
            <div className="rate-types__heading__container">
                {/* TITLE */}
                <div className="rate-types__heading__title">
                    {`${capitalizeWord(type)} Rate Types`}
                </div>
                {/* ADD BUTTON */}
                {shouldSee([type === 'employee' ? 'PAY_TYPES_CREATE' : 'SERVICE_TYPES_CREATE']) ? (
                    deviceType === DEVICE_TYPE.MOBILE ? (
                        mobileAddButton(() => setDisplayAddModal(true))
                    ) : (
                        <div className="standard-button" onClick={() => setDisplayAddModal(true)}>
                            ADD RATE TYPE
                        </div>
                    )
                ) : null}
            </div>

            {/* TABLE */}
            <div className="rate-types__table__container">
                {deviceType === DEVICE_TYPE.NOT_MOBILE ? (
                    <Table
                        columns={RATE_TYPES_COLUMNS}
                        dataSource={data}
                        rowKey={(record) => `table_record_${record[Object.keys(record)[0]]}`}
                        rowClassName="table-component__table__row"
                        pagination={false}
                    />
                ) : (
                    <MobileList data={getMobileData()} />
                )}
            </div>

            {/* ADD MODAL */}
            <Modal
                title={InfoModalHeader(`Add ${type} rate`)}
                visible={displayAddModal}
                onOk={() => submitNewRateType()}
                onCancel={() => {
                    setDisplayAddModal(false);
                    setNewRateLabel('');
                    setNewRateDefaultRate('');
                }}
                closable={false}
                keyboard={false}
                maskClosable={false}
                confirmLoading={submittingNewRateType}
                okButtonProps={
                    newRateLabel.length > 0 && newRateDefaultRate.length > 0
                        ? {}
                        : { disabled: true }
                }
                cancelButtonProps={submittingNewRateType ? { disabled: true } : {}}
            >
                {getModalContent()}
            </Modal>
        </div>
    );
};
export default RateType;

RateType.propTypes = {
    // The type of the rate, used for API and heading
    type: PropTypes.string.isRequired,
    // Rate data to be displayed in the table
    data: PropTypes.arrayOf(
        PropTypes.objectOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)])
        )
    ).isRequired,
};
