import { Checkbox, Modal, Select, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AiOutlineDelete, AiOutlineSetting } from 'react-icons/ai';
import { getNotificationSettingTypes } from '@wx/common';
import NotificationSettingsApi from '../../../api/notifications/notificationSettings';
import UsersApi from '../../../api/userManagement/users';
import {
    notificationSettingsDeleteErrorText,
    notificationSettingsFetchErrorText,
    notificationSettingsHelperText,
    notificationSettingsSaveErrorText,
    notificationSettingsUpdateErrorText,
} from '../../../util/longText';
import {
    displaySimpleErrorModal,
    getUserConfirmation,
    InfoModalHeader,
} from '../../global/ModalController';
import { beautifyTextValue } from '../../../util/formUtil';
import { shouldSee } from '../../../util/permissionsUtil';

const DISABLED_ICON_STYLE = { fontSize: 20, cursor: 'not-allowed', color: '#b3b3b3' };

const NotificationSettings = () => {
    const dispatch = useDispatch();
    const [notificationSettings, setNotificationSettings] = useState([]);
    const [users, setUsers] = useState([]);

    const [displayAddSetting, setDisplayAddSetting] = useState(null);
    const [displayUpdateSetting, setDisplayUpdateSetting] = useState(null);
    const [selectedUser, setSelectedUser] = useState(undefined);
    const [selectedSettingType, setSelectedSettingType] = useState(undefined);
    const [selectedSettings, setSelectedSettings] = useState([]);
    const [savingNewSetting, setSavingNewSetting] = useState(false);
    const [updatingSetting, setUpdatingSetting] = useState(false);
    const [deletingSetting, setDeletingSetting] = useState(false);

    // Fetch configs and users on mount
    useEffect(() => {
        fetchNotificationSettings();
        fetchUsers();
    }, []);

    useEffect(() => {
        // Pre-set values if displaying update modal
        if (displayUpdateSetting) {
            setSelectedUser(displayUpdateSetting.user.email);
            setSelectedSettingType(displayUpdateSetting.notificationSettingType);
            const selectedSettings = [];
            if (displayUpdateSetting.isEmailOn) selectedSettings.push('email-notification');
            if (displayUpdateSetting.isDesktopOn) selectedSettings.push('desktop-notification');
            setSelectedSettings([...selectedSettings]);
        }
    }, [displayUpdateSetting]);

    const fetchNotificationSettings = () => {
        NotificationSettingsApi.getAllNotificationSettings()
            .then((data) => {
                const sortedData = data.sort((a, b) => a.user.email.localeCompare(b.user.email));
                setNotificationSettings([...sortedData]);
            })
            .catch(() => {
                displaySimpleErrorModal(dispatch, notificationSettingsFetchErrorText);
            });
    };

    const fetchUsers = () => {
        UsersApi.getUsers('', true, 0, 1000000)
            .then(({ data }) => {
                setUsers([...data]);
            })
            .catch(() => {
                displaySimpleErrorModal(dispatch, notificationSettingsFetchErrorText);
            });
    };

    const saveNewUserSetting = () => {
        setSavingNewSetting(true);
        const { userId } = users.find((user) => user.email === selectedUser);
        const isEmailOn = selectedSettings.includes('email-notification');
        const isDesktopOn = selectedSettings.includes('desktop-notification');

        NotificationSettingsApi.createNotificationSetting(
            userId,
            selectedSettingType,
            isEmailOn,
            isDesktopOn
        )
            .then((data) => {
                const updatedSettings = [...notificationSettings, { ...data }].sort((a, b) =>
                    a.user.email.localeCompare(b.user.email)
                );

                setNotificationSettings([...updatedSettings]);
                setSavingNewSetting(false);
                resetModal();
            })

            .catch(() => {
                displaySimpleErrorModal(dispatch, notificationSettingsSaveErrorText);
                setSavingNewSetting(false);
            });
    };

    const udpateNotificationSetting = () => {
        setUpdatingSetting(true);
        const { notificationSettingId } = displayUpdateSetting;
        const isEmailOn = selectedSettings.includes('email-notification');
        const isDesktopOn = selectedSettings.includes('desktop-notification');

        NotificationSettingsApi.updateNotificationSetting(
            notificationSettingId,
            isEmailOn,
            isDesktopOn
        )
            .then((data) => {
                const updatedSettings = [...notificationSettings];
                const indexToUpdate = updatedSettings.findIndex(
                    (updateSetting) =>
                        updateSetting.notificationSettingId === data.notificationSettingId
                );
                updatedSettings[indexToUpdate] = { ...data };
                setNotificationSettings([...updatedSettings]);

                setUpdatingSetting(false);
                resetModal();
            })

            .catch(() => {
                displaySimpleErrorModal(dispatch, notificationSettingsUpdateErrorText);
                setUpdatingSetting(false);
            });
    };

    const deleteNotificationSetting = (setting) => {
        const deleteConfirmed = () => {
            console.log(setting);
            setDeletingSetting(true);

            NotificationSettingsApi.deleteNotificationSetting(setting.notificationSettingId)
                .then(() => {
                    const updatedSettings = [...notificationSettings];
                    const indexToRemove = updatedSettings.findIndex(
                        (updateSetting) =>
                            updateSetting.notificationSettingId === setting.notificationSettingId
                    );
                    updatedSettings.splice(indexToRemove, 1);
                    setNotificationSettings([...updatedSettings]);

                    setDeletingSetting(false);
                })
                .catch(() => {
                    displaySimpleErrorModal(dispatch, notificationSettingsDeleteErrorText);
                    setDeletingSetting(false);
                });
        };

        getUserConfirmation(dispatch, 'Are you sure you want to remove this setting?', () =>
            deleteConfirmed()
        );
    };

    const settingExists = () =>
        notificationSettings.some(
            (setting) =>
                setting.notificationSettingType === selectedSettingType &&
                setting.user.email === selectedUser
        );

    const newSettingValid = () => {
        if (!selectedUser) return false;
        if (!selectedSettingType) return false;
        if (settingExists()) return false;
        return true;
    };

    const getAddSettingModalContent = (isUpdate) => {
        return (
            <>
                {/* USER */}
                <div style={{ width: '100%', marginBottom: 15 }}>
                    <Select
                        size="large"
                        style={{ width: '100%' }}
                        placeholder="Select user"
                        value={selectedUser}
                        status={selectedUser ? null : 'error'}
                        onChange={(value) => {
                            setSelectedUser(value);
                        }}
                        disabled={isUpdate}
                    >
                        {users.map((user) => (
                            <Select.Option key={user.email}>{user.email}</Select.Option>
                        ))}
                    </Select>
                </div>

                {/* SETTING TYPE */}
                <div style={{ width: '100%', marginBottom: 15 }}>
                    <Select
                        size="large"
                        style={{ width: '100%' }}
                        placeholder="Select setting type"
                        value={selectedSettingType}
                        status={selectedSettingType ? null : 'error'}
                        onChange={(value) => {
                            setSelectedSettingType(value);
                        }}
                        disabled={isUpdate}
                    >
                        {Object.values(getNotificationSettingTypes()).map((settingType) => (
                            <Select.Option key={settingType}>
                                {beautifyTextValue(settingType)}
                            </Select.Option>
                        ))}
                    </Select>
                </div>

                {/* NOTIFICATION SETTINGS */}
                <div>
                    <Checkbox.Group
                        options={[
                            {
                                label: 'Email Notification',
                                value: 'email-notification',
                            },
                            {
                                label: 'Desktop Notification',
                                value: 'desktop-notification',
                            },
                        ]}
                        onChange={(checkedValues) => {
                            setSelectedSettings([...checkedValues]);
                        }}
                        value={selectedSettings}
                    />
                </div>

                {/* ERROR TEXT */}
                {settingExists() && !isUpdate ? (
                    <div className="notification-settings__error-text">
                        Selected setting for selected user already exists, update it to make changes
                    </div>
                ) : null}
            </>
        );
    };

    const resetModal = () => {
        setDisplayAddSetting(null);
        setDisplayUpdateSetting(null);
        setSelectedUser(undefined);
        setSelectedSettingType(undefined);
        setSelectedSettings([]);
    };

    const getSettingRow = (setting, isHeader = false) => {
        return (
            <div
                key={`notification-setting-${setting.notificationSettingId}`}
                className="notification-settings__row__container"
            >
                {/* USER */}
                <div
                    className="notification-settings__row__cell"
                    style={isHeader ? { width: '20%', fontWeight: 'bold' } : { width: '20%' }}
                >
                    {isHeader ? 'User Email' : setting.user.email}
                </div>

                {/* SETTING TYPE */}
                <div
                    className="notification-settings__row__cell"
                    style={isHeader ? { width: '30%', fontWeight: 'bold' } : { width: '30%' }}
                >
                    {isHeader ? 'Event' : beautifyTextValue(setting.notificationSettingType)}
                </div>

                {/* SETTING CONFIG */}
                <div
                    className="notification-settings__row__cell"
                    style={isHeader ? { width: '40%', fontWeight: 'bold' } : { width: '40%' }}
                >
                    {isHeader ? (
                        'Enabled Settings'
                    ) : (
                        <>
                            <Checkbox disabled checked={setting.isEmailOn}>
                                Email Notification
                            </Checkbox>
                            <Checkbox disabled checked={setting.isDesktopOn}>
                                Desktop Notification
                            </Checkbox>
                        </>
                    )}
                </div>

                {/* ACTIONS */}
                <div className="notification-settings__row__cell" style={{ width: '10%' }}>
                    {shouldSee(['USER_MANAGE_UPDATE']) && !isHeader ? (
                        <>
                            <Tooltip title="Update">
                                <AiOutlineSetting
                                    style={
                                        deletingSetting
                                            ? {
                                                  ...DISABLED_ICON_STYLE,
                                                  marginRight: 20,
                                              }
                                            : {
                                                  fontSize: 20,
                                                  cursor: 'pointer',
                                                  color: '#000',
                                                  marginRight: 20,
                                              }
                                    }
                                    onClick={
                                        deletingSetting
                                            ? null
                                            : () => setDisplayUpdateSetting(setting)
                                    }
                                />
                            </Tooltip>
                            <Tooltip title="Delete">
                                <AiOutlineDelete
                                    style={
                                        deletingSetting
                                            ? {
                                                  ...DISABLED_ICON_STYLE,
                                              }
                                            : {
                                                  fontSize: 20,
                                                  cursor: 'pointer',
                                                  color: '#cf0000',
                                              }
                                    }
                                    onClick={
                                        deletingSetting
                                            ? null
                                            : () => deleteNotificationSetting(setting)
                                    }
                                />
                            </Tooltip>
                        </>
                    ) : null}
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="notification-settings__outer-container">
                <div className="notification-settings__container">
                    <div className="notification-settings__title">
                        <div>Notifications Settings</div>
                        {shouldSee(['USER_MANAGE_UPDATE']) ? (
                            <div
                                className="standard-button"
                                onClick={() => setDisplayAddSetting(true)}
                            >
                                ADD NOTIFICATION SETTING
                            </div>
                        ) : null}
                    </div>

                    <div className="notification-settings__helper-text">
                        {notificationSettingsHelperText}
                    </div>

                    <div className="notification-settings__table">
                        {notificationSettings.length ? (
                            <div className="notification-settings__table__inner-container">
                                {[{}, ...notificationSettings].map((setting, idx) =>
                                    idx === 0
                                        ? getSettingRow({ notificationSettingId: 0 }, true)
                                        : getSettingRow(setting)
                                )}
                            </div>
                        ) : (
                            <div className="heading--smaller">
                                No notification settings yet, add one...
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {displayAddSetting || displayUpdateSetting ? (
                <Modal
                    title={InfoModalHeader(
                        displayAddSetting
                            ? 'Add new Notification Setting'
                            : 'Update Notification Setting'
                    )}
                    open
                    onOk={
                        displayAddSetting
                            ? () => saveNewUserSetting()
                            : () => udpateNotificationSetting()
                    }
                    onCancel={() => {
                        resetModal();
                    }}
                    closable={false}
                    keyboard={false}
                    maskClosable={false}
                    confirmLoading={savingNewSetting || updatingSetting}
                    okButtonProps={
                        newSettingValid() || displayUpdateSetting ? {} : { disabled: true }
                    }
                    cancelButtonProps={
                        savingNewSetting || updatingSetting ? { disabled: true } : {}
                    }
                >
                    {getAddSettingModalContent(displayUpdateSetting)}
                </Modal>
            ) : null}
        </>
    );
};
export default NotificationSettings;
