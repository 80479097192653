import { APP_ACTION_TYPES, SIDEBAR_VIEWS } from '../constants/appActionTypes';

const defaultState = {
    device: null,
    loggedIn: false,
    userData: null,
    userPermissions: [],
    sidebarView: SIDEBAR_VIEWS.COLLAPSED,
    displayModal: null,
    modalActionLoading: false,
    displayInfoMessage: null,
    editingInvoice: null,
    viewingDriverRates: null,
    viewingCustomerRates: null,
    triggerFetch: null,
    viewingEmployeePayrollDetails: null,
    socketServer: null,
    notifications: [],
};

const appReducer = (state = defaultState, action) => {
    switch (action.type) {
        case APP_ACTION_TYPES.SET_DEVICE:
            return {
                ...state,
                device: action.payload,
            };
        case APP_ACTION_TYPES.TOGGLE_LOGGED_IN:
            return {
                ...state,
                loggedIn: action.payload,
            };
        case APP_ACTION_TYPES.SET_USER_DATA:
            return {
                ...state,
                userData: action.payload,
            };
        case APP_ACTION_TYPES.SET_USER_PERMISSIONS:
            return {
                ...state,
                userPermissions: action.payload,
            };
        case APP_ACTION_TYPES.TOGGLE_SIDEBAR_VIEW:
            return {
                ...state,
                sidebarView: action.payload,
            };
        case APP_ACTION_TYPES.SET_DISPLAY_MODAL:
            return {
                ...state,
                displayModal: action.payload,
            };
        case APP_ACTION_TYPES.SET_MODAL_AS_LOADING:
            return {
                ...state,
                modalActionLoading: action.payload,
            };
        case APP_ACTION_TYPES.SET_DISPLAY_INFO_MESSAGE:
            return {
                ...state,
                displayInfoMessage: action.payload,
            };
        case APP_ACTION_TYPES.SET_EDITING_INVOICE:
            return {
                ...state,
                editingInvoice: action.payload,
            };
        case APP_ACTION_TYPES.SET_VIEWING_DRIVER_RATES:
            return {
                ...state,
                viewingDriverRates: action.payload,
            };
        case APP_ACTION_TYPES.SET_VIEWING_CUSTOMER_RATES:
            return {
                ...state,
                viewingCustomerRates: action.payload,
            };
        case APP_ACTION_TYPES.SET_TRIGGER_FETCH:
            return {
                ...state,
                triggerFetch: action.payload,
            };
        case APP_ACTION_TYPES.SET_VIEWING_EMPLOYEE_PAYROLL_DETAILS:
            return {
                ...state,
                viewingEmployeePayrollDetails: action.payload,
            };
        case APP_ACTION_TYPES.SET_SOCKET_SERVER:
            return {
                ...state,
                socketServer: action.payload,
            };
        case APP_ACTION_TYPES.SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload,
            };
        case APP_ACTION_TYPES.ADD_NOTIFICATION:
            if (
                state.notifications.some(
                    (notification) =>
                        notification.desktopNotificationId === action.payload.desktopNotificationId
                )
            ) {
                return { ...state };
            }
            return {
                ...state,
                notifications: [...state.notifications, action.payload],
            };
        default:
            return state;
    }
};

export default appReducer;
