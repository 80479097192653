import { useSelector } from 'react-redux';
import moment from 'moment';

import TableComponent from '../../reusable/TableComponent';
import {
    FAILED_TO_SEND_INVOICES_COLUMNS,
    INVOICES_FOR_APPROVAL_COLUMNS,
} from '../../../util/tableColumns';
import { DEVICE_TYPE } from '../../../store/constants/appActionTypes';

export const TODO_TYPE = Object.freeze({
    INVOICES_FOR_APPROVAL: 'invoices_for_approval',
    FAILED_TO_SEND_INVOICES: 'failed_to_send_invoices',
    INVOICES_ON_HOLD: 'invoices_on_hold',
});

const TODO_DETAILS = {
    invoices_for_approval: {
        heading: 'The invoices below require approval',
        columns: INVOICES_FOR_APPROVAL_COLUMNS,
    },
    failed_to_send_invoices: {
        heading: 'The invoices below failed to send',
        columns: FAILED_TO_SEND_INVOICES_COLUMNS,
    },
    invoices_on_hold: {
        heading: 'The invoices below have been placed on hold',
        columns: INVOICES_FOR_APPROVAL_COLUMNS,
    },
};

const TodoDetails = ({ type }) => {
    const deviceType = useSelector((state) => state.app.device);
    const invoicesNeedingApproval = useSelector((state) => state.todo.invoicesNeedingApproval);
    const invoicesFailedToSend = useSelector((state) => state.todo.invoicesFailedToSend);
    const invoicesOnHold = useSelector((state) => state.todo.invoicesOnHold);

    const getMobileData = () => {
        const filteredData =
            type === TODO_TYPE.INVOICES_FOR_APPROVAL
                ? [...invoicesNeedingApproval]
                : type === TODO_TYPE.FAILED_TO_SEND_INVOICES
                ? [...invoicesFailedToSend]
                : [...invoicesOnHold];
        const mobileData = [];
        filteredData.forEach((record) => {
            mobileData.push({
                id: record.customerInvoiceId,
                title: `${record.billingAddress.firstName} ${record.billingAddress.lastName}`,
                subtitle:
                    type === TODO_TYPE.INVOICES_FOR_APPROVAL
                        ? `Balance Due: $${(Math.round(record.balanceDue * 100) / 100).toFixed(2)}`
                        : `Created On: ${moment(record.createdTs).format('yyyy-MM-DD')}`,
            });
        });
        return mobileData;
    };

    return (
        <div
            className={`todo-list__invoices-for-approval__container${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
        >
            <div className="heading--smaller">{TODO_DETAILS[type].heading}</div>

            <TableComponent
                tableColumns={TODO_DETAILS[type].columns}
                tableData={
                    type === TODO_TYPE.INVOICES_FOR_APPROVAL
                        ? invoicesNeedingApproval
                        : type === TODO_TYPE.FAILED_TO_SEND_INVOICES
                        ? invoicesFailedToSend
                        : invoicesOnHold
                }
                noPagination
                mobileListData={getMobileData()}
                customWidth={800}
            />
        </div>
    );
};
export default TodoDetails;
