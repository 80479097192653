/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { DatePicker, Checkbox, Select, Tooltip } from 'antd';
import PulseLoader from 'react-spinners/PulseLoader';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { AiFillFilter } from 'react-icons/ai';
import { AuditCategory, AuditAction } from '@wx/common';

import AuditsApi from '../../api/audit/audits';
import { DEVICE_TYPE } from '../../store/constants/appActionTypes';
import { onAPIError } from '../../util/apiUtil';
import { beautifyTextValue } from '../../util/formUtil';
import { auditDataFetchErrorText } from '../../util/longText';
import { AUDIT_COLUMNS } from '../../util/tableColumns';
import TableComponent from '../reusable/TableComponent';

const DEFAULT_FILTERS = {
    date: null,
    category: null,
    subCategory: null,
    action: null,
    user: null,
};

const Audits = () => {
    const dispatch = useDispatch();
    const deviceType = useSelector((state) => state.app.device);

    const [auditsData, setAuditsData] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState(DEFAULT_FILTERS);
    const [subCategories, setSubCategories] = useState([]);
    const [filterableUsers, setFilterableUsers] = useState([]);

    useEffect(() => {
        setAuditData();
    }, []);

    useDeepCompareEffect(() => {
        setAuditData();
    }, [filters]);

    const setAuditData = async () => {
        setIsFetching(true);

        let fetchFilters = null;
        if (Object.values(filters).some((filter) => filter !== null)) {
            fetchFilters = Object.fromEntries(
                Object.entries(filters).filter(([_, filter]) => filter !== null)
            );
        }

        const audits = await AuditsApi.getAudits(fetchFilters).catch(() =>
            onAPIError(dispatch, auditDataFetchErrorText)
        );

        // Set filter categories on initial load
        if (!subCategories.length) setInitialFilterData(audits);

        setAuditsData([...audits]);
        setIsFetching(false);
    };

    const setInitialFilterData = (audits) => {
        const allSubCategories = [...new Set(audits.map((audit) => audit.subCategory))];
        setSubCategories(allSubCategories);

        const allFilterableUsers = [
            ...new Set(audits.map((audit) => `${audit.user.firstName} ${audit.user.lastName}`)),
        ];
        setFilterableUsers(allFilterableUsers);
    };

    const getMobileData = () => {
        const filteredData = [...auditsData];
        const mobileData = [];
        filteredData.forEach((record) => {
            mobileData.push({
                id: record.auditId,
                title: `${moment(record.auditTs).format('DD-MM-YYYY HH:mm')} ${beautifyTextValue(
                    record.action
                )}`,
                subtitle: `${beautifyTextValue(record.subCategory)} (${beautifyTextValue(
                    record.category
                )}) | ${record.user.firstName} ${record.user.lastName}`,
            });
        });
        return mobileData;
    };

    const getFilters = () => {
        return (
            <>
                {/* DATE FILTER */}
                <div className="audits__filters__filter__container">
                    <div className="input-label">Filter by Date</div>
                    <DatePicker
                        size="large"
                        style={{ width: '100%' }}
                        value={filters.date ? moment(filters.date) : null}
                        onChange={(date) =>
                            setFilters({
                                ...filters,
                                date: date ? date.format('YYYY-MM-DD') : null,
                            })
                        }
                        allowClear
                    />
                </div>

                {/* CATEGORY */}
                <div className="audits__filters__filter__container">
                    <div className="input-label">Filter by Category</div>
                    <Checkbox.Group
                        style={{ display: 'flex', flexDirection: 'column' }}
                        options={Object.values(AuditCategory).map((cat) => ({
                            label: beautifyTextValue(cat),
                            value: cat,
                        }))}
                        onChange={(checkedValues) => {
                            setFilters({
                                ...filters,
                                category: checkedValues.length ? checkedValues : null,
                            });
                        }}
                        value={filters.category ? filters.category : []}
                    />
                </div>

                {/* SUB CATEGORY */}
                <div className="audits__filters__filter__container">
                    <div className="input-label">Filter by Sub Category</div>
                    <Checkbox.Group
                        style={{ display: 'flex', flexDirection: 'column' }}
                        options={subCategories.map((cat) => ({
                            label: beautifyTextValue(cat),
                            value: cat,
                        }))}
                        onChange={(checkedValues) => {
                            setFilters({
                                ...filters,
                                subCategory: checkedValues.length ? checkedValues : null,
                            });
                        }}
                        value={filters.subCategory ? filters.subCategory : []}
                    />
                </div>

                {/* ACTION */}
                <div className="audits__filters__filter__container">
                    <div className="input-label">Filter by Action</div>
                    <Checkbox.Group
                        style={{ display: 'flex', flexDirection: 'column' }}
                        options={Object.values(AuditAction).map((action) => ({
                            label: beautifyTextValue(action),
                            value: action,
                        }))}
                        onChange={(checkedValues) => {
                            setFilters({
                                ...filters,
                                action: checkedValues.length ? checkedValues : null,
                            });
                        }}
                        value={filters.action ? filters.action : []}
                    />
                </div>

                {/* USER */}
                <div className="audits__filters__filter__container">
                    <div className="input-label">Filter by User</div>
                    <Select
                        size="large"
                        allowClear
                        value={filters.user}
                        style={{ width: '100%' }}
                        onChange={(value) =>
                            setFilters({
                                ...filters,
                                user: value || null,
                            })
                        }
                    >
                        {filterableUsers.map((user) => (
                            <Select.Option key={`selectrecord_${user}`} value={user}>
                                {user}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </>
        );
    };

    return (
        <div className={`audits__container${deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''}`}>
            {/* FILTERS */}
            {deviceType === DEVICE_TYPE.NOT_MOBILE && showFilters ? (
                <div className="audits__filters__container">{getFilters()}</div>
            ) : null}

            {/* DATA FETCH SPINNER */}
            {deviceType === DEVICE_TYPE.NOT_MOBILE && isFetching ? (
                <div className="audits__spinner">
                    <PulseLoader color="#0e76bc" size="10" />
                </div>
            ) : null}

            {/* FILTERS BUTTON */}
            {deviceType === DEVICE_TYPE.NOT_MOBILE ? (
                <div className="audits__filters__button__container">
                    <div>
                        {Object.values(filters).some((filter) => filter !== null) ? (
                            <Tooltip title="One or more filters are applied">
                                <AiFillFilter
                                    style={{ fontSize: 24, color: '#1890ff', cursor: 'help' }}
                                />
                            </Tooltip>
                        ) : null}
                    </div>
                    <div className="standard-button" onClick={() => setShowFilters(!showFilters)}>
                        {showFilters ? 'HIDE FILTERS' : 'SHOW FILTERS'}
                    </div>
                </div>
            ) : null}

            {/* TABLE */}
            <TableComponent
                tableColumns={AUDIT_COLUMNS}
                tableData={auditsData}
                mobileListData={getMobileData()}
                noPagination
                onRowClick={() => null}
            />
        </div>
    );
};
export default Audits;
