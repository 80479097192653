import axios from 'axios';
import { getAuthHeader, getErrorMessage, getHost } from '../../util/apiUtil';
import { shouldSee } from '../../util/permissionsUtil';

const NOTIFICATION_SETTINGS_URL = `${getHost()}/notificationsettings`;

class NotificationSettingsApi {
    static createNotificationSetting(userId, notificationType, isEmailOn, isDesktopOn) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['USER_MANAGE_UPDATE'])) {
                console.error('API ERROR | Notification Settings: User not permitted');
                reject();
            }

            axios
                .post(
                    NOTIFICATION_SETTINGS_URL,
                    {
                        userId,
                        notificationType,
                        isEmailOn,
                        isDesktopOn,
                    },
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Notification Settings: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static getAllNotificationSettings() {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['USER_MANAGE_USER_READ'])) {
                console.error('API ERROR | Notification Settings: User not permitted');
                reject();
            }

            axios
                .get(NOTIFICATION_SETTINGS_URL, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Notification Settings: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static getNotificationSettingById(notificationSettingId) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['USER_MANAGE_USER_READ'])) {
                console.error('API ERROR | Notification Settings: User not permitted');
                reject();
            }

            axios
                .get(`${NOTIFICATION_SETTINGS_URL}/${notificationSettingId}`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Notification Settings: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static getNotificationSettingsByUser(userId) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['USER_MANAGE_USER_READ'])) {
                console.error('API ERROR | Notification Settings: User not permitted');
                reject();
            }

            axios
                .get(`${NOTIFICATION_SETTINGS_URL}/user/${userId}`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Notification Settings: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static updateNotificationSetting(notificationSettingId, isEmailOn, isDesktopOn) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['USER_MANAGE_UPDATE'])) {
                console.error('API ERROR | Notification Settings: User not permitted');
                reject();
            }

            axios
                .patch(
                    NOTIFICATION_SETTINGS_URL,
                    {
                        notificationSettingId,
                        isEmailOn,
                        isDesktopOn,
                    },
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Notification Settings: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static deleteNotificationSetting(notificationSettingId) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['USER_MANAGE_UPDATE'])) {
                console.error('API ERROR | Notification Settings: User not permitted');
                reject();
            }

            axios
                .delete(`${NOTIFICATION_SETTINGS_URL}/${notificationSettingId}`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Notification Settings: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }
}
export default NotificationSettingsApi;
