import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { DEVICE_TYPE } from '../../../store/constants/appActionTypes';

import Breadcrumbs from '../../../component/reusable/Breadcrumbs';
import TableComponent from '../../../component/reusable/TableComponent';

import TdmsApi from '../../../api/dataManagement/tdms';

import { newTdmFailErrorText, tdmUpdateFailErrorText } from '../../../util/longText';
import { archiveTdm, TDM_COLUMNS } from '../../../util/tableColumns';
import { onAPIError, onAPISuccess } from '../../../util/apiUtil';
import { shouldSee } from '../../../util/permissionsUtil';
import TdmModal from '../../../component/dataManagement/TdmModal';

const BREADCRUMB_LINKS = [
    { title: 'Data Management', href: `${(window.location, origin)}/datamanagement` },
    { title: 'TDMs', href: null },
];

const TdmsTable = ({ match }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const deviceType = useSelector((state) => state.app.device);

    const [tdmData, setTdmData] = useState([]);
    const [tdmCount, setTdmCount] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchKey, setSearchKey] = useState('');
    const [showInactive, setShowInactive] = useState(true);

    // MODAL
    const [displayTdmModal, setDisplayTdmModal] = useState(false);
    const [routeName, setRouteName] = useState('');
    const [specialPayEligible, setSpecialPayEligible] = useState(false);
    const [submittingTdm, setSubmittingTdm] = useState(false);
    const [editingTdmId, setEditingTdmId] = useState(null);

    useEffect(() => {
        /* On mount check if navigated here from data management page via "new"
           button click (new in the path params). If so open create modal and
           remove "new" from URL */
        if (match.params.id === 'new') {
            setDisplayTdmModal(true);
            const currentUrl = window.location.href;
            window.history.replaceState(
                null,
                '',
                `${currentUrl.substring(0, currentUrl.length - 4)}`
            );
        }
    }, []);

    useEffect(() => {
        getTdmData();
    }, [searchKey, showInactive, currentPage]);

    useEffect(() => {
        // Reset page selection on show inactive toggle (causes errors otherwise)
        setCurrentPage(1);
    }, [showInactive]);

    const getTdmData = async () => {
        // Amount of recrods to be skipped based on page number, 0 if searching for something
        const skip = searchKey.length ? 0 : (currentPage - 1) * 10;
        const tdms = await TdmsApi.getTdms(searchKey, showInactive, skip);

        setTdmCount(tdms.count);
        setTdmData([...tdms.data]);
    };

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const getMobileData = () => {
        const filteredData = [...tdmData];
        const mobileData = [];
        filteredData.forEach((record) => {
            mobileData.push({
                id: record.tdmId,
                title: record.routeName,
                subtitle: record.specialPayEligible
                    ? 'Special Pay Eligible'
                    : 'Not Special Pay Eligible',
                active: record.active,
            });
        });
        return mobileData;
    };

    const submitAddNewTdm = () => {
        setSubmittingTdm(true);

        TdmsApi.createTdm({
            routeName,
            specialPayEligible,
            active: true,
        })
            .then(() => {
                setSubmittingTdm(false);
                setRouteName('');
                setSpecialPayEligible(false);
                setDisplayTdmModal(false);

                onAPISuccess(dispatch, 'New TDM added successfully.');
                getTdmData();
            })
            .catch(() => {
                setSubmittingTdm(false);

                onAPIError(dispatch, newTdmFailErrorText);
            });
    };

    const submitUpdateTdm = () => {
        setSubmittingTdm(true);

        TdmsApi.updateTdm({
            tdmId: editingTdmId,
            routeName,
            specialPayEligible,
            active: true,
        })
            .then(() => {
                setSubmittingTdm(false);
                setRouteName('');
                setSpecialPayEligible(false);
                setDisplayTdmModal(false);
                setEditingTdmId(null);

                onAPISuccess(dispatch, 'TDM updated successfully.');
                getTdmData();
            })
            .catch(() => {
                setSubmittingTdm(false);

                onAPIError(dispatch, tdmUpdateFailErrorText);
            });
    };

    const handleRecordClick = (recordId) => {
        setEditingTdmId(recordId);
        setRouteName(tdmData.find((tdm) => tdm.tdmId === recordId).routeName);
        setSpecialPayEligible(tdmData.find((tdm) => tdm.tdmId === recordId).specialPayEligible);
        setDisplayTdmModal(true);
    };

    const handleMobileArchiveClick = (recordId) => {
        archiveTdm(dispatch, history, recordId);
    };

    return (
        <div
            className={`tdms-table__container${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
        >
            <Breadcrumbs links={BREADCRUMB_LINKS} />

            {/* TABLE */}
            <TableComponent
                rightButtonText={shouldSee(['TDM_CREATE']) ? 'ADD TDM' : null}
                rightButtonAction={() => setDisplayTdmModal(true)}
                searchValue={searchKey}
                searchOnChange={setSearchKey}
                searchPlaceholder="Search by route name"
                switchValue={showInactive}
                switchOnChange={setShowInactive}
                switchHelpText="Show Inactive"
                tableColumns={TDM_COLUMNS}
                tableData={tdmData}
                mobileListData={getMobileData()}
                currentPage={currentPage}
                onPageChange={onPageChange}
                totalPageCount={tdmCount}
                onRowClick={(record) =>
                    record.active && shouldSee(['TDM_UPDATE'])
                        ? record.tdmId
                            ? handleRecordClick(record.tdmId)
                            : handleRecordClick(record.id)
                        : null
                }
                onMobileActionClick={
                    shouldSee(['TDM_DELETE'])
                        ? (record) => handleMobileArchiveClick(record.id)
                        : null
                }
            />

            {/* ADD/VIEW/EDIT MODAL */}
            <TdmModal
                editingTdmId={editingTdmId}
                displayTdmModal={displayTdmModal}
                submitAddNewTdm={submitAddNewTdm}
                submitUpdateTdm={submitUpdateTdm}
                onCancel={() => {
                    setRouteName('');
                    setSpecialPayEligible(false);
                    setDisplayTdmModal(false);
                    setEditingTdmId(null);
                }}
                submittingTdm={submittingTdm}
                routeName={routeName}
                setRouteName={setRouteName}
                specialPayEligible={specialPayEligible}
                setSpecialPayEligible={setSpecialPayEligible}
            />
        </div>
    );
};
export default TdmsTable;
