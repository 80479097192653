import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';
import TrucksApi from '../../api/dataEntry/trucks';

import PayTypesApi from '../../api/rateProfiles/payTypes';
import ServiceTypesApi from '../../api/rateProfiles/serviceTypes';

import { APP_ACTION_TYPES, DEVICE_TYPE } from '../../store/constants/appActionTypes';
import { onAPIError, onAPISuccess } from '../../util/apiUtil';
import {
    customerRateFetchDataErrorText,
    driverRateFetchDataErrorText,
    invoiceSaveErrorText,
    invoiceSaveSuccessText,
    invoicesFetchErrorText,
    invoiceUpdateSuccessText,
} from '../../util/longText';
import { displaySimpleErrorModal } from '../global/ModalController';
import Navigation from './Navigation';
import SingleInvoiceForm from './SingleInvoiceForm';

const DEFAULT_INVOICE = {
    invoiceId: '',
    truckId: '',
    invoiceReference: '',
    customerName: '',
    customerAddress: '',
    billToCustomerId: null,
    revenueCollected: '',
    specialService: '',
    incompleteReason: '',
    invoiceStatus: '',
    invoiceServices: [],
    invoiceLineHaulStatus: 'n/a',
};

const InvoicesForm = ({ truckId, truckStatus, customerRecords, userHasEditAccess }) => {
    const dispatch = useDispatch();
    const deviceType = useSelector((state) => state.app.device);
    const editingInvoice = useSelector((state) => state.app.editingInvoice);

    const [invoiceData, setInvoiceData] = useState({ ...DEFAULT_INVOICE, truckId });
    const [invoices, setInvoices] = useState([]);
    const [payTypes, setPayTypes] = useState([]);
    const [serviceTypes, setServiceTypes] = useState([]);
    const [selectedInvoiceIndex, setSelectedInvoiceIndex] = useState(null);
    const [isInvoiceComplete, setIsInvoiceComplete] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        fetchPayTypeServiceTypeData();
        fetchInvoiceData();
    }, []);

    // Invoice validation
    useEffect(() => {
        if (selectedInvoiceIndex === null || isEditing) {
            validateInvoice();
        }
    }, [invoiceData, isEditing]);

    /* On truck navigation interaction (if editingInvoice exists
       meaning user redirected here from invoices or payroll page so ignore flow) */
    useEffect(() => {
        if (editingInvoice === null) {
            // Reset fields
            setSelectedInvoiceIndex(null);

            // Refetch invoices for current truck
            setInvoices([]);
            fetchInvoiceData();
        }
    }, [truckId]);

    /* Populate form on invoice navigation change, unless editingInvoice
       exists meaning user redirected here from invoices or payroll page */
    useEffect(() => {
        if (editingInvoice === null) {
            if (selectedInvoiceIndex !== null) {
                setFormDataToMatchSelectedInvoice();
            } else {
                // Reset invoice fields
                populateInvoice();
                setIsInvoiceComplete(false);
            }
            // Reset edit flag
            setIsEditing(false);
        }
    }, [selectedInvoiceIndex]);

    useEffect(() => {
        /* If redirected from invoices or payroll page, store will hold details
           of invoice which the user wants to edit */
        if (editingInvoice) {
            setPreselectedInvoiceRecord();
        }
    }, [editingInvoice]);

    const populateInvoice = (data) => {
        const latestInvoice = getLatestInvoiceFromTruck(data);
        if (latestInvoice) {
            setInvoiceData({
                ...DEFAULT_INVOICE,
                invoiceLineHaulStatus: latestInvoice.invoiceLineHaulStatus,
                truckId,
            });
        } else {
            setInvoiceData({ ...DEFAULT_INVOICE, truckId });
        }
    };

    const setPreselectedInvoiceRecord = () => {
        TrucksApi.getInvoices(0, 10000, editingInvoice.truckId)
            .then(({ data }) => {
                /* If redirected here from payroll page, we don't care which invoice is displayed
                   so ignore preselecting invoice flow, otherwise pre-select the invoice record of
                   the invoice which the user clicked to edit */
                if (editingInvoice.invoiceId) {
                    const invoiceIndex =
                        data.findIndex((record) => record.invoiceId === editingInvoice.invoiceId) >=
                        0
                            ? data.findIndex(
                                  (record) => record.invoiceId === editingInvoice.invoiceId
                              )
                            : null;
                    if (invoiceIndex !== null) {
                        setInvoices([...data]);
                        setSelectedInvoiceIndex(invoiceIndex);
                        const selectedInvoice = { ...data[invoiceIndex] };
                        const { _createdTs, _updatedTs, ...requiredInvoiceData } = {
                            ...selectedInvoice,
                        };

                        setInvoiceData(requiredInvoiceData);
                        setIsEditing(true);
                    }
                } else {
                    setInvoices([...data]);
                }
            })
            .catch(() => displaySimpleErrorModal(dispatch, invoicesFetchErrorText));

        // Change store editingInvoice flag back to default after form is populated
        setTimeout(() => {
            dispatch({ type: APP_ACTION_TYPES.SET_EDITING_INVOICE, payload: null });
        }, 2500);
    };
    const setFormDataToMatchSelectedInvoice = () => {
        const selectedInvoice = { ...invoices[selectedInvoiceIndex] };
        const { _createdTs, _updatedTs, ...requiredInvoiceData } = { ...selectedInvoice };
        setInvoiceData(requiredInvoiceData);
    };

    const resetInvoiceServices = () => {
        // Invoices services not resetting properly when cancel edit is clicked,
        // manually resetting them from fresh fetch
        TrucksApi.getInvoiceById(invoices[selectedInvoiceIndex].invoiceId)
            .then((fetchedInvoiceData) => {
                setInvoiceData({
                    ...invoiceData,
                    invoiceServices: [...fetchedInvoiceData.invoiceServices],
                });

                const stateInvoices = [...invoices];
                stateInvoices[selectedInvoiceIndex].invoiceServices = [
                    ...fetchedInvoiceData.invoiceServices,
                ];
                setInvoices(stateInvoices);
            })
            .catch(() => displaySimpleErrorModal(dispatch, invoicesFetchErrorText));
    };

    const fetchPayTypeServiceTypeData = () => {
        PayTypesApi.getPayTypes('', false, 0, 10000)
            .then(({ data }) => {
                setPayTypes(data);
            })
            .catch(() => displaySimpleErrorModal(dispatch, driverRateFetchDataErrorText));

        ServiceTypesApi.getServiceTypes('', false, 0, 10000)
            .then(({ data }) => {
                setServiceTypes(data);
            })
            .catch(() => displaySimpleErrorModal(dispatch, customerRateFetchDataErrorText));
    };

    const fetchInvoiceData = (afterUpdate = false) => {
        if (truckId) {
            TrucksApi.getInvoices(0, 100000, truckId)
                .then(({ data }) => {
                    setInvoices([...data]);

                    /* Don't populate invoice after update, it should already
                       have the correct field values */
                    if (!afterUpdate) {
                        populateInvoice(data);
                    }
                })
                .catch(() => displaySimpleErrorModal(dispatch, invoicesFetchErrorText));
        } else {
            setInvoices([]);
        }
    };

    const getLatestInvoiceFromTruck = (data) => {
        let latestInvoice;
        if (data) {
            latestInvoice = data.find(
                (invoice) => invoice.invoiceId === Math.max(...data.map((inv) => inv.invoiceId))
            );
        } else if (invoices) {
            latestInvoice = invoices.find(
                (invoice) => invoice.invoiceId === Math.max(...invoices.map((inv) => inv.invoiceId))
            );
        }
        return latestInvoice;
    };

    const updateInvoiceServices = (serviceValues, type) => {
        let updatePayTypes = invoiceData.invoiceServices.filter(
            (service) => service.type === 'pay_type'
        );
        let updateServiceTypes = invoiceData.invoiceServices.filter(
            (service) => service.type === 'service_type'
        );

        if (type === 'pay_type') {
            // Iterate through all values in the pay/service type select to
            // add/remove entries
            updatePayTypes = serviceValues.map((value) => {
                // Get the ID of the pay type to add from all available pay types
                const serviceOrPayTypeId = payTypes.find(
                    (payType) => payType.label === value
                ).payTypeId;

                // Check if the pay type in current iteration already exists in
                // invoice's services (used for quantity). If editing an invoice,
                // the pay type will have a label so check for it
                const existingInvoiceService = updatePayTypes.find((type) =>
                    type.label
                        ? type.label === value
                        : type.serviceOrPayTypeId === serviceOrPayTypeId
                );

                return {
                    type: 'pay_type',
                    serviceOrPayTypeId,
                    quantity: existingInvoiceService ? existingInvoiceService.quantity : '',
                };
            });
        } else if (type === 'service_type') {
            // Iterate through all values in the pay/service type select to
            // add/remove entries
            updateServiceTypes = serviceValues.map((value) => {
                // Get the ID of the service type to add from all available service types
                const serviceOrPayTypeId = serviceTypes.find(
                    (serviceType) => serviceType.label === value
                ).serviceTypeId;

                // Check if the service type in current iteration already exists in
                // invoice's services (used for quantity). If editing an invoice,
                // the pay type will have a label so check for it
                const existingInvoiceService = updateServiceTypes.find((type) =>
                    type.label
                        ? type.label === value
                        : type.serviceOrPayTypeId === serviceOrPayTypeId
                );

                return {
                    type: 'service_type',
                    serviceOrPayTypeId,
                    quantity: existingInvoiceService ? existingInvoiceService.quantity : '',
                };
            });
        }

        setInvoiceData({
            ...invoiceData,
            invoiceServices: [...updatePayTypes, ...updateServiceTypes],
        });
    };

    const updateInvoiceServiceValue = (value, serviceId, type) => {
        const serviceToUpdateIndex = invoiceData.invoiceServices.findIndex(
            (service) => service.serviceOrPayTypeId === serviceId && service.type === type
        );
        const allServices = [...invoiceData.invoiceServices];
        allServices[serviceToUpdateIndex].quantity = value;

        setInvoiceData({
            ...invoiceData,
            invoiceServices: allServices,
        });
    };

    const validateInvoice = () => {
        // Single field validations
        if (
            invoiceData.invoiceReference.length < 1 ||
            invoiceData.billToCustomerId === null ||
            !validator.isCurrency(invoiceData.revenueCollected)
        ) {
            setIsInvoiceComplete(false);

            return;
        }

        // Services validation
        for (let i = 0; i < invoiceData.invoiceServices.length; i++) {
            // Check if value exists
            if (invoiceData.invoiceServices[i].quantity.length < 1) {
                setIsInvoiceComplete(false);

                return;
            }

            // Check against data type (if editing existing invoice, each service will have a label
            // so get the pay/service type based on the label)
            const typeDetails =
                invoiceData.invoiceServices[i].type === 'pay_type'
                    ? payTypes.find((type) =>
                          invoiceData.invoiceServices[i].label
                              ? type.label === invoiceData.invoiceServices[i].label
                              : type.payTypeId === invoiceData.invoiceServices[i].serviceOrPayTypeId
                      )
                    : serviceTypes.find((type) =>
                          invoiceData.invoiceServices[i].label
                              ? type.label === invoiceData.invoiceServices[i].label
                              : type.serviceTypeId ===
                                invoiceData.invoiceServices[i].serviceOrPayTypeId
                      );
            const isDollarType = typeDetails && typeDetails.dataType === 'dollar_number';
            if (isDollarType && !validator.isCurrency(invoiceData.invoiceServices[i].quantity)) {
                setIsInvoiceComplete(false);

                return;
            }
        }

        setIsInvoiceComplete(true);
    };

    const submitCreateInvoice = () => {
        setIsSubmitting(true);

        // Remove properties not required by the API
        const { invoiceId, invoiceServices, invoiceStatus, ...submitInvoiceData } = {
            ...invoiceData,
        };

        submitInvoiceData.services = [...invoiceServices];
        submitInvoiceData.billToCustomerId = parseInt(submitInvoiceData.billToCustomerId, 10);
        if (!submitInvoiceData.specialService || !submitInvoiceData.specialService.length) {
            delete submitInvoiceData.specialService;
        }
        if (!submitInvoiceData.incompleteReason || !submitInvoiceData.incompleteReason.length) {
            delete submitInvoiceData.incompleteReason;
        }

        const billToCustomer = customerRecords.find(
            (record) => record.customerId.toString() === invoiceData.billToCustomerId
        ).contactInfo;
        submitInvoiceData.customerAddress = `${billToCustomer.addressLineOne} ${billToCustomer.addressLineTwo} ${billToCustomer.city} ${billToCustomer.province} ${billToCustomer.postalCode} ${billToCustomer.province} ${billToCustomer.country}`;
        submitInvoiceData.customerName = `${billToCustomer.firstName} ${billToCustomer.lastName}`;

        TrucksApi.createInvoice(submitInvoiceData)
            .then(() => {
                setIsSubmitting(false);
                populateInvoice();
                onAPISuccess(dispatch, invoiceSaveSuccessText);
                fetchInvoiceData();
            })
            .catch(() => {
                setIsSubmitting(false);
                onAPIError(dispatch, invoiceSaveErrorText);
            });
    };

    const submitEditInvoice = () => {
        setIsSubmitting(true);

        // Remove properties not required by the API
        const { createdTs, updatedTs, invoiceServices, invoiceStatus, ...submitInvoiceData } = {
            ...invoiceData,
        };

        if (!submitInvoiceData.specialService || !submitInvoiceData.specialService.length) {
            delete submitInvoiceData.specialService;
        }
        if (!submitInvoiceData.incompleteReason || !submitInvoiceData.incompleteReason.length) {
            delete submitInvoiceData.incompleteReason;
        }

        // If service already existed on the invoice, it needs to be transformed to
        // the template of a new service
        submitInvoiceData.services = invoiceServices.map((service) => {
            if (service.createdTs) {
                return {
                    type: service.type,
                    serviceOrPayTypeId:
                        service.type === 'pay_type'
                            ? payTypes.find((type) => type.label === service.label).payTypeId
                            : serviceTypes.find((type) => type.label === service.label)
                                  .serviceTypeId,
                    quantity: service.quantity,
                };
            } else {
                return { ...service };
            }
        });
        submitInvoiceData.billToCustomerId = parseInt(submitInvoiceData.billToCustomerId, 10);
        const billToCustomer = customerRecords.find(
            (record) => record.customerId.toString() === invoiceData.billToCustomerId.toString()
        ).contactInfo;
        submitInvoiceData.customerAddress = `${billToCustomer.addressLineOne} ${billToCustomer.addressLineTwo} ${billToCustomer.city} ${billToCustomer.province} ${billToCustomer.postalCode} ${billToCustomer.province} ${billToCustomer.country}`;
        submitInvoiceData.customerName = `${billToCustomer.firstName} ${billToCustomer.lastName}`;

        TrucksApi.updateInvoice(submitInvoiceData)
            .then(() => {
                setIsSubmitting(false);
                setIsEditing(false);
                onAPISuccess(dispatch, invoiceUpdateSuccessText);
                fetchInvoiceData(true);
            })
            .catch(() => {
                setIsSubmitting(false);
                onAPIError(dispatch, invoiceSaveErrorText);
            });
    };

    return (
        <div className="invoices-form__container">
            {/* FORM HEADING */}
            {deviceType === DEVICE_TYPE.NOT_MOBILE ? (
                <div className="form-header">Invoices</div>
            ) : (
                <div className="input-label" style={{ fontSize: 30 }}>
                    Invoices
                </div>
            )}

            {truckId ? (
                <>
                    <Navigation
                        records={invoices.map((invoice) => ({
                            recordId: invoice.invoiceId,
                            name: invoice.invoiceReference,
                        }))}
                        selectedRecord={selectedInvoiceIndex}
                        setSelectedRecord={setSelectedInvoiceIndex}
                        deviceType={deviceType}
                    />
                    <SingleInvoiceForm
                        deviceType={deviceType}
                        invoiceData={invoiceData}
                        customerRecords={customerRecords}
                        setInvoiceValue={(newFieldValue) =>
                            setInvoiceData({ ...invoiceData, ...newFieldValue })
                        }
                        updateInvoiceServices={updateInvoiceServices}
                        updateInvoiceServiceValue={updateInvoiceServiceValue}
                        payTypes={payTypes}
                        serviceTypes={serviceTypes}
                        isInvoiceComplete={isInvoiceComplete}
                        submitCreateInvoice={submitCreateInvoice}
                        isSubmitting={isSubmitting}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        selectedInvoiceIndex={selectedInvoiceIndex}
                        setFormDataToMatchSelectedInvoice={setFormDataToMatchSelectedInvoice}
                        resetInvoiceServices={resetInvoiceServices}
                        submitEditInvoice={submitEditInvoice}
                        userHasEditAccess={userHasEditAccess}
                        truckStatus={truckStatus}
                    />
                </>
            ) : (
                <div
                    className={`heading--smaller invoices-form__info__container${
                        deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                    }`}
                    style={{ fontStyle: 'italic' }}
                >
                    {' '}
                    Save new or select existing truck to view invoices.
                </div>
            )}
        </div>
    );
};
export default InvoicesForm;
