import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Breadcrumbs from '../../../component/reusable/Breadcrumbs';
import { DEVICE_TYPE } from '../../../store/constants/appActionTypes';
import { archiveCustomer, CUSTOMER_COLUMNS } from '../../../util/tableColumns';
import TableComponent from '../../../component/reusable/TableComponent';
import CustomersApi from '../../../api/dataManagement/customers';
import { shouldSee } from '../../../util/permissionsUtil';
import RatesView from '../../../component/dataManagement/RatesView';

const BREADCRUMB_LINKS = [
    { title: 'Data Management', href: `${(window.location, origin)}/datamanagement` },
    { title: 'Customers', href: null },
];

const CustomersTable = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const deviceType = useSelector((state) => state.app.device);

    const [customerData, setCustomerData] = useState([]);
    const [customerCount, setCustomerCount] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchKey, setSearchKey] = useState('');
    const [showInactive, setShowInactive] = useState(true);

    useEffect(() => {
        getCustomerData();
    }, [searchKey, showInactive, currentPage]);

    useEffect(() => {
        // Reset page selection on show inactive toggle (causes errors otherwise)
        setCurrentPage(1);
    }, [showInactive]);

    const getCustomerData = async () => {
        // Amount of recrods to be skipped based on page number, 0 if searching for something
        const skip = searchKey.length ? 0 : (currentPage - 1) * 10;
        const customers = await CustomersApi.getCustomers(searchKey, showInactive, skip);

        setCustomerCount(customers.count);
        setCustomerData([...customers.data]);
    };

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const goToCustomerRecord = (customerId) => {
        history.push(`/customers/${customerId}`);
    };

    const getMobileListData = () => {
        const filteredData = [...customerData];
        const mobileData = [];

        filteredData.forEach((record) => {
            mobileData.push({
                id: record.customerId,
                title:
                    record.contactInfo.firstName && record.contactInfo.lastName
                        ? `${record.contactInfo.firstName} ${record.contactInfo.lastName}`
                        : '',
                subtitle: `${record.orgName || ''} | ${record.contactInfo.phoneNumber || ''}`,
                active: record.active,
            });
        });

        return mobileData;
    };

    const handleMobileArchiveClick = (recordId) => {
        archiveCustomer(dispatch, history, recordId);
    };

    return (
        <div
            className={`customers-table__container${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
        >
            <Breadcrumbs links={BREADCRUMB_LINKS} />

            {/* TABLE */}
            <TableComponent
                rightButtonText={shouldSee(['CUSTOMER_CREATE']) ? 'ADD CUSTOMER' : null}
                rightButtonAction={() => history.push('/customers/new')}
                searchValue={searchKey}
                searchOnChange={setSearchKey}
                searchPlaceholder="Search by organization"
                switchValue={showInactive}
                switchOnChange={setShowInactive}
                switchHelpText="Show Inactive"
                tableColumns={CUSTOMER_COLUMNS}
                tableData={customerData}
                mobileListData={getMobileListData()}
                currentPage={currentPage}
                onPageChange={onPageChange}
                totalPageCount={customerCount}
                onRowClick={(record) =>
                    record.customerId
                        ? goToCustomerRecord(record.customerId)
                        : goToCustomerRecord(record.id)
                }
                onMobileActionClick={
                    shouldSee(['CUSTOMER_DELETE'])
                        ? (record) => handleMobileArchiveClick(record.id)
                        : null
                }
            />

            {/* RATE VIEW MODAL */}
            <RatesView />
        </div>
    );
};
export default CustomersTable;
