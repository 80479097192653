import axios from 'axios';
import { getAuthHeader, getErrorMessage, getHost } from '../../util/apiUtil';
import { shouldSee } from '../../util/permissionsUtil';

const USERS_URL = `${getHost()}/account/user`;
const EMAIL_CHANGE_URL = `${getHost()}/account/user/update/email`;

class UsersApi {
    static getUsers(email = '', includeInactive = true, skip = 0, take = 10) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['USER_MANAGE_USER_READ_MANY'])) {
                console.error('API ERROR |Users: User not permitted');
                reject();
            }

            let queriedUrl = `${USERS_URL}?take=${take}&skip=${skip}&includeInactive=${includeInactive}`;
            if (email.length) {
                queriedUrl += `&emailLike=${email}`;
            }

            axios
                .get(queriedUrl, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Users: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static createUserAccount(signUpDetails) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${USERS_URL}`,
                    { ...signUpDetails },
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Users: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static updateUserAccount(userData) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['USER_MANAGE_UPDATE'])) {
                console.error('API ERROR |Users: User not permitted');
                reject();
            }

            axios
                .patch(`${USERS_URL}/update`, { ...userData }, { headers: getAuthHeader() })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Users: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static archiveUserAccount(userId) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['USER_MANAGE_DELETE'])) {
                console.error('API ERROR |Users: User not permitted');
                reject();
            }

            axios
                .delete(`${USERS_URL}/${userId}`, { headers: getAuthHeader() })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Users: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static createUserInvite(newUserData) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['USER_MANAGE_INVITE'])) {
                console.error('API ERROR |Users: User not permitted');
                reject();
            }

            axios
                .post(
                    `${USERS_URL}/invite`,
                    { ...newUserData },
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Users: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static getInviteDetails(inviteToken) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${USERS_URL}/invite/${inviteToken}`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Users: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static changeEmail(newEmail) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['USER_MANAGE_UPDATE_EMAIL'])) {
                console.error('API ERROR |Users: User not permitted');
                reject();
            }

            axios
                .post(
                    `${EMAIL_CHANGE_URL}`,
                    {
                        email: newEmail,
                    },
                    { headers: getAuthHeader() }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Users: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }

    static confirmChangeEmail(confirmToken) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['USER_MANAGE_UPDATE_EMAIL'])) {
                console.error('API ERROR |Users: User not permitted');
                reject();
            }

            axios
                .get(`${EMAIL_CHANGE_URL}/${confirmToken}`, { headers: getAuthHeader() })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Users: ${getErrorMessage(error)}`);
                    reject(getErrorMessage(error));
                });
        });
    }
}
export default UsersApi;
