import { Modal } from 'antd';
import { InfoModalHeader } from '../../global/ModalController';

const GenerateReportModal = ({
    title,
    visible,
    onOkAction,
    onCancelAction,
    confirmLoading,
    okButtonEnabled,
    content,
    hideButtons = false,
}) => {
    return hideButtons ? (
        <Modal
            title={InfoModalHeader(title)}
            visible={visible}
            footer={null}
            closable={false}
            keyboard={false}
            maskClosable
            onCancel={onCancelAction}
        >
            {content}
        </Modal>
    ) : (
        <Modal
            title={InfoModalHeader(title)}
            visible={visible}
            onOk={onOkAction}
            onCancel={onCancelAction}
            closable={false}
            keyboard={false}
            maskClosable={false}
            confirmLoading={confirmLoading}
            okButtonProps={okButtonEnabled ? {} : { disabled: true }}
            cancelButtonProps={confirmLoading ? { disabled: true } : {}}
        >
            {content}
        </Modal>
    );
};
export default GenerateReportModal;
