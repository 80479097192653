/* eslint-disable react/jsx-indent */
import { Select, Switch } from 'antd';
import { useSelector } from 'react-redux';
import validator from 'validator';
import AddressForm from '../../../component/reusable/AddressForm';

import LabelledInput from '../../../component/reusable/LabelledInput';

import { DEVICE_TYPE } from '../../../store/constants/appActionTypes';

import { beautifyTextValue, TITLE_OPTIONS } from '../../../util/formUtil';

const { Option } = Select;

const DriverForm = ({ formData, driverRates, setFormData, isEditing, formStyle }) => {
    const deviceType = useSelector((state) => state.app.device);

    const extractAddressData = () => {
        const addressData = {
            addressLineOne: formData.contactInfo.addressLineOne,
            addressLineTwo: formData.contactInfo.addressLineTwo,
            city: formData.contactInfo.city,
            province: formData.contactInfo.province,
            country: formData.contactInfo.country,
            postalCode: formData.contactInfo.postalCode,
        };

        return addressData;
    };

    return (
        <div className="driver-form__container">
            {/* FORM HEADING (NON-MOBILE) */}
            {deviceType === DEVICE_TYPE.NOT_MOBILE ? (
                <div className="form-header">Driver</div>
            ) : null}

            {/* NAME SECTION */}
            <div className={`driver-form__name-section${formStyle}`}>
                <div>
                    <div className="input-label" style={{ marginTop: 25 }}>
                        Title
                    </div>
                    <Select
                        size="large"
                        value={beautifyTextValue(formData.title)}
                        style={formStyle.includes('mobile') ? { width: '100%' } : { width: 120 }}
                        onChange={(value) => setFormData({ ...formData, title: value })}
                        disabled={!isEditing}
                    >
                        {TITLE_OPTIONS.map((opt) => (
                            <Option key={`${opt}-formtitle`} value={opt}>
                                {beautifyTextValue(opt)}
                            </Option>
                        ))}
                    </Select>
                </div>

                <LabelledInput
                    labelText="First Name"
                    value={formData.contactInfo.firstName}
                    onChangeAction={(value) =>
                        setFormData({
                            ...formData,
                            contactInfo: { ...formData.contactInfo, firstName: value },
                        })
                    }
                    withValidation={isEditing}
                    isValid={formData.contactInfo.firstName.length > 1}
                    validationText="Enter a valid name"
                    customWidth={formStyle.includes('mobile') ? '100%' : '300px'}
                    isDisabled={!isEditing}
                />
                <LabelledInput
                    labelText="Last Name"
                    value={formData.contactInfo.lastName}
                    onChangeAction={(value) =>
                        setFormData({
                            ...formData,
                            contactInfo: { ...formData.contactInfo, lastName: value },
                        })
                    }
                    withValidation={isEditing}
                    isValid={formData.contactInfo.lastName.length > 1}
                    validationText="Enter a valid name"
                    customWidth={formStyle.includes('mobile') ? '100%' : '300px'}
                    isDisabled={!isEditing}
                />
                <LabelledInput
                    labelText="Nickname"
                    value={formData.nickname}
                    onChangeAction={(value) => setFormData({ ...formData, nickname: value })}
                    customWidth={formStyle.includes('mobile') ? '100%' : '300px'}
                    isDisabled={!isEditing}
                    withValidation={isEditing}
                    isValid={formData.nickname.length > 1}
                    validationText="Enter a valid nickname"
                />
            </div>

            {/* PHONE SECTION */}
            <div className={`driver-form__phone-section${formStyle}`}>
                <LabelledInput
                    labelText="Phone #"
                    value={formData.contactInfo.phoneNumber}
                    onChangeAction={(value) =>
                        setFormData({
                            ...formData,
                            contactInfo: { ...formData.contactInfo, phoneNumber: value },
                        })
                    }
                    customWidth={formStyle.includes('mobile') ? '100%' : '30%'}
                    isDisabled={!isEditing}
                />

                <LabelledInput
                    labelText="Work Phone #"
                    value={formData.workPhoneNumber}
                    onChangeAction={(value) => setFormData({ ...formData, workPhoneNumber: value })}
                    customWidth={formStyle.includes('mobile') ? '100%' : '30%'}
                    isDisabled={!isEditing}
                    withValidation={isEditing}
                    isValid={validator.isMobilePhone(formData.workPhoneNumber, ['en-CA', 'en-US'], {
                        strictMode: true,
                    })}
                    validationText="Enter a valid phone number"
                />

                <LabelledInput
                    labelText="Email"
                    value={formData.email}
                    onChangeAction={(value) => setFormData({ ...formData, email: value })}
                    customWidth={formStyle.includes('mobile') ? '100%' : '30%'}
                    isDisabled={!isEditing}
                    withValidation={isEditing}
                    isValid={formData.email ? validator.isEmail(formData.email) : false}
                    validationText="Enter a valid email"
                />
            </div>

            {/* ADDRESS */}
            <AddressForm
                formData={extractAddressData()}
                setFieldValue={(newFieldValue) =>
                    setFormData({
                        ...formData,
                        contactInfo: { ...formData.contactInfo, ...newFieldValue },
                    })
                }
                formStyle={formStyle}
                isEditing={isEditing}
            />

            {/* CONFIG SECTION */}
            <div className={`driver-form__config-section${formStyle}`}>
                <div className={`driver-form__switch__container${formStyle}`}>
                    <div className="input-label" style={{ marginRight: 10 }}>
                        Active
                    </div>
                    <Switch
                        checkedChildren="YES"
                        unCheckedChildren="NO"
                        checked={formData.active}
                        onChange={(checked) => setFormData({ ...formData, active: checked })}
                        disabled={!isEditing}
                    />
                </div>

                <div>
                    <div className="input-label" style={{ marginTop: 25 }}>
                        Rate Profile
                    </div>
                    <Select
                        size="large"
                        placeholder="Select one"
                        value={formData.rateProfile ? formData.rateProfile.profileName : null}
                        style={formStyle.includes('mobile') ? { width: '100%' } : { width: 250 }}
                        status={formData.rateProfile ? null : 'error'}
                        onChange={(value) => {
                            const rateId = value.split('_')[1];
                            const newRate = driverRates.find(
                                (rate) =>
                                    rate.driverRateProfile.driverRateProfileId.toString() === rateId
                            );
                            setFormData({
                                ...formData,
                                rateProfile: { ...newRate.driverRateProfile },
                            });
                        }}
                        disabled={!isEditing}
                    >
                        {driverRates.map((rate) => (
                            <Option
                                key={`driver-rate-type_${rate.driverRateProfile.driverRateProfileId}`}
                            >
                                {rate.driverRateProfile.profileName}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div>
        </div>
    );
};
export default DriverForm;
