import axios from 'axios';
import { getAuthHeader, getErrorMessage, getHost } from '../../util/apiUtil';
import { shouldSee } from '../../util/permissionsUtil';

const CUSTOMER_RATES_URL = `${getHost()}/rates/customer`;

class CustomerRatesApi {
    static getCustomerRates(profileNameLike = '', includeInactive = true, skip = 0, take = 10) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['CRP_READ_MANY'])) {
                console.error('API ERROR | Customer Rates: User not permitted');
                reject();
            }

            let queriedUrl = `${CUSTOMER_RATES_URL}?take=${take}&skip=${skip}&includeInactive=${includeInactive}`;
            if (profileNameLike.length) {
                queriedUrl += `&profileNameLike=${profileNameLike}`;
            }

            axios
                .get(queriedUrl, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Customer Rates: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static getCustomerRateById(id) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['CRP_READ'])) {
                console.error('API ERROR | Customer Rates: User not permitted');
                reject();
            }

            axios
                .get(`${CUSTOMER_RATES_URL}/${id}`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Customer Rates: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static createCustomerRate(customerRateData) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['CRP_CREATE'])) {
                console.error('API ERROR | Customer Rates: User not permitted');
                reject();
            }

            axios
                .post(
                    `${CUSTOMER_RATES_URL}`,
                    { ...customerRateData },
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Customer Rates: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static updateCustomerRate(customerRateData) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['CRP_UPDATE'])) {
                console.error('API ERROR | Customer Rates: User not permitted');
                reject();
            }

            axios
                .patch(CUSTOMER_RATES_URL, { ...customerRateData }, { headers: getAuthHeader() })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Customer Rates: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static archiveCustomerRate(customerRateId) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['CRP_DELETE'])) {
                console.error('API ERROR | Customer Rates: User not permitted');
                reject();
            }

            axios
                .delete(`${CUSTOMER_RATES_URL}/${customerRateId}`, { headers: getAuthHeader() })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Customer Rates: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }
}
export default CustomerRatesApi;
