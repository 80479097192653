import { useSelector } from 'react-redux';
import { List } from 'antd-mobile';
import { Pagination } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

/** Data passed into this component must be in the following format:
 * {
 *    id: used for key value (string)
 *    title: displayed on the first line of each list item (string)
 *    subtitle: displayed on the second line of each list item (string)
 *    active: used to check if the delete icon should be displayed,
 *            if not provided, nothig will be displayed on the right
 *            side of the record
 *    additionalDetails: extra data
 * }
 */

const MobileList = ({
    data,
    onRowClick,
    currentPage,
    onPageChange,
    recordCount,
    onMobileActionClick,
}) => {
    const loggedInUserId = useSelector((state) => state.app.userData.userId);

    // Used to cancel both onClick events from firing when delete is clicked
    const cancelEventPropagation = (e) => {
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();
    };

    const shouldDisplayDeleteIcon = (recordId) => {
        const pathName = window.location.pathname;

        // If in user manamgement, don't let the user delete their own account
        if (pathName.includes('/users')) {
            return recordId !== loggedInUserId;
        }

        return true;
    };

    return (
        <>
            <List>
                {data && data.length ? (
                    data.map((record) => (
                        <List.Item
                            key={`mobilelist__${record.id}`}
                            description={record.subtitle}
                            extra={
                                record.hasOwnProperty('active') &&
                                shouldDisplayDeleteIcon(record.id) &&
                                onMobileActionClick ? (
                                    record.active ? (
                                        <DeleteOutlined
                                            style={{
                                                fontSize: 20,
                                            }}
                                            onClick={(e) => {
                                                cancelEventPropagation(e);
                                                onMobileActionClick(record);
                                            }}
                                        />
                                    ) : (
                                        'Inactive'
                                    )
                                ) : null
                            }
                            onClick={
                                onRowClick
                                    ? () => {
                                          onRowClick(record);
                                      }
                                    : null
                            }
                            arrow={false}
                        >
                            {record.title}
                        </List.Item>
                    ))
                ) : (
                    <div className="input-label mobile-list__no-record">NO RECORDS FOUND</div>
                )}
            </List>
            {data && data.length && currentPage ? (
                <div className="mobile-list__pagination__container">
                    <Pagination
                        simple
                        current={currentPage}
                        onChange={onPageChange}
                        total={recordCount}
                    />
                </div>
            ) : null}
        </>
    );
};
export default MobileList;
