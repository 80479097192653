import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
    HomeOutlined,
    TableOutlined,
    DiffOutlined,
    DashboardOutlined,
    UsergroupAddOutlined,
    DollarOutlined,
    SettingOutlined,
    MenuOutlined,
} from '@ant-design/icons';
import { GrLock } from 'react-icons/gr';
import { RiLogoutBoxRLine } from 'react-icons/ri';
import { IoNotificationsOutline, IoNotificationsOffOutline } from 'react-icons/io5';
import { Tooltip, Popover, Badge } from 'antd';
import { isMobile } from 'react-device-detect';

import { isLoggedIn, removeCachedToken } from '../../util/authentication';

import logo from '../../images/logo-mini.png';
import { APP_ACTION_TYPES, DEVICE_TYPE } from '../../store/constants/appActionTypes';
import { getPageHeader } from '../../util/locationTitleMatrix';
import {
    NAVIGATION_PERMISSIONS,
    PERMISSION_CONDITION,
    shouldSee,
} from '../../util/permissionsUtil';
import { closeSocket, initSocket } from '../../util/sockets';
import { dismissNotificationErrorText } from '../../util/longText';
import NotificationsApi from '../../api/notifications/notifications';
import Notifications from '../reusable/Notifications';
import { displaySimpleErrorModal } from '../global/ModalController';

const ICON_STYLE = { fontSize: 30, margin: '10px 0', cursor: 'pointer', color: 'white' };
const NAV_ICON_STYLE = {
    fontSize: 25,
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '15px 5px',
};

const Navbar = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const socketServer = useSelector((state) => state.app.socketServer);
    const userData = useSelector((state) => state.app.userData);
    const notifications = useSelector((state) => state.app.notifications);

    const [deviceType] = useState(isMobile ? DEVICE_TYPE.MOBILE : DEVICE_TYPE.NOT_MOBILE);
    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

    const [dismissingNotification, setDismissingNotification] = useState(false);

    // Set device type for the app's views
    useEffect(() => {
        if (isMobile) {
            dispatch({ type: APP_ACTION_TYPES.SET_DEVICE, payload: DEVICE_TYPE.MOBILE });
        } else {
            dispatch({ type: APP_ACTION_TYPES.SET_DEVICE, payload: DEVICE_TYPE.NOT_MOBILE });
        }
    }, []);

    // Handle socket messages
    useEffect(() => {
        if (socketServer) {
            socketServer.on('connect', () => {
                console.log('Connected to socket server');
            });
            socketServer.on('desktopNotification', (data) => {
                newNotificationReceived(data);
            });
            socketServer.on('error', (errMsg) => {
                console.error(`Socket server error: ${errMsg}`);
            });
            socketServer.on('disconnect', () => {
                console.error('Disconnected from socket server');
            });
        }
    }, [socketServer]);

    /* When user logs in, initialize websockets and save the connection in
       redux and fetch notifications */
    useEffect(() => {
        if (isLoggedIn() && !socketServer) {
            const socket = initSocket();
            dispatch({ type: APP_ACTION_TYPES.SET_SOCKET_SERVER, payload: socket });

            fetchUserNotifications();
        }
    }, [isLoggedIn()]);

    useEffect(() => {
        // Forced re-render
    }, [location]);

    const fetchUserNotifications = () => {
        if (userData) {
            const { userId } = userData;
            NotificationsApi.getUserNotifications(userId)
                .then((data) => {
                    dispatch({ type: APP_ACTION_TYPES.SET_NOTIFICATIONS, payload: [...data] });
                })
                .catch(() => {
                    dispatch({ type: APP_ACTION_TYPES.SET_NOTIFICATIONS, payload: null });
                });
        }
    };

    const newNotificationReceived = (notification) => {
        dispatch({
            type: APP_ACTION_TYPES.ADD_NOTIFICATION,
            payload: { ...notification },
        });
    };

    const dismissNotification = (notificationId) => {
        setDismissingNotification(true);

        NotificationsApi.dismissNotification(notificationId)
            .then(() => {
                const updatedNotifications = [...notifications];
                const indexToRemove = notifications.findIndex(
                    (notification) => notification.desktopNotificationId === notificationId
                );
                updatedNotifications.splice(indexToRemove, 1);
                dispatch({
                    type: APP_ACTION_TYPES.SET_NOTIFICATIONS,
                    payload: [...updatedNotifications],
                });

                setDismissingNotification(false);
            })
            .catch(() => {
                displaySimpleErrorModal(dispatch, dismissNotificationErrorText);
                setDismissingNotification(false);
            });
    };

    // Any update to this must also be made to the same component in
    // src>component>layout>Sidebar.js
    const SIDEBAR_COMPONENTS = [
        {
            component: HomeOutlined,
            componentTitle: 'Dashboard',
            onClickAction: () => history.push('/'),
        },
        {
            component: TableOutlined,
            componentTitle: 'Data Entry',
            onClickAction: () => history.push('/dataentry'),
            locked: !shouldSee(NAVIGATION_PERMISSIONS.DATA_ENTRY),
        },
        {
            component: DiffOutlined,
            componentTitle: 'Data Management',
            onClickAction: () => history.push('/datamanagement'),
            locked: !shouldSee(NAVIGATION_PERMISSIONS.DATA_MANAGEMENT, PERMISSION_CONDITION.OR),
        },
        {
            component: DashboardOutlined,
            componentTitle: 'Rate Profiles',
            onClickAction: () => history.push('/rateprofiles'),
            locked: !shouldSee(NAVIGATION_PERMISSIONS.RATE_PROFILES, PERMISSION_CONDITION.OR),
        },
        {
            component: UsergroupAddOutlined,
            componentTitle: 'User Management',
            onClickAction: () => history.push('/users'),
            locked: !shouldSee(NAVIGATION_PERMISSIONS.USER_MANAGEMENT),
        },
        {
            component: DollarOutlined,
            componentTitle: 'Invoices & Payroll',
            onClickAction: () => history.push('/invoices'),
            locked: !shouldSee(NAVIGATION_PERMISSIONS.INVOICES),
        },
    ];

    // Used for non-mobile version
    const NAVBAR_COMPONENTS = [
        {
            component: notifications === null ? IoNotificationsOffOutline : IoNotificationsOutline,
            color: notifications === null ? '#d93c20' : '#fff',
            componentTitle: notifications === null ? 'Error, please refresh' : 'Notifications',
            onClickAction: null,
        },
        {
            component: RiLogoutBoxRLine,
            color: '#fff',
            componentTitle: 'Log Out',
            onClickAction: () => toggleLoggedOut(),
        },
    ];

    const getNavbarComponenets = (element, color, tooltipText, onClickAction) => {
        const icon = React.createElement(element, {
            style: { ...ICON_STYLE, color },
            onClick: onClickAction,
        });
        return (
            <Tooltip title={tooltipText} placement="left">
                {notifications && notifications.length && tooltipText === 'Notifications' ? (
                    <Popover
                        placement="bottomRight"
                        trigger="click"
                        content={
                            <Notifications
                                notifications={notifications}
                                dismissNotification={dismissNotification}
                                dismissingNotification={dismissingNotification}
                            />
                        }
                    >
                        <Badge dot offset={[-8, 15]}>
                            {icon}
                        </Badge>
                    </Popover>
                ) : (
                    icon
                )}
            </Tooltip>
        );
    };

    const toggleLoggedOut = () => {
        dispatch({ type: APP_ACTION_TYPES.SET_USER_DATA, payload: null });
        dispatch({ type: APP_ACTION_TYPES.SET_USER_PERMISSIONS, payload: [] });
        dispatch({ type: APP_ACTION_TYPES.TOGGLE_LOGGED_IN, payload: false });
        dispatch({ type: APP_ACTION_TYPES.SET_SOCKET_SERVER, payload: null });
        removeCachedToken();
        closeSocket();
        history.push('/login');
    };

    const getMobileNavOptions = () => {
        // Nav options taken from the sidebar
        const navigationOptions = [
            ...SIDEBAR_COMPONENTS,
            {
                component: SettingOutlined,
                componentTitle: 'Settings',
                onClickAction: () => history.push('/settings'),
            },
            {
                component: RiLogoutBoxRLine,
                componentTitle: 'Log Out',
                onClickAction: () => toggleLoggedOut(),
            },
        ];

        return (
            <div>
                {navigationOptions.map((comp) => (
                    <div key={`${comp.componentTitle}`}>
                        {getMobileNavComponenet(
                            comp.component,
                            comp.componentTitle,
                            comp.onClickAction,
                            comp.locked
                        )}
                    </div>
                ))}
            </div>
        );
    };

    const getMobileNavComponenet = (element, componentTitle, onClickAction, locked = false) => {
        // Close navbar menu when firing onClick action
        const clickAction = () => {
            setMobileMenuVisible(false);
            onClickAction();
        };

        const icon = React.createElement(element, {
            style: locked ? { ...NAV_ICON_STYLE, color: '#b3b3b3' } : NAV_ICON_STYLE,
            onClick: locked ? null : clickAction,
        });

        return (
            <div className="navbar__navigation__component__container">
                {locked ? (
                    <Badge offset={[0, 10]} count={<GrLock />}>
                        {icon}
                    </Badge>
                ) : (
                    icon
                )}
                <div
                    className="navbar__navigation__component__title"
                    style={locked ? { color: '#b3b3b3' } : {}}
                    onClick={locked ? null : clickAction}
                >
                    {componentTitle}
                </div>
            </div>
        );
    };

    return isLoggedIn() ? (
        <nav className="navbar">
            {/* MOBILE MENU */}
            {deviceType === DEVICE_TYPE.MOBILE ? (
                <Popover
                    placement="bottomRight"
                    trigger="click"
                    content={getMobileNavOptions()}
                    visible={mobileMenuVisible}
                    onVisibleChange={() => setMobileMenuVisible(!mobileMenuVisible)}
                >
                    <MenuOutlined
                        style={{ ...ICON_STYLE, margin: '0 15px 0 15px' }}
                        onClick={() => setMobileMenuVisible(!mobileMenuVisible)}
                    />
                </Popover>
            ) : null}

            {/* LOGO */}
            <div className="navbar__logo__container">
                <a href="/">
                    <img src={logo} alt="logo" style={{ height: '100%' }} />
                </a>
            </div>

            {/* PAGE TITLE (NON-MOBILE) */}
            {deviceType === DEVICE_TYPE.NOT_MOBILE ? (
                <div className="navbar__title">{getPageHeader(location)}</div>
            ) : null}

            {/* NAVBAR ICONS */}
            <div className="navbar__icons__container">
                {NAVBAR_COMPONENTS.map((comp) => {
                    if (deviceType === DEVICE_TYPE.MOBILE && comp.componentTitle === 'Log Out') {
                        return null;
                    }
                    return (
                        <div key={`${comp.componentTitle}`} onClick={comp.clickAction}>
                            {getNavbarComponenets(
                                comp.component,
                                comp.color,
                                comp.componentTitle,
                                comp.onClickAction
                            )}
                        </div>
                    );
                })}
            </div>
        </nav>
    ) : null;
};

export default Navbar;
