import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import {
    CheckCircleFilled,
    ExclamationCircleFilled,
    CloseCircleFilled,
    SettingFilled,
} from '@ant-design/icons';

import { APP_ACTION_TYPES, MODAL_TYPES } from '../../store/constants/appActionTypes';

/** Modal obj structure in redux:
 * {
 *   type!: enum
 *   customTitle: string
 *   content: element
 *   okAction: function
 *   cancelAction: function
 *   hideOkButton: boolean
 *   hideCancelButton: boolean
 * }
 *
 * To show a modal, dispatch a redux action like this:
 * dispatch({
        type: APP_ACTION_TYPES.SET_DISPLAY_MODAL,
        payload: {
            type: MODAL_TYPES.SUCCESS,
            content: <div>This is the content of the modal.</div>,
        },
    })
 */
const ModalController = () => {
    const dispatch = useDispatch();
    const displayModal = useSelector((state) => state.app.displayModal);
    const modalActionLoading = useSelector((state) => state.app.modalActionLoading);

    const cancelAction = () => {
        if (displayModal.cancelAction) displayModal.cancelAction();

        dispatch({
            type: APP_ACTION_TYPES.SET_MODAL_AS_LOADING,
            payload: false,
        });
        dispatch({
            type: APP_ACTION_TYPES.SET_DISPLAY_MODAL,
            payload: null,
        });
    };

    const getModalHeader = () => {
        switch (displayModal.type) {
            case MODAL_TYPES.SUCCESS:
                return SuccessModalHeader();
            case MODAL_TYPES.WARNING:
                return WarningModalHeader();
            case MODAL_TYPES.ERROR:
                return ErrorModalHeader();
            case MODAL_TYPES.INFO:
                return InfoModalHeader(
                    displayModal.customTitle ? displayModal.customTitle : 'Info'
                );
            default:
                return null;
        }
    };

    return displayModal ? (
        <Modal
            title={getModalHeader()}
            visible={displayModal}
            onOk={displayModal.okAction}
            onCancel={cancelAction}
            closable={false}
            keyboard={false}
            confirmLoading={modalActionLoading}
            okButtonProps={displayModal.hideOkButton ? { style: { display: 'none' } } : {}}
            cancelButtonProps={displayModal.hideCancelButton ? { style: { display: 'none' } } : {}}
        >
            {displayModal.content}
        </Modal>
    ) : null;
};
export default ModalController;

// SIMPLE ERROR MODAL WITH OPTIONAL ON OK ACTION
export const displaySimpleErrorModal = (dispatch, errorText, extraOkAction = null) => {
    dispatch({
        type: APP_ACTION_TYPES.SET_DISPLAY_MODAL,
        payload: {
            type: MODAL_TYPES.ERROR,
            hideCancelButton: true,
            okAction: () => {
                if (extraOkAction) {
                    extraOkAction();
                }
                dispatch({
                    type: APP_ACTION_TYPES.SET_DISPLAY_MODAL,
                    payload: null,
                });
            },
            content: <div>{errorText}</div>,
        },
    });
};

// SIMPLE SUCCESS MODAL WITH OPTIONAL ON OK ACTION
export const displaySimpleSuccessModal = (dispatch, successText, extraOkAction = null) => {
    dispatch({
        type: APP_ACTION_TYPES.SET_DISPLAY_MODAL,
        payload: {
            type: MODAL_TYPES.SUCCESS,
            hideCancelButton: true,
            okAction: () => {
                if (extraOkAction) {
                    extraOkAction();
                }
                dispatch({
                    type: APP_ACTION_TYPES.SET_DISPLAY_MODAL,
                    payload: null,
                });
            },
            content: <div>{successText}</div>,
        },
    });
};

// TWO FACTOR CONFIRMATION
export const getUserConfirmation = (dispatch, confirmationMessage, onConfirm, onCancel = null) => {
    dispatch({
        type: APP_ACTION_TYPES.SET_DISPLAY_MODAL,
        payload: {
            type: MODAL_TYPES.WARNING,
            okAction: () => {
                onConfirm();
                dispatch({
                    type: APP_ACTION_TYPES.SET_DISPLAY_MODAL,
                    payload: null,
                });
            },
            cancelAction: () => {
                if (onCancel) {
                    onCancel();
                }
                dispatch({
                    type: APP_ACTION_TYPES.SET_DISPLAY_MODAL,
                    payload: null,
                });
            },
            content: <div>{confirmationMessage}</div>,
        },
    });
};

// HEADER STYLERS
const ICON_STYLE = { fontSize: 80, opacity: '50%', position: 'absolute', top: -10, left: -10 };
export const SuccessModalHeader = () => (
    <div className="modal__header__container--success">
        <CheckCircleFilled style={ICON_STYLE} />
        <div className="modal__header__title">Success</div>
    </div>
);

export const WarningModalHeader = () => (
    <div className="modal__header__container--warning">
        <ExclamationCircleFilled style={ICON_STYLE} />
        <div className="modal__header__title">Warning</div>
    </div>
);

export const ErrorModalHeader = () => (
    <div className="modal__header__container--error">
        <CloseCircleFilled style={ICON_STYLE} />
        <div className="modal__header__title">Error</div>
    </div>
);

export const InfoModalHeader = (title) => (
    <div className="modal__header__container--info">
        <SettingFilled style={ICON_STYLE} />
        <div className="modal__header__title">{title}</div>
    </div>
);
