import { useEffect, useState } from 'react';
import { Modal, Select, Checkbox, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { AiOutlineWarning } from 'react-icons/ai';
import PulseLoader from 'react-spinners/PulseLoader';
import moment from 'moment';

import {
    displaySimpleErrorModal,
    getUserConfirmation,
    InfoModalHeader,
} from '../global/ModalController';
import DriversApi from '../../api/dataManagement/drivers';
import TrucksApi from '../../api/dataEntry/trucks';
import SupplementalPayModal from '../global/SupplementalPayModal';
import PayrollApi from '../../api/payroll/payroll';
import { formatCurrency } from '../../util/formUtil';
import EmployeePayrollDetails from './EmployeePayrollDetails';
import { generateSpecialPayrollErrorText } from '../../util/longText';
import { onAPISuccess } from '../../util/apiUtil';

const { Option } = Select;
const GENERATE_CONFIRMATION_MSG =
    'By generating this payroll, selected driver will be issued payroll for the selected trucks and will not be included in the regularly generated payroll. Do you wish to continue?';

const CreateSpecialPayroll = ({ onCancel, fetchPayrollData }) => {
    const dispatch = useDispatch();
    const [isFetching, setIsFetching] = useState(false);

    const [drivers, setDrivers] = useState([]);
    const [selectedDriver, setSelectedDriver] = useState(null);

    const [driverTrucks, setDriverTrucks] = useState([]);
    const [selectedTrucks, setSelectedTrucks] = useState([]);
    const [displaySupplementalDataModal, setDisplaySupplementalDataModal] = useState(null);
    const [payrollPreviewData, setPayrollPreviewData] = useState(null);

    // Fetch drivers on mount
    useEffect(() => {
        fetchDrivers();
    }, []);

    // Fetch drivers' trucks on driver change
    useEffect(() => {
        fetchDriverTrucks();
    }, [selectedDriver]);

    const fetchDrivers = async () => {
        setIsFetching(true);

        const { data } = await DriversApi.getDrivers('', false, 0, 1000000);
        setDrivers([...data]);

        setIsFetching(false);
    };

    const fetchDriverTrucks = async () => {
        if (!selectedDriver) return;

        setIsFetching(true);
        setSelectedTrucks([]);

        const data = await TrucksApi.getTruckByDriver(selectedDriver.driverId, true, true, true);

        // Sort trucks by date
        data.sort((a, b) => moment.utc(a.date).diff(moment.utc(b.date)));
        setDriverTrucks([...data]);

        setIsFetching(false);
    };

    const generatePayrollPreview = async () => {
        setIsFetching(true);
        const data = await PayrollApi.generateSpecialPayrollPreview(
            selectedDriver.driverId,
            selectedTrucks
        );

        setPayrollPreviewData(data);
        setIsFetching(false);
    };

    const finalizeSpecialPayroll = async () => {
        setIsFetching(true);
        PayrollApi.finalizeSpecialPayroll(selectedDriver.driverId, selectedTrucks)
            .then(() => {
                fetchPayrollData();
                onAPISuccess(dispatch, 'Special payroll generated');
                onCancel();
            })
            .catch((e) => {
                console.error(e);
                setIsFetching(false);

                displaySimpleErrorModal(dispatch, generateSpecialPayrollErrorText);
            });
    };

    const isMissingSupData = (truckId) => {
        const truck = driverTrucks.find((truck) => truck.truckId === truckId);
        return truck && truck.tdm.specialPayEligible && !truck.specialPayDayCount;
    };

    const canGeneratePreview = () => {
        if (!selectedTrucks.length) return false;
        if (selectedTrucks.some((truckId) => isMissingSupData(truckId))) return false;

        return true;
    };

    const getModalContent = () => {
        return (
            <div>
                {/* FETCH INDICATOR */}
                {isFetching ? (
                    <div className="create-special-payroll__spinner">
                        <PulseLoader color="#fff" size="10" />
                    </div>
                ) : null}

                {/* DRIVER SELECT */}
                <div className="create-special-payroll__driver-select__container">
                    <div className="input-label">Driver</div>
                    <Select
                        size="large"
                        style={{ width: '100%' }}
                        placeholder="Select driver"
                        disabled={isFetching || payrollPreviewData}
                        value={
                            selectedDriver
                                ? `${selectedDriver.contactInfo.firstName} ${selectedDriver.contactInfo.lastName}`
                                : undefined
                        }
                        onChange={(value) => {
                            setSelectedDriver(
                                drivers.find((driver) => `${driver.driverId}` === `${value}`)
                            );
                            setSelectedTrucks([]);
                        }}
                    >
                        {drivers.map((driver) => (
                            <Option key={driver.driverId}>
                                {`${driver.contactInfo.firstName} ${driver.contactInfo.lastName}`}
                            </Option>
                        ))}
                    </Select>
                </div>

                {/* TRUCK SELECT */}
                <div className="create-special-payroll__truck-select__container">
                    {driverTrucks.length ? (
                        <Checkbox.Group
                            disabled={payrollPreviewData}
                            options={driverTrucks.map((truck) => ({
                                label: (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <span className="create-special-payroll__truck-select__heading">
                                            Truck Date:{' '}
                                        </span>
                                        {truck.date}
                                        <span
                                            className="create-special-payroll__truck-select__heading"
                                            style={{ marginLeft: 15 }}
                                        >
                                            TDM:{' '}
                                        </span>
                                        {truck.tdm.routeName}
                                        {isMissingSupData(truck.truckId) ? (
                                            <Tooltip title="Missing supplemental pay data. Click to add.">
                                                <AiOutlineWarning
                                                    fontSize={16}
                                                    style={{ color: '#ffa630', marginLeft: 10 }}
                                                    onClick={() => {
                                                        setDisplaySupplementalDataModal(truck);
                                                    }}
                                                />
                                            </Tooltip>
                                        ) : null}
                                    </div>
                                ),
                                value: truck.truckId,
                            }))}
                            onChange={(checkedValues) => {
                                setSelectedTrucks([...checkedValues]);
                            }}
                            value={selectedTrucks}
                        />
                    ) : selectedDriver ? (
                        <div className="create-special-payroll__truck-select__no-trucks">
                            No trucks for this driver which are not already included on another
                            payroll
                        </div>
                    ) : null}

                    {/* PAYROLL PREVIEW */}
                    {payrollPreviewData ? getPayrollPreviewSection() : null}
                </div>
            </div>
        );
    };

    const getPayrollPreviewSection = () => {
        return (
            <div className="create-special-payroll__payroll-preview__container">
                <div className="create-special-payroll__payroll-preview__line-break" />
                <div className="create-special-payroll__payroll-preview__totals-container">
                    <div>
                        <span className="create-special-payroll__payroll-preview__totals-label">
                            Total Gross Pay:
                        </span>
                        {formatCurrency(payrollPreviewData.totalGrossPay)}
                    </div>
                    <div>
                        <span className="create-special-payroll__payroll-preview__totals-label">
                            Total Special Pay:
                        </span>
                        {formatCurrency(payrollPreviewData.totalSpecialPay)}
                    </div>
                </div>

                <div className="create-special-payroll__payroll-preview__services-container">
                    <EmployeePayrollDetails
                        payrollDetails={{
                            driver: payrollPreviewData.payrollReports[0].driver,
                            helper: [],
                            invoiceServices:
                                payrollPreviewData.payrollReports[0].payrollInvoiceServices,
                        }}
                        asModal={false}
                    />
                </div>
            </div>
        );
    };

    const getfooter = () => {
        return (
            <div className="create-special-payroll__footer__container">
                <div className="create-special-payroll__footer__cancel" onClick={onCancel}>
                    CANCEL
                </div>

                {/* SUBMIT BUTTONS */}
                {!payrollPreviewData ? (
                    <div
                        className={`create-special-payroll__footer__generate${
                            !canGeneratePreview() ? '--disabled' : ''
                        }`}
                        onClick={canGeneratePreview() ? () => generatePayrollPreview() : null}
                    >
                        PREVIEW PAYROLL
                    </div>
                ) : (
                    <div
                        className="create-special-payroll__footer__generate"
                        onClick={() =>
                            getUserConfirmation(dispatch, GENERATE_CONFIRMATION_MSG, () =>
                                finalizeSpecialPayroll()
                            )
                        }
                    >
                        GENERATE SPECIAL PAYROLL
                    </div>
                )}
            </div>
        );
    };

    return (
        <>
            <Modal
                title={InfoModalHeader('Create Special Payroll')}
                visible
                width={800}
                closable={false}
                keyboard={false}
                maskClosable={false}
                footer={getfooter()}
            >
                {getModalContent()}
            </Modal>

            {/* SUPPLEMENTAL PAY MODAL */}
            {displaySupplementalDataModal ? (
                <SupplementalPayModal
                    modalData={displaySupplementalDataModal}
                    onSubmitSuccess={() => {
                        setDisplaySupplementalDataModal(false);
                        fetchDriverTrucks();
                    }}
                />
            ) : null}
        </>
    );
};
export default CreateSpecialPayroll;
