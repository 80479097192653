/* eslint-disable react/jsx-wrap-multilines */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

import { APP_ACTION_TYPES, MODAL_TYPES } from '../../store/constants/appActionTypes';
import {
    hasSessionExpired,
    isLoggedIn,
    isSessionAboutToExpire,
    loginNotRequiredPaths,
    removeCachedToken,
    setAccessToken,
} from '../../util/authentication';
import AuthenticationApi from '../../api/authentication';
import { refreshSessionErrorText } from '../../util/longText';

const SessionTimer = () => {
    const [displayRefreshModal, setDisplayRefreshModal] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        // Session timer
        const timer = setInterval(() => {
            checkSession();
        }, 5000);

        // UNMOUNT
        return () => {
            clearInterval(timer);
        };
    }, []);

    const checkSession = () => {
        // Don't log out if already on login page or on password reset
        const pathName = window.location.pathname;
        if (loginNotRequiredPaths.some((path) => pathName.includes(path))) {
            return;
        }

        if (!isLoggedIn() || hasSessionExpired()) {
            setDisplayRefreshModal(false);
            toggleLoggedOut();
        } else if (isSessionAboutToExpire()) {
            setDisplayRefreshModal(true);
        }
    };

    const getFreshSessionToken = () => {
        setDisplayRefreshModal(false);

        AuthenticationApi.refreshSession()
            .then((loginDetails) => {
                setAccessToken(loginDetails.token, loginDetails.expiry_seconds);
            })
            .catch(() => {
                dispatch({
                    type: APP_ACTION_TYPES.SET_DISPLAY_MODAL,
                    payload: {
                        type: MODAL_TYPES.ERROR,
                        content: <div>{refreshSessionErrorText}</div>,
                    },
                });
            });
    };

    const toggleLoggedOut = () => {
        dispatch({ type: APP_ACTION_TYPES.SET_USER_DATA, payload: null });
        dispatch({ type: APP_ACTION_TYPES.SET_USER_PERMISSIONS, payload: [] });
        dispatch({ type: APP_ACTION_TYPES.TOGGLE_LOGGED_IN, payload: false });
        removeCachedToken();
        history.push('/login');
    };

    return displayRefreshModal ? (
        <Modal
            title={
                <div className="modal__header__container--info">
                    <ExclamationCircleFilled
                        style={{
                            fontSize: 80,
                            opacity: '50%',
                            position: 'absolute',
                            top: -10,
                            left: -10,
                        }}
                    />
                    <div className="modal__header__title">Session Expiry</div>
                </div>
            }
            visible
            keyboard
            onOk={getFreshSessionToken}
            onCancel={() => setDisplayRefreshModal(false)}
        >
            <div>Your sessions is about to expire. Would you like to refresh?</div>
        </Modal>
    ) : null;
};

export default SessionTimer;
