import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Tooltip } from 'antd';
import { BsPatchCheckFill, BsPatchExclamationFill } from 'react-icons/bs';
import { TbHandStop } from 'react-icons/tb';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { APP_ACTION_TYPES, DEVICE_TYPE } from '../../store/constants/appActionTypes';
import { convertToTwoDecimals } from '../../util/formUtil';
import { shouldSee } from '../../util/permissionsUtil';

const CustomerInvoiceTemplate = ({
    invoiceData,
    approveInvoice,
    holdInvoice,
    recalculateInvoice,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const deviceType = useSelector((state) => state.app.device);
    const formStyle = deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : '';

    const onViewInvoiceClick = (invoice) => {
        dispatch({
            type: APP_ACTION_TYPES.SET_EDITING_INVOICE,
            payload: {
                truckId: invoice.truckInvoiceServices[0].invoice.truckId,
                invoiceId: invoice.truckInvoiceServices[0].invoiceId,
            },
        });
        history.push('/dataentry');
    };

    const getLabelledData = (label, data) => (
        <div style={{ display: 'flex' }}>
            <div className="input-label" style={{ fontWeight: 'bold', marginRight: 5 }}>
                {`${label}:`}
            </div>
            {data}
        </div>
    );

    const calculateGST = () =>
        (
            Math.round(
                (parseFloat(invoiceData.invoiceDetails.balanceDue) -
                    parseFloat(invoiceData.invoiceDetails.subtotal) -
                    parseFloat(invoiceData.invoiceDetails.fuelRateServiceCharge)) *
                    100
            ) / 100
        ).toFixed(2);

    const getServiceTotal = (invoiceService) =>
        (
            Math.round(
                parseFloat(invoiceService.settledRate) * parseFloat(invoiceService.quantity) * 100
            ) / 100
        ).toFixed(2);

    const getDetailedCustomerData = () => {
        const billingDetails = invoiceData.invoiceDetails.billingAddress;
        const customerDetails = invoiceData.invoiceDetails.customer;

        return (
            <>
                <div>{`${billingDetails.firstName} ${billingDetails.lastName} | ${customerDetails.orgName}`}</div>
                <div>{billingDetails.addressLineOne}</div>
                <div>{billingDetails.addressLineTwo}</div>
                <div>{`${billingDetails.city} ${billingDetails.province} ${billingDetails.postalCode}`}</div>
                <div>{billingDetails.country}</div>
                <div>{customerDetails.contactEmails ? customerDetails.contactEmails[0] : ''}</div>
                <div>{`Phone: ${billingDetails.phoneNumber}`}</div>
                <div>{`Fax: ${customerDetails.faxNumber}`}</div>
            </>
        );
    };

    const getTableHead = (type) => (
        <div
            className={`customer-invoice-template__charges-invoice-line${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
            style={{ fontWeight: 'bold' }}
        >
            <div
                className="customer-invoice-template__charges-invoice-line-item--centered"
                style={deviceType === DEVICE_TYPE.NOT_MOBILE ? { width: '20%' } : { width: '25%' }}
            >
                {type === 'invoice' ? 'TDM' : 'Service'}
            </div>
            <div
                className="customer-invoice-template__charges-invoice-line-item--centered"
                style={deviceType === DEVICE_TYPE.NOT_MOBILE ? { width: '20%' } : { width: '25%' }}
            >
                {type === 'invoice' ? 'Truck Date' : 'Quantity'}
            </div>
            <div
                className="customer-invoice-template__charges-invoice-line-item--centered"
                style={deviceType === DEVICE_TYPE.NOT_MOBILE ? { width: '30%' } : { width: '25%' }}
            >
                {type === 'invoice' ? 'Line Haul Status' : 'Rate'}
            </div>
            <div
                className="customer-invoice-template__charges-invoice-line-item--centered"
                style={deviceType === DEVICE_TYPE.NOT_MOBILE ? { width: '30%' } : { width: '25%' }}
            >
                {type === 'invoice' ? 'Truck Inv. Reference' : 'Service Total'}
            </div>
        </div>
    );

    return (
        <div
            className={`customer-invoice-template__container${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
        >
            {invoiceData ? (
                <>
                    {/* HEADER */}
                    <div
                        className={`customer-invoice-template__header__container${
                            deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                        }`}
                    >
                        <>
                            {/* BILLING DETAILS */}
                            <div
                                className={`customer-invoice-template__billing-details__container${formStyle}`}
                            >
                                <div
                                    className="input-label"
                                    style={{ fontSize: 20, fontWeight: 'bold' }}
                                >
                                    Billed To
                                </div>

                                {getDetailedCustomerData()}
                            </div>

                            {/* INVOICE DETAILS */}
                            {invoiceData.invoiceDetails.paymentTerms ? (
                                <div
                                    className={`customer-invoice-template__invoice-details__container${formStyle}`}
                                >
                                    {getLabelledData(
                                        'Payment Terms',
                                        invoiceData.invoiceDetails.paymentTerms
                                    )}
                                </div>
                            ) : null}
                        </>

                        {/* INVOICE ACTIONS */}
                        {/* All invoice actions require INVOICE_UPDATE permission */}
                        {shouldSee(['INVOICE_UPDATE']) ? (
                            invoiceData.invoiceDetails.requiresUpdate ? (
                                // RE_CALCULATE BUTTON
                                <Tooltip title="Service(s) on this invoice have been modifed and the invoice requires re-calculating">
                                    <div
                                        className="customer-invoice-template__calculate__container"
                                        onClick={() => recalculateInvoice()}
                                    >
                                        RE-CALCULATE
                                        <BsPatchExclamationFill
                                            fontSize={100}
                                            style={{ color: '#ffa630' }}
                                        />
                                    </div>
                                </Tooltip>
                            ) : ['generated', 'on_hold'].includes(
                                  invoiceData.invoiceDetails.invoiceStatus
                              ) ? (
                                // APPROVE BUTTON
                                <div
                                    className="customer-invoice-template__approve__container"
                                    onClick={() => approveInvoice()}
                                >
                                    CLICK TO APPROVE
                                    <BsPatchCheckFill fontSize={100} style={{ color: '#99c24d' }} />
                                </div>
                            ) : invoiceData.invoiceDetails.invoiceStatus === 'approved' ? (
                                // HOLD BUTTON
                                <Tooltip title="Putting invoice on hold will prevent it from being sent to customer until it's approved again">
                                    <div
                                        className="customer-invoice-template__hold__container"
                                        onClick={() => holdInvoice()}
                                    >
                                        PUT ON HOLD
                                        <TbHandStop fontSize={100} style={{ color: '#d93c20' }} />
                                    </div>
                                </Tooltip>
                            ) : null
                        ) : null}
                    </div>
                    {/* CONTENT */}
                    <div
                        className={`customer-invoice-template__content__container${
                            deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                        }`}
                    >
                        {/* EMAIL WARNING MESSAGE */}
                        {invoiceData && invoiceData.invoiceDetails.invoiceStatus === 'approved' ? (
                            <div className="customer-invoice-template__content__warning-message">
                                This invoice will be emailed to the customer at 5:00am
                            </div>
                        ) : null}

                        {/* CHARGES */}
                        <div
                            className={`customer-invoice-template__charges__container${
                                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                            }`}
                        >
                            {invoiceData.charges.map((charge, idx) => (
                                <div
                                    className={`customer-invoice-template__charges__invoice-container${
                                        deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                                    }`}
                                    key={`invoice-charge-${idx}`}
                                >
                                    {getTableHead('invoice')}
                                    {/* TRUCK INVOICE DETAILS */}
                                    <div
                                        className={`customer-invoice-template__charges-invoice-line${
                                            deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                                        }`}
                                    >
                                        {/* ROUTE NAME */}
                                        <div
                                            className="customer-invoice-template__charges-invoice-line-item--centered"
                                            style={
                                                deviceType === DEVICE_TYPE.NOT_MOBILE
                                                    ? { width: '20%' }
                                                    : { width: '25%' }
                                            }
                                        >
                                            {charge.tdm.routeName}
                                        </div>

                                        {/* TRUCK DATE */}
                                        <div
                                            className="customer-invoice-template__charges-invoice-line-item--centered"
                                            style={
                                                deviceType === DEVICE_TYPE.NOT_MOBILE
                                                    ? { width: '20%' }
                                                    : { width: '25%' }
                                            }
                                        >
                                            {charge.truckDate}
                                        </div>

                                        {/* LINE HAUL STATUS */}
                                        <div
                                            className="customer-invoice-template__charges-invoice-line-item--centered"
                                            style={
                                                deviceType === DEVICE_TYPE.NOT_MOBILE
                                                    ? { width: '30%' }
                                                    : { width: '25%' }
                                            }
                                        >
                                            {charge.truckInvoiceLineHaulStatus}
                                        </div>

                                        {/* INVOICE REF */}
                                        <div
                                            className="customer-invoice-template__charges-invoice-line-item--centered"
                                            style={
                                                deviceType === DEVICE_TYPE.NOT_MOBILE
                                                    ? { width: '30%' }
                                                    : { width: '25%' }
                                            }
                                        >
                                            {charge.truckInvoiceReference}
                                        </div>
                                    </div>

                                    {/* TRUCK INVOICE SERVICES */}
                                    {getTableHead('invoice-service')}
                                    {charge.truckInvoiceServices.map((invoiceService, idx) => {
                                        if (invoiceService.type === 'service_type') {
                                            return (
                                                <div
                                                    key={`invoice-service-charge-${idx}`}
                                                    className={`customer-invoice-template__charges-invoice-line${
                                                        deviceType === DEVICE_TYPE.MOBILE
                                                            ? '--mobile'
                                                            : ''
                                                    }`}
                                                >
                                                    {/* SERVICE NAME */}
                                                    <div
                                                        className="customer-invoice-template__charges-invoice-line-item--centered"
                                                        style={
                                                            deviceType === DEVICE_TYPE.NOT_MOBILE
                                                                ? { width: '20%' }
                                                                : { width: '25%' }
                                                        }
                                                    >
                                                        {invoiceService.label}
                                                    </div>

                                                    {/* QUANTITY */}
                                                    <div
                                                        className="customer-invoice-template__charges-invoice-line-item--centered"
                                                        style={
                                                            deviceType === DEVICE_TYPE.NOT_MOBILE
                                                                ? { width: '20%' }
                                                                : { width: '25%' }
                                                        }
                                                    >
                                                        {invoiceService.quantity}
                                                    </div>

                                                    {/* RATE */}
                                                    <div
                                                        className="customer-invoice-template__charges-invoice-line-item--centered"
                                                        style={
                                                            deviceType === DEVICE_TYPE.NOT_MOBILE
                                                                ? { width: '30%' }
                                                                : { width: '25%' }
                                                        }
                                                    >
                                                        {`$${invoiceService.settledRate}`}
                                                    </div>

                                                    {/* SERVICE TOTAL */}
                                                    <div
                                                        className="customer-invoice-template__charges-invoice-line-item--centered"
                                                        style={
                                                            deviceType === DEVICE_TYPE.NOT_MOBILE
                                                                ? { width: '30%' }
                                                                : { width: '25%' }
                                                        }
                                                    >
                                                        {`$${getServiceTotal(invoiceService)}`}
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}

                                    {/* REDIRECT TO VIEW TRUCK INVOICE */}
                                    <div className="customer-invoice-template__charges__view-invoice">
                                        <Tooltip title="View Invoice">
                                            <MdOutlineOpenInNew
                                                style={{
                                                    fontSize: 18,
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => onViewInvoiceClick(charge)}
                                            />
                                        </Tooltip>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* FOOTER */}
                        <div
                            className={`customer-invoice-template__footer__container${
                                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                            }`}
                        >
                            {/* TOTALS */}
                            <div
                                className={`customer-invoice-template__totals__container${
                                    deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                                }`}
                            >
                                <div>
                                    {`Subtotal: $${convertToTwoDecimals(
                                        invoiceData.invoiceDetails.subtotal
                                    )}`}
                                </div>
                                <div>
                                    {`FSC: $${convertToTwoDecimals(
                                        invoiceData.invoiceDetails.fuelRateServiceCharge
                                    )}`}
                                </div>
                                <div>{`GST 5%: $${calculateGST()}`}</div>
                                <div>
                                    {`Balance Due: $${convertToTwoDecimals(
                                        invoiceData.invoiceDetails.balanceDue
                                    )}`}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    );
};
export default CustomerInvoiceTemplate;
