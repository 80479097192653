import { useLayoutEffect, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, useLocation } from 'react-router-dom';

import Users from './component/userManagement/Users';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import DataEntry from './pages/dataEntry/dataEntry';
import DataManagement from './pages/dataManagement/DataManagement';
import Driver from './pages/dataManagement/drivers/Driver';
import DriversTable from './pages/dataManagement/drivers/DriversTable';
import Customer from './pages/dataManagement/customers/Customer';
import CustomersTable from './pages/dataManagement/customers/CustomersTable';
import TdmsTable from './pages/dataManagement/tdms/TdmsTable';
import RateProfiles from './pages/rateProfiles/RateProfiles';
import ConfirmEmailUpdate from './pages/userManagement/ConfirmEmailUpdate';
import SingleTruckInvoice from './pages/invoices/SingleTruckInvoice';
import NewUserSignUp from './pages/userManagement/NewUserSignUp';
import Settings from './pages/Settings';
import ForgotPassword from './pages/ForgotPassword';
import Invoices from './pages/invoices/Invoices';
import SingleCustomerInvoice from './pages/invoices/SingleCustomerInvoice';
import SinglePayroll from './pages/payroll/SinglePayroll';
import TrucksTable from './pages/dataManagement/trucks/TrucksTable';
import UserManagement from './pages/userManagement/UserManagement';

import { DEVICE_TYPE, SIDEBAR_VIEWS } from './store/constants/appActionTypes';

import { PrivateRoute, PublicRoute } from './util/routeTypes';
import { getPageHeader, getPageTitle } from './util/locationTitleMatrix';
import useWindowDimensions from './util/useWindowDimensions';
import { loginNotRequiredPaths } from './util/authentication';
import { NAVIGATION_PERMISSIONS } from './util/permissionsUtil';
import { getHost } from './util/apiUtil';

/** IMPORTANT:
 * Wrap all routes with <PrivateRoute /> OR <PublicRoute />
 */
function Routes() {
    const pathName = window.location.pathname;

    const loggedIn = !loginNotRequiredPaths.some((path) => pathName.includes(path));
    let deviceType = DEVICE_TYPE.NOT_MOBILE;

    const location = useLocation();
    useLayoutEffect(() => {
        getPageTitle(location.pathname);
    }, [location]);

    // Required for state update on loggedIn change
    useEffect(() => {}, [loggedIn]);

    const sidebarState = useSelector((state) => state.app.sidebarView);
    deviceType = useSelector((state) => state.app.device);

    const { width } = useWindowDimensions();

    return (
        <div
            className="content"
            style={
                loggedIn && deviceType === DEVICE_TYPE.NOT_MOBILE
                    ? {
                          position: 'absolute',
                          left: sidebarState === SIDEBAR_VIEWS.EXPANDED ? 250 : 70,
                          width: sidebarState === SIDEBAR_VIEWS.EXPANDED ? width - 270 : width - 90,
                      }
                    : { position: 'relative' }
            }
        >
            {loggedIn && deviceType === DEVICE_TYPE.MOBILE ? (
                <div className="mobile-page-title">{getPageHeader(location)}</div>
            ) : null}
            <Switch>
                <PrivateRoute exact path="/" component={Dashboard} />

                <PublicRoute path="/login/:infoMessage" component={Login} />
                <PublicRoute path="/login" component={Login} />
                <PublicRoute
                    path="/oauthLogin"
                    component={() => {
                        window.location.href = `${getHost()}/account/auth/preSsoLogin`;
                    }}
                />
                <PublicRoute path="/postoauth" component={Dashboard} />

                <PrivateRoute
                    path="/dataentry"
                    component={DataEntry}
                    requiredPermissions={NAVIGATION_PERMISSIONS.DATA_ENTRY}
                />

                <PrivateRoute
                    path="/datamanagement"
                    component={DataManagement}
                    requiredPermissions={NAVIGATION_PERMISSIONS.DATA_MANAGEMENT}
                />
                <PrivateRoute
                    path="/drivers/:id"
                    component={Driver}
                    requiredPermissions={['DRIVER_READ']}
                />
                <PrivateRoute
                    path="/drivers"
                    component={DriversTable}
                    requiredPermissions={['DRIVER_READ']}
                />
                <PrivateRoute
                    path="/customers/:id"
                    component={Customer}
                    requiredPermissions={['CUSTOMER_READ']}
                />
                <PrivateRoute
                    path="/customers"
                    component={CustomersTable}
                    requiredPermissions={['CUSTOMER_READ']}
                />
                <PrivateRoute
                    path="/tdms/:id"
                    component={TdmsTable}
                    requiredPermissions={['TDM_READ']}
                />
                <PrivateRoute
                    path="/tdms"
                    component={TdmsTable}
                    requiredPermissions={['TDM_READ']}
                />
                <PrivateRoute
                    path="/trucks"
                    component={TrucksTable}
                    requiredPermissions={['TRUCK_READ']}
                />

                <PrivateRoute
                    path="/rateprofiles"
                    component={RateProfiles}
                    requiredPermissions={NAVIGATION_PERMISSIONS.RATE_PROFILES}
                />

                <PrivateRoute
                    path="/users/editing/:id"
                    component={Users}
                    requiredPermissions={NAVIGATION_PERMISSIONS.USER_MANAGEMENT}
                />
                <PrivateRoute
                    path="/users"
                    component={UserManagement}
                    requiredPermissions={NAVIGATION_PERMISSIONS.USER_MANAGEMENT}
                />

                <PrivateRoute
                    path="/invoices/search/:customerLike"
                    component={Invoices}
                    requiredPermissions={NAVIGATION_PERMISSIONS.INVOICES}
                />
                <PrivateRoute
                    path="/invoices/truck/:id"
                    component={SingleTruckInvoice}
                    requiredPermissions={NAVIGATION_PERMISSIONS.INVOICES}
                />
                <PrivateRoute
                    path="/invoices/customer/:id"
                    component={SingleCustomerInvoice}
                    requiredPermissions={NAVIGATION_PERMISSIONS.INVOICES}
                />
                <PrivateRoute
                    path="/invoices/payroll/:id"
                    component={SinglePayroll}
                    requiredPermissions={NAVIGATION_PERMISSIONS.PAYROLL}
                />
                <PrivateRoute
                    path="/invoices"
                    component={Invoices}
                    requiredPermissions={NAVIGATION_PERMISSIONS.INVOICES}
                />

                <PrivateRoute path="/settings" component={Settings} />
                <PublicRoute path="/forgotPassword/:token" component={ForgotPassword} />
                <PublicRoute path="/updateEmail/:token" component={ConfirmEmailUpdate} />
                <PublicRoute path="/setPassword/:token" component={NewUserSignUp} />
            </Switch>
        </div>
    );
}

export default Routes;
