import { Checkbox, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PayrollApi from '../../api/payroll/payroll';
import { onAPIError } from '../../util/apiUtil';
import { capitalizeWord } from '../../util/formUtil';
import {
    payrollPartialTrucksAdjustErrorText,
    payrollTrucksAdjustEmptyErrorText,
    payrollTrucksAdjustErrorText,
    payrollTrucksHelperText,
} from '../../util/longText';
import { InfoModalHeader } from '../global/ModalController';

const PayrollTrucks = ({ onCancel, payrollTrucks, payrollData, fetchPayrollData }) => {
    const dispatch = useDispatch();
    const [selectedTrucks, setSelectedTrucks] = useState([]);
    const [submittingTruckAdjustment, setSubmittingTruckAdjustment] = useState(false);

    useEffect(() => {
        setSelectedTrucks([...payrollTrucks]);
    }, [payrollTrucks]);

    const toggleTruckPayroll = (checked, truckId) => {
        const trucks = [...selectedTrucks];
        const indexToUpdate = trucks.findIndex((truck) => truck.truckId === truckId);
        trucks[indexToUpdate] = { ...trucks[indexToUpdate], onPayroll: checked };
        setSelectedTrucks([...trucks]);
    };

    const toggleAllPayroll = () => {
        const trucks = [...selectedTrucks].map((truck) => ({ ...truck, onPayroll: true }));
        setSelectedTrucks([...trucks]);
    };

    const submitTruckAdjustment = () => {
        const trucksToSubmit = selectedTrucks
            .filter((selectedTruck) => selectedTruck.onPayroll)
            .map((truck) => truck.truckId);

        /* Don't allow removing all trucks from a generated payroll, causes
           issues with auto payrol re-generating */
        if (payrollData.alreadyGenerated && !trucksToSubmit.length) {
            onAPIError(dispatch, payrollTrucksAdjustEmptyErrorText);
            return;
        }

        setSubmittingTruckAdjustment(true);
        PayrollApi.adjustPayrollTrucks(payrollData.payrollId, trucksToSubmit)
            .then((data) => {
                if (data) {
                    displayPartailSuccessMessage(data.details);
                }

                fetchPayrollData();
                onCancel();
            })
            .catch(() => {
                fetchPayrollData();
                onCancel();
                onAPIError(dispatch, payrollTrucksAdjustErrorText);
            });
    };

    const displayPartailSuccessMessage = (details) => {
        // If truck adjust operation was only partially successful, display info modal
        const detailsWithTrucks = details.map((detail) => {
            const truckDetails = selectedTrucks.find((truck) => truck.truckId === detail.truckId);
            return {
                ...detail,
                truckDetail: `${truckDetails.date} | ${truckDetails.tdm.routeName}`,
            };
        });

        const errMsg = (
            <div>
                <div>{payrollPartialTrucksAdjustErrorText}</div>
                <div style={{ marginTop: 15 }}>
                    <div className="payroll-trucks__partial-row-container--header">
                        <div className="payroll-trucks__partial-cell">Truck Date | TDM</div>
                        <div className="payroll-trucks__partial-cell">Action</div>
                        <div className="payroll-trucks__partial-cell">Reason For Failure</div>
                    </div>
                    {detailsWithTrucks.map((detail) => (
                        <div className="payroll-trucks__partial-row-container">
                            <div className="payroll-trucks__partial-cell">{detail.truckDetail}</div>
                            <div className="payroll-trucks__partial-cell">
                                {capitalizeWord(detail.action)}
                            </div>
                            <div className="payroll-trucks__partial-cell">{detail.reason}</div>
                        </div>
                    ))}
                </div>
            </div>
        );

        onAPIError(dispatch, errMsg);
    };

    const getModalContent = () => {
        return (
            <div className="payroll-trucks__container">
                <div className="payroll-create__helper-text">{payrollTrucksHelperText}</div>

                <div className="payroll-trucks__row-container--header">
                    <div className="payroll-trucks__cell">Truck Date</div>
                    <div className="payroll-trucks__cell">TDM</div>
                    <div className="payroll-trucks__cell">Driver</div>
                    <div className="payroll-trucks__cell">Part of Payroll?</div>
                </div>
                {selectedTrucks.map((truck) => (
                    <div
                        key={`payroll-truck-${truck.truckId}`}
                        className="payroll-trucks__row-container"
                    >
                        <div className="payroll-trucks__cell">{truck.date}</div>
                        <div className="payroll-trucks__cell">{truck.tdm.routeName}</div>
                        <div className="payroll-trucks__cell">{`${truck.driver.contactInfo.firstName} ${truck.driver.contactInfo.lastName}`}</div>
                        <div className="payroll-trucks__cell">
                            <Checkbox
                                checked={truck.onPayroll}
                                onChange={(e) =>
                                    toggleTruckPayroll(e.target.checked, truck.truckId)
                                }
                            />
                        </div>
                    </div>
                ))}
                <div className="payroll-trucks__row-container" style={{ marginTop: 10 }}>
                    <div className="payroll-trucks__cell--filler" />
                    <div className="payroll-trucks__cell">
                        <div className="standard-button--mini" onClick={() => toggleAllPayroll()}>
                            SELECT ALL
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Modal
            title={InfoModalHeader('Payroll Trucks')}
            visible
            onOk={() => submitTruckAdjustment()}
            onCancel={() => onCancel()}
            closable={false}
            keyboard={false}
            maskClosable={false}
            width={700}
            confirmLoading={submittingTruckAdjustment}
            cancelButtonProps={submittingTruckAdjustment ? { disabled: true } : {}}
        >
            {getModalContent()}
        </Modal>
    );
};
export default PayrollTrucks;
