import { notification } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import CustomersApi from '../../api/dataManagement/customers';
import DriversApi from '../../api/dataManagement/drivers';
import TdmsApi from '../../api/dataManagement/tdms';
import { onAPIError } from '../../util/apiUtil';
import {
    customersSaveDataErrorText,
    customersSaveDataSuccessText,
    driversSaveDataErrorText,
    driversSaveDataSuccessText,
    newTdmFailErrorText,
} from '../../util/longText';
import CustomerModal from '../dataManagement/CustomerModal';
import DriverModal from '../dataManagement/DriverModal';
import TdmModal from '../dataManagement/TdmModal';

const DEFAULT_NEW_DRIVER = {
    firstName: '',
    lastName: '',
    nickname: '',
    workPhoneNumber: '',
    email: '',
    rateProfile: {
        profileName: '',
        driverRateProfileId: null,
    },
};
const DEFAULT_NEW_CUSTOMER = {
    firstName: '',
    lastName: '',
    orgName: '',
    rateProfile: {
        profileName: '',
        driverRateProfileId: null,
    },
};

const QuickCreateItems = ({
    displayQuickCreateDriver,
    setDisplayQuickCreateDriver,
    driverRates,
    fetchDriverData,
    displayQuickCreateCustomer,
    setDisplayQuickCreateCustomer,
    customerRates,
    organizationOptions,
    fetchCustomerData,
    displayQuickCreateTdm,
    setDisplayQuickCreateTdm,
    fetchTDMData,
}) => {
    const dispatch = useDispatch();

    // Drivers
    const [submittingDriver, setSubmittingDriver] = useState(false);
    const [newDriverData, setNewDriverData] = useState({ ...DEFAULT_NEW_DRIVER });

    // Customers
    const [submittingCustomer, setSubmittingCustomer] = useState(false);
    const [newCustomerData, setNewCustomerData] = useState({ ...DEFAULT_NEW_CUSTOMER });

    // TDMs
    const [routeName, setRouteName] = useState('');
    const [specialPayEligible, setSpecialPayEligible] = useState(false);
    const [submittingTdm, setSubmittingTdm] = useState(false);

    const submitAddNewDriver = () => {
        setSubmittingDriver(true);
        // Extract rate profile ID
        const rateProfileId = parseInt(newDriverData.rateProfile.driverRateProfileId, 10);

        // Save new driver record
        DriversApi.createDriver({
            nickname: newDriverData.nickname,
            workPhoneNumber: newDriverData.workPhoneNumber,
            email: newDriverData.email,
            driverRateProfileId: rateProfileId,
            contactInfo: {
                firstName: newDriverData.firstName,
                lastName: newDriverData.lastName,
            },
        })
            .then(() => {
                fetchDriverData();
                setSubmittingDriver(false);
                setNewDriverData({ ...DEFAULT_NEW_DRIVER });
                setDisplayQuickCreateDriver(false);
                displaySuccessNotification(driversSaveDataSuccessText);
            })
            .catch(() => {
                setSubmittingDriver(false);
                onAPIError(dispatch, driversSaveDataErrorText);
            });
    };

    const submitAddNewCustomer = () => {
        setSubmittingCustomer(true);
        // Extract rate profile ID
        const { customerRateProfileId } = newCustomerData.rateProfile;

        // Save new customer record
        CustomersApi.createCustomer({
            orgName: newCustomerData.orgName,
            customerRateProfileId,
            contactInfo: {
                firstName: newCustomerData.firstName,
                lastName: newCustomerData.lastName,
            },
        })
            .then(() => {
                fetchCustomerData();
                setSubmittingCustomer(false);
                setNewCustomerData({ ...DEFAULT_NEW_CUSTOMER });
                setDisplayQuickCreateCustomer(false);
                displaySuccessNotification(customersSaveDataSuccessText);
            })
            .catch(() => {
                setSubmittingCustomer(false);
                onAPIError(dispatch, customersSaveDataErrorText);
            });
    };

    const submitAddNewTdm = () => {
        setSubmittingTdm(true);

        TdmsApi.createTdm({
            routeName,
            specialPayEligible,
            active: true,
        })
            .then(() => {
                fetchTDMData();
                setSubmittingTdm(false);
                setRouteName('');
                setSpecialPayEligible(false);
                setDisplayQuickCreateTdm(false);
                displaySuccessNotification('New TDM added succesfully');
            })
            .catch(() => {
                setSubmittingTdm(false);

                onAPIError(dispatch, newTdmFailErrorText);
            });
    };

    const displaySuccessNotification = (message) => {
        notification.success({
            message,
            duration: 3,
            placement: 'top',
        });
    };

    return (
        <>
            {/* DRIVER */}
            <DriverModal
                displayDriverModal={displayQuickCreateDriver}
                driverData={newDriverData}
                setDriverData={setNewDriverData}
                submitAddNewDriver={() => submitAddNewDriver()}
                onCancel={() => {
                    setDisplayQuickCreateDriver(false);
                    setNewDriverData({ ...DEFAULT_NEW_DRIVER });
                }}
                submittingDriver={submittingDriver}
                driverRates={driverRates}
            />

            {/* Customer */}
            <CustomerModal
                displayCustomerModal={displayQuickCreateCustomer}
                customerData={newCustomerData}
                setCustomerData={setNewCustomerData}
                submitAddNewCustomer={() => submitAddNewCustomer()}
                onCancel={() => {
                    setDisplayQuickCreateCustomer(false);
                    setNewCustomerData({ ...DEFAULT_NEW_CUSTOMER });
                }}
                submittingCustomer={submittingCustomer}
                customerRates={customerRates}
                organizationOptions={organizationOptions}
            />

            {/* TDM */}
            <TdmModal
                displayTdmModal={displayQuickCreateTdm}
                submitAddNewTdm={submitAddNewTdm}
                onCancel={() => {
                    setRouteName('');
                    setSpecialPayEligible(false);
                    setDisplayQuickCreateTdm(false);
                }}
                submittingTdm={submittingTdm}
                routeName={routeName}
                setRouteName={setRouteName}
                specialPayEligible={specialPayEligible}
                setSpecialPayEligible={setSpecialPayEligible}
            />
        </>
    );
};
export default QuickCreateItems;
