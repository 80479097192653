import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { GrLock } from 'react-icons/gr';

import { DEVICE_TYPE } from '../../store/constants/appActionTypes';

const EntityTile = ({ title, icon, tileAction, createButtonAction, locked, canCreate }) => {
    const deviceType = useSelector((state) => state.app.device);

    const tooltipTitle = () => `Add ${title.slice(0, title.length - 1)}`;

    return (
        <div
            className={`entity-tile__container${locked ? '--disabled' : ''}${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
            onClick={!locked ? tileAction : null}
        >
            <div className="entity-tile__header">
                {/* LOGO */}
                <div className={`entity-tile__logo__container${locked ? '--disabled' : ''}`}>
                    <img className="entity-tile__logo__img" src={icon} alt="icon" />
                </div>

                {/* TITILE */}
                <div className={`entity-tile__title${locked ? '--disabled' : ''}`}>{title}</div>
            </div>

            <div className="entity-tile__footer">
                {/* ADD BUTTON */}
                {!locked ? (
                    canCreate ? (
                        <Tooltip title={tooltipTitle()}>
                            <div
                                className="entity-tile__button"
                                onClick={canCreate ? createButtonAction : null}
                            >
                                +
                            </div>
                        </Tooltip>
                    ) : null
                ) : (
                    <GrLock fontSize={30} />
                )}
            </div>
        </div>
    );
};

export default EntityTile;
