export const mobileAddButton = (onClickAction) => (
    <div className="mobile-add-button" onClick={() => onClickAction()}>
        +
    </div>
);

export const addButton = (onClickAction) => (
    <div className="add-button" onClick={() => onClickAction()}>
        +
    </div>
);
