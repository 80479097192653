import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Badge, Select, Tabs as AntTabs } from 'antd';
import { DEVICE_TYPE } from '../../store/constants/appActionTypes';

const { TabPane } = AntTabs;
const { Option } = Select;

const Tabs = ({ tabViews, selectedTab, onTabChange, verticalContainer }) => {
    const deviceType = useSelector((state) => state.app.device);

    // Check for URL fragment and pre-open specific tab
    useEffect(() => {
        const fragment = window.location.hash;
        if (fragment.length) {
            const selectedTab = fragment.substring(1);
            onTabChange(tabViews.find((view) => view.key === selectedTab).viewName);
        }
    }, [window.location.hash]);

    const onNewTabSelected = (newKey) => {
        // Add key of selecte tab as URL fragment for navigating back to the same tab
        const selectedTabKey = tabViews.find((view) => view.viewName === newKey).key;
        window.location.hash = `#${selectedTabKey}`;

        onTabChange(newKey);
    };

    return deviceType === DEVICE_TYPE.NOT_MOBILE ? (
        <AntTabs
            activeKey={selectedTab}
            onChange={(newKey) => onNewTabSelected(newKey)}
            type="card"
        >
            {tabViews.map((view) =>
                !view.locked ? (
                    <TabPane
                        key={view.viewName}
                        tab={
                            view.displayBadge ? (
                                <Badge dot offset={[6, 1]}>
                                    {view.viewName}
                                </Badge>
                            ) : (
                                view.viewName
                            )
                        }
                        disabled={view.isDisabled}
                    >
                        <div className={`tabs__container${verticalContainer ? '--vertical' : ''}`}>
                            {view.component}
                        </div>
                    </TabPane>
                ) : null
            )}
        </AntTabs>
    ) : (
        <div className="tabs__container--mobile">
            <Select
                size="large"
                value={selectedTab}
                style={{ width: '100%' }}
                onChange={(value) => onNewTabSelected(value)}
            >
                {tabViews.map((view) =>
                    !view.locked ? (
                        <Option
                            key={`${view.viewName}-rateview`}
                            value={view.viewName}
                            disabled={view.isDisabled}
                        >
                            {view.viewName}
                        </Option>
                    ) : null
                )}
            </Select>

            {tabViews.map((view) => {
                if (view.viewName === selectedTab) {
                    return view.component;
                }
                return null;
            })}
        </div>
    );
};
export default Tabs;

// PROP TYPES
const tabViewPropType = {
    // Displayed name of the tab/view
    viewName: PropTypes.string.isRequired,
    // View key used for returning to pre-selected tab
    key: PropTypes.string.isRequired,
    // Component rendered in each tab
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(null)]),
    // If tab should be locked
    locked: PropTypes.bool,
    // If tab should be disabled
    isDisabled: PropTypes.bool,
};
Tabs.propTypes = {
    // Components to render in each tab and their properties
    tabViews: PropTypes.arrayOf(PropTypes.exact(tabViewPropType)),
    // Name of currently selected tab/view
    selectedTab: PropTypes.string.isRequired,
    // Callback when a tab is clicked
    onTabChange: PropTypes.func.isRequired,
};
