import { useSelector } from 'react-redux';
import { DEVICE_TYPE } from '../../../store/constants/appActionTypes';
import ChangeEmail from '../ChangeEmail';
import ResetPassword from '../ResetPassword';

const Credentials = () => {
    const deviceType = useSelector((state) => state.app.device);

    const credentialComponents = [
        { title: 'Reset Password', component: <ResetPassword /> },
        { title: 'Change Email', component: <ChangeEmail /> },
    ];
    return (
        <div className="settings__tab-content__container">
            {credentialComponents.map((comp) => (
                <div key={comp.title} className="settings__credentials__component__container">
                    <div
                        className={`settings__credentials__component__title${
                            deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                        }`}
                    >
                        {comp.title}
                        {comp.component}
                    </div>
                </div>
            ))}
        </div>
    );
};
export default Credentials;
