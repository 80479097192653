export const APP_ACTION_TYPES = Object.freeze({
    SET_DEVICE: 'SET_DEVICE',
    TOGGLE_LOGGED_IN: 'TOGGLE_LOGGED_IN',
    SET_USER_DATA: 'SET_USER_DATA',
    SET_USER_PERMISSIONS: 'SET_USER_PERMISSIONS',
    TOGGLE_SIDEBAR_VIEW: 'TOGGLE_SIDEBAR_VIEW',
    SET_DISPLAY_MODAL: 'SET_DISPLAY_MODAL',
    SET_MODAL_AS_LOADING: 'SET_MODAL_AS_LOADING',
    SET_DISPLAY_INFO_MESSAGE: 'SET_DISPLAY_INFO_MESSAGE',
    SET_EDITING_INVOICE: 'SET_EDITING_INVOICE',
    SET_VIEWING_DRIVER_RATES: 'SET_VIEWING_DRIVER_RATES',
    SET_VIEWING_CUSTOMER_RATES: 'SET_VIEWING_CUSTOMER_RATES',
    SET_TRIGGER_FETCH: 'SET_TRIGGER_FETCH',
    SET_VIEWING_EMPLOYEE_PAYROLL_DETAILS: 'SET_VIEWING_EMPLOYEE_PAYROLL_DETAILS',
    SET_SOCKET_SERVER: 'SET_SOCKET_SERVER',
    SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
    ADD_NOTIFICATION: 'ADD_NOTIFICATION',
});

export const SIDEBAR_VIEWS = Object.freeze({
    EXPANDED: 'expanded',
    COLLAPSED: 'collapsed',
});

export const MODAL_TYPES = Object.freeze({
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    INFO: 'info',
});

export const INFO_MESSAGE_TYPES = Object.freeze({
    ...MODAL_TYPES,
    LOADING: 'loading',
});

export const DEVICE_TYPE = Object.freeze({
    MOBILE: 'mobile',
    NOT_MOBILE: 'not_mobile',
});

export const TRIGGER_FETCH_TYPES = Object.freeze({
    REPORT_EMAIL_CONFIGURATION: 'REPORT_EMAIL_CONFIGURATION',
    PAYROLL_LIST: 'PAYROLL_LIST',
});
