import cookie from 'react-cookies';
import { displaySimpleErrorModal } from '../component/global/ModalController';
import { APP_ACTION_TYPES, INFO_MESSAGE_TYPES } from '../store/constants/appActionTypes';

export const getHost = () => `${getProtocol()}//api.${getDomain()}`;

export const getAuthHeader = () => ({
    Authorization: `Bearer ${getAccessToken()}`,
});

export const getErrorMessage = (error) => {
    if (
        error.hasOwnProperty('response') &&
        error.response.hasOwnProperty('data') &&
        error.response.data.hasOwnProperty('message')
    ) {
        return error.response.data.message;
    }

    return error.message;
};

const getProtocol = () => {
    return new URL(window.location.href).protocol;
};

const getDomain = () => {
    return new URL(window.location.href).hostname;
};

const getAccessToken = () => {
    return cookie.load('accessToken');
};

export const onAPISuccess = (dispatch, successMessage) => {
    dispatch({
        type: APP_ACTION_TYPES.SET_DISPLAY_INFO_MESSAGE,
        payload: {
            type: INFO_MESSAGE_TYPES.SUCCESS,
            message: successMessage,
        },
    });
};

export const onAPIError = (dispatch, errorMessage) => {
    displaySimpleErrorModal(dispatch, errorMessage);
};
