/* eslint-disable react/no-typos */
import { Select } from 'antd';
import validator from 'validator';
import PropTypes from 'prop-types';

import LabelledInput from './LabelledInput';
import { CAD_PROVINCES, US_STATES } from '../../util/formUtil';

const { Option } = Select;
export const CANADA_IDENTIFIERS = ['CANADA', 'CA', 'C'];

/** See prop types at the end of file for description. */
const AddressForm = ({ formData, setFieldValue, formStyle, isEditing }) => {
    return (
        <>
            <div className={`address-form__line${formStyle}`}>
                <LabelledInput
                    labelText="Address Line 1"
                    value={formData.addressLineOne}
                    onChangeAction={(value) => setFieldValue({ addressLineOne: value })}
                    withValidation={isEditing}
                    isValid={formData.addressLineOne.length > 1}
                    validationText="Enter a valid address"
                    customWidth={formStyle.includes('mobile') ? '100%' : '49%'}
                    isDisabled={!isEditing}
                />
                <LabelledInput
                    labelText="Address Line 2"
                    value={formData.addressLineTwo}
                    onChangeAction={(value) => setFieldValue({ addressLineTwo: value })}
                    customWidth={formStyle.includes('mobile') ? '100%' : '49%'}
                    isDisabled={!isEditing}
                />
            </div>
            <div className={`address-form__line${formStyle}`}>
                <LabelledInput
                    labelText="City"
                    value={formData.city}
                    onChangeAction={(value) => setFieldValue({ city: value })}
                    withValidation={isEditing}
                    isValid={formData.city.length > 2}
                    validationText="Enter a valid address"
                    customWidth={formStyle.includes('mobile') ? '100%' : '24%'}
                    isDisabled={!isEditing}
                />

                <div
                    style={
                        formStyle.includes('mobile')
                            ? { width: '100%' }
                            : { width: '24%', marginRight: '25px' }
                    }
                >
                    <div className="input-label" style={{ marginTop: 25 }}>
                        {CANADA_IDENTIFIERS.includes(formData.country) ? 'Province' : 'State'}
                    </div>
                    <Select
                        size="large"
                        value={formData.province}
                        style={{ width: '100%' }}
                        onChange={(value) => setFieldValue({ province: value })}
                        disabled={!isEditing}
                        status={formData.province === '' ? 'error' : null}
                    >
                        {CANADA_IDENTIFIERS.includes(formData.country)
                            ? CAD_PROVINCES.map((opt) => (
                                  <Option key={`${opt}-formprovince`} value={opt}>
                                      {opt}
                                  </Option>
                              ))
                            : US_STATES.map((opt) => (
                                  <Option key={`${opt}-formprovince`} value={opt}>
                                      {opt}
                                  </Option>
                              ))}
                    </Select>
                </div>

                <LabelledInput
                    labelText={
                        CANADA_IDENTIFIERS.includes(formData.country) ? 'Postal Code' : 'Zip Code'
                    }
                    value={formData.postalCode}
                    onChangeAction={(value) => setFieldValue({ postalCode: value })}
                    withValidation={isEditing}
                    isValid={validator.isPostalCode(
                        formData.postalCode,
                        CANADA_IDENTIFIERS.includes(formData.country) ? 'CA' : 'US'
                    )}
                    validationText={`Enter a valid ${
                        CANADA_IDENTIFIERS.includes(formData.country) ? 'postal' : 'zip'
                    } code`}
                    customWidth={formStyle.includes('mobile') ? '100%' : '24%'}
                    isDisabled={!isEditing}
                />

                <div style={formStyle.includes('mobile') ? { width: '100%' } : { width: '24%' }}>
                    <div className="input-label" style={{ marginTop: 25 }}>
                        Country
                    </div>
                    <Select
                        size="large"
                        value={formData.country}
                        style={{ width: '100%' }}
                        onChange={(value) => setFieldValue({ country: value, province: '' })}
                        disabled={!isEditing}
                    >
                        {['CANADA', 'UNITED STATES'].map((opt) => (
                            <Option key={`${opt}-formtitle`} value={opt}>
                                {opt}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div>
        </>
    );
};
export default AddressForm;

AddressForm.propTypes = {
    // Data to be displayed in the form, see format below
    formData: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
        .isRequired,
    // Call back to adjust form field on change
    setFieldValue: PropTypes.func,
    // Mobile styling or regular styling
    formStyle: PropTypes.oneOf(['--mobile', '']).isRequired,
    // If form is in edit mode
    isEditing: PropTypes.bool.isRequired,
};

/* FORM DATA FORMAT
{
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    province: '',
    country: 'CANADA',
    postalCode: '',
};
*/
