import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { DEVICE_TYPE } from '../../store/constants/appActionTypes';
import MobileList from './MobileList';
import TableSearchAndFilter from './TableSearchAndFilter';

/** See prop types at the end of file for description. */
const TableComponent = ({
    leftButtonText,
    leftButtonAction,
    leftButtonDisabled,
    rightButtonText,
    rightButtonAction,
    rightButtonDisabled,
    searchValue,
    searchOnChange,
    searchPlaceholder,
    switchValue,
    switchOnChange,
    switchHelpText,
    tableColumns,
    tableData,
    mobileListData,
    currentPage,
    onPageChange,
    totalPageCount,
    onRowClick,
    onMobileActionClick,
    noPagination,
    customWidth,
    customStyle,
    tableHeader,
}) => {
    const deviceType = useSelector((state) => state.app.device);

    return (
        <div
            className={`table-component__content__container${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
            style={customWidth ? { width: customWidth, ...customStyle } : { ...customStyle }}
        >
            {/* ACTION BUTTONS */}
            <div
                className="table-component__action-buttons__container"
                style={
                    !leftButtonText && !rightButtonText
                        ? { margin: 0 }
                        : !leftButtonText
                        ? { justifyContent: 'flex-end' }
                        : !rightButtonText
                        ? { justifyContent: 'flex-start' }
                        : {}
                }
            >
                {leftButtonText ? (
                    <div
                        className={`standard-button${leftButtonDisabled ? '--disabled' : ''}`}
                        onClick={() => leftButtonAction()}
                    >
                        {leftButtonText}
                    </div>
                ) : null}
                {rightButtonText ? (
                    <div
                        className={`standard-button${rightButtonDisabled ? '--disabled' : ''}`}
                        onClick={() => rightButtonAction()}
                    >
                        {rightButtonText}
                    </div>
                ) : null}
            </div>

            {/* TABLE HEADER */}
            {tableHeader || null}

            {/* TABLE INTERACTION */}
            <TableSearchAndFilter
                searchValue={searchValue}
                searchOnChange={searchOnChange}
                searchPlaceholder={searchPlaceholder}
                switchValue={switchValue}
                switchOnChange={switchOnChange}
                switchHelpText={switchHelpText}
            />

            {/* TABLE */}
            <div
                className={`table-component__table__container${
                    deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                }`}
            >
                {deviceType === DEVICE_TYPE.NOT_MOBILE ? (
                    <Table
                        columns={tableColumns}
                        dataSource={tableData}
                        rowKey={() => `table_record_${Math.random()}`}
                        rowClassName="table-component__table__row"
                        locale={{
                            triggerDesc: 'Sort in descending order',
                            triggerAsc: 'Sort in ascending order',
                            cancelSort: 'Remove sort order',
                        }}
                        pagination={
                            noPagination
                                ? false
                                : {
                                      position: ['bottomCenter'],
                                      current: currentPage,
                                      onChange: onPageChange,
                                      total: totalPageCount,
                                  }
                        }
                        onRow={(record) => {
                            return {
                                onClick: onRowClick ? () => onRowClick(record) : null,
                            };
                        }}
                    />
                ) : (
                    <MobileList
                        data={mobileListData}
                        onRowClick={onRowClick}
                        currentPage={currentPage}
                        onPageChange={onPageChange}
                        recordCount={totalPageCount}
                        onMobileActionClick={onMobileActionClick}
                    />
                )}
            </div>
        </div>
    );
};
export default TableComponent;

TableComponent.propTypes = {
    // Text displayed in the left header button
    leftButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    // Left header button on click action
    leftButtonAction: PropTypes.func,
    // Text displayed in the right header button
    rightButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    // Right header button on click action
    rightButtonAction: PropTypes.func,
    // Value inside the search box
    searchValue: PropTypes.string,
    // Callback when value is entered in the search box
    searchOnChange: PropTypes.func,
    // Placeholder in the searchbox
    searchPlaceholder: PropTypes.string,
    // Boolean value of the switch
    switchValue: PropTypes.bool,
    // Callback when switch is clicked
    switchOnChange: PropTypes.func,
    // Switch label
    switchHelpText: PropTypes.string,
    // Column defs for the table
    tableColumns: PropTypes.arrayOf(
        PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func]))
    ).isRequired,
    // Data to be displayed in the table
    tableData: PropTypes.arrayOf(
        PropTypes.objectOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
                PropTypes.instanceOf(Date),
                PropTypes.array,
                PropTypes.objectOf(
                    PropTypes.oneOfType([
                        PropTypes.string,
                        PropTypes.number,
                        PropTypes.bool,
                        PropTypes.instanceOf(Date),
                        PropTypes.array,
                        PropTypes.object,
                    ])
                ),
            ])
        )
    ).isRequired,
    // Data passed to the mobile list
    mobileListData: PropTypes.arrayOf(
        PropTypes.objectOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.func,
                PropTypes.bool,
                PropTypes.object,
            ])
        )
    ).isRequired,
    // Callback when table row is clicked
    onRowClick: PropTypes.func,
    // Callback when mobile action icon is clicked
    onMobileActionClick: PropTypes.func,
    // Custom width of the table
    customWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
