import { DatePicker, Modal } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PayrollApi from '../../api/payroll/payroll';
import { onAPIError } from '../../util/apiUtil';
import {
    payrollCreateAlreadyExistsErrorText,
    payrollCreateErrorText,
    payrollCreateHelperText,
    payrollDataFetchErrorText,
} from '../../util/longText';
import { displaySimpleErrorModal, InfoModalHeader } from '../global/ModalController';

const CreatePayroll = ({ onCancel, fetchPayrollData }) => {
    const dispatch = useDispatch();

    const [unavailableDates, setUnavailableDates] = useState([]);
    const [selectedDateRange, setSelectedDateRange] = useState(['', '']);
    const [submittingNewPayroll, setSubmittingNewPayroll] = useState(false);

    useEffect(() => {
        fetchUnavailableDates();
    }, []);

    const fetchUnavailableDates = async () => {
        const unavailableDates = await PayrollApi.getUnavailableDates().catch(() =>
            displaySimpleErrorModal(dispatch, payrollDataFetchErrorText)
        );

        setUnavailableDates([...unavailableDates]);
    };

    const createNewPayroll = () => {
        setSubmittingNewPayroll(true);

        PayrollApi.createPayroll(selectedDateRange[0], selectedDateRange[1])
            .then(() => {
                fetchPayrollData();
                onCancel();
            })
            .catch((e) => {
                setSubmittingNewPayroll(false);
                const errMsg = e.includes('are already payrolls')
                    ? payrollCreateAlreadyExistsErrorText
                    : payrollCreateErrorText;
                onAPIError(dispatch, errMsg);
            });
    };

    /* Checks if date should be disabled by checking each calendar date against
       the arrays of unavailble dates from the API */
    const disabledDates = (current) => {
        return unavailableDates.some((unavailableRange) =>
            moment(current).isBetween(
                moment(unavailableRange[0]),
                unavailableRange[1],
                'days',
                '[]'
            )
        );
    };

    const getModalContent = () => (
        <div>
            <div className="payroll-create__helper-text">{payrollCreateHelperText}</div>
            <div className="input-label">Date Range</div>
            <DatePicker.RangePicker
                size="middle"
                style={{ width: '100%' }}
                disabledDate={disabledDates}
                value={[
                    selectedDateRange[0].length ? moment(selectedDateRange[0]) : null,
                    selectedDateRange[1].length ? moment(selectedDateRange[1]) : null,
                ]}
                onCalendarChange={(val, dateStrings) => setSelectedDateRange(dateStrings)}
                status={selectedDateRange[0].length && selectedDateRange[1].length ? null : 'error'}
            />
        </div>
    );

    return (
        <Modal
            title={InfoModalHeader('Create New Payroll')}
            visible
            onOk={() => createNewPayroll()}
            onCancel={() => onCancel()}
            closable={false}
            keyboard={false}
            maskClosable={false}
            confirmLoading={submittingNewPayroll}
            okButtonProps={
                selectedDateRange[0].length && selectedDateRange[1].length ? {} : { disabled: true }
            }
            cancelButtonProps={submittingNewPayroll ? { disabled: true } : {}}
        >
            {getModalContent()}
        </Modal>
    );
};
export default CreatePayroll;
