export const getPageTitle = (path) => {
    const pathName = path.split('/')[1];
    switch (pathName) {
        case 'login':
            document.title = 'Western Xpress | Login';
            break;
        case 'dataentry':
            document.title = 'Western Xpress | Data Entry';
            break;
        case 'datamanagement':
            document.title = 'Western Xpress | Data Management';
            break;
        case 'drivers':
            document.title = 'Western Xpress | Drivers';
            break;
        case 'customers':
            document.title = 'Western Xpress | Customers';
            break;
        case 'tdms':
            document.title = 'Western Xpress | TDMs';
            break;
        case 'rateprofiles':
            document.title = 'Western Xpress | Rate Profiles';
            break;
        case 'users':
            document.title = 'Western Xpress | User Management';
            break;
        case 'invoices':
            document.title = 'Western Xpress | Invoices & Payroll';
            break;
        case 'settings':
            document.title = 'Western Xpress | Settings';
            break;
        default:
            document.title = 'Western Xpress';
    }
};

// Extract page title from the URL pathname (first word after first /)
export const getPageHeader = (location) => {
    const pagePath = location.pathname.split('/')[1];

    switch (pagePath) {
        case 'dataentry':
            return 'Data Entry';
        case 'datamanagement':
            return 'Data Management';
        case 'tdms':
            return 'TDMs';
        case 'rateprofiles':
            return 'Rate Profiles';
        case 'users':
            return 'User Management';
        case 'invoices':
            return 'Invoices & Payroll';
        default:
            return pagePath.slice(0, 1).toUpperCase() + pagePath.slice(1, pagePath.lenght);
    }
};
