import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { getUsersRolePermissions } from '@wx/common';

import { removeCachedToken, isLoggedIn, setAccessToken } from './authentication';
import { APP_ACTION_TYPES, MODAL_TYPES } from '../store/constants/appActionTypes';
import { shouldSee, PERMISSION_CONDITION } from './permissionsUtil';
import { userNotPermittedText } from './longText';
import { onAPIError } from './apiUtil';

/** Used for routes which require user to be logged in.
 * If user is logged in, the requested route will be displayed,
 * otherwise, user will be redirected to /login.
 * If location change is detected, logged in status will be checked
 * again,
 */
export const PrivateRoute = ({ component: Component, requiredPermissions, ...rest }) => {
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(isLoggedIn());
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        // Check if user still logged in
        setIsUserLoggedIn(isLoggedIn());
        if (!isLoggedIn()) {
            dispatch({ type: APP_ACTION_TYPES.SET_USER_DATA, payload: null });
            dispatch({ type: APP_ACTION_TYPES.SET_USER_PERMISSIONS, payload: [] });
            dispatch({ type: APP_ACTION_TYPES.TOGGLE_LOGGED_IN, payload: false });
            removeCachedToken();
        }

        // Check if user has permissions to see this page (direct URL entry)
        if (requiredPermissions && !shouldSee([...requiredPermissions], PERMISSION_CONDITION.OR)) {
            dispatch({
                type: APP_ACTION_TYPES.SET_DISPLAY_MODAL,
                payload: {
                    type: MODAL_TYPES.WARNING,
                    hideCancelButton: true,
                    okAction: () => {
                        dispatch({
                            type: APP_ACTION_TYPES.SET_DISPLAY_MODAL,
                            payload: null,
                        });
                    },
                    content: <div>{userNotPermittedText}</div>,
                },
            });
        }
    }, [location]);

    return requiredPermissions && !shouldSee([...requiredPermissions], PERMISSION_CONDITION.OR) ? (
        <Redirect to={{ pathname: '/' }} />
    ) : (
        <Route
            {...rest}
            render={(props) =>
                isUserLoggedIn ? <Component {...props} /> : <Redirect to={{ pathname: '/login' }} />
            }
        />
    );
};

/** Used for public routes which do not require authentication.
 * If user is logged in, they will be redirected to "/", otherewise
 * the requsted route will be returned.
 */
export const PublicRoute = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(isLoggedIn());

    useEffect(() => {
        const { pathname } = window.location;
        if (pathname.includes('postoauth') && !isLoggedIn()) {
            isSSOLogin();
        }
        return () => setIsUserLoggedIn(false);
    }, []);

    const isSSOLogin = () => {
        const { search } = window.location;
        const params = new URLSearchParams(search);
        const loginToken = params.get('token');
        const expiry = params.get('expiry');
        const user = JSON.parse(params.get('user'));
        const error = params.get('error');

        if (loginToken && expiry && user) {
            setAccessToken(loginToken, expiry);
            dispatch({
                type: APP_ACTION_TYPES.SET_USER_DATA,
                payload: user,
            });
            const userPermissions = getUsersRolePermissions(user.roles);
            dispatch({
                type: APP_ACTION_TYPES.SET_USER_PERMISSIONS,
                payload: [...userPermissions],
            });
            dispatch({
                type: APP_ACTION_TYPES.TOGGLE_LOGGED_IN,
                payload: true,
            });
            setIsUserLoggedIn(true);
        } else {
            if (error) {
                onAPIError(
                    dispatch,
                    `Looks like something went wrong during authorization: ${error}`
                );
            } else {
                onAPIError(dispatch, 'Authorization error, please contact support');
            }
            history.push('login');
        }
    };

    return (
        <Route
            {...rest}
            render={(props) =>
                isUserLoggedIn ? <Redirect to={{ pathname: '/' }} /> : <Component {...props} />
            }
        />
    );
};
