import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GrHistory } from 'react-icons/gr';
import { Tooltip } from 'antd';

import CustomerRatesApi from '../../api/rateProfiles/customerRates';
import { onAPIError, onAPISuccess } from '../../util/apiUtil';
import {
    customerRateArchivedErrorText,
    customerRateArchivedSuccessText,
    customerRateFetchDataErrorText,
    customerRateUpdateErrorText,
    customerRateUpdateSuccessText,
    newCustomerRateAddedErrorText,
    newCustomerRateAddedSuccessText,
} from '../../util/longText';
import { shouldSee } from '../../util/permissionsUtil';
import { displaySimpleErrorModal, getUserConfirmation } from '../global/ModalController';
import EntityRatesComponent from './EntityRatesComponent';
import { DEVICE_TYPE } from '../../store/constants/appActionTypes';

const CustomerRates = ({ viewHistory }) => {
    const dispatch = useDispatch();
    const deviceType = useSelector((state) => state.app.device);

    const [customerRates, setCustomerRates] = useState([]);

    const [displayAddModal, setDisplayAddModal] = useState(false);
    const [newRateName, setNewRateName] = useState('');

    const [displayUpdateModal, setDisplayUpdateModal] = useState(false);

    const [submittingNewRate, setSubmittingNewRate] = useState(false);
    const [submittingRateUpdate, setSubmittingRateUpdate] = useState(false);

    useEffect(() => {
        getCustomerRateData();
    }, []);

    const getCustomerRateData = async () => {
        CustomerRatesApi.getCustomerRates('', true, 0, 1000)
            .then((customerRatesData) => setCustomerRates(customerRatesData.data))
            .catch(() => displaySimpleErrorModal(dispatch, customerRateFetchDataErrorText));
    };

    const submitNewRate = () => {
        setSubmittingNewRate(true);

        CustomerRatesApi.createCustomerRate({
            profileName: newRateName,
            active: true,
        })
            .then(() => {
                setSubmittingNewRate(false);
                setDisplayAddModal(false);
                setNewRateName('');

                onAPISuccess(dispatch, newCustomerRateAddedSuccessText);
                getCustomerRateData();
            })
            .catch(() => {
                setSubmittingNewRate(false);
                onAPIError(dispatch, newCustomerRateAddedErrorText);
            });
    };

    const updateRate = (rate, updatedValues) => {
        setSubmittingRateUpdate(true);
        const updateRates = updatedValues.map((value) => ({
            rateId: value.rateId,
            label: value.label,
            rate: value.activeRate,
        }));

        CustomerRatesApi.updateCustomerRate({
            ...rate,
            serviceTypes: [...updateRates],
        })
            .then(() => {
                setSubmittingRateUpdate(false);
                setDisplayUpdateModal(false);

                onAPISuccess(dispatch, customerRateUpdateSuccessText);
                getCustomerRateData();
            })
            .catch(() => {
                setSubmittingRateUpdate(false);
                onAPIError(dispatch, customerRateUpdateErrorText);
            });
    };

    const archiveRate = (rateId) => {
        const proceedWithArchive = () =>
            CustomerRatesApi.archiveCustomerRate(rateId)
                .then(() => {
                    onAPISuccess(dispatch, customerRateArchivedSuccessText);
                    getCustomerRateData();
                })
                .catch(() => {
                    onAPIError(dispatch, customerRateArchivedErrorText);
                });

        getUserConfirmation(
            dispatch,
            'Are you sure you want to delete this rate?',
            proceedWithArchive
        );
    };

    return (
        <>
            {/* AUDIT VIEW */}
            {deviceType === DEVICE_TYPE.NOT_MOBILE && shouldSee(['USER_MANAGE_VIEW_AUDITS']) ? (
                <div className="tier-rates__audit__container">
                    <Tooltip title="View history of rate changes" placement="topRight">
                        <GrHistory className="tier-rates__audit__icon" onClick={viewHistory} />
                    </Tooltip>
                </div>
            ) : null}

            <EntityRatesComponent
                rates={customerRates}
                entityKey="customerRateProfile"
                typesKey="serviceTypes"
                displayAddModal={displayAddModal}
                setDisplayAddModal={setDisplayAddModal}
                newRateName={newRateName}
                setNewRateName={setNewRateName}
                submitNewRate={submitNewRate}
                submittingNewRate={submittingNewRate}
                displayUpdateModal={displayUpdateModal}
                setDisplayUpdateModal={setDisplayUpdateModal}
                updateRate={shouldSee(['CRP_UPDATE']) ? updateRate : null}
                submittingRateUpdate={submittingRateUpdate}
                archiveRate={shouldSee(['CRP_DELETE']) ? archiveRate : null}
                canCreate={shouldSee(['CRP_CREATE'])}
            />
        </>
    );
};
export default CustomerRates;
