import axios from 'axios';

import { getAuthHeader, getErrorMessage, getHost } from '../../util/apiUtil';
import { shouldSee } from '../../util/permissionsUtil';

const AUDITS_URL = `${getHost()}/audit`;

class AuditsApi {
    static getAudits(filters = null) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['USER_MANAGE_VIEW_AUDITS'])) {
                console.error('API ERROR | Audits: User not permitted');
                reject();
            }

            // Construct filter query
            let URL = AUDITS_URL;
            if (filters) {
                URL += '?';
                Object.entries(filters).forEach(([filterName, filterValue]) => {
                    if (['date', 'user'].includes(filterName)) {
                        URL += `${filterName}=${filterValue}&`;
                    } else {
                        // Category, sub-category and action filters are multiple choice
                        URL += `${filterName}=${filterValue.join(',')}&`;
                    }
                });
                URL = URL.slice(0, URL.length - 1);
            }

            axios
                .get(URL, { headers: getAuthHeader() })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Audits: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }
}
export default AuditsApi;
