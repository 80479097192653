import { useSelector } from 'react-redux';

export const TODO_ACTION_TYPES = Object.freeze({
    SET_INCOMPLETE_ENTITIES: 'SET_INCOMPLETE_ENTITIES',
    SET_INVOICES_NEEDING_APPROVAL: 'SET_INVOICES_NEEDING_APPROVAL',
    SET_TRUCKS_NEEDING_SUP_PAY: 'SET_TRUCKS_NEEDING_SUP_PAY',
    SET_INVOICES_FAILED_TO_SEND: 'SET_INVOICES_FAILED_TO_SEND',
    SET_DISPLAY_SUPPLEMENTAL_DATA_MODAL: 'SET_DISPLAY_SUPPLEMENTAL_DATA_MODAL',
    SET_INVOICES_ON_HOLD: 'SET_INVOICES_ON_HOLD',
});

export const getTotalTodoCount = () => {
    let total = 0;

    const incompleteEntities = useSelector((state) => state.todo.incompleteEntities);
    if (incompleteEntities) total += incompleteEntities.length;

    const invoicesNeedingApproval = useSelector((state) => state.todo.invoicesNeedingApproval);
    if (invoicesNeedingApproval) total += invoicesNeedingApproval.length;

    const trucksNeedingSupPay = useSelector((state) => state.todo.trucksNeedingSupPay);
    if (trucksNeedingSupPay) total += trucksNeedingSupPay.length;

    const invoicesFailedToSend = useSelector((state) => state.todo.invoicesFailedToSend);
    if (invoicesFailedToSend) total += invoicesFailedToSend.length;

    const invoicesOnHold = useSelector((state) => state.todo.invoicesOnHold);
    if (invoicesOnHold) total += invoicesOnHold.length;

    return total;
};
