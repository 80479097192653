import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PulseLoader from 'react-spinners/PulseLoader';

import AuthenticationApi from '../../api/authentication';

import { APP_ACTION_TYPES, DEVICE_TYPE, MODAL_TYPES } from '../../store/constants/appActionTypes';
import {
    passwordChangeInvalidCurrentPasswordText,
    passwordChangeSuccessText,
    passwordResetFailErrorText,
} from '../../util/longText';
import LabelledInput from '../reusable/LabelledInput';

const ResetPassword = () => {
    const dispatch = useDispatch();
    const deviceType = useSelector((state) => state.app.device);
    const userEmail = useSelector((state) => state.app.userData.email);

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatNewPassword, setRepeatNewPassword] = useState('');

    const [submittingPasswordChange, setSubmittingPasswordChange] = useState(false);

    const inputComponents = [
        {
            labelText: 'Current Password',
            value: currentPassword,
            onChangeAction: setCurrentPassword,
            isValid: true,
            validationText: '',
            isPassword: true,
        },
        {
            labelText: 'New Password',
            value: newPassword,
            onChangeAction: setNewPassword,
            isValid: newPassword.length > 5, // TODO: minimum password validation?
            validationText: 'Must be minmum of 6 characters',
            isPassword: true,
        },
        {
            labelText: 'Repeat New Password',
            value: repeatNewPassword,
            onChangeAction: setRepeatNewPassword,
            isValid: repeatNewPassword === newPassword,
            validationText: 'Passwords must match',
            isPassword: true,
        },
    ];

    const passwordInputsValid = () => newPassword.length > 5 && newPassword === repeatNewPassword;

    const submitPasswordChange = () => {
        setSubmittingPasswordChange(true);
        AuthenticationApi.changePassword(userEmail, currentPassword, newPassword)
            .then(() => {
                setSubmittingPasswordChange(false);
                setCurrentPassword('');
                setNewPassword('');
                setRepeatNewPassword('');

                dispatch({
                    type: APP_ACTION_TYPES.SET_DISPLAY_MODAL,
                    payload: {
                        type: MODAL_TYPES.SUCCESS,
                        hideCancelButton: true,
                        okAction: () => {
                            dispatch({
                                type: APP_ACTION_TYPES.SET_DISPLAY_MODAL,
                                payload: null,
                            });
                        },
                        content: <div>{passwordChangeSuccessText}</div>,
                    },
                });
            })
            .catch((e) => {
                setSubmittingPasswordChange(false);

                dispatch({
                    type: APP_ACTION_TYPES.SET_DISPLAY_MODAL,
                    payload: {
                        type: MODAL_TYPES.ERROR,
                        hideCancelButton: true,
                        okAction: () => {
                            dispatch({
                                type: APP_ACTION_TYPES.SET_DISPLAY_MODAL,
                                payload: null,
                            });
                        },
                        content: (
                            <div>
                                {e.includes('Unauthorized')
                                    ? passwordChangeInvalidCurrentPasswordText
                                    : passwordResetFailErrorText}
                            </div>
                        ),
                    },
                });
            });
    };

    return (
        <>
            <div
                className={`reset-password__container${
                    deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                }`}
            >
                {inputComponents.map((comp) => (
                    <LabelledInput
                        key={comp.labelText}
                        labelText={comp.labelText}
                        value={comp.value}
                        onChangeAction={comp.onChangeAction}
                        withValidation
                        isValid={comp.isValid}
                        validationText={comp.validationText}
                        isPassword={comp.isPassword}
                    />
                ))}
            </div>

            <div className="reset-password__button__container">
                {!submittingPasswordChange ? (
                    <div
                        className={`standard-button${!passwordInputsValid() ? '--disabled' : ''}${
                            deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                        }`}
                        onClick={passwordInputsValid() ? () => submitPasswordChange() : null}
                    >
                        SAVE
                    </div>
                ) : (
                    // SPINNER - WHILE SUBMITTING
                    <PulseLoader color="#0e76bc" />
                )}
            </div>
        </>
    );
};

export default ResetPassword;
