/* eslint-disable no-unreachable */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';

import Breadcrumbs from '../../../component/reusable/Breadcrumbs';

import CustomersLogo from '../../../images/customer.png';
import { DEVICE_TYPE } from '../../../store/constants/appActionTypes';
import CustomerForm from './CustomerForm';
import CustomersApi from '../../../api/dataManagement/customers';
import CustomerRatesApi from '../../../api/rateProfiles/customerRates';
import {
    customerRateFetchDataErrorText,
    customersFetchDataErrorText,
    customersSaveDataErrorText,
    customersSaveDataSuccessText,
    customersUpdateDataErrorText,
    customersUpdateDataSuccessText,
    managersFetchDataErrorText,
    organizationsFetchDataErrorText,
} from '../../../util/longText';
import EntityFormComponent from '../../../component/reusable/EntityFormComponent';
import { onAPIError, onAPISuccess } from '../../../util/apiUtil';
import { displaySimpleErrorModal } from '../../../component/global/ModalController';
import { shouldSee } from '../../../util/permissionsUtil';

const BREADCRUMB_LINKS = [
    { title: 'Data Management', href: `${(window.location, origin)}/datamanagement` },
    { title: 'Customers', href: `${(window.location, origin)}/customers` },
    { title: 'Customer', href: null },
];

const DEFAULT_FORM_DATA = {
    orgName: '',
    faxNumber: '',
    manager: '',
    contactEmails: [''],
    rateProfile: null,
    active: true,
    contactInfo: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        addressLineOne: '',
        addressLineTwo: '',
        city: '',
        postalCode: '',
        province: '',
        country: 'CANADA',
    },
    billingFrequency: 'daily',
};

const Customer = ({ match }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const deviceType = useSelector((state) => state.app.device);
    const newCustomer = match.params.id === 'new';

    // Used to revert form to saved state when user clicks cancel
    const [savedCustomerData, setSavedCustomerData] = useState({});

    const [formData, setFormData] = useState(DEFAULT_FORM_DATA);
    const [customerRates, setCustomerRates] = useState([]);
    const [organizationOptions, setOrganizationOptions] = useState([]);
    const [managerOptions, setManagerOptions] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (!newCustomer) {
            setIsFetching(true);

            CustomersApi.getCustomerById(match.params.id)
                .then((customerData) => {
                    setSavedCustomerData({ ...customerData });
                    setFormData({ ...customerData });
                    setIsFetching(false);
                })
                .catch(() =>
                    displaySimpleErrorModal(dispatch, customersFetchDataErrorText, () =>
                        history.push('/datamanagement/customers')
                    )
                );
        }

        if (shouldSee(['CUSTOMER_LIST_ORGS'])) {
            CustomersApi.getOrganizations()
                .then((organizations) => {
                    const orgOptions = organizations.map((org) => ({ value: org, label: org }));
                    orgOptions.unshift({
                        value: null,
                        label: 'Select option or type...',
                        isDisabled: true,
                    });
                    setOrganizationOptions(orgOptions);
                })
                .catch(() => displaySimpleErrorModal(dispatch, organizationsFetchDataErrorText));
        }

        if (shouldSee(['CUSTOMER_LIST_MANAGERS'])) {
            CustomersApi.getManagers()
                .then((managers) => {
                    const managerOptions = managers.map((manager) => ({
                        value: manager,
                        label: manager,
                    }));
                    managerOptions.unshift({
                        value: null,
                        label: 'Select option or type...',
                        isDisabled: true,
                    });
                    setManagerOptions(managerOptions);
                })
                .catch(() => displaySimpleErrorModal(dispatch, managersFetchDataErrorText));
        }

        if (shouldSee(['CRP_READ_MANY'])) {
            CustomerRatesApi.getCustomerRates('', false, 0, 10000)
                .then((customerRates) => {
                    setCustomerRates(customerRates.data);
                })
                .catch(() => displaySimpleErrorModal(dispatch, customerRateFetchDataErrorText));
        }
    }, []);

    const isRequiredDataEntered = () => {
        if (formData.orgName.length <= 1) {
            return false;
        }
        if (formData.manager.length <= 1) {
            return false;
        }
        if (formData.contactInfo.firstName.length <= 1) {
            return false;
        }
        if (formData.contactInfo.lastName.length <= 1) {
            return false;
        }
        if (
            !validator.isMobilePhone(formData.contactInfo.phoneNumber, ['en-CA', 'en-US'], {
                strictMode: true,
            })
        ) {
            return false;
        }
        if (
            !validator.isMobilePhone(formData.faxNumber, ['en-CA', 'en-US'], {
                strictMode: true,
            })
        ) {
            return false;
        }
        for (let i = 0; i < formData.contactEmails.length; i++) {
            if (!validator.isEmail(formData.contactEmails[i])) {
                return false;
            }
        }
        if (formData.contactInfo.addressLineOne.length <= 1) {
            return false;
        }
        if (formData.contactInfo.city.length <= 2) {
            return false;
        }
        if (formData.contactInfo.province === '') {
            return false;
        }
        if (
            !validator.isPostalCode(
                formData.contactInfo.postalCode,
                formData.contactInfo.country === 'CANADA' ? 'CA' : 'US'
            )
        ) {
            return false;
        }
        if (formData.rateProfile === null) {
            return false;
        }

        return true;
    };

    const saveCustomer = (isNewCustomer) => {
        setIsSaving(true);
        // Extract rate profile ID
        formData.customerRateProfileId = formData.rateProfile.customerRateProfileId;

        if (isNewCustomer) {
            // Flow to save new customer record
            CustomersApi.createCustomer(formData)
                .then(() => {
                    setIsSaving(false);
                    onAPISuccess(dispatch, customersSaveDataSuccessText);
                    history.push('/customers');
                })
                .catch(() => {
                    setIsSaving(false);
                    onAPIError(dispatch, customersSaveDataErrorText);
                });
        } else {
            // Flow to update record
            CustomersApi.updateCustomer(formData)
                .then(() => {
                    setIsSaving(false);
                    onAPISuccess(dispatch, customersUpdateDataSuccessText);
                    history.push('/customers');
                })
                .catch(() => {
                    setIsSaving(false);
                    onAPIError(dispatch, customersUpdateDataErrorText);
                });
        }
    };

    return (
        <div
            className={`customer__container${deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''}`}
        >
            <Breadcrumbs links={BREADCRUMB_LINKS} />

            <EntityFormComponent
                logo={CustomersLogo}
                title={
                    newCustomer
                        ? 'New Customer'
                        : isFetching
                        ? 'Loading...'
                        : `${formData.contactInfo.firstName} ${formData.contactInfo.lastName}`
                }
                editAction={
                    newCustomer || isEditing || !shouldSee(['CUSTOMER_UPDATE'])
                        ? null
                        : () => setIsEditing(true)
                }
                isFetching={isFetching}
                isEditing={isEditing}
                isSaving={isSaving}
                formComponent={
                    <CustomerForm
                        formData={formData}
                        customerRates={customerRates}
                        setFormData={setFormData}
                        isEditing={newCustomer || isEditing}
                        formStyle={deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''}
                        organizationOptions={organizationOptions}
                        managerOptions={managerOptions}
                    />
                }
                newEntity={newCustomer}
                onCancelClick={
                    newCustomer
                        ? () => history.push('/datamanagement')
                        : () => {
                              setIsEditing(false);
                              setFormData({ ...savedCustomerData });
                          }
                }
                onSaveClick={() => saveCustomer(newCustomer)}
                formValid={isRequiredDataEntered()}
            />
        </div>
    );
};
export default Customer;
