import { Select } from 'antd';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import validator from 'validator';

import { DEVICE_TYPE } from '../../store/constants/appActionTypes';
import { beautifyTextValue } from '../../util/formUtil';
import { editLockedInvoiceHelperText, saveLockedInvoiceHelperText } from '../../util/longText';
import { getUserConfirmation } from '../global/ModalController';
import LabelledInput from '../reusable/LabelledInput';
import SimpleDetailsForm from '../reusable/SimpleDetailsForm';
import TruckServices from './TruckServices';

const { Option } = Select;

const EMPTY_ADDRESS = {
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    province: '',
    country: 'CANADA',
    postalCode: '',
};
const LINE_HAUL_STATUS_OPTIONS = ['single', 'team', 'n/a', 'hv_team', 'shared'];

const SingleInvoiceForm = ({
    deviceType,
    invoiceData,
    customerRecords,
    setInvoiceValue,
    updateInvoiceServices,
    updateInvoiceServiceValue,
    payTypes,
    serviceTypes,
    isInvoiceComplete,
    submitCreateInvoice,
    isSubmitting,
    isEditing,
    setIsEditing,
    selectedInvoiceIndex,
    setFormDataToMatchSelectedInvoice,
    resetInvoiceServices,
    submitEditInvoice,
    userHasEditAccess,
    truckStatus,
}) => {
    const dispatch = useDispatch();
    const formStyle = deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : '';
    const billToCustomer = customerRecords.find(
        (record) => `${record.customerId}` === `${invoiceData.billToCustomerId}`
    );
    const isExistingInvoice = selectedInvoiceIndex !== null;
    let firstElementRef = null;

    useEffect(() => {
        firstElementRef.focus();
        const form = document.getElementById('single-invoice-form-container');
        form.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, []);

    // Save new invoice on ctrl+enter
    const handleEnterKey = useCallback(
        (e) => {
            const { keyCode, ctrlKey } = e;
            if (ctrlKey && keyCode === 13) {
                if (truckStatus !== 'hard_locked' && isInvoiceComplete && !isSubmitting) {
                    if (isEditing) {
                        submitEditInvoice();
                    } else {
                        if (
                            (invoiceData.truck && invoiceData.truck.status === 'locked') ||
                            truckStatus === 'locked'
                        ) {
                            getUserConfirmation(dispatch, saveLockedInvoiceHelperText, () =>
                                submitCreateInvoice()
                            );
                        } else {
                            submitCreateInvoice();
                        }
                    }
                }
            }
        },
        [invoiceData]
    );

    useEffect(() => {
        window.addEventListener('keydown', handleEnterKey);
        return () => {
            window.removeEventListener('keydown', handleEnterKey);
        };
    }, [handleEnterKey]);

    return (
        <div className="single-invoice-form__container">
            {/* INVOICE DETAILS */}
            <div
                className={`single-invoice-form__invoice-details__container${formStyle}`}
                id="single-invoice-form-container"
            >
                {/* INVOICE REFERENCE */}
                <LabelledInput
                    labelText="Invoice Ref"
                    value={invoiceData.invoiceReference}
                    customWidth={deviceType === DEVICE_TYPE.MOBILE ? '100%' : '20%'}
                    onChangeAction={(value) => setInvoiceValue({ invoiceReference: value })}
                    withValidation={!isExistingInvoice || isEditing}
                    isValid={invoiceData.invoiceReference.length > 0}
                    validationText="Enter a value"
                    isDisabled={(isExistingInvoice && !isEditing) || !userHasEditAccess}
                    setRef={(r) => {
                        firstElementRef = r;
                    }}
                />

                {/* LINE HAUL STATUS */}
                <div
                    style={deviceType === DEVICE_TYPE.MOBILE ? { width: '100%' } : { width: '20%' }}
                >
                    <div className="input-label" style={{ marginTop: 25 }}>
                        Line Haul
                    </div>
                    <Select
                        size="large"
                        style={{ width: '100%' }}
                        placeholder="Select one"
                        value={invoiceData.invoiceLineHaulStatus}
                        onChange={(value) => {
                            setInvoiceValue({
                                invoiceLineHaulStatus: value,
                            });
                        }}
                        disabled={(isExistingInvoice && !isEditing) || !userHasEditAccess}
                    >
                        {LINE_HAUL_STATUS_OPTIONS.map((opt) => (
                            <Option key={`${opt}`}>{beautifyTextValue(opt)}</Option>
                        ))}
                    </Select>
                </div>

                {/* INCOMPLETE REASON */}
                <LabelledInput
                    labelText="Incomplete Reason"
                    customWidth={deviceType === DEVICE_TYPE.MOBILE ? '100%' : '20%'}
                    value={invoiceData.incompleteReason}
                    onChangeAction={(value) => setInvoiceValue({ incompleteReason: value })}
                    isDisabled={(isExistingInvoice && !isEditing) || !userHasEditAccess}
                />

                {/* SPECIAL SERVICE */}
                <LabelledInput
                    labelText="Special Service"
                    customWidth={deviceType === DEVICE_TYPE.MOBILE ? '100%' : '20%'}
                    value={invoiceData.specialService}
                    onChangeAction={(value) => setInvoiceValue({ specialService: value })}
                    isDisabled={(isExistingInvoice && !isEditing) || !userHasEditAccess}
                />
            </div>

            {/* SERVICES */}
            <div className={`single-invoice-form__invoice-details__container${formStyle}`}>
                <TruckServices
                    deviceType={deviceType}
                    invoiceData={invoiceData}
                    payTypes={payTypes}
                    serviceTypes={serviceTypes}
                    updateInvoiceServices={updateInvoiceServices}
                    updateInvoiceServiceValue={updateInvoiceServiceValue}
                    readOnly={(isExistingInvoice && !isEditing) || !userHasEditAccess}
                />
            </div>

            {/* BILLING DETAILS */}
            <div className={`single-invoice-form__billing-details__container${formStyle}`}>
                {/* BILL TO */}
                <div
                    className={`single-invoice-form__billing-details__container--inner${formStyle}`}
                >
                    <div
                        className={`single-invoice-form__billing-details__bill-to__container${formStyle}`}
                    >
                        <div className="input-label" style={{ fontSize: 30 }}>
                            Bill To
                        </div>
                        <div
                            className={`single-invoice-form__billing-details__bill-to__select${formStyle}`}
                        >
                            <Select
                                size="large"
                                placeholder="Select billable customer..."
                                value={
                                    billToCustomer
                                        ? `${billToCustomer.contactInfo.firstName} ${billToCustomer.contactInfo.lastName} | ${billToCustomer.orgName}`
                                        : null
                                }
                                status={invoiceData.billToCustomerId ? null : 'error'}
                                style={
                                    deviceType === DEVICE_TYPE.MOBILE
                                        ? { width: '100%' }
                                        : { width: 600 }
                                }
                                onChange={(_value, option) => {
                                    const customerId = option.key.split('_')[1];
                                    setInvoiceValue({ billToCustomerId: customerId });
                                }}
                                disabled={(isExistingInvoice && !isEditing) || !userHasEditAccess}
                            >
                                {customerRecords.map((customer) => (
                                    <Option
                                        key={`billtocustomer_${customer.customerId}`}
                                        value={`${customer.contactInfo.firstName} ${customer.contactInfo.lastName} | ${customer.orgName}`}
                                    >
                                        {`${customer.contactInfo.firstName} ${customer.contactInfo.lastName} | ${customer.orgName}`}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>

                    {/* READ ONLY BILLING DETAILS */}
                    <SimpleDetailsForm
                        formData={
                            billToCustomer
                                ? { ...billToCustomer.contactInfo }
                                : { ...EMPTY_ADDRESS }
                        }
                        formStyle={deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''}
                    />
                </div>
            </div>

            {/* PROFIT */}
            <div className={`single-invoice-form__profit__container${formStyle}`}>
                {/* REVENUE */}
                <LabelledInput
                    labelText="REVENUE COLLECTED"
                    value={invoiceData.revenueCollected}
                    onChangeAction={(value) => setInvoiceValue({ revenueCollected: value })}
                    withValidation={!isExistingInvoice || isEditing}
                    isValid={validator.isCurrency(invoiceData.revenueCollected)}
                    validationText="Enter a valid amount"
                    addonBefore="$"
                    isDisabled={(isExistingInvoice && !isEditing) || !userHasEditAccess}
                />
            </div>

            {/* CREATE/EDIT BUTTON */}
            {(isEditing || !isExistingInvoice) && userHasEditAccess ? (
                <div className={`single-invoice-form__save-invoice-button__container${formStyle}`}>
                    {isEditing ? (
                        <div
                            className={`standard-button${isSubmitting ? '--disabled' : ''}${
                                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                            }`}
                            style={deviceType === DEVICE_TYPE.MOBILE ? {} : { marginRight: 10 }}
                            onClick={
                                isSubmitting
                                    ? null
                                    : () => {
                                          setIsEditing(false);
                                          setFormDataToMatchSelectedInvoice();
                                          resetInvoiceServices();
                                      }
                            }
                        >
                            CANCEL
                        </div>
                    ) : null}
                    {truckStatus !== 'hard_locked' ? (
                        <div
                            className={`standard-button--double${
                                !isInvoiceComplete || isSubmitting ? '--disabled' : ''
                            }${deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''}`}
                            onClick={
                                !isInvoiceComplete || isSubmitting
                                    ? null
                                    : isEditing
                                    ? () => submitEditInvoice()
                                    : (invoiceData.truck &&
                                          invoiceData.truck.status === 'locked') ||
                                      truckStatus === 'locked'
                                    ? () =>
                                          getUserConfirmation(
                                              dispatch,
                                              saveLockedInvoiceHelperText,
                                              () => submitCreateInvoice()
                                          )
                                    : () => submitCreateInvoice()
                            }
                        >
                            <span>SAVE INVOICE</span>
                            <span>(CTRL + ENTER)</span>
                        </div>
                    ) : null}
                </div>
            ) : !isEditing && isExistingInvoice && userHasEditAccess ? (
                invoiceData.truck && invoiceData.truck.status !== 'hard_locked' ? (
                    <div
                        className={`single-invoice-form__save-invoice-button__container${formStyle}`}
                    >
                        <div
                            className={`standard-button${
                                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                            }`}
                            onClick={
                                invoiceData.truck && invoiceData.truck.status === 'locked'
                                    ? () =>
                                          getUserConfirmation(
                                              dispatch,
                                              editLockedInvoiceHelperText,
                                              () => setIsEditing(true)
                                          )
                                    : () => setIsEditing(true)
                            }
                        >
                            EDIT INVOICE
                        </div>
                    </div>
                ) : null
            ) : null}
        </div>
    );
};

export default SingleInvoiceForm;
