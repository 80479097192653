import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { DEVICE_TYPE } from '../../store/constants/appActionTypes';

const EntityHeader = ({ logo, title, editAction }) => {
    const deviceType = useSelector((state) => state.app.device);

    return (
        <div className="entity-header__container">
            <div
                className={`entity-header__container--left${
                    deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                }`}
            >
                {/* LOGO (not for mobile) */}
                {deviceType === DEVICE_TYPE.NOT_MOBILE ? (
                    <div className="entity-header__logo__container">
                        <img className="entity-header__logo__img" src={logo} alt="icon" />
                    </div>
                ) : null}

                {/* TITLE */}
                <div
                    className={`entity-header__title${
                        deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                    }`}
                >
                    {title}
                </div>
            </div>
            <div className="entity-header__container--right">
                {editAction ? (
                    <Tooltip title="Edit">
                        <EditOutlined
                            style={{
                                fontSize: 40,
                                cursor: 'pointer',
                            }}
                            onClick={editAction}
                        />
                    </Tooltip>
                ) : null}
            </div>
        </div>
    );
};
export default EntityHeader;
