import { EditOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PulseLoader from 'react-spinners/PulseLoader';

import TrucksApi from '../../api/dataEntry/trucks';
import CustomersApi from '../../api/dataManagement/customers';
import { displaySimpleErrorModal } from '../../component/global/ModalController';
import Breadcrumbs from '../../component/reusable/Breadcrumbs';
import { APP_ACTION_TYPES, DEVICE_TYPE } from '../../store/constants/appActionTypes';
import { customerDataFetchErrorText, invoiceDataFetchErrorText } from '../../util/longText';
import { shouldSee } from '../../util/permissionsUtil';
import TruckInvoiceTemplate from '../../component/invoices/TruckInvoiceTemplate';

const BREADCUMB_LINKS = [
    { title: 'All Invoices', href: `${(window.location, origin)}/invoices#truck-invoices` },
    { title: 'Truck Invoice', href: null },
];

const SingleTruckInvoice = ({ match }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const deviceType = useSelector((state) => state.app.device);
    const invoiceId = match.params.id;

    const [invoiceData, setInvoicesData] = useState(null);
    const [customerData, setCustomerData] = useState(null);

    useEffect(() => {
        fetchInvoiceData();
    }, []);

    const fetchInvoiceData = () => {
        if (invoiceId) {
            TrucksApi.getInvoiceById(invoiceId)
                .then((data) => {
                    setInvoicesData({ ...data });

                    const customerId = data.billToCustomerId;

                    // Some roles may have access to read invoice data but not customer data
                    if (shouldSee(['CUSTOMER_READ'])) {
                        CustomersApi.getCustomerById(customerId)
                            .then((data) => {
                                setCustomerData({ ...data });
                            })
                            .catch(() =>
                                displaySimpleErrorModal(dispatch, invoiceDataFetchErrorText)
                            );
                    }
                })
                .catch(() => displaySimpleErrorModal(dispatch, customerDataFetchErrorText));
        }
    };

    return (
        <div
            className={`single-truck-invoice__container${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
        >
            <Breadcrumbs links={BREADCUMB_LINKS} />

            {/* LOADING SPINNER */}
            {!invoiceData ? (
                <div className="centered-container" style={{ margin: '200px 0' }}>
                    <PulseLoader color="#0e76bc" />
                </div>
            ) : (
                <>
                    <div className="single-truck-invoice__actions-container">
                        {shouldSee(['INVOICE_UPDATE']) ? (
                            <Tooltip title="Edit">
                                <EditOutlined
                                    style={{
                                        fontSize: 35,
                                        cursor: 'pointer',
                                    }}
                                    onClick={
                                        invoiceData
                                            ? () => {
                                                  dispatch({
                                                      type: APP_ACTION_TYPES.SET_EDITING_INVOICE,
                                                      payload: {
                                                          ...invoiceData,
                                                      },
                                                  });
                                                  history.push('/dataentry');
                                              }
                                            : null
                                    }
                                />
                            </Tooltip>
                        ) : null}
                    </div>
                    <TruckInvoiceTemplate invoiceData={invoiceData} customerData={customerData} />
                </>
            )}
        </div>
    );
};
export default SingleTruckInvoice;
