import io from 'socket.io-client';
import cookie from 'react-cookies';

import { getHost } from './apiUtil';

let client;

export const initSocket = () => {
    const host = getHost();
    const accessToken = cookie.load('accessToken');

    client = io(host, {
        auth: {
            token: accessToken,
        },
    });
    client.connect();

    return client;
};

export const closeSocket = () => {
    client.close();
};

export const getSocket = () => {
    return client;
};
