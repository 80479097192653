import { TODO_ACTION_TYPES } from '../constants/todoActionTypes';

const defaultState = {
    incompleteEntities: [],
    invoicesNeedingApproval: [],
    trucksNeedingSupPay: [],
    invoicesFailedToSend: [],
    invoicesOnHold: [],
    supplementalDataModal: null,
};

/* IMPORTANT: When adding todo category, add it to total count in
   src>store>constants>todoActionTypes.js */
const todoReducer = (state = defaultState, action) => {
    switch (action.type) {
        case TODO_ACTION_TYPES.SET_INCOMPLETE_ENTITIES:
            return {
                ...state,
                incompleteEntities: [...action.payload],
            };
        case TODO_ACTION_TYPES.SET_INVOICES_NEEDING_APPROVAL:
            return {
                ...state,
                invoicesNeedingApproval: [...action.payload],
            };
        case TODO_ACTION_TYPES.SET_TRUCKS_NEEDING_SUP_PAY:
            return {
                ...state,
                trucksNeedingSupPay: [...action.payload],
            };
        case TODO_ACTION_TYPES.SET_INVOICES_FAILED_TO_SEND:
            return {
                ...state,
                invoicesFailedToSend: [...action.payload],
            };
        case TODO_ACTION_TYPES.SET_INVOICES_ON_HOLD:
            return {
                ...state,
                invoicesOnHold: [...action.payload],
            };
        case TODO_ACTION_TYPES.SET_DISPLAY_SUPPLEMENTAL_DATA_MODAL:
            return {
                ...state,
                supplementalDataModal: action.payload,
            };
        default:
            return state;
    }
};

export default todoReducer;
