import axios from 'axios';
import { getAuthHeader, getErrorMessage, getHost } from '../../util/apiUtil';
import { shouldSee } from '../../util/permissionsUtil';

const DRIVER_RATES_URL = `${getHost()}/rates/driver`;

class DriverRatesApi {
    static getDriverRates(profileNameLike = '', includeInactive = true, skip = 0, take = 10) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['DRP_READ_MANY'])) {
                console.error('API ERROR | Driver Rates: User not permitted');
                reject();
            }

            let queriedUrl = `${DRIVER_RATES_URL}?take=${take}&skip=${skip}&includeInactive=${includeInactive}`;
            if (profileNameLike.length) {
                queriedUrl += `&profileNameLike=${profileNameLike}`;
            }

            axios
                .get(queriedUrl, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Driver Rates: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static getDriverRateById(id) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['DRP_READ'])) {
                console.error('API ERROR | Driver Rates: User not permitted');
                reject();
            }

            axios
                .get(`${DRIVER_RATES_URL}/${id}`, {
                    headers: getAuthHeader(),
                })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Driver Rates: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static createDriverRate(driverRateData) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['DRP_CREATE'])) {
                console.error('API ERROR | Driver Rates: User not permitted');
                reject();
            }

            axios
                .post(
                    `${DRIVER_RATES_URL}`,
                    { ...driverRateData },
                    {
                        headers: getAuthHeader(),
                    }
                )
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Driver Rates: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static updateDriverRate(driverRateData) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['DRP_UPDATE'])) {
                console.error('API ERROR | Driver Rates: User not permitted');
                reject();
            }

            axios
                .patch(DRIVER_RATES_URL, { ...driverRateData }, { headers: getAuthHeader() })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Driver Rates: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }

    static archiveDriverRate(driverRateId) {
        return new Promise((resolve, reject) => {
            if (!shouldSee(['DRP_DELETE'])) {
                console.error('API ERROR | Driver Rates: User not permitted');
                reject();
            }

            axios
                .delete(`${DRIVER_RATES_URL}/${driverRateId}`, { headers: getAuthHeader() })
                .then((data) => resolve(data.data))
                .catch((error) => {
                    console.error(`API ERROR | Driver Rates: ${getErrorMessage(error)}`);
                    reject();
                });
        });
    }
}
export default DriverRatesApi;
