import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PulseLoader from 'react-spinners/PulseLoader';

import { DEVICE_TYPE } from '../../store/constants/appActionTypes';
import EntityHeader from '../dataManagement/EntityHeader';

/** Used to display the driver/customer view/edit form.
 * Includes the header with logo, title and edit action.
 * See prop types at the end of file for description.
 */
const EntityFormComponent = ({
    logo,
    title,
    editAction,
    isFetching,
    isEditing,
    isSaving,
    formComponent,
    newEntity,
    onCancelClick,
    onSaveClick,
    formValid,
}) => {
    const deviceType = useSelector((state) => state.app.device);

    return (
        <div
            className={`entity-form-container__content__container${
                deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
            }`}
        >
            {/* HEADER */}
            <EntityHeader logo={logo} title={title} editAction={editAction} />

            {isFetching ? (
                <div className="entity-form-container__content__spinner__container">
                    <PulseLoader color="#0e76bc" />
                </div>
            ) : (
                // FORM
                <div
                    className={`entity-form-container__content__form__container${
                        deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                    }`}
                >
                    {/* FORM */}
                    {formComponent}

                    {/* BUTTONS */}
                    {newEntity || isEditing ? (
                        <div
                            className={
                                deviceType === DEVICE_TYPE.NOT_MOBILE
                                    ? 'entity-form-container__content__form__buttons__outer-container'
                                    : ''
                            }
                        >
                            <div
                                className={`entity-form-container__content__form__buttons__container${
                                    deviceType === DEVICE_TYPE.MOBILE ? '--mobile' : ''
                                }`}
                            >
                                <div
                                    className="standard-button--empty"
                                    style={{ width: '48%' }}
                                    onClick={onCancelClick}
                                >
                                    CANCEL
                                </div>
                                <div
                                    className={`standard-button${
                                        isSaving || !formValid ? '--disabled' : ''
                                    }`}
                                    style={{ width: '48%' }}
                                    onClick={isSaving || !formValid ? null : onSaveClick}
                                >
                                    SAVE
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            )}
        </div>
    );
};
export default EntityFormComponent;

EntityFormComponent.propTypes = {
    // The logo on top of the form
    logo: PropTypes.string.isRequired,
    /* Title above the form, used to display entity name or
       any message such as new or loading... */
    title: PropTypes.string.isRequired,
    // Callback when edit icon is clicked
    editAction: PropTypes.func,
    // Check if data is being fetched
    isFetching: PropTypes.bool.isRequired,
    // Check if form is in editing state
    isEditing: PropTypes.bool.isRequired,
    // Check if form is in saving state
    isSaving: PropTypes.bool.isRequired,
    // Actual form component containing inputs
    formComponent: PropTypes.element.isRequired,
    // Check whether displayin existing entity or creating a new one
    newEntity: PropTypes.bool.isRequired,
    // Callback when form cancel is clicked
    onCancelClick: PropTypes.func.isRequired,
    // Callback when form save is clicked
    onSaveClick: PropTypes.func.isRequired,
    // If required data is entered and valid
    formValid: PropTypes.bool.isRequired,
};
